import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { WhiteSpaceValidator } from 'src/app/_helpers';
import { ConfirmationDialogComponent } from 'src/app/shared-dialog/confirmation-dialog/confirmation-dialog.component';
import { TextEditorConfig } from '../../../_helpers/text-editor-config';
import { TransactionService } from '../../../_services/transaction.service';


export class NoteTemplateModel {
  NoteTemplateID?: number;
  NoteCode: string;
  Note: string;

}
@Component({
  selector: 'cxr-add-note-template',
  templateUrl: './add-note-template.component.html',
  styleUrls: ['./add-note-template.component.scss']
})

export class AddEditNoteTemplateComponent implements OnInit {
  NoteTemplateForm: UntypedFormGroup;
  submitted: boolean = false;
  modules: {} = TextEditorConfig.shortModule;
  multipleDataAdded: any = null;




  constructor (
    public _dialog: MatDialog,
    private _fb: UntypedFormBuilder,
    private _transactionService: TransactionService,
    private _dialogRef: MatDialogRef<AddEditNoteTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NoteTemplateModel,
    private translate: TranslateService


  ) {
    //Translate for Localization
    // translate.addLangs(Localisation.supportLanguages);
    // const browserLang = GetUserObject.getLanguage();
    // translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
    _dialogRef.disableClose = true;
    this.formInit(new NoteTemplateModel());



  }

  ngOnInit(): void {
    if (this.data) {
      this.formInit(this.data);
    }
  }


  //Data Form 
  formInit(val: NoteTemplateModel) {
    this.NoteTemplateForm = this._fb.group({
      NoteTemplateID: new UntypedFormControl(val.NoteTemplateID),
      NoteCode: new UntypedFormControl(val.NoteCode, [Validators.required, WhiteSpaceValidator.cannotContainSpace]),
      Note: new UntypedFormControl(val.Note, [Validators.required])
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.NoteTemplateForm.controls; }

  //Sumbit data form
  onSubmit(val: boolean) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.NoteTemplateForm.invalid) {
      return;
    }

    this._transactionService.fnNoteTemplateUpsert(this.NoteTemplateForm.value).subscribe(
      data => {
        if (data['Success'] && !val) {
          this._dialogRef.close(data);
        }
        else if (data['Success'] && val) {
          this.submitted = false;
          this.multipleDataAdded = data;
          this.NoteTemplateForm.reset();
        }
      });

  }//Close popup Function 
  close() {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: null,
        buttonText: {
          ok: 'Ignore and Continue',
          cancel: 'Back to Form'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed && !this.multipleDataAdded) {
        this._dialogRef.close(null);
      }
      else if (confirmed && this.multipleDataAdded) {
        this._dialogRef.close(this.multipleDataAdded);
      }
    });
  }

}
