import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { MaterialTimePickerModule } from '@candidosales/material-time-picker';
// Import your library
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChartModule } from 'angular-highcharts';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { CarouselModule, ModalModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
//import { NgxPayPalModule } from 'ngx-paypal';
import { AccountComponent } from 'projects/cxr-crm/src/app/pages/manage-contact/transactions/account/account.component';
import { CashComponent } from 'projects/cxr-crm/src/app/pages/manage-contact/transactions/cash/cash.component';
import { ChequeComponent } from 'projects/cxr-crm/src/app/pages/manage-contact/transactions/cheque/cheque.component';
import { EwireComponent } from 'projects/cxr-crm/src/app/pages/manage-contact/transactions/ewire/ewire.component';
import { InvoicePreviewComponent } from 'projects/cxr-crm/src/app/pages/manage-contact/transactions/invoice-preview/invoice-preview.component';
import { InvoicesComponent } from 'projects/cxr-crm/src/app/pages/manage-contact/transactions/invoices/invoices.component';
import { TransactionsComponent } from 'projects/cxr-crm/src/app/pages/manage-contact/transactions/transactions.component';
import { WireComponent } from 'projects/cxr-crm/src/app/pages/manage-contact/transactions/wire/wire.component';
import { translationUrl } from 'projects/cxr-crm/src/app/translationurl';
import { PqgridComponent } from '../pqgrid.component';
import { SharedDialogModule } from '../shared-dialog/shared-dialog.module';
import { PipesModule } from '../theme/pipes/pipes.module';
import { InputFeildComponent } from './_generic/input-feild/input-feild.component';
import { SortByPipe } from './_pipes/sort-by.pipe';
import { AlertService } from './_services/alert.service';
import { NotificationService } from './_services/notification.service';
import { MaterialModule } from './material.module';
import { UnderConstructionComponent } from './under-construction/under-construction.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, translationUrl, '');

    // return new TranslateHttpLoader(httpClient, environment.deployUrl + 'assets/i18n/${lang}.json');
}

@NgModule({
    imports: [
        CommonModule,
        ChartModule,
        FlexLayoutModule,
        BsDatepickerModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        CarouselModule.forRoot(),
        Ng2TelInputModule,
        SharedDialogModule,
        // NgxPayPalModule,
        MaterialModule,
        NgxCaptchaModule,
        PasswordStrengthMeterModule,
        PipesModule,
        //MaterialTimePickerModule,
        NgxMaterialTimepickerModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            extend: true
        })
    ],
    exports: [
        TransactionsComponent, InvoicesComponent, CashComponent, ChequeComponent, WireComponent, EwireComponent, AccountComponent, InvoicePreviewComponent,
        PqgridComponent,
        InputFeildComponent,
        UnderConstructionComponent,
        SortByPipe
    ],
    declarations: [
        InputFeildComponent,
        UnderConstructionComponent,
        SortByPipe,
        TransactionsComponent, InvoicesComponent, CashComponent, ChequeComponent, WireComponent, EwireComponent, AccountComponent, InvoicePreviewComponent,

    ],
    providers: [
        NotificationService,
        AlertService
    ]
})
export class SharedModule { }
