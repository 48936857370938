<div class="row">
    <div class="col-sm-12">
        <div class="col-md-3">
            <div class=" form-group-height">
                <div class="form-group">
                    <label for="" class="mandatory">{{'Currency' | translate}}:</label>
                    <input type="text" class="form-control" placeholder="{{'Currency' | translate}}" [(ngModel)]="iso"
                        oninput="this.value = this.value.toUpperCase()">
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <label for="" class="mandatory">{{'Value' |
                translate}}:</label>
            <cxr-generic-number-input [(ngModel)]="value" numberType="4" name="Value">
            </cxr-generic-number-input>

        </div>
        <div class="col-md-3 mt-3">
            <mat-checkbox [(ngModel)]="showExpired" class="dark-red-text">
                {{'Show Expired Banknotes' | translate}}
            </mat-checkbox>
        </div>


        <div class="col-md-3">
            <button mat-raised-button class="btn-success m-t-20" (click)="getBankNotes()">{{'Go' | translate}}</button>
        </div>
    </div>
</div>


<div class="row">
    <ng-container *ngFor="let row of resultView">
        <div [ngClass]="isCashTellerProof ? 'col-md-6 col-sm-12' : 'col-md-4 col-sm-6 col-xs-12'">
            <img class="card-img-top" style="width : 100%; height : auto; margin: auto" [src]="row.Image[0].Url" />
            <h3>{{row.Name}}</h3>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">Value: {{row.Value}}</li>
                <li class="list-group-item">Version: {{row.Version}}</li>
                <li class="list-group-item">ISO: {{row.ISO}}</li>
                <li class="list-group-item"><a [href]="row.URL1" class="" target="_blank">URL</a> </li>
                <li class="list-group-item">{{row.Description}}</li>
            </ul>
        </div>
    </ng-container>
</div>
