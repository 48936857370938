import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { PictureIdentityModel } from 'projects/cxr-crm/src/app/pages/_models/generic-models/generic-model';
import { PqgridComponent } from 'src/app/pqgrid.component';
import { ConfirmationDialogComponent } from 'src/app/shared-dialog/confirmation-dialog/confirmation-dialog.component';
import { GenericPreviewModel, PreviewGenericRowDataDialogComponent } from 'src/app/shared-dialog/preview-generic-row-data-dialog/preview-generic-row-data-dialog.component';
import { NotificationService } from 'src/app/shared/_services/notification.service';
import { environment } from 'src/environments/environment';
import pq from 'src/paramquery';
import { GenericComponentService } from '../../_services/generic-component.service';

@Component({
  selector: 'cxr-request-picture-id',
  templateUrl: './request-picture-id.component.html',
  styleUrls: ['./request-picture-id.component.scss']
})
export class RequestPictureIdComponent implements OnInit {


  // create: function () {
  //   //restore state of grid.
  //   var StateVal = localStorage.getItem('pq-grid' + self.GridName);
  //   this.loadState({ refresh: false, state: StateVal });
  // },
  @HostListener('window:beforeunload', ['$event'])
  saveGridState($event) {
    this.grid.SaveState();
  }
  ngOnDestroy() {
    this.grid.SaveState();
  }
  GridName: string = 'GenericPictureID' + GetUserObject.GetUserID();

  @Input('InternalTableName') InternalTableName: string;
  @Input('InternalRecordID') InternalRecordID: number;
  @Input('PictureIDTypeDD') PictureIDTypeDD: any[] = [];
  @Input('countriesList') countriesList: any[] = [];
  @Input('isForCustomerReport') isForCustomerReport: boolean;


  @ViewChild('grid', { static: false }) grid: PqgridComponent;
  optionsGrid: pq.gridT.options;
  fileToUpload: File;
  fileName: string = null;
  fileNameToggler: boolean = true;
  error: any = { isError: false, errorMessage: '' };
  submitted: boolean;

  constructor (
    private _formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private _genericComponentService: GenericComponentService,
    private _notificationService: NotificationService,
    private _dialog: MatDialog
  ) {
  }
  // picture id form

  @ViewChild('addEditPictureIDModal', { static: false }) addEditPictureIDModal: ModalDirective;
  @ViewChild('gridPictureIds', { static: false }) gridPictureIds: PqgridComponent;
  PictureIDSubmitted: boolean;
  PictureIDForm: UntypedFormGroup;
  toggleFileInput(val) {
    this.fileNameToggler = val;
  }
  pictureIDFormInit(val: PictureIdentityModel) {
    this.PictureIDForm = this._formBuilder.group({
      PictureID: new UntypedFormControl(val.PictureID),
      InternalRecordID: new UntypedFormControl(this.InternalRecordID, [Validators.required]),
      InternalTableName: new UntypedFormControl(this.InternalTableName, [Validators.required]),
      PictureIDType: new UntypedFormControl(val.PictureIDType, Validators.required),
      PictureIDNumber: new UntypedFormControl(val.PictureIDNumber, Validators.required),
      IssueDate: new UntypedFormControl(val.IssueDate),
      ExpiryDate: new UntypedFormControl(val.ExpiryDate),
      CountryOfIssue: new UntypedFormControl(val.CountryOfIssue),
      IssuingStateProvince: new UntypedFormControl(val.IssuingStateProvince),
      IssuingAuthority: new UntypedFormControl(val.IssuingAuthority),
      PicturePath: new UntypedFormControl(val.PicturePath),
      OwnerID: GetUserObject.GetOwnerID(),
      isActive: new UntypedFormControl(val.isActive),
      isDeleted: new UntypedFormControl(val.isDeleted)
    });
    this.fileToUpload = null;
  }

  // convenience getter for easy access to form fields
  get PictureIDF() {
    return this.PictureIDForm.controls;
  }

  compareTwoDates() {
    if (this.PictureIDF['IssueDate'].value != null || this.PictureIDF['ExpiryDate'].value != null) {
      if (new Date(this.PictureIDF['IssueDate'].value) > new Date(this.PictureIDF['ExpiryDate'].value)) {
        this.error = { isError: true, errorMessage: 'Date of Issue should be less than Expiry Date.' };
        return false;
      }
    }
    return true;
  }

  onPictureIDSubmit() {
    this.submitted = true;
    this.error.isError = false;
    if (this.compareTwoDates()) {

      if (this.PictureIDForm.invalid) {
        return;
      }
      // stop here if form is invalid
      //if (this.PictureIDForm.invalid && (this.fileToUpload == null || this.fileName == null)) {

      // Mandatory File availability
      // if ((this.PictureIDF.PictureID.value == null && this.fileToUpload == null) || (this.PictureIDF.PictureID.value != null && this.fileName == null)) {
      //   return;
      // }

      let formData: FormData = new FormData();
      if (this.fileToUpload != null) {
        formData.append('file', this.fileToUpload, this.fileToUpload.name);
      }
      formData.append('pictureIDData', JSON.stringify(this.PictureIDForm.value));

      this._genericComponentService.fnUpsertPictureID(formData).subscribe(data => {
        if (data.Success) {
          this.addEditPictureID(null, 0);
          this.grid.refreshDataAndView();
          this.toggleFileInput(true);
          this.fileName = null;
        }
      });
    }
  }
  addEditPictureID(val: PictureIdentityModel, flag) {

    this.submitted = false;
    if (flag == 1) {
      this._genericComponentService.fnGetPictureIDData(val).subscribe(data => {
        this.pictureIDFormInit(data.Data);
        this.addEditPictureIDModal.show();
      });
    }
    else {
      this.addEditPictureIDModal.hide();
      this.fileName = null;
    }
  }

  handleFileInput(files: FileList) {
    if (files != null) {
      var type = files.item(0).type;
      var size = files.item(0).size;
      if (size <= 5000000) {
        this.fileToUpload = files.item(0);
      }
      else {
        this.reset();
        this.translate.get("File size must be less than 5 MB").subscribe(res => {
          this._notificationService.error(res);
        });
        // this._snackBar.open('File size must be less than 5 MB', '', {
        //   duration: 3000,
        //   panelClass: 'redSnackBar'
        // });
      }
    }
  }
  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;
  reset() {
    this.myInputVariable.nativeElement.value = "";
  }
  @ViewChild('textInput', { static: false }) textInput;
  setFocus() {
    this.textInput.nativeElement.focus();
  }
  removeFocus() {
    this.textInput.nativeElement.blur();
  }


  // grid helper code

  // grid helper code ends here



  ngOnInit() {
    this.pictureIDFormInit(new PictureIdentityModel());
    this.GridInit();
  }

  GridInit() {
    var self = this;
    this.optionsGrid = {
      title: "Picture IDs",

      scrollModel: { autoFit: false },
      // create: function () {
      //   //restore state of grid.
      //   var StateVal = localStorage.getItem('pq-grid' + self.GridName);
      //   this.loadState({ refresh: false, state: StateVal });
      // },
      toolbar:


      {
        items: this.isForCustomerReport ? [] : [
          // {
          //   type: 'button',
          // label: self._route.snapshot.data.Data['Reset filters'],
          //   listener: function () {
          //     this.reset({ filter: true });
          //   }
          // },
          {
            type: 'button',
            icon: 'ui-icon-plus',
            cls: 'pq-add-button',
            label: '',
            listener: function () {
              self.addEditPictureID(new PictureIdentityModel(), 1);
            }
          }
        ]
      },
      // {
      //   items: [
      //     // {
      //     //   type: 'button',
      //     // label: self._route.snapshot.data.Data['Reset filters'],
      //     //   listener: function () {
      //     //     this.reset({ filter: true });
      //     //   }
      //     // },
      //     {
      //       type: 'button',
      //       icon: 'ui-icon-plus',
      //       label: '',
      //       listener: function () {
      //         self.addEditPictureID(new PictureIdentityModel(), 1);
      //       }
      //     }
      //   ]
      // },
      pageModel: {
        type: "remote", rPP: environment.pq_grid.default_rPP, curPage: 1, rPPOptions: [5, 10, 20, 50, 100],
        layout: []
        // layout: ['prev', 'next', "|", "strPage", "|", "refresh", "|", "strRpp"]
        // layout: ['prev', 'next', "|", "strPage"]
        // layout: ['first', 'prev', 'next', 'last', "|", "strPage"]
      },
      filterModel: {
        on: false,
        header: false,
        type: 'remote',
        menuIcon: false
      },
      sortModel: {
        type: 'remote',
        single: false,
        sorter: [{ dataIndx: 'PictureID', dir: 'down' }],
        space: true,
        multiKey: null
      },
      colModel: [

        {
          title: "", editable: false, width: 25, sortable: false, align: "center", menuIcon: false,
          cls: this.isForCustomerReport ? "hide" : "",
          render: function (ui) {
            return "<a style='color: green;cursor: pointer;' title='Click to edit' class='edit_btn'><span>&#x270F;</span></a>\
            <a style='color: red;cursor: pointer;' title='Click to delete' class='delete_btn'>&#x274C;</a>";
          },
          postRender: function (ui) {
            var rowIndx = ui.rowIndx,
              grid = this,
              $cell = grid.getCell(ui);
            //edit button
            $cell.find(".edit_btn")
              .off("click")
              .on("click", function (evt) {
                self.fileName = ui.rowData.PictureName;
                self.addEditPictureID(ui.rowData, 1);
              });
            //delete button
            $cell.find(".delete_btn")
              .on("click", function (evt) {
                self.showConfirmationModal();
                self.deleteRowValue = ui.rowData;
                self.deleteRowType = 'pictureID';
              });
          }
        },
        {
          title: "View Document", dataType: "image", dataIndx: 'PicturePath', editable: false, minWidth: 300, sortable: false, align: "center",
          render: function (ui) {
            var rowIndx = ui.rowIndx,
              grid = this,
              $cell = grid.getCell(ui);
            return "<a class = 'thumbnail-button'> <img style='max-width: 300px !important;' class='img-thumbnail' src='" + ui.rowData.PicturePath + "'/>&nbsp; </a>";
          }, postRender: function (ui) {
            var rowIndx = ui.rowIndx,
              grid = this,
              $cell = grid.getCell(ui);
            //edit button
            $cell.find(".thumbnail-button")
              .off("click")
              .on("click", function (evt) {
                window.open(ui.rowData.PicturePath);
              });
          }
        },
        { title: "Picture ID Type", dataType: "string", dataIndx: "PictureIDType", filter: { crules: [{ condition: 'range' }] } },
        { title: "Picture ID #", dataType: "string", dataIndx: "PictureIDNumber", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Date of Issue", dataType: "date", dataIndx: "IssueDate", filter: { crules: [{ condition: 'between' }] } },
        { title: "Expiry Date", dataType: "date", dataIndx: "ExpiryDate", filter: { crules: [{ condition: 'between' }] } },
        { title: "Country of Issue", dataType: "string", dataIndx: "CountryOfIssue", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Issuing State/Province ", dataType: "string", dataIndx: "IssuingStateProvince", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Issuing Authority", dataType: "string", dataIndx: "IssuingAuthority", filter: { crules: [{ condition: 'contain' }] } }

      ],
      rowDblClick: function (evt, ui) {
        var previewObj: GenericPreviewModel = new GenericPreviewModel();
        previewObj.Columns = this.colModel;
        previewObj.RowData = ui.rowData;
        previewObj.Heading = 'Picture ID Details Preview';
        const dialogRef = self._dialog.open(PreviewGenericRowDataDialogComponent, {
          data: previewObj,
          width: '1000px'
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      },
      selectionModel: { type: 'row' },

      // sort: function (evt, ui) {
      //   this.onSort(evt, ui);
      // },
      dataModel: {
        location: "remote",
        postData:
        {
          InternalRecordID: self.InternalRecordID,
          InternalTableName: self.InternalTableName
        },
        dataType: "JSON",
        method: "POST",
        url: environment.apiUrl + "generic/get-picture-ID-list",
        getData: function (dataJSON) {
          var data = JSON.parse(dataJSON.Data);
          return { curPage: data.curPage, totalRecords: data.totalRecords, data: data.data };
        }
      }
    };
  }

  // get Modal Pop-up data
  fnGetDropDownData() {

  }

  // get Modal Pop-up data ends here


  // Confirmation modal pop over
  @ViewChild('confirmationModal', { static: false }) confirmationModal: ModalDirective;
  deleteRowValue: any;
  deleteRowType: string;
  showConfirmationModal(): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Are you sure you want to delete this record?",
        isDelete: true,
        buttonText: {
          ok: 'Confirm',
          cancel: 'Decline'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteRecord();
      }
    });
  }

  hideConfirmationModal(): void {
    this.confirmationModal.hide();
  }
  // Confirmation modal pop over end here
  // Picture ID code starts here

  deleteRecord() {
    this._genericComponentService.fnDeletePictureID(this.deleteRowValue).subscribe(data => {
      if (data.Success) {
        this.grid.refreshDataAndView();
        this.hideConfirmationModal();
      }
      this.deleteRowValue = null;
      this.deleteRowType = null;
    });
  }
  // Picture ID code ends here

}
