import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ChangeBranchDialogComponent } from 'projects/cxr-crm/src/app/pages/_dialogs/change-branch-dialog/change-branch-dialog.component';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { MergeAccountsComponent } from 'projects/cxr-crm/src/app/pages/admin/merge-accounts/merge-accounts.component';
import { ChangeManualRatesComponent } from 'projects/cxr-crm/src/app/pages/currency/change-manual-rates/change-manual-rates.component';
import { TillReportComponent } from 'projects/cxr-crm/src/app/pages/teller/till-report/till-report.component';
import { TransferBetweenTillsDialogComponent } from 'projects/cxr-crm/src/app/pages/teller/transfer-between-tills-dialog/transfer-between-tills-dialog.component';
import { SaLookupOptionsService } from 'projects/cxr-customer-support/src/app/pages/_services/sa-lookup-options.service';
import { Localisation } from 'src/app/_services/localisation';
import { ConfirmationDialogComponent } from 'src/app/shared-dialog/confirmation-dialog/confirmation-dialog.component';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MenuService } from '../menu.service';


@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService]
})
export class HorizontalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId;
  public menuItems: Array<any>;
  public settings: Settings;
  public hideReports: boolean = !(GetUserObject.isAdmin() || GetUserObject.isOwner() || GetUserObject.isAllowToPrintReports());
  public hidePLStatement: boolean = !(GetUserObject.isAdmin() || GetUserObject.isOwner() || GetUserObject.isAllowToViewProfitsAndLosses());
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  showDeleteAllTransactions: boolean = GetUserObject.isOwner() && GetUserObject.GetUserObject().isDemo;
  constructor (public appSettings: AppSettings,
    public menuService: MenuService,
    public router: Router,
    public translate: TranslateService,
    private _dialog: MatDialog,
    private _saLookupOptionsService: SaLookupOptionsService) {
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.menuItems = this.menuService.getHorizontalMenuItems();
    let modules = this.menuItems;
    let finalMenu = [];
    let ownerPermisssions = GetUserObject.getOwnerPermissions();
    let permissions = GetUserObject.getPermissions();

    let noPermissionsModules = [];

    ownerPermisssions.forEach(x => {
      modules.forEach(y => {
        if (parseInt(x['ModuleID']) == (parseInt(y['id']))) {
          if (x['isHide']) {
            noPermissionsModules.push(y);
          }
        }
      });
    });

    permissions.forEach(x => {
      modules.forEach(y => {
        if (parseInt(x['ModuleID']) == (parseInt(y['id']))) {
          if (!x['CanRead'] && !(GetUserObject.isOwner() || GetUserObject.isAdmin())) {
            noPermissionsModules.push(y);
          }
        }
      });
    });

    modules.forEach(x => {
      if (noPermissionsModules.indexOf(x) == -1) {
        if (x['title'] === "Admin") {
          if ((GetUserObject.isOwner() || GetUserObject.isAdmin() || GetUserObject.isAgent()))
            finalMenu.push(x);
        }
        else if (x['parentId'] === 7 || x.id === 7) {
          if ((GetUserObject.isComplianceOfficer()))
            finalMenu.push(x);
        }
        else {
          finalMenu.push(x);
        }
      }
    });

    this.menuItems = finalMenu.filter(x => (x.title == "Power BI DashBoard") == false || GetUserObject.enablePoweBI());
    this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId);

  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.settings.fixedHeader) {
          let mainContent = document.getElementById('main-content');
          if (mainContent) {
            mainContent.scrollTop = 0;
          }
        }
        else {
          document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
        }
      }
    });
  }

  getUrl(val): string {
    return this.router.url;
  }

  openDialog(val) {
    if (val === 'Till Report') {
      const dialogRef = this._dialog.open(TillReportComponent, {
        data: val,
        width: '1000px'
      });

      dialogRef.afterClosed().subscribe((data) => {
        //this.grid.refreshDataAndView();
      });

    }
    else if (val === 'Transfer between Tills') {
      const dialogRef = this._dialog.open(TransferBetweenTillsDialogComponent, {
        data: val,
        width: 'auto'
      });
      dialogRef.afterClosed().subscribe((data) => {
        //this.grid.refreshDataAndView();
      });

    }
    //For Merge Account pop-up
    else if (val === 'Merge Accounts') {
      const dialogRef = this._dialog.open(MergeAccountsComponent, {
        data: val,
        width: '700px'
      });
      dialogRef.afterClosed().subscribe((data) => {
      });

    }
    else if (val === 'Change Manual Rates') {
      const dialogRef = this._dialog.open(ChangeManualRatesComponent, {
        data: {},
        width: 'auto'
      });
      dialogRef.afterClosed().subscribe((data) => {
        //this.grid.refreshDataAndView();
      });

    }
    else if (val === 'Currencies Rate Board') {
      let pickNonBranch = this.translate.instant('Common Rates');
      const dialogRef = this._dialog.open(ChangeBranchDialogComponent, {
        data: {
          isForCurrencyRateBoard: true,
          pickNonBranch: pickNonBranch
        },
        width: '600px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let url = '/currency-rate-board' + '?ownerCode=' + GetUserObject.GetOwnerCode();
          if (result === 'all') {
            window.open(url, '_blank');
          }
          else {
            window.open(url + '&branchCode=' + result.BranchCode, '_blank');
          }
        }
      });



      //   window.open('/currency-rate-board' + '?ownerCode=' + GetUserObject.GetOwnerCode(), '_blank');
    }
    else if (val === 'Delete All Transactions') {
      this.deleteAllTransactions();
    }
  }

  private deleteAllTransactions() {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Are you sure you want to delete all records ",
        isDelete: false,
        buttonText: {
          ok: 'Confirm',
          cancel: 'Decline'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
          data: {
            message: "This action cannot be undo. you wil lose all the records permanently. Are you sure you wants to continue ?",
            isDelete: false,
            buttonText: {
              ok: 'Confirm',
              cancel: 'Decline'
            }
          }
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
            this._saLookupOptionsService.fnDeleteAllTransactionsOfOwner(GetUserObject.GetOwnerID()).subscribe();
          }
        });
      }
    });
  }
}
