import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { PqgridComponent } from 'src/app/pqgrid.component';
import { environment } from 'src/environments/environment';
import pq from 'src/paramquery/pqgrid';
import { SharedService } from '../shared.service';

@Component({
  selector: 'cxr-pick-user-dialog',
  templateUrl: './pick-user-dialog.component.html',
  styleUrls: ['./pick-user-dialog.component.scss']
})
export class PickUserDialogComponent implements OnInit {

  @HostListener('window:beforeunload', ['$event'])
  saveGridState($event) {
    this.grid.SaveState();
  }
  GridName: string = 'PickUserDialog' + GetUserObject.GetUserID();
  ngOnDestroy() {
    this.grid.SaveState();
  }


  @ViewChild('grid', { static: false }) grid: PqgridComponent;
  gridOptions: pq.gridT.options;


  constructor (private _dialogRef: MatDialogRef<PickUserDialogComponent>, private _fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any, private _sharedService: SharedService) {
    _dialogRef.disableClose = true;
    var self = this;
    this.gridOptions = {
      title: "Pick User",
      height: "300",
      pageModel: { type: "local", rPP: environment.pq_grid.default_rPP, curPage: 1, rPPOptions: [5, 10, 20, 50, 100] },

      dataModel: {
        data: []
      },
      selectionModel: { type: 'row', mode: 'single', toggle: true },
      filterModel: {
        on: true,
        mode: "AND",
        header: true,
        type: 'local',
        menuIcon: false //show filter row icon initially.
      },

      colModel: [
        {
          title: "User ID", dataType: "string", dataIndx: "UserExtID", filter: {
            crules: [{
              condition: 'contain'
            }]
          }, render: function (ui) {
            return {
              style: ui.rowData.isOwner ? 'background-color:#fff200;font-weight: bold;' : '',
            };
          }
        },
        {
          title: "Name", dataType: "string", dataIndx: "Name", filter: {
            crules: [{
              condition: 'contain'
            }]
          }, render: function (ui) {
            return {
              style: ui.rowData.isOwner ? 'background-color:#fff200;font-weight: bold;' : '',
            };
          }
        },
        {
          title: "isOwner", dataType: "string", dataIndx: "isOwner", filter: {
            crules: [{
              condition: 'contain'
            }]
          }, render: function (ui) {
            return {
              style: ui.rowData.isOwner ? 'background-color:#fff200;font-weight: bold;' : '',
            };
          }
        }

      ],
      rowDblClick: function (ui, evt) {
        self.pickUser(evt.rowData);
      },
    };
  }

  ngOnInit() {
    this._sharedService.fnGetUsersDD(this.data.ownerId).subscribe(result => {
      if (this.data.showOwner == false)
        result.Data = result.Data.filter(x => x.isOwner == false);
      if (this.data.branchId)
        result.Data = result.Data.filter(x => x.BranchID == this.data.branchId);
      this.gridOptions.dataModel.data = result.Data;
      this.grid.refreshDataAndView();
    });

  }

  onNoClick(): void {
    this._dialogRef.close();
  }


  pickUser(val) {
    this._dialogRef.close(val);
  }



}
