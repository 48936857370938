import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EditorChangeContent } from 'ngx-quill';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { TextEditorConfig } from 'projects/cxr-crm/src/app/pages/_helpers/text-editor-config';
import 'quill-emoji/dist/quill-emoji.js';
import { Localisation } from 'src/app/_services/localisation';

@Component({
  selector: 'cxr-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextEditorComponent),
      multi: true,
    }

  ]
})
export class RichTextEditorComponent implements ControlValueAccessor {
  modules: {} = TextEditorConfig.module;
  shortModule: {} = TextEditorConfig.shortModule;
  value: string;
  @Input('Note') Note: string;
  @Input('Type') Type: number;
  @Input('Placeholder') Placeholder: string;

  private onChange: (val: string) => void;
  private onTouched: () => void;


  constructor (private translate: TranslateService) {
    //Translate for Localization
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
  }

  writeValue(value: any) {
    this.value = null;
    this.Note = null;
    this.value = value !== null ? value : this.Note;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnInit(): void {


  }
  changedEditor(event: EditorChangeContent) {
    if (event.event === "text-change") {
      this.onChange(this.Note = event.html);
      this.onTouched();
    }

  }
}
