import { Menu } from './menu.model';

export const CustomerVerticalMenuItems = [
    new Menu(1, 'Dashboard', '/customer/dashboard', null, 'fa fa-tasks', null, false, 0),
    new Menu(2, 'Details', '/customer/details', null, 'fa fa-tasks', null, false, 0),
    new Menu(3, 'Transactions', '/customer/transactions', null, 'fa fa-files-o', null, true, 0),

    // new Menu(30, 'Registers', '/customer/transactions/registers', null, 'fa fa-files-o', null, true, 3),
    new Menu(31, 'Invoices', '/customer/transactions/invoice', null, 'fa fa-files-o', null, true, 3),

]
export const CustomerHorizontalMenuItems = [
    new Menu(1, 'Dashboard', '/customer/dashboard', null, 'fa fa-tasks', null, false, 0),
    new Menu(2, 'Details', '/customer/details', null, 'fa fa-tasks', null, false, 0),
    new Menu(3, 'Transactions', '/customer/transactions', null, 'fa fa-files-o', null, true, 0),

    // new Menu(30, 'Registers', '/customer/transactions/registers', null, 'fa fa-files-o', null, true, 3),
    new Menu(31, 'Invoices', '/customer/transactions/invoice', null, 'fa fa-files-o', null, true, 3),

]
