<form [formGroup]="IndividualProfileForm" (ngSubmit)="onSubmit()">
    <h4 mat-dialog-title> {{'KYC Review'| translate}} {{IndividualValue?.IndividualExtID}}
        <button type="button" class="close pull-right" (click)="OpenConfirmationDialog()" aria-label="Close">
            <span aria-hidden="true" class="text-black">&times;</span>
        </button>
    </h4>
    <div mat-dialog-content>
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="form-group form-group-height">
                    <label class="" for="Salutation">{{'Salutation' | translate}}:</label>
                    <mat-select placeholder="{{'Select Salutation' | translate}}" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.Salutation.errors }" formControlName="Salutation">
                        <mat-option [value]="null" selected>Select</mat-option>
                        <mat-option *ngFor="let item of SalutationDD" [value]="item.LookupID">
                            {{item.LookupName}}
                        </mat-option>
                    </mat-select>
                    <div *ngIf="submitted && f.Salutation.errors" class="invalid-feedback">
                        <div *ngIf="f.Salutation.errors.required">{{'Required' | translate}}</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="form-group form-group-height">
                    <label class="mandatory">{{'First Name' | translate}}:</label>
                    <input type="text" class="form-control" placeholder="{{'First Name' | translate}}"
                        formControlName="FirstName" [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }">
                    <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
                        <div *ngIf="f.FirstName.errors.required">{{'Required' | translate}}</div>
                        <div *ngIf="f.FirstName.hasError('pattern')">{{'pattern mismatch' | translate}}</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="form-group form-group-height">
                    <label class="mandatory">{{'Last Name' | translate}}:</label>
                    <input type="text" class="form-control" placeholder="{{'Last Name' | translate}}"
                        formControlName="LastName" [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }">
                    <div *ngIf="submitted && f.LastName.errors" class="invalid-feedback">
                        <div *ngIf="f.LastName.errors.required">{{'Required' | translate}}</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="form-group form-group-height">
                    <label class="" for="Gender">{{'Gender' | translate}}:</label>
                    <mat-select placeholder="{{'Select Gender' | translate}}" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.Gender.errors }" formControlName="Gender">
                        <mat-option [value]="null" selected disabled>{{'Select' | translate}}</mat-option>
                        <mat-option *ngFor="let item of GenderDD" [value]="item.LookupID">
                            {{item.LookupName}}
                        </mat-option>
                    </mat-select>
                    <div *ngIf="submitted && f.Gender.errors" class="invalid-feedback">
                        <div *ngIf="f.Gender.errors.required">{{'Required' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <mat-progress-bar mode="determinate" color="Warn" [value]="ProgressValue">
                </mat-progress-bar>
            </div>
        </div>
        <div class="row m-t-10">
            <div class="col-xs-12">
                <mat-horizontal-stepper (selectionChange)="selectionChange($event)" #stepper>
                    <mat-step>
                        <ng-template matStepLabel>{{'General Info' | translate}}</ng-template>
                        <div class="row m-t-10">
                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group form-group-height">
                                    <label class="">{{'Date Of Birth' | translate}}:</label>
                                    <cxr-prime-calendar formControlName="DateOfBirth"
                                        [ngClass]="{ 'is-date-invalid': submitted && f.DateOfBirth.errors }">
                                    </cxr-prime-calendar>
                                    <div *ngIf="submitted && f.DateOfBirth.errors" class="invalid-feedback">
                                        <div *ngIf="f.DateOfBirth.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group form-group-height">
                                    <label class="">{{'Nationality' | translate}}:</label>
                                    <mat-select placeholder="{{'Select Nationality' | translate}}" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.Nationality.errors }"
                                        formControlName="Nationality">
                                        <mat-option [value]="null" selected>{{'Select' | translate}}</mat-option>
                                        <mat-option *ngFor="let item of countriesList" [value]="item.demonym">
                                            {{item.alpha2Code}} - {{item.demonym}}
                                        </mat-option>
                                    </mat-select>
                                    <div *ngIf="submitted && f.Nationality.errors" class="invalid-feedback">
                                        <div *ngIf="f.Nationality.errors.required">{{'Required' | translate}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group form-group-height">
                                    <label class="" for="Country of Residence ">{{'Country of Residence' |
                                        translate}}:</label>
                                    <mat-select placeholder="{{'Select Country' | translate}}" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.CountryOfResidence.errors }"
                                        formControlName="CountryOfResidence">
                                        <mat-option [value]="null" selected>{{'Select' | translate}}</mat-option>
                                        <mat-option *ngFor="let item of countriesList" [value]="item.name">
                                            {{item.alpha2Code}} - {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                    <div *ngIf="submitted && f.CountryOfResidence.errors" class="invalid-feedback">
                                        <div *ngIf="f.CountryOfResidence.errors.required">{{'Required' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group form-group-height">
                                    <label class="">{{'Ethnic Spelling' | translate}}:</label>
                                    <input type="text" class="form-control"
                                        placeholder="{{'Ethnic Spelling' | translate}}" formControlName="EthnicSpelling"
                                        [ngClass]="{ 'is-invalid': submitted && f.EthnicSpelling.errors }">
                                    <div *ngIf="submitted && f.EthnicSpelling.errors" class="invalid-feedback">
                                        <div *ngIf="f.EthnicSpelling.errors.required">{{'Required' | translate}}</div>
                                        <div *ngIf="f.EthnicSpelling.hasError('pattern')">{{'pattern mismatch' |
                                            translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group form-group-height">
                                    <label class="">{{'Occupation' | translate}}:</label>
                                    <input type="text" class="form-control" placeholder="{{'Occupation' | translate}}"
                                        formControlName="Occupation" list="OccupationDD"
                                        [ngClass]="{ 'is-invalid': submitted && f.Occupation.errors }">
                                    <datalist id="OccupationDD">
                                        <option *ngFor="let item of OccupationDataTypeAhead" [value]="item">{{item}}
                                        </option>
                                    </datalist>
                                    <div *ngIf="submitted && f.Occupation.errors" class="invalid-feedback">
                                        <div *ngIf="f.Occupation.errors.required">{{'Required' | translate}}</div>
                                        <div *ngIf="f.Occupation.hasError('pattern')">{{'pattern mismatch' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group form-group-height">
                                    <label class="">{{'Job Title/Position' | translate}}:</label>
                                    <input type="text" class="form-control"
                                        placeholder="{{'Job Title/Position' | translate}}" formControlName="JobPosition"
                                        [ngClass]="{ 'is-invalid': submitted && f.JobPosition.errors }">
                                    <div *ngIf="submitted && f.JobPosition.errors" class="invalid-feedback">
                                        <div *ngIf="f.JobPosition.errors.required">{{'Required' | translate}}</div>
                                        <div *ngIf="f.JobPosition.hasError('pattern')">{{'pattern mismatch' |
                                            translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3 offset-lg-1">
                                <div class="form-group form-group-height">
                                    <label class="" for="Company">{{'Company' | translate}}:</label>
                                    <input type="text" list="Company" placeholder="{{'Company' | translate}}"
                                        class="form-control" formControlName="CompanyName">
                                    <datalist id="Company">
                                        <option *ngFor="let x of companiesDD" [value]="x.Name">{{x.Name}}
                                        </option>
                                    </datalist>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-3" *ngIf="f.IndividualID.value ==0">
                                <div class="form-group form-group-height">
                                    <label class="">{{'Email Address' | translate}}:</label>
                                    <input type="text" class="form-control" placeholder="Email"
                                        formControlName="EmailAddress"
                                        [ngClass]="{ 'is-invalid': submitted && f.EmailAddress.errors }">
                                    <div *ngIf="submitted && f.EmailAddress.errors" class="invalid-feedback">
                                        <div *ngIf="f.EmailAddress.errors.required">required</div>
                                        <div *ngIf="f.EmailAddress.hasError('invalidEmail') && f.EmailAddress.touched">
                                            {{'Invalid Email' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-3" *ngIf="f.IndividualID.value ==0">
                                <div class="form-group form-group-height">
                                    <label class="">{{'Phone Number' | translate}}:</label>
                                    <input type="text" class="form-control" placeholder="Phone" formControlName="Phone"
                                        [ngClass]="{ 'is-invalid': submitted && f.Phone.errors }">
                                    <div *ngIf="submitted && f.Phone.errors" class="invalid-feedback">
                                        <div *ngIf="f.Phone.errors.required">required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-3 offset-lg-1">
                                <div class="form-group form-group-height">
                                    <label class="" for="Group Type">{{'Group Type' | translate}}:</label>
                                    <mat-select placeholder="Select Group Type" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.GroupType.errors }"
                                        formControlName="GroupType">
                                        <mat-option [value]="null" selected>{{'Select' | translate}}</mat-option>
                                        <mat-option *ngFor="let item of GroupTypeDD" [value]="item.LookupID">
                                            {{item.LookupName}}
                                        </mat-option>

                                    </mat-select>
                                    <div *ngIf="submitted && f.GroupType.errors" class="invalid-feedback">
                                        <div *ngIf="f.GroupType.errors.required">{{'Required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                <div class="form-group form-group-height">
                                    <!-- Social Security/SIN  -->
                                    <label class="">{{'National ID' | translate}}:</label>
                                    <input type="text" class="form-control" placeholder="Social Security/SIN"
                                        formControlName="NationalID"
                                        [ngClass]="{ 'is-invalid': submitted && f.NationalID.errors }">
                                    <div *ngIf="submitted && f.NationalID.errors" class="invalid-feedback">
                                        <div *ngIf="f.NationalID.errors.required">{{'Required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group">
                                    <mat-checkbox class="w-100" formControlName="isInsider"> is Insider
                                    </mat-checkbox>
                                </div>
                            </div> -->
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <cxr-tri-state-checkbox (click)="CheckProgressValue()" class="w-100"
                                    formControlName="isCompanyOccupationVerified">

                                    <span class="font-20-bold" *ngIf="f.isCompanyOccupationVerified.value == null">
                                        {{'Company/Occupation is not available' | translate}}</span>
                                    <span class="font-20-bold text-blue"
                                        *ngIf="f.isCompanyOccupationVerified.value == true">
                                        {{'Company/Occupation Verified' | translate}}!</span>
                                    <span class="font-20-bold text-red"
                                        *ngIf="f.isCompanyOccupationVerified.value == false">{{'Company/Occupation not
                                        Verified' | translate}}</span>
                                </cxr-tri-state-checkbox>
                                <div *ngIf="submitted && f.isCompanyOccupationVerified.errors" class="invalid-feedback">
                                    <div *ngIf="f.isCompanyOccupationVerified.errors.required">{{'Required' |
                                        translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <button mat-raised-button type="button" class="add-dark-hover btn-secondary  btn"
                                    (click)="OpenConfirmationDialog()">{{'Cancel' | translate}}</button>

                                <button mat-raised-button type="button"
                                    class="add-dark-hover btn-primary pull-right btn" matStepperNext>{{'Next' |
                                    translate}}</button>
                                <!-- <button mat-raised-button type="button"
                                    class="add-dark-hover btn-default pull-right btn  m-r-5"
                                    matStepperPrevious>Back</button> -->
                            </div>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>{{'Phone & Email' | translate}}</ng-template>
                        <div class="row m-t-10">
                            <div class="col-xs-12 col-md-6">
                                <app-generic-phone-numbers [InternalTableName]="TableNames.TableNameIndividual"
                                    [InternalRecordID]="f.IndividualID.value" [PhoneTypeDD]="PhoneTypeDD">
                                </app-generic-phone-numbers>
                            </div>
                            <div class="col-xs-12 col-md-6">
                                <app-generic-email [InternalTableName]="TableNames.TableNameIndividual"
                                    [InternalRecordID]="f.IndividualID.value" [EmailTypeDD]="EmailTypeDD">
                                </app-generic-email>

                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <cxr-tri-state-checkbox (click)="CheckProgressValue()" class="w-100"
                                    formControlName="isPhoneEmailVerified">
                                    <span class="font-20-bold" *ngIf="f.isPhoneEmailVerified.value == null">
                                        {{'Phone and Email is not available' | translate}}</span>
                                    <span class="font-20-bold text-blue"
                                        *ngIf="f.isPhoneEmailVerified.value == true">{{'Phone and Email Verified' |
                                        translate}}!</span>
                                    <span class="font-20-bold text-red" *ngIf="f.isPhoneEmailVerified.value == false">
                                        {{'Phone and Email not Verified' | translate}}</span>
                                </cxr-tri-state-checkbox>
                                <div *ngIf="submitted && f.isPhoneEmailVerified.errors" class="invalid-feedback">
                                    <div *ngIf="f.isPhoneEmailVerified.errors.required">{{'Required' | translate}}</div>
                                </div>

                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <button mat-raised-button type="button" class="add-dark-hover btn-secondary  btn"
                                    (click)="OpenConfirmationDialog()">{{'Cancel' | translate}}</button>

                                <button mat-raised-button type="button"
                                    class="add-dark-hover btn-primary pull-right btn" matStepperNext>{{'Next' |
                                    translate}}</button>
                                <button mat-raised-button type="button"
                                    class="add-dark-hover btn-default pull-right btn  m-r-5" matStepperPrevious>{{'Back'
                                    | translate}}</button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>{{'Picture ID' | translate}}</ng-template>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <!-- {{IndividualValue.IndividualID}} -->
                                <app-generic-picture-id [InternalTableName]="TableNames.TableNameIndividual"
                                    [countriesList]="countriesList" [InternalRecordID]="IndividualValue.IndividualID"
                                    [PictureIDTypeDD]="PictureIDTypeDD"></app-generic-picture-id>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <cxr-tri-state-checkbox (click)="CheckProgressValue()" class="w-100"
                                    formControlName="isPictureIDVerifired">
                                    <span class="font-20-bold" *ngIf="f.isPictureIDVerifired.value == null">
                                        {{'Picture ID is not available' | translate}}</span>
                                    <span class="font-20-bold text-blue"
                                        *ngIf="f.isPictureIDVerifired.value == true">{{'Picture ID Verified' |
                                        translate}}!</span>
                                    <span class="font-20-bold text-red"
                                        *ngIf="f.isPictureIDVerifired.value == false">{{'Picture ID not Verified' |
                                        translate}}</span>
                                </cxr-tri-state-checkbox>
                                <div *ngIf="submitted && f.isPictureIDVerifired.errors" class="invalid-feedback">
                                    <div *ngIf="f.isPictureIDVerifired.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <button mat-raised-button type="button" class="add-dark-hover btn-secondary  btn"
                                    (click)="OpenConfirmationDialog()">{{'Cancel' | translate}}</button>
                                <button mat-raised-button type="button"
                                    class="add-dark-hover btn-primary pull-right btn" matStepperNext>{{'Next' |
                                    translate}}</button>
                                <button mat-raised-button type="button"
                                    class="add-dark-hover btn-default pull-right btn  m-r-5" matStepperPrevious>{{'Back'
                                    | translate}}</button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>{{'Address & Notes' | translate}}</ng-template>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <app-generic-address [countriesList]="countriesList"
                                    [InternalTableName]="TableNames.TableNameIndividual"
                                    [InternalRecordID]="f.IndividualID.value" [AddressTypeDD]="AddressTypeDD">
                                </app-generic-address>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <cxr-tri-state-checkbox class="w-100" (click)="CheckProgressValue()"
                                    formControlName="isAddressVerified">
                                    <span class="font-20-bold" *ngIf="f.isAddressVerified.value == null">
                                        {{'Address is not available' | translate}}</span>
                                    <span class="font-20-bold text-blue"
                                        *ngIf="f.isAddressVerified.value == true">{{'Address Verified' |
                                        translate}}!</span>
                                    <span class="font-20-bold text-red"
                                        *ngIf="f.isAddressVerified.value == false">{{'Address not Verified' |
                                        translate}}</span>
                                </cxr-tri-state-checkbox>
                                <div *ngIf="submitted && f.isAddressVerified.errors" class="invalid-feedback">
                                    <div *ngIf="f.isAddressVerified.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group form-group-height">
                                    <label class="mandatory">{{'Review Notes' | translate}}:</label>
                                    <cxr-rich-text-editor [Placeholder]="'Review Notes'" formControlName="ReviewNotes"
                                        [Type]="2">
                                    </cxr-rich-text-editor>
                                    <div class="pull-right m-t-10">
                                        <cxr-note-pick-button class="pull-left" (note)="emitData($event)">
                                        </cxr-note-pick-button>
                                    </div>
                                    <div *ngIf="submitted && f.ReviewNotes.errors" class="invalid-feedback">
                                        <div *ngIf="f.ReviewNotes.errors.required">{{'Required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-t-10" *ngIf="ProgressValue >=80">
                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group">
                                    <mat-checkbox class="w-100" (ngModelChange)="CheckProgressValue()"
                                        formControlName="isAllVerified">
                                        <span class="font-20-bold text-blue">
                                            {{'All Documents Checked and Confirmed Original' | translate}}
                                        </span>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>

                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <button mat-raised-button type="button" class="add-dark-hover btn-secondary  btn"
                                    (click)="OpenConfirmationDialog()">{{'Cancel' | translate}}</button>
                                <button mat-raised-button type="submit" class="pull-right btn btn-success">{{'Confirm' |
                                    translate}}</button>
                                <button mat-raised-button type="button"
                                    class="add-dark-hover btn-default pull-right btn  m-r-5" matStepperPrevious>{{'Back'
                                    | translate}}</button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>KYC Review</ng-template>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <cxr-kyc-review-log [CustomerExtID]="IndividualValue.IndividualExtID"
                                    [InternalTableName]="TableNames.TableNameIndividual"
                                    [InternalRecordID]="f.IndividualID.value"></cxr-kyc-review-log>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <button mat-raised-button type="button" class="add-dark-hover btn-secondary  btn"
                                    (click)="OpenConfirmationDialog()">{{'Cancel' | translate}}</button>
                                <button mat-raised-button type="button"
                                    class="add-dark-hover btn-default pull-right btn" matStepperPrevious>{{'Back' |
                                    translate}}</button>
                            </div>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
        </div>
    </div>
    <!-- <mat-dialog-actions align="end">
        <button mat-raised-button type="button" class="add-dark-hover btn-default" (click)="OpenConfirmationDialog()">Cancel</button>
        <button mat-raised-button class="add-hover btn-success" type="submit">Confirm</button>
    </mat-dialog-actions> -->
</form>
