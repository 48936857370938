import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { GetUserObject } from '../../_helpers/get-user-object';
import { AccountsService } from '../../_services/accounts.service';
import { DashboardService } from '../../_services/dashboard.service';

@Component({
  selector: 'cxr-till-summary',
  templateUrl: './till-summary.component.html',
  styleUrls: ['./till-summary.component.scss']
})
export class TillSummaryComponent implements OnInit {
  tableList: any = [];
  isIncludeSharedTills = localStorage.getItem("isIncludeSharedTills") ? (JSON.parse(localStorage.getItem("isIncludeSharedTills"))) : false;
  ReportDate;
  ActiveNow = localStorage.getItem("ActiveNow") ? (JSON.parse(localStorage.getItem("ActiveNow"))) : false;
  constructor (private _dashboardService: DashboardService, private datePipe: DatePipe, private _accountsService: AccountsService) {
    if (GetUserObject.GetUserObject().TimeZone) {
      this.ReportDate = GetUserObject.GetCurrentDate();
    }
    else {
      this.ReportDate = datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
  }

  ngOnInit(): void {
    // this.ReportDate = this.datePipe.transform(moment().tz(GetUserObject.getTimeZone())?.toString(), "yyyy-MM-dd");
    if (this.ActiveNow) {
      this.getTillReport(this.ActiveNow);
    }
    else {
      this.getTillReport(this.ActiveNow);
    }

  }

  getTillReport(Active) {
    let val = {
      isZeroBalanceAccounts: null,
      isInclueEodTellerProof: null,
      isIncludeSharedTills: this.isIncludeSharedTills,
      OwnerID: GetUserObject.GetOwnerID(),
      ReportDate: this.ReportDate,
      BranchID: GetUserObject.GetBranchID(),
      ActiveAccounts: Active,
    };
    this._dashboardService.fnGetAssignedtillSummary(val).subscribe(data => {
      if (data['Success']) {
        if (data['Data'].length > 0) {
          this.tableList = data['Data'];
        }
        else {
          this.tableList = [];
        }
      }
      else {
        this.tableList = [];
      }
    });
  }


  isShardTill(event) {
    localStorage.setItem('isIncludeSharedTills', JSON.stringify(event.checked));
    this.getTillReport(this.ActiveNow);
  }

  activeAccounts(event) {
    localStorage.setItem('ActiveNow', JSON.stringify(event.checked));
    if (event.checked) {
      this.getTillReport(event.checked);
    }
    else {
      this.getTillReport(event.checked);
    }
  }


}
