import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { apiResponse, responseModel } from '../_models/response.model';

@Injectable({
  providedIn: 'root'
})
export class LookupsService {


  headers: { headers: any; };

  constructor (private _http: HttpClient) { }
  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  fnGetRolesDD(val) {
    return this._http.get(environment.apiUrl + 'get-lookups-Roles?OwnerID=' + val);
  }

  fnGetBranchesDD(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'get-branches-dd', val);
  }
  fnGetDropDownsByOwnerID(val) {
    return this._http.get(environment.apiUrl + 'get-lookups-by-owner-id?OwnerID=' + val);
  }

  getAddressAutoCompleteTypes(): Promise<string> {
    return this._http.get<apiResponse<string>>(environment.apiUrl + 'addressautocomplete/type')
      .pipe(map(x => { return x.Data; })).toPromise();

  }

  fnGetCompaniesDD(val) {
    return this._http.get(environment.apiUrl + 'get-companies?OwnerID=' + val);
  }

  fnGetCountries() {
    // return this._http.get<any[]>('https://restcountries.eu/rest/v2/all');
    return this._http.get<any[]>(environment.apiUrl + 'get-countries-list-dd');
  }

  fnGetCurrencyListDD() {
    return this._http.get<responseModel>(environment.apiUrl + 'get-currency-list-dd');
  }



  //fn fetch main account type dd 
  fnFetchMainAccountTypeDD(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'get-main-account-type-dd', val);
  }

  fnGetCurrencyConversionRate() {
    return this._http.get<responseModel>(`${ environment.apiUrl }get-currencies-DD`);
  }
}
