import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { responseModel } from 'projects/cxr-crm/src/app/pages/_models/response.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ExportPdfHttpService {

    constructor (private _http: HttpClient) { }

    fnGeneratePdf(val, fileName, isPrint?: boolean): void {
        this._http.post<responseModel>(environment.apiUrl + "generate-pdf", val).subscribe((res: responseModel) => {

            if (isPrint) {
                this.printPDf(res.Data['Result']);
            }
            else {
                this.downloadPdf(res.Data['Result'], fileName);
            }

        });
    }

    fnExportToCSV(val, fileName): void {
        this._http.post<responseModel>(environment.apiUrl + "generate-csv", val).subscribe((res: any) => {
            const byteCharacters = atob((res.Data));
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'text/csv' });
            saveAs(blob, fileName + '.csv');
        });
    }


    fnExportGrid(val, fileName, isCSV?: boolean): void {
        this._http.post<responseModel>(environment.apiUrl + "export-grid", val).subscribe((res: responseModel) => {
            if (isCSV) {
                this.downloadCSV(res.Data['Result'], fileName);
            }
            else {
                this.downloadPdf(res.Data['Result'], fileName);
            }
        });
    }

    exportAllCsv(val) {
        let url: string = '';
        if (val?.FromDate && val?.ToDate) {
            url = `?from=${ val.FromDate }&to=${ val.ToDate }`;
        }
        return this._http.get<responseModel>(environment.apiUrl + "export/register" + url);
    }
    private downloadPdf(base64String, fileName) {
        const source = `data:application/pdf;base64,${ base64String }`;
        const link = document.createElement("a");
        link.href = source;
        link.download = `${ fileName }.pdf`;
        link.click();
    }

    private downloadCSV(res, fileName) {
        const byteCharacters = atob(res);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'text/csv' });

        saveAs(blob, fileName + ((new Date()).getTime()) + '.csv');
    }


    private printPDf(base64String) {
        let winparams = 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,' +
            'resizable,screenX=50,screenY=50,width=850,height=1050';
        let printWindow = window.open("", "pdf", winparams);
        let htmlPop = '<embed width=100% height=100%'
            + ' type="application/pdf"'
            + ' src="data:application/pdf;base64,'
            + escape(base64String)
            + '"></embed>';

        if (printWindow) {
            printWindow.document.write(htmlPop);
        }

        //printWindow.close();

    }
}
