<h4 mat-dialog-title><span>{{f.AMLAlertID.value > 0 ? ('Update AML Alert' | translate) : ('Add AML Alert' |
        translate)}}</span>
    <button type="button" class="close pull-right" (click)="close()" aria-label="Close">
        <span aria-hidden="true" class="text-black">&times;</span>
    </button>
</h4>
<div mat-dialog-content>
    <div class="row">
        <div class="col-xs-12 padding-top">
            <form [formGroup]="AMLAlertForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
                <div class="row">
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div class="form-group form-group-height">
                                <label class="mandatory hyper-link " (click)="openCustomerDetails()">{{'Customer ID' |
                                    translate}}:</label>
                                <div class=" input-group">
                                    <input type="text" class="form-control" placeholder="{{'Customer ID' | translate}}"
                                        formControlName="CustomerID" (keydown.enter)="openDialogPickCustomer()"
                                        #textInput [readonly]="AMLAlertForm.value && AMLAlertForm.value.AMLAlertID">
                                    <div class="input-group-btn">
                                        <button class="btn btn-default" type="button"
                                            [disabled]="AMLAlertForm.value && AMLAlertForm.value.AMLAlertID"
                                            (click)="openDialogPickCustomer()">
                                            <i class=" glyphicon glyphicon-search"></i>
                                        </button>
                                        <!-- <button class="btn btn-success" type="button" (click)="viewCustomerDetails()">
                                        View
                                    </button> -->
                                    </div>
                                </div>
                                <div *ngIf="submitted && f.CustomerID.errors" class="invalid-feedback">
                                    <div *ngIf="f.CustomerID.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div class="form-group form-group-height">
                                <label class="">{{'Customer Name' | translate}}:</label>
                                <input type="text" readonly class="form-control" formControlName="CustomerName"
                                    placeholder="{{'Customer Name' | translate}}">
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div class="form-group form-group-height">
                                <label class="hyper-link" (click)="openInvoiceDetails()">{{'Invoice ID' |
                                    translate}}:</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="{{'Invoice ID' | translate}}"
                                        formControlName="InvoiceID" (keydown.enter)="openDialogPickInvoice()" #textInput
                                        [readonly]="AMLAlertForm.value && AMLAlertForm.value.AMLAlertID">
                                    <div class="input-group-btn">
                                        <button class="btn btn-default" type="button"
                                            [disabled]="AMLAlertForm.value && AMLAlertForm.value.AMLAlertID"
                                            (click)="openDialogPickInvoice()">
                                            <i class=" glyphicon glyphicon-search"></i>
                                        </button>
                                    </div>
                                </div>
                                <div *ngIf="submitted && f.InvoiceID.errors" class="invalid-feedback">
                                    <div *ngIf="f.InvoiceID.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4"
                            *ngIf="f.AMLAlertID.value != null || f.AMLAlertID.value != 0">
                            <div class="form-group form-group-height">
                                <label class="mandatory">{{'Subject' | translate}}:</label>
                                <input type="text" class="form-control" formControlName="Subject"
                                    placeholder="{{'Subject' | translate}}">
                                <div *ngIf="submitted && f.Subject.errors" class="invalid-feedback">
                                    <div *ngIf="f.Subject.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div class="form-group form-group-height">
                                <label class="mandatory">{{'AML Type' | translate}}:</label>
                                <input type="text" class="form-control" formControlName="Type"
                                    placeholder="{{'AML Type' | translate}}" list="Lookups">
                                <datalist id="Lookups">
                                    <option *ngFor="let x of AMLTypes" [value]="x.LookupName">
                                        {{x.LookupName | translate}}
                                    </option>
                                </datalist>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" *ngIf="f.AMLAlertID.value > 0">
                            <div class="form-group form-group-height">
                                <label>{{'SanctionList Log ID' | translate}}:</label>
                                <input type="text" readonly class="form-control" maxlength="250"
                                    placeholder="{{'SanctionList Log ID' | translate}}"
                                    formControlName="SanctionListLogID">
                                <!-- <div *ngIf="submitted && f.Subject.errors" class="invalid-feedback">
                                <div *ngIf="f.Subject.errors.required">Required</div>
                            </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class=" form-group-height form-group">
                                <label for="Description" class="mandatory">{{'Description' | translate}}:</label>

                                <cxr-rich-text-editor [Placeholder]="'Description'" formControlName="Description"
                                    [Type]="2">
                                </cxr-rich-text-editor>
                                <div *ngIf="submitted && f.Description.errors" class="invalid-feedback">
                                    <div *ngIf="f.Description.errors.required">{{'Required' | translate}}</div>
                                </div>
                                <div class="pull-right m-t-10">
                                    <cxr-note-pick-button class="pull-left" (note)="emitData($event)">
                                    </cxr-note-pick-button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="row" *ngIf="f.AMLAlertID.value > 0">
                    <div class="col-xs-12">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div class="form-group form-group-height">
                                <label class="">{{'Rule ID' | translate}}:</label>
                                <input type="text" class="form-control" formControlName="RuleID" readonly
                                    placeholder="{{'Rule ID' | translate}}">
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div class="form-group form-group-height">
                                <label class="">{{'Rule Name' | translate}}:</label>
                                <input type="text" class="form-control" readonly formControlName="RuleName"
                                    placeholder="{{'Rule Name' | translate}}">
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 cursor-disable">
                            <label class="">{{'Rule Description' | translate}}:</label>
                            <cxr-rich-text-editor [Placeholder]="'Rule Description'" formControlName="RuleDescription"
                                [Type]="2">
                            </cxr-rich-text-editor>
                        </div>
                    </div>
                </div>
                <h4 style="background-color: #ff4c4c69" class="flex-p m-t-20">{{'Resolve' | translate}}</h4>
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                        <div class=" form-group-height form-group">
                            <label for="Status">{{'Status' | translate}}: &nbsp;&nbsp;
                                <cxr-tri-state-checkbox class="w-100" formControlName="isResolved"
                                    (ngModelChange)="changeStatus(f.isResolved.value)">
                                    <span *ngIf="f.isResolved.value == null">{{'Resolve' | translate}} ?
                                    </span>
                                    <span class="text-green" *ngIf="f.isResolved.value == true">{{'Resolved' |
                                        translate}}
                                    </span>
                                    <span class="text-red" *ngIf="f.isResolved.value ==  false">{{'Unresolved' |
                                        translate}}</span>
                                </cxr-tri-state-checkbox>
                            </label>

                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                        <div class=" form-group-height form-group">
                            <label for="Status" [class.mandatory]="(f.isResolved.value != null)">
                                {{'Resolution Notes' | translate}}:</label>
                            <div class=" input-group">
                                <textarea class="form-control" maxlength="500" [readonly]="f.isResolved.value == null"
                                    formControlName="ResolutionNotes" [required]="f.isResolved.value != null"
                                    placeholder="{{'Resolution Notes' | translate}}"></textarea>
                                <div class="input-group-btn">
                                    <button class="btn btn-default notes-button" type="button"
                                        [disabled]="f.isResolved.value == null" (click)="openNotesDialog()">
                                        {{'Notes' | translate}}
                                        <!-- <i class=" glyphicon glyphicon-search"></i> -->
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="submitted && f.ResolutionNotes.errors" class="invalid-feedback">
                                <div *ngIf="f.ResolutionNotes.errors.required">{{'Required' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-xs-12  col-sm-4 col-lg-2" *ngIf="f.isResolved.value != null">

                        <button (click)="openNotesDialog()" type="button" class="flex-p" mat-button
                            class="btn-success pull-right m-t-20 ">Notes</button>
                    </div> -->
                </div>
                <div class="row m-t-20">
                    <div class="col-xs-12">
                        <button type="submit" color="primary" mat-raised-button class="m-b-10 pull-right btn-success">
                            <i class="fa fa-save m-r-5"></i>{{'Save' | translate}} </button>
                        <!-- <button type="submit" (click)="fnSaveAndAdd()" color="primary" mat-raised-button
                            class="m-b-10 pull-right btn-success  m-r-5 ">
                            <i class="fa fa-save m-r-5"></i>Save & Add More</button> -->
                        <button mat-raised-button type="button" (click)="close()"
                            class="pull-right m-r-5 btn-secondary">{{'Cancel' | translate}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


<!-- <ng-template #target></ng-template> -->
