<button mat-raised-button type="button" *ngIf="showValidateButton" class="add-dark-hover btn-secondary btn mb-5 m-r-5"
    (click)="validate()">{{'Validate' |
    translate}}</button>

<span *ngIf="emailValue?.status" class="m-t-10 m-r-5" style="padding-right: 10px;"><span><b> Status
            :</b></span>
    {{emailValue?.status}}</span>

<ng-container *ngIf="showValidationIcon">
    <ng-container *ngIf="isValid == null;else conditionCheck">
    </ng-container>
    <ng-template #conditionCheck>
        <ng-container *ngIf="isValid;else Invalid"><span class="text-green font-weight-bold validation-icon"
                style="font-size: 24px;padding-right: 10px;">&#x2714;</span>
        </ng-container>
    </ng-template>
    <ng-template #Invalid><span class="text-red font-weight-bold validation-icon"
            style="font-size: 24px;padding-right: 10px;">&#x2716;</span>
    </ng-template>
</ng-container>

<!-- <button mat-raised-button type="button" *ngIf="showValidateButton"
    class="add-dark-hover btn-secondary btn pull-right mb-5" (click)="validate()">{{'Validate' |
    translate}}</button>

<ng-container *ngIf="showValidationIcon">
    <ng-container *ngIf="isValid == null;else conditionCheck">
    </ng-container>
    <ng-template #conditionCheck>
        <ng-container *ngIf="isValid;else Invalid"><span class="text-green font-weight-bold validation-icon"
                style="font-size: 24px;padding-right: 10px;">&#x2714;</span>
        </ng-container>
    </ng-template>
    <ng-template #Invalid><span class="text-red font-weight-bold validation-icon"
            style="font-size: 24px;padding-right: 10px;">&#x2716;</span>
    </ng-template>
</ng-container>
<span *ngIf="emailValue?.status" class="pull-right m-t-10" style="padding-right: 10px;"><span>Status :</span>
    {{emailValue?.status}}</span> -->