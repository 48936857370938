<!-- <mat-horizontal-stepper linear="true" #stepper>
    <mat-step>
        <ng-template matStepLabel>Fill out your name</ng-template>
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
        </mat-form-field>
        <div>
            <button mat-button matStepperNext>Next</button>
        </div>
    </mat-step>
</mat-horizontal-stepper> -->


<form [formGroup]="companyAddEditForm" (ngSubmit)="onSubmit()">
    <h4 mat-dialog-title> {{'KYC Review' | translate}} {{companyValue?.CompanyExtID}}
        <button type="button" class="close pull-right" (click)="onNoClick()" aria-label="Close">
            <span aria-hidden="true" class="text-black">&times;</span>
        </button>
    </h4>
    <div mat-dialog-content>
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="form-group form-group-height">
                    <label class="mandatory">{{'Company Name' | translate}}:</label>
                    <input type="text" class="form-control" placeholder="{{'Company Name' | translate}}"
                        formControlName="CompanyName" [ngClass]="{ 'is-invalid': submitted && f.CompanyName.errors }">
                    <div *ngIf="submitted && f.CompanyName.errors" class="invalid-feedback">
                        <div *ngIf="f.CompanyName.errors.required">{{'Required' | translate}}</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4">
                <div class="form-group form-group-height">
                    <label class="">{{'Nature Of Business' | translate}}:</label>
                    <input type="text" class="form-control" placeholder="{{'Nature Of Business' | translate}}"
                        formControlName="NatureOfBusiness"
                        [ngClass]="{ 'is-invalid': submitted && f.NatureOfBusiness.errors }">
                    <div *ngIf="submitted && f.NatureOfBusiness.errors" class="invalid-feedback">
                        <div *ngIf="f.NatureOfBusiness.errors.required">{{'Required' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <mat-progress-bar mode="determinate" color="Warn" [value]="ProgressValue">
                </mat-progress-bar>
            </div>
        </div>
        <div class="row m-t-10">
            <div class="col-xs-12">
                <mat-horizontal-stepper (selectionChange)="selectionChange($event)" #stepper>
                    <mat-step>
                        <ng-template matStepLabel>{{'General Info' | translate}}</ng-template>
                        <div class="row m-t-10">
                            <div class="col-xs-12 col-sm-6 col-md-4">
                                <div class="form-group form-group-height">
                                    <label class="">{{'Type of Business' | translate}}:</label>
                                    <input type="text" class="form-control"
                                        placeholder="{{'Type of Business' | translate}}"
                                        formControlName="TypeOfBusiness"
                                        [ngClass]="{ 'is-invalid': submitted && f.TypeOfBusiness.errors }">
                                    <div *ngIf="submitted && f.TypeOfBusiness.errors" class="invalid-feedback">
                                        <div *ngIf="f.TypeOfBusiness.errors.required">{{'Required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4">
                                <div class="form-group form-group-height">
                                    <label class="">{{'Incorporation' | translate}} #:</label>
                                    <input type="text" class="form-control"
                                        placeholder="{{'Incorporation No.' | translate}}"
                                        formControlName="IncorporationNumber"
                                        [ngClass]="{ 'is-invalid': submitted && f.IncorporationNumber.errors }">
                                    <div *ngIf="submitted && f.IncorporationNumber.errors" class="invalid-feedback">
                                        <div *ngIf="f.IncorporationNumber.errors.required">{{'Required' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4">
                                <div class="form-group form-group-height">
                                    <label class="">{{'Incorporation Date' | translate}}:</label>
                                    <cxr-prime-calendar formControlName="IncorporationDate"
                                        [ngClass]="{ 'is-date-invalid': submitted && f.IncorporationDate.errors }">
                                    </cxr-prime-calendar>
                                    <div *ngIf="submitted && f.IncorporationDate.errors" class="invalid-feedback">
                                        <div *ngIf="f.IncorporationDate.errors.required">{{'Required' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4">
                                <div class="form-group form-group-height">
                                    <label class="">{{'MSB Registration' | translate}} #:</label>
                                    <input type="text" class="form-control"
                                        placeholder="{{'MSB Registration' | translate}} #"
                                        formControlName="MSBRegistrationNumber"
                                        [ngClass]="{ 'is-invalid': submitted && f.MSBRegistrationNumber.errors }">
                                    <div *ngIf="submitted && f.MSBRegistrationNumber.errors" class="invalid-feedback">
                                        <div *ngIf="f.MSBRegistrationNumber.errors.required">{{'Required' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4">
                                <div class="form-group form-group-height">
                                    <label class="">{{'MSB Registration Date' | translate}}:</label>
                                    <cxr-prime-calendar formControlName="MSBRegistrationDate"
                                        [ngClass]="{ 'is-date-invalid': submitted && f.MSBRegistrationDate.errors }">
                                    </cxr-prime-calendar>
                                    <div *ngIf="submitted && f.MSBRegistrationDate.errors" class="invalid-feedback">
                                        <div *ngIf="f.MSBRegistrationDate.errors.required">{{'Required' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-4">
                                <div class="form-group form-group-height">
                                    <label class="mandatory" for="Status">{{'Status' | translate}}:</label>
                                    <mat-select placeholder="{{'Select Status' | translate}}" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.isActive.errors }"
                                        formControlName="isActive">
                                        <mat-option [value]="null" selected disabled>{{'Select' | translate}}
                                        </mat-option>
                                        <mat-option [value]="true">{{'Active' | translate}}</mat-option>
                                        <mat-option [value]="false">{{'Inactive' | translate}}</mat-option>
                                    </mat-select>
                                    <div *ngIf="submitted && f.isActive.errors" class="invalid-feedback">
                                        <div *ngIf="f.isActive.errors.required">{{'Required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <cxr-tri-state-checkbox (click)="CheckProgressValue()" class="w-100"
                                    formControlName="isCompanyOccupationVerified">

                                    <span class="font-20-bold" *ngIf="f.isCompanyOccupationVerified.value == null">
                                        {{'Company/Occupation is not available' | translate}}</span>
                                    <span class="font-20-bold text-blue"
                                        *ngIf="f.isCompanyOccupationVerified.value == true">
                                        {{'Company/Occupation Verified' | translate}}!</span>
                                    <span class="font-20-bold text-red"
                                        *ngIf="f.isCompanyOccupationVerified.value == false">
                                        {{'Company/Occupation not Verified' | translate}}</span>
                                </cxr-tri-state-checkbox>
                                <div *ngIf="submitted && f.isCompanyOccupationVerified.errors" class="invalid-feedback">
                                    <div *ngIf="f.isCompanyOccupationVerified.errors.required">{{'Required' |
                                        translate}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <button mat-raised-button type="button" class="add-dark-hover btn-secondary  btn"
                                    (click)="onNoClick()">{{'Cancel' | translate}}</button>

                                <button mat-raised-button type="button"
                                    class="add-dark-hover btn-primary pull-right btn" matStepperNext>{{'Next' |
                                    translate}}</button>
                                <!-- <button mat-raised-button type="button"
                                    class="add-dark-hover btn-default pull-right btn  m-r-5"
                                    matStepperPrevious>Back</button> -->
                            </div>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>{{'Phone & Email' | translate}}</ng-template>
                        <div class="row m-t-10">
                            <div class="col-xs-12 col-sm-6 col-md-4">
                                <div class="form-group form-group-height">
                                    <label class="">{{'Work Phone' | translate}}:</label>
                                    <input type="text" class="form-control" placeholder="{{'Work Phone' | translate}}"
                                        formControlName="WorkPhone"
                                        [ngClass]="{ 'is-invalid': submitted && f.WorkPhone.errors }">
                                    <div *ngIf="submitted && f.WorkPhone.errors" class="invalid-feedback">
                                        <div *ngIf="f.WorkPhone.errors.required">{{'Required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4">
                                <div class="form-group form-group-height">
                                    <label class="">{{'Work Fax' | translate}}:</label>
                                    <input type="text" class="form-control" placeholder="{{'Work Fax' | translate}}"
                                        formControlName="WorkFax"
                                        [ngClass]="{ 'is-invalid': submitted && f.WorkFax.errors }">
                                    <div *ngIf="submitted && f.WorkFax.errors" class="invalid-feedback">
                                        <div *ngIf="f.WorkFax.errors.required">{{'Required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4">
                                <div class="form-group form-group-height">
                                    <label class="">{{'Email' | translate}}: </label>
                                    <input type="text" class="form-control" placeholder="{{'Email' | translate}}"
                                        formControlName="EmailAddress"
                                        [ngClass]="{ 'is-invalid': submitted && f.EmailAddress.errors }">
                                    <div *ngIf="submitted && f.EmailAddress.errors" class="invalid-feedback">
                                        <div *ngIf="f.EmailAddress.errors.required">{{'Required' | translate}}</div>
                                        <div *ngIf="f.EmailAddress.hasError('invalidEmail') && f.EmailAddress.touched">
                                            Invalid Email
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-4">
                                <div class="form-group form-group-height">
                                    <label class="">{{'Phone Number' | translate}}:</label>
                                    <input type="text" class="form-control" placeholder="{{'Phone' | translate}}"
                                        formControlName="Phone"
                                        [ngClass]="{ 'is-invalid': submitted && f.Phone.errors }">
                                    <div *ngIf="submitted && f.Phone.errors" class="invalid-feedback">
                                        <div *ngIf="f.Phone.errors.required">{{'Required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <cxr-tri-state-checkbox (click)="CheckProgressValue()" class="w-100"
                                    formControlName="isPhoneEmailVerified">
                                    <span class="font-20-bold" *ngIf="f.isPhoneEmailVerified.value == null">
                                        {{'Phone and Email is not available' | translate}}</span>
                                    <span class="font-20-bold text-blue"
                                        *ngIf="f.isPhoneEmailVerified.value == true">{{'Phone and Email Verified' |
                                        translate}}!</span>
                                    <span class="font-20-bold text-red" *ngIf="f.isPhoneEmailVerified.value == false">
                                        {{'Phone and Email not Verified' | translate}}</span>
                                </cxr-tri-state-checkbox>
                                <div *ngIf="submitted && f.isPhoneEmailVerified.errors" class="invalid-feedback">
                                    <div *ngIf="f.isPhoneEmailVerified.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <button mat-raised-button type="button" class="add-dark-hover btn-secondary  btn"
                                    (click)="onNoClick()">{{'Cancel' | translate}}</button>
                                <button mat-raised-button type="button"
                                    class="add-dark-hover btn-primary pull-right btn" matStepperNext>{{'Next' |
                                    translate}}</button>
                                <button mat-raised-button type="button"
                                    class="add-dark-hover btn-default pull-right btn  m-r-5" matStepperPrevious>{{'Back'
                                    | translate}}</button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>{{'Picture ID' | translate}}</ng-template>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <app-generic-picture-id [InternalTableName]="TableNames.TableNameCompany"
                                    [countriesList]="countriesList" [InternalRecordID]="companyValue.CompanyID"
                                    [PictureIDTypeDD]="PictureIDTypeDD"></app-generic-picture-id>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <cxr-tri-state-checkbox (click)="CheckProgressValue()" class="w-100"
                                    formControlName="isPictureIDVerifired">
                                    <span class="font-20-bold" *ngIf="f.isPictureIDVerifired.value == null">
                                        {{'Picture ID is not available' | translate}}</span>
                                    <span class="font-20-bold text-blue"
                                        *ngIf="f.isPictureIDVerifired.value == true">{{'Picture ID Verified' |
                                        translate}}!</span>
                                    <span class="font-20-bold text-red"
                                        *ngIf="f.isPictureIDVerifired.value == false">{{'Picture ID not Verified' |
                                        translate}}</span>
                                </cxr-tri-state-checkbox>
                                <div *ngIf="submitted && f.isPictureIDVerifired.errors" class="invalid-feedback">
                                    <div *ngIf="f.isPictureIDVerifired.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <button mat-raised-button type="button" class="add-dark-hover btn-secondary  btn"
                                    (click)="onNoClick()">{{'Cancel' | translate}}</button>
                                <button mat-raised-button type="button"
                                    class="add-dark-hover btn-primary pull-right btn" matStepperNext>{{'Next' |
                                    translate}}</button>
                                <button mat-raised-button type="button"
                                    class="add-dark-hover btn-default pull-right btn  m-r-5" matStepperPrevious>{{'Back'
                                    | translate}}</button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>{{'Address & Notes' | translate}}</ng-template>
                        <div class="row m-t-10">
                            <div class="col-xs-12 col-sm-6 col-md-4">
                                <div class="form-group form-group-height">
                                    <label class="">{{'Registration State/Province' | translate}}:</label>
                                    <input type="text" class="form-control" placeholder="Registration State/Province"
                                        formControlName="RegistrationStateProvince"
                                        [ngClass]="{ 'is-invalid': submitted && f.RegistrationStateProvince.errors }">
                                    <div *ngIf="submitted && f.RegistrationStateProvince.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="f.RegistrationStateProvince.errors.required">{{'Required' |
                                            translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4">
                                <div class="form-group form-group-height">
                                    <label class="" for="Country">{{'Country' | translate}}:</label>
                                    <mat-select placeholder="{{'Select Country' | translate}}" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.Country.errors }"
                                        formControlName="Country">
                                        <mat-option [value]="null" selected>{{'Select' | translate}}</mat-option>
                                        <mat-option *ngFor="let item of countriesList" [value]="item.name">
                                            {{item.alpha2Code}} - {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                    <div *ngIf="submitted && f.Country.errors" class="invalid-feedback">
                                        <div *ngIf="f.Country.errors.required">{{'Required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <cxr-tri-state-checkbox class="w-100" (click)="CheckProgressValue()"
                                    formControlName="isAddressVerified">
                                    <span class="font-20-bold" *ngIf="f.isAddressVerified.value == null">
                                        {{'Address is not available' | translate}}</span>
                                    <span class="font-20-bold text-blue"
                                        *ngIf="f.isAddressVerified.value == true">{{'Address Verified' |
                                        translate}}!</span>
                                    <span class="font-20-bold text-red"
                                        *ngIf="f.isAddressVerified.value == false">{{'Address not Verified' |
                                        translate}}</span>
                                </cxr-tri-state-checkbox>
                                <div *ngIf="submitted && f.isAddressVerified.errors" class="invalid-feedback">
                                    <div *ngIf="f.isAddressVerified.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group form-group-height">
                                    <label class="mandatory">{{'Review Notes' | translate}}:</label>
                                    <textarea type="text" class="form-control" placeholder="" rows="1"
                                        formControlName="ReviewNotes"
                                        [ngClass]="{ 'is-invalid': submitted && f.ReviewNotes.errors }">  </textarea>
                                    <div *ngIf="submitted && f.ReviewNotes.errors" class="invalid-feedback">
                                        <div *ngIf="f.ReviewNotes.errors.required">{{'Required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-t-10" *ngIf="ProgressValue >=80">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                                <div class="form-group">
                                    <mat-checkbox class="w-100" (ngModelChange)="CheckProgressValue()"
                                        formControlName="isAllVerified">
                                        <span class="font-20-bold text-blue">
                                            {{'All Documents Checked and Confirmed Original' | translate}}
                                        </span>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <button mat-raised-button type="button" class="add-dark-hover btn-secondary  btn"
                                    (click)="onNoClick()">{{'Cancel' | translate}}</button>
                                <button mat-raised-button type="submit" class="pull-right btn btn-success">{{'Confirm' |
                                    translate}}</button>
                                <button mat-raised-button type="button"
                                    class="add-dark-hover btn-default pull-right btn  m-r-5" matStepperPrevious>{{'Back'
                                    | translate}}</button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>{{'KYC Review' | translate}}</ng-template>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <cxr-kyc-review-log [CustomerExtID]="companyValue.CompanyExtID"
                                    [InternalTableName]="TableNames.TableNameCompany"
                                    [InternalRecordID]="f.CompanyID.value"></cxr-kyc-review-log>
                            </div>
                        </div>
                        <div class="row m-t-10">
                            <div class="col-xs-12">
                                <button mat-raised-button type="button" class="add-dark-hover btn-secondary  btn"
                                    (click)="onNoClick()">{{'Cancel' | translate}}</button>
                                <button mat-raised-button type="button"
                                    class="add-dark-hover btn-default pull-right btn" matStepperPrevious>{{'Back' |
                                    translate}}</button>
                            </div>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
        </div>
    </div>
    <!-- <mat-dialog-actions align="end">
        <button mat-raised-button type="button" class="add-dark-hover btn-default" (click)="onNoClick()">Cancel</button>
        <button mat-raised-button class="add-hover btn-success" type="submit">Confirm</button>
    </mat-dialog-actions> -->
</form>
