import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import pq from 'src/paramquery';
import 'src/paramquery/localize/pq-localize-de.js';
import 'src/paramquery/localize/pq-localize-en.js';
import 'src/paramquery/localize/pq-localize-es.js';
import 'src/paramquery/localize/pq-localize-fr.js';
import 'src/paramquery/localize/pq-localize-it.js';
import 'src/paramquery/localize/pq-localize-ja.js';
import 'src/paramquery/localize/pq-localize-kr.js';
import 'src/paramquery/localize/pq-localize-pt.js';
import 'src/paramquery/localize/pq-localize-tr.js';
import { NotificationService } from './shared/_services';

@Component({
  selector: 'pqgrid',
  template: `<div [id]="GridID"></div>`,
  //styles: [`h1 { font-family: Lato; }`]
})
export class PqgridComponent implements OnInit {
  @Input() GridID: string = '';
  @Input() options: pq.gridT.options;
  grid: any;
  constructor (
    public el: ElementRef,
    private _changes: ChangeDetectorRef,
    public renderer: Renderer2,
    private _dialog: MatDialog,
    private _router: Router,
    private translate: TranslateService,
    private _notificationService: NotificationService
  ) { }

  ngOnInit() {
    sessionStorage.setItem('tokenTime', JSON.stringify(new Date()));
    this.SettingDefaultValues();
    this.SettingHeader();
    this.CheckingStatusCode();
    this.grid = pq.grid(this.el.nativeElement.children[0], this.options);
  }

  checkUndefinedOrNull(val) {
    if (val == null || val == undefined) {
      return true;
    } else {
      return false;
    }
  }

  SettingDefaultValues() {
    var self = this;
    (this.options.create = function () {
      //restore state of grid.
      var StateVal = localStorage.getItem('pq-grid' + self.GridID);
      if (StateVal != null) this.loadState({ refresh: false, state: StateVal });
    }),
      (this.options.stateColKeys = { filter: 0 });
    this.options.stateKeys = { pageModel: 0, sortModel: 0 };
    this.options.wrap =
      this.checkUndefinedOrNull(this.options.wrap) == true ? true : false;
    this.options.hwrap =
      this.checkUndefinedOrNull(this.options.hwrap) == true ? true : false;
    this.options.columnTemplate = { render: this.tooltipRender, width: 100 };
    this.options.showTop =
      this.checkUndefinedOrNull(this.options.showTop) != true ? false : true;
    this.options.reactive =
      this.checkUndefinedOrNull(this.options.reactive) != true ? false : true;
    this.options.locale = GetUserObject.getLanguage()
      ? GetUserObject.getLanguage()
      : 'en'; //this.options.locale != 'en' ? this.options.locale : 'en';
    this.options.height =
      this.checkUndefinedOrNull(this.options.height) == false
        ? this.options.height == 'flex'
          ? 'flex'
          : this.options.height
        : 'flex';
    this.options.stripeRows =
      this.checkUndefinedOrNull(this.options.stripeRows) != true ? false : true;
    this.options.editable = false; // this.checkUndefinedOrNull(this.options.editable) != false ? true : false;
    this.options.menuIcon =
      this.checkUndefinedOrNull(this.options.menuIcon) != true ? false : true;
    this.options.resizable =
      this.checkUndefinedOrNull(this.options.resizable) != true ? false : true;
    this.options.postRenderInterval = -1;
    this.options.collapsible = {
      on: true,
      collapsed: false,
    };
    // if (this.options.menuUI.tabs[0] == 'hideCols') {
    this.options.menuUI = {
      tabs: ['hideCols'],
    };
    this.options.autoRow = true;
    // } else {
    //   this.options.menuUI = {
    //     tabs: ['filter']
    //   }
    // }
    this.options.numberCell = {
      show: false,
    };
    this.options.animModel = {
      on: true,
    };

    this.options.dragModel = this.options.dragModel;
    this.options.dropModel = this.options.dropModel;

    // if (this.options.flex != null && this.options.flex != undefined) {
    //   this.options.flex = {
    //     one: (this.options.flex.one == true ? true : false)
    //   }
    // } else {
    //   this.options.flex = { one: true }
    // }

    this.options.swipeModel = {
      on: false,
    };
    this.options.sort = function (evt, ui) {
      self.onSort(evt, ui);
    };
    // this.options.numberCell.show = this.options.numberCell.show != false ? this.options.numberCell.show : false;
    // this.options.animModel.on = this.options.animModel.on != true ? this.options.animModel.on : true;
    // this.options.flex.one = this.options.flex.one != true ? this.options.flex.one : true;
    // this.options.swipeModel.on = this.options.swipeModel.on != false ? this.options.swipeModel.on : false;
  }

  onSort(evt, ui) {
    JSON.stringify(ui.sorter);
  }
  tooltipRender(ui) {
    return {
      //here we inject html title attribute in each cell.
      attr: 'title="' + ui.formatVal + '"',
    };
  }
  SettingHeader() {
    // this.options.dataModel.contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
    this.options.dataModel.beforeSend = function (jqXHR, settings) {
      let test = settings.data;
      var user: any = null;

      if (
        GetUserObject.GetSession() != null &&
        GetUserObject.GetUserObject() != 'undefined'
      ) {
        user = GetUserObject.GetUserObject();
      }
      if (
        localStorage.getItem('AuthToken') == null ||
        localStorage.getItem('AuthToken') == undefined
      ) {
        this._router.navigateByUrl('/login');
      }

      if (user != null) {
        // jqXHR.setRequestHeader("contentType", "application/x-www-form-urlencoded; charset=UTF-8");
        jqXHR.setRequestHeader(
          'Authorization',
          'Bearer ' + localStorage.getItem('AuthToken')
        ); // `Bearer ${user['SessionKey']}`);
        jqXHR.setRequestHeader(
          'BranchId',
          JSON.stringify(GetUserObject.GetBranchID())
        );

        jqXHR.setRequestHeader(
          'ModuleId',
          sessionStorage.getItem('ModuleId') ?? ''
        );

        jqXHR.setRequestHeader('Language', GetUserObject.getLanguage());
      }
    };
  }
  CheckingStatusCode() {
    var self = this;
    this.options.dataModel.error = function (jqXHR, textStatus, errorThrown) {
      var err = jqXHR;
      //sessionStorage.setItem('tokenTime', JSON.stringify(new Date()))
      if (
        localStorage.getItem('AuthToken') == null ||
        localStorage.getItem('AuthToken') == undefined
      ) {
        this._router.navigateByUrl('/login');
        this._dialog.closeAll();
      }
      // if (err.status == 0) { //or whatever condition you like to put
      // self._notificationService.error("Contact Administrator");
      // self._router.navigateByUrl("/login");
      // this._dialog.closeAll();
      // }
      if (err.statusText == 'Unauthorized' || err.status == 401) {
        self._dialog.closeAll();
        // self._notificationService.error("Authentication Required");
        self.translate
          ? self._notificationService.error("Authentication Required") : '';
        self._router.navigateByUrl('/login');
      } else if (err.statusText == 'Forbidden' || err.status == 403) {
        self._dialog.closeAll();
        self.translate
          ? self._notificationService.error("Forbidden") : '';

      } else if (
        err.status == 500 ||
        err.statusText == 'Internal Server Error'
      ) {
        self.translate
          ? self._notificationService.error("Internal Server Error") : '';

      } else {
        self.translate
          ? self._notificationService.error("Internal Server Error") : '';

      }
    };
  }

  refreshDataAndView() {
    this.grid?.refreshDataAndView();
  }
  refresh() {
    this.grid?.refresh();
  }
  refreshHeader() {
    this.grid?.refreshHeader();
  }
  SaveState() {
    this.grid?.saveState();
  }
  LoadState() {
    this.grid?.loadState();
  }
}
