
<div id="app" class="app" [dir]="(settings.rtl) ? 'rtl' : 'ltr'" [ngClass]="settings.theme"
    [class.fixed-header]="settings.fixedHeader" [class.horizontal-menu]="settings.menu == 'horizontal'"
    [class.compact]="settings.menuType == 'compact'" [class.mini]="settings.menuType == 'mini'">
    <router-outlet></router-outlet>
</div>

<!-- <ng-http-loader [backgroundColor]="'#000000'" [spinner]="spinkit.skWave" [debounceDelay]="100" [minDuration]="300"
    [extraDuration]="300">
</ng-http-loader> -->

<!-- <i class='fa fa-spinner fa-spin'></i> -->

<div *ngIf="loaderMessage$.isLoaderOn == true && loaderMessage$.isCheckAgainstSanctionList == false" id="app-spinner">
    <mat-spinner></mat-spinner>
</div>

<!-- 
<div class="lds-roller" *ngIf="loaderMessage$">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <b>Checking Against the sanction List...</b>
</div> -->

<!-- spinner -->
<div *ngIf="loaderMessage$.isLoaderOn == true && loaderMessage$.isCheckAgainstSanctionList == true" id="overlay">
    <div class="spinner"></div>
    <br />
    <b>Checking Against the Sanction List...</b>
</div>