import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-contact-layout',
  templateUrl: './manage-contact-layout.component.html',
  styleUrls: ['./manage-contact-layout.component.scss']
})
export class ManageContactLayoutComponent implements OnInit {

  constructor () { }

  ngOnInit() {
  }

}
