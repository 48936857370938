export interface AddressAutoComplete {
  items: Item[];
}
export interface Item {
  id: string;
  text: string;
  highlight: string;
  cursor: number;
  description: string;
  next: string;
  error: string;
  cause: string;
  resolution: string;
  domesticId: string;
  language: string;
  languageAlternatives: string;
  department: string;
  company: string;
  subBuilding: string;
  buildingNumber: string;
  buildingName: string;
  secondaryStreet: string;
  street: string;
  block: string;
  neighbourhood: string;
  district: string;
  city: string;
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  line5: string;
  adminAreaName: string;
  adminAreaCode: string;
  province: string;
  provinceName: string;
  provinceCode: string;
  postalCode: string;
  countryName: string;
  countryIso2: string;
  countryIso3: string;
  countryIsoNumber: string;
  sortingNumber1: string;
  sortingNumber2: string;
  barcode: string;
  pOBoxNumber: string;
  label: string;
  type: string;
  dataLevel: string;
  acRbdi: string;
  acMua: string;
}

export interface AddressAutoCompleteSearchModel {
  searchTerm: string;
  country: string;
}
export enum AddressAutoCompletetype {
  Find = 'Find',
  Retrieve = 'Retrieve',
}

export interface AutoCompleteAddressResult {
  countryIso2?: string;
  countryIso3?: string;
  country: string;
  type?: string;
  street: string;
  province: string;
  provinceCode: string;
  city: string;
  zipCode: string;
}
