import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { IbanValidationButtonComponent } from './iban-validation-button.component';
import { IbanValidationService } from './iban-validation.service';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { translationUrl } from 'projects/cxr-crm/src/app/translationurl';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, translationUrl, '');
}
@NgModule({
  declarations: [IbanValidationButtonComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      extend: true
    })
  ],
  exports: [IbanValidationButtonComponent],
  providers: [IbanValidationService]
})
export class IbanValidationModule { }
