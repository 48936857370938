
export class DateTimeHelper {

    static convertDateTimeToDate(dateTime) {
        if (dateTime == null || dateTime == undefined || dateTime.trim() == "") {
            return null;
        } else {
            if (isNaN(Date.parse(dateTime))) {
                return null;
            } else {
                if (typeof (dateTime == "string")) {
                    return dateTime.split("T")[0]
                } else {
                    return new Date(dateTime).toISOString().split("T")[0]
                }

            }
        }
    }

}
