import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { PhoneModel } from 'projects/cxr-crm/src/app/pages/_models/generic-models/generic-model';
import { Localisation } from 'src/app/_services/localisation';
import { PqgridComponent } from 'src/app/pqgrid.component';
import { ConfirmationDialogComponent } from 'src/app/shared-dialog/confirmation-dialog/confirmation-dialog.component';
import { GenericPreviewModel, PreviewGenericRowDataDialogComponent } from 'src/app/shared-dialog/preview-generic-row-data-dialog/preview-generic-row-data-dialog.component';
import { environment } from 'src/environments/environment';
import pq from 'src/paramquery';
import { GenericComponentService } from '../../../_services/generic-component.service';

@Component({
  selector: 'app-generic-phone-numbers',
  templateUrl: './generic-phone-numbers.component.html',
  styleUrls: ['./generic-phone-numbers.component.scss']
})
export class GenericPhoneNumbersComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  saveGridState($event) {
    this.grid.SaveState();
  }
  GridName: string = 'GenericPhoneNumbers' + GetUserObject.GetUserID();

  @Input('InternalTableName') InternalTableName: string;
  @Input('InternalRecordID') InternalRecordID: number;
  @Input('PhoneTypeDD') PhoneTypeDD: any[] = [];
  @Input('isForCustomerReport') isForCustomerReport: boolean;
  @Input('hideAddButton') hideAddButton: boolean = false;
  @ViewChild('grid', { static: false }) grid: PqgridComponent;
  optionsGrid: pq.gridT.options;
  clickToEdit = 'Click to edit';
  clickToDelete = 'Click to delete';
  constructor (
    private _formBuilder: UntypedFormBuilder,
    private _dialog: MatDialog,
    private translate: TranslateService,
    private _genericComponentService: GenericComponentService
  ) {
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
  }
  PhoneForm: UntypedFormGroup;
  phoneSubmitted: boolean;
  PhoneValue: PhoneModel;
  @ViewChild('addEditPhoneModal', { static: false }) addEditPhoneModal: ModalDirective;
  phoneFormInit(val: PhoneModel) {
    this.PhoneForm = this._formBuilder.group({
      PhoneID: new UntypedFormControl(val.PhoneID),
      InternalRecordID: new UntypedFormControl(this.InternalRecordID, [Validators.required]),
      InternalTableName: new UntypedFormControl(this.InternalTableName, [Validators.required]),
      PhoneType: new UntypedFormControl(val.PhoneType, Validators.required),
      Phone: new UntypedFormControl(val.Phone, [Validators.required]),
      isDeleted: new UntypedFormControl(false),
    });
  }
  // convenience getter for easy access to form fields
  get PhoneF() {
    return this.PhoneForm.controls;
  }
  onPhoneFormSubmit() {
    this.phoneSubmitted = true;
    // stop here if form is invalid
    if (this.PhoneForm.invalid) {
      return;
    }
    this._genericComponentService.fnUpsertPhone(this.PhoneForm.value)
      .subscribe(data => {
        if (data.Success) {
          this.PhoneValue = new PhoneModel();
          this.phoneSubmitted = false;
          this.addEditPhone(new PhoneModel(), 0);
          this.grid.refreshDataAndView();
        }
      });
  }
  addEditPhone(val: PhoneModel, flag) {
    this.phoneSubmitted = false;
    if (flag == 1) {
      this._genericComponentService.fnGetPhoneData(val).subscribe(data => {
        this.phoneFormInit(data.Data);
        this.addEditPhoneModal.show();
      });
    }
    else
      this.addEditPhoneModal.hide();
  }
  //phone grid code ends here



  // grid helper code

  // grid helper code ends here



  ngOnInit() {
    this.phoneFormInit(new PhoneModel());
    this.GridInit();
    this.fnTranslate();
  }

  ngOnDestroy() {
    this.grid.SaveState();
  }

  GridInit() {
    var self = this;
    this.optionsGrid = {
      title: "Phone Numbers",
      scrollModel: { autoFit: true },
      // create: function () {
      //   //restore state of grid.
      //   var StateVal = localStorage.getItem('pq-grid' + self.GridName);
      //   this.loadState({ refresh: false, state: StateVal });
      // },

      toolbar: {
        items: this.hideAddButton ? [] : [
          // {
          //   type: 'button',
          // label: self._route.snapshot.data.Data['Reset filters'],
          //   listener: function () {
          //     this.reset({ filter: true });
          //   }
          // },
          {
            type: 'button',
            icon: 'ui-icon-plus',
            label: '',
            cls: 'pq-add-button',
            listener: function () {
              self.addEditPhone(new PhoneModel(), 1);
            }
          }
        ]
      },
      rowDblClick: function (evt, ui) {
        var previewObj: GenericPreviewModel = new GenericPreviewModel();
        previewObj.Columns = this.colModel;
        previewObj.RowData = ui.rowData;
        previewObj.Heading = 'Grid Details Preview';
        const dialogRef = self._dialog.open(PreviewGenericRowDataDialogComponent, {
          data: previewObj,
          width: '1000px'
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      },
      pageModel: {
        type: "remote", rPP: environment.pq_grid.default_rPP, curPage: 1, rPPOptions: [5, 10, 20, 50, 100],
        layout: []
        // layout: ['prev', 'next', "|", "strPage", "|", "refresh", "|", "strRpp"]
        // layout: ['prev', 'next', "|", "strPage"]
        // layout: ['first', 'prev', 'next', 'last', "|", "strPage"]
      },
      filterModel: {
        on: false,
        header: false,
        type: 'remote',
        menuIcon: false
      },
      sortModel: {
        type: 'remote',
        single: false,
        sorter: [{ dataIndx: 'PhoneID', dir: 'down' }],
        space: true,
        multiKey: null
      },
      colModel: [
        {
          title: "", editable: false, width: 25, sortable: false, align: "center", menuIcon: false, cls: this.isForCustomerReport ? "hide" : "",
          render: function (ui) {
            return "<a style='color: green;cursor: pointer;' title= " + self.clickToEdit + "     class='edit_btn'><span>&#x270F;</span></a>\
            <a style='color: red;cursor: pointer;' title= "+ self.clickToDelete + "     class='delete_btn'><span>&#x274C;</span></a>";
          },
          postRender: function (ui) {
            var rowIndx = ui.rowIndx,
              grid = this,
              $cell = grid.getCell(ui);
            //edit button
            $cell.find(".edit_btn").off("click")
              .on("click", function (evt) {
                self.addEditPhone(ui.rowData, 1);
              });
            //delete button
            $cell.find(".delete_btn").off("click")
              .on("click", function (evt) {
                self.showConfirmationModal();
                self.deleteRowValue = ui.rowData;
                self.deleteRowType = 'phone';
              });
          }
        },
        { title: "Type", dataType: "string", dataIndx: "PhoneType", filter: { crules: [{ condition: 'range' }] } },
        { title: "Number", dataType: "string", dataIndx: "Phone", filter: { crules: [{ condition: 'contain' }] } },
        // {
        //   title: "Actions", hidden: true, editable: false, sortable: false, align: "center",
        //   render: function (ui) {
        //     return "<button type='button' class='edit_btn m-r-5'></button> <button type='button' class='delete_btn'></button>";
        //   },
        //   postRender: function (ui) {
        //     var rowIndx = ui.rowIndx,
        //       grid = this,
        //       $cell = grid.getCell(ui);
        //     //edit button
        //     $cell.find(".edit_btn").button({ icons: { primary: 'ui-icon-pencil' } }).off("click")
        //       .on("click", function (evt) {
        //         self.addEditPhone(ui.rowData, 1);
        //       });
        //     //delete button
        //     $cell.find(".delete_btn")
        //       .button({ icons: { primary: 'ui-icon-close' } }).off("click")
        //       .on("click", function (evt) {
        //         self.showConfirmationModal();
        //         self.deleteRowValue = ui.rowData;
        //         self.deleteRowType = 'phone';
        //       });
        //   }
        // }
      ],
      selectionModel: { type: 'row' },
      // sort: function (evt, ui) {
      //   this.onSort(evt, ui);
      // },
      dataModel: {
        location: "remote",
        postData:
        {
          InternalRecordID: self.InternalRecordID,
          InternalTableName: self.InternalTableName
        },
        dataType: "JSON",
        method: "POST",
        url: environment.apiUrl + "generic/get-phone-list",
        getData: function (dataJSON) {
          var data = JSON.parse(dataJSON.Data);
          return { curPage: data.curPage, totalRecords: data.totalRecords, data: data.data };
        }
      }
    };
  }

  // get Modal Pop-up data
  fnGetDropDownData() {

  }

  // get Modal Pop-up data ends here


  // Confirmation modal pop over
  @ViewChild('confirmationModal', { static: false }) confirmationModal: ModalDirective;
  deleteRowValue: any;
  deleteRowType: string;
  showConfirmationModal(): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Are you sure you want to delete this record?",
        isDelete: true,
        buttonText: {
          ok: 'Confirm',
          cancel: 'Decline'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteRecord();
      }
    });
  }


  hideConfirmationModal(): void {
    this.confirmationModal.hide();
  }
  // Confirmation modal pop over end here
  // Address code starts here

  deleteRecord() {
    this._genericComponentService.fnDeletePhone(this.deleteRowValue).subscribe(data => {
      if (data.Success) {
        this.grid.refreshDataAndView();
      }
      this.hideConfirmationModal();
      this.deleteRowValue = null;
      this.deleteRowType = null;
    });
  }
  // Address code ends here

  fnTranslate() {
    for (let index = 0; index < this.optionsGrid.colModel.length; index++) {
      if (this.optionsGrid.colModel[index].title) {
        this.translate.get(this.optionsGrid.colModel[index].title.toString()).subscribe(translationValue => {
          this.optionsGrid.colModel[index].title = translationValue;
        });
      }
    }
    this.translate.get(this.optionsGrid.title).subscribe(translationValue => {
      this.optionsGrid.title = translationValue;
    });

    this.translate.get(this.clickToEdit).subscribe(translationValue => {
      this.clickToEdit = translationValue;
    });
    this.translate.get(this.clickToDelete).subscribe(translationValue => {
      this.clickToDelete = translationValue;
    });
  }
}
