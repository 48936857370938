<mat-sidenav-container>
    <mat-sidenav *ngIf="settings.menu == 'vertical'" [opened]="settings.sidenavIsOpened"
        [mode]="(settings.sidenavIsPinned) ? 'side' : 'over'" #sidenav class="sidenav mat-elevation-z6">
        <app-sidenav></app-sidenav>
    </mat-sidenav>
    <mat-sidenav-content id="main" perfectScrollbar [disabled]="settings.fixedHeader" (psScrollY)="onPsScrollY($event)">
        <mat-toolbar color="primary" class="flex-p-x">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <button *ngIf="settings.menu == 'vertical'" mat-icon-button (click)="toggleSidenav()">
                        <mat-icon>menu</mat-icon>
                    </button>
                    <!-- <button fxShow="false" fxShow.gt-xs *ngIf="settings.menu == 'vertical'" mat-icon-button
                        (click)="settings.sidenavUserBlock = !settings.sidenavUserBlock">
                        <mat-icon>person</mat-icon>
                    </button> -->
                    <!-- <a (click)="closeSubMenus()" class="small-logo">
                        <img src="assets/images/clearviewsyslogo.png" class="img-fluid" alt="logo"
                            style="width:40px">
                    </a> -->
                    <a class="logo f-z-16" (click)="closeSubMenus()">CXR</a>
                    <span class="f-z-16">
                        <span *ngIf="OwnerCompanyName != null">-</span> {{OwnerCompanyName}} <small
                            style="font-size: 10px;">{{appVersion}}</small>
                        <span *ngIf="isDemo" class="" class="f-z-16" style=" color: #f99898;">
                            {{'Demo' | translate}}
                        </span>
                    </span>
                </div>
                <div style="z-index: 22; margin-right: 1" *ngIf="settings.menu != 'vertical'">
                    <app-horizontal-menu fxShow.gt-sm="true" fxShow="false" [menuParentId]="0"></app-horizontal-menu>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center">
                    <app-user-menu></app-user-menu>
                    <app-fullscreen></app-fullscreen>
                </div>
            </div>
        </mat-toolbar>
        <div id="main-content" class="inner-sidenav-content transition-2" perfectScrollbar
            [disabled]="!settings.fixedHeader" [class.horizontal-menu-hidden]="isStickyMenu"
            style="padding-top:0px !important">
            <app-breadcrumb></app-breadcrumb>
            <router-outlet style="margin-bottom: 20px;"></router-outlet>
        </div>

        <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition-2"
            (click)="scrollToTop();">
            <mat-icon>arrow_upward</mat-icon>
        </div>

        <div #backToTop fxLayout="row" fxLayoutAlign="center center" class="back-to-top" (click)="scrollToTop()">
            <mat-icon>arrow_upward</mat-icon>
        </div>

    </mat-sidenav-content>

</mat-sidenav-container>












<!-- <mat-sidenav-container>
    <mat-sidenav *ngIf="settings.menu == 'vertical'" [opened]="settings.sidenavIsOpened"
        [mode]="(settings.sidenavIsPinned) ? 'side' : 'over'" #sidenav class="sidenav mat-elevation-z6">
        <app-sidenav></app-sidenav>
    </mat-sidenav>
    <mat-sidenav-content id="main" perfectScrollbar [disabled]="settings.fixedHeader" (psScrollY)="onPsScrollY($event)">
        <mat-toolbar color="primary" class="flex-p-x">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <a (click)="closeSubMenus()" class="small-logo">
                        <img src="assets/images/clearviewsyslogo.png" alt="logo" style="width:40px">
                    </a>
                    <a class="logo" (click)="closeSubMenus()">CXR</a>
                </div>
                <div style="z-index: 22; margin-right: 1">
                    <app-horizontal-menu fxShow.gt-sm="true" fxShow="false" [menuParentId]="0"></app-horizontal-menu>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <span>CRM</span>
                    </div>
                    <app-fullscreen></app-fullscreen>
                    <app-user-menu></app-user-menu>
                </div>
            </div>
        </mat-toolbar>
        <div id="main-content" class="inner-sidenav-content transition-2" perfectScrollbar
            [disabled]="!settings.fixedHeader" [class.horizontal-menu-hidden]="isStickyMenu">
            <app-breadcrumb></app-breadcrumb>
            <router-outlet></router-outlet>
        </div>
        <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition-2"
            (click)="scrollToTop();">
            <mat-icon>arrow_upward</mat-icon>
        </div>

        <div #backToTop fxLayout="row" fxLayoutAlign="center center" class="back-to-top" (click)="scrollToTop()">
            <mat-icon>arrow_upward</mat-icon>
        </div>

    </mat-sidenav-content>
</mat-sidenav-container> -->
