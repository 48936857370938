import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderService } from '../_services/loader.service';
import { AmlAlertDisplayDialogComponent } from '../shared-dialog/aml-alert-display-dialog/aml-alert-display-dialog.component';
import { NotificationService } from '../shared/_services/notification.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  loader: boolean;
  isLoaderActive: boolean;
  TokenTime: Date = new Date();

  // count: number = 0;
  // ApiDoneCount: number = 0;
  constructor (
    private _notificationService: NotificationService,
    private _loaderService: LoaderService,
    private _dialog: MatDialog
  ) {
    //this.TokenTime.setMinutes(this.TokenTime.getMinutes() + 1)
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this._loaderService.Count++;

    if (!this._loaderService.disableLoader) {
      // add authorization header with jwt token if available
      this._loaderService.showLoader(
        request.headers.get('isCheckAgainstSanctionList') == 'true'
          ? true
          : false
      );
    }

    this._loaderService.resetDisableLoader();

    var user: any = null;

    if (
      GetUserObject.GetSession() != null &&
      decodeURIComponent(escape(atob(GetUserObject.GetSession()))) !=
      'undefined'
    ) {
      user = GetUserObject.GetUserObject();
    }

    if (user != null) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + localStorage.getItem('AuthToken'), //`Bearer ${user['SessionKey']}`,
          BranchId: JSON.stringify(GetUserObject.GetBranchID()),
          Language: GetUserObject.getLanguage(),
          ModuleId: sessionStorage.getItem('ModuleId') ?? ''

        },
      });
    }
    if (request.body) {
      for (var key in request.body) {
        if (typeof (request.body[key]) === 'string' && key?.toLowerCase().includes("password") == false) {
          request.body[key] = request.body[key]?.trim().replace(/  +/g, ' ');
        }
      }
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.status == 403) {
            }
            if (event.body == null) {
              this._loaderService.Count = this._loaderService.ApiDoneCount = 0;
              // this.translate.get("Internal Server Error").subscribe(res => {
              //     this._notificationService.error(res)
              // })
              this.removeLoader();
            } else {
              sessionStorage.setItem('tokenTime', JSON.stringify(new Date()));
              if (event.body.Success == true) {
                if (
                  event.body.Message != null &&
                  event.body.Message.trim() != ''
                ) {
                  this._notificationService.success(event.body.Message);
                }
              } else if (event.body.Success == false) {
                if (
                  event.body.Message != null &&
                  event.body.Message.trim() != ''
                ) {
                  this._notificationService.error(event.body.Message);
                }
              }
              if ((event.body?.Messages && event.body.Messages.length > 0) && !request.url.includes("invoice-submit")) {
                // call dialog
                setTimeout(() => {
                  this._dialog.open(AmlAlertDisplayDialogComponent, {
                    data: { data: event.body?.Messages, isInvoice: false },
                    width: "600px"
                  });
                }, 300);

              }

              if (!this._loaderService.disableLoader) {
                this._loaderService.ApiDoneCount++;

              }
              if (
                this._loaderService.Count == this._loaderService.ApiDoneCount
              ) {
                this.removeLoader();
              }
            }

            //  clearTimeout(timer);
          }
        },
        (err: any) => {
          this._loaderService.ApiDoneCount--;
          // // TODO: error handling if required
          // console.log("Error ............");
          // if (err.status == 401) {
          //   console.log("Error in Inter");
          // }
          return throwError(err);
        },
        () => {
          this.removeLoader();
        }
      )
    );
  }

  public handleSuccess() {
    this.removeLoader();
  }
  public removeLoader() {
    this._loaderService.hideLoader();
    this.loader = false;
    this.isLoaderActive = false;
  }
  public addLoader(type: boolean) {
    if (!this.loader) {
      if (this.isLoaderActive) {
        this._loaderService.showLoader(type);
        this.loader = true;
      }
    }
  }
}

// export class loginModel {
//     email: string;
//     password: string;
//     authToken: string;
//     permissionsName: Array<any>;
//     roleID: string;
//     userID: number;
//   }
