import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export class loaderModel {
  isLoaderOn: boolean;
  isCheckAgainstSanctionList: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  Count = 0;
  ApiDoneCount = 0;
  disableLoader = false;

  constructor() {}
  private loaderState = new Subject<loaderModel>();
  message$: Observable<loaderModel> = this.loaderState.asObservable();

  showLoader(type: boolean) {
    var data = new loaderModel();
    data.isLoaderOn = true;
    data.isCheckAgainstSanctionList = type;
    this.loaderState.next(data);
  }

  hideLoader() {
    var data = new loaderModel();
    data.isLoaderOn = false;
    data.isCheckAgainstSanctionList = false;
    this.loaderState.next(data);
  }

  resetDisableLoader(): void {
    this.disableLoader = false;
  }
  setDisableLoader(): void {
    this.disableLoader = true;
  }
}
