<div>
    <h4 mat-dialog-title>{{'Till Report' | translate}}
        <button type="button" class="close pull-right" (click)="onNoClick()" aria-label="Close">
            <span aria-hidden="true" class="text-black">&times;</span>
        </button>
    </h4>
    <div mat-dialog-content>
        <div class="">
            <div class="col-xs-12 col-sm-4 col-md-4  m-b-10">
                <cxr-prime-calendar [(ngModel)]="ReportDate">
                </cxr-prime-calendar>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 m-b-10" *ngIf="this.isAdminLoggedIn && !this.isAgent">
                <mat-select class="form-control" [placeholder]="'Select User' | translate" [(ngModel)]="UserId">
                    <mat-option *ngFor="let user of UsersList" [value]="user.ID">
                        {{user.Name}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 m-b-10" *ngIf="this.isAdminLoggedIn && !this.isAgent">
                <mat-select class="form-control" [placeholder]="'Select Branch' | translate" [(ngModel)]="BranchId">
                    <mat-option *ngFor="let branch of BranchesList" [value]="branch.BranchID">
                        {{branch.BranchName +' - '+ branch.BranchCode}}
                    </mat-option>
                </mat-select>
            </div>
            <!-- // <h4 class="pull-left row">Select Preference</h4> -->

            <div class="col-xs-12">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-4 pull-left-b-10"
                        *ngIf="this.isAdminLoggedIn && !this.isAgent">
                        <mat-select class="form-control" [placeholder]="'Select Till' | translate" [(ngModel)]="TillId">
                            <mat-option>{{'Select' | translate}}</mat-option>
                            <mat-option *ngFor="let item of tillsList" [value]="item.TillID">
                                {{item.TillExtID}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4">
                        <mat-checkbox [(ngModel)]="isZeroBalanceAccounts">{{'Include zero Balance Accounts' |
                            translate}}
                        </mat-checkbox>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4">
                        <mat-checkbox [(ngModel)]="isEodTellerProof">{{'Include EOD Tellerproof lines' | translate}}
                        </mat-checkbox>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4 margin-top-10">
                        <mat-checkbox [(ngModel)]="isIncludeSharedTills">{{'Include Shared Tills' | translate}}
                        </mat-checkbox>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 margin-top-10">
                        <mat-radio-group [(ngModel)]="isReportByUser">
                            <mat-radio-button [value]="true">{{'Query by User' | translate}}</mat-radio-button>
                            <mat-radio-button [value]="false" style="margin-left: 10px;">{{'Query by Account' |
                                translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end">
        <button mat-raised-button type="button" class="add-dark-hover btn-default" (click)="onNoClick()">{{'Cancel' |
            translate}}</button>
        <button mat-raised-button (click)="generateTillReport()" class="add-hover btn-success" type="submit">{{'Confirm'
            | translate}}</button>
    </mat-dialog-actions>
</div>
