import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DateRangeDialogComponent } from 'src/app/shared-dialog/date-range-dialog/date-range-dialog.component';
import { NotificationService } from 'src/app/shared/_services';
import { DateRangeModel } from './date-rage-model';


@Component({
  selector: 'cxr-date-range-pick-button',
  templateUrl: './date-range-pick-button.component.html',
  styleUrls: ['./date-range-pick-button.component.scss']
})
export class DateRangePickButtonComponent {

  @Input() fromDate: string;
  @Input() toDate: string;

  @Output() dateRange = new EventEmitter<DateRangeModel>();
  constructor (private notificationService: NotificationService,
    private _dialog: MatDialog) { }


  ngOnChanges(changes: SimpleChanges): void {
  }

  openDateRangeDialog(): void {
    const dialogRef = this._dialog.open(DateRangeDialogComponent, {
      width: "700px",
      data: {
        fromDate: this.fromDate,
        toDate: this.toDate
      }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.dateRange.emit(data);
      }
    });
  }

}
