<div class="row m-b-10">
    <div class="col-xs-12">
        <pqgrid [GridID]="GridName" #grid [options]="optionsGrid"></pqgrid>
    </div>
</div>

<!--Add edit Picture ID modal pop up-->
<div bsModal #addEditPictureIDModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-sizes-name1" [config]="{backdrop: 'static'}">
    <form [formGroup]="PictureIDForm" (ngSubmit)="onPictureIDSubmit()">
        <bs-modal-backdrop></bs-modal-backdrop>
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 id="dialog-sizes-name1" class="modal-title pull-left">
                        <span *ngIf="PictureIDF.PictureID.value == null">{{'Add Supporting Document' |
                            translate}}</span>
                        <span *ngIf="PictureIDF.PictureID.value != null">{{'Update Supporting Document' |
                            translate}}</span>

                    </h4>
                    <button type="button" class="close pull-right" (click)="addEditPictureID(null,0)"
                        aria-label="Close">
                        <span aria-hidden="true" class="text-white">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="mandatory" for="">{{'PictureID Type' | translate}}:</label>
                                <mat-select placeholder="{{'Select PictureID Type' | translate}}" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && PictureIDF.PictureIDType.errors }"
                                    formControlName="PictureIDType">
                                    <mat-option [value]="null" selected disabled>{{'Select' | translate}}</mat-option>
                                    <mat-option *ngFor="let item of PictureIDTypeDD" [value]="item.LookupID">
                                        {{item.LookupName}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="submitted && PictureIDF.PictureIDType.errors" class="invalid-feedback">
                                    <div *ngIf="PictureIDF.PictureIDType.errors.required">{{'Required' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="mandatory">{{'Picture ID' | translate}} #:</label>
                                <input type="text" class="form-control" placeholder="{{'Picture ID' | translate}} #"
                                    formControlName="PictureIDNumber"
                                    [ngClass]="{ 'is-invalid': submitted && PictureIDF.PictureIDNumber.errors }">
                                <div *ngIf="submitted && PictureIDF.PictureIDNumber.errors" class="invalid-feedback">
                                    <div *ngIf="PictureIDF.PictureIDNumber.errors.required">{{'Required' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="">{{'Date of Issue' | translate}}:</label>
                                <cxr-prime-calendar formControlName="IssueDate" [max]="today"
                                    [ngClass]="{ 'is-date-invalid': submitted && PictureIDF.IssueDate.errors }">
                                </cxr-prime-calendar>
                                <div *ngIf="submitted && PictureIDF.IssueDate.errors" class="invalid-feedback">
                                    <div *ngIf="PictureIDF.IssueDate.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="">{{'Expiry Date' | translate}}:</label>
                                <cxr-prime-calendar formControlName="ExpiryDate" [min]="today"
                                    [ngClass]="{ 'is-date-invalid': submitted && PictureIDF.ExpiryDate.errors }">
                                </cxr-prime-calendar>
                                <div *ngIf="submitted && PictureIDF.ExpiryDate.errors" class="invalid-feedback">
                                    <div *ngIf="PictureIDF.ExpiryDate.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="error.isError" class="col-xs-12 m-b-10"
                            style="text-align: center !important;color:red;">
                            {{ error.errorMessage }}
                        </div>

                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="">{{'Country of Issue' | translate}}:</label>
                                <cxr-country-dropdown [countriesListDD]="countriesListDD"
                                    [countriesList]="countriesListDD" formControlName="CountryOfIssue"
                                    [isInvalid]="submitted && PictureIDF.CountryOfIssue.errors">
                                </cxr-country-dropdown>
                                <!-- <mat-select placeholder="{{'Select Country' | translate}}" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && PictureIDF.CountryOfIssue.errors }"
                                    formControlName="CountryOfIssue">
                                    <input type="text" class="form-control" placeholder="Search"
                                        [formControl]="countryControl">
                                    <mat-option [value]="null" selected>{{'Select' | translate}}</mat-option>
                                    <mat-option *ngFor="let item of countriesListDD" [value]="item.name">
                                        {{item.alpha2Code}} - {{item.name}}
                                    </mat-option>

                                </mat-select> -->
                                <!-- <input type="text" class="form-control" placeholder="Enter Country Of Issue"
                                    formControlName="CountryOfIssue"
                                    [ngClass]="{ 'is-invalid': submitted && PictureIDF.CountryOfIssue.errors }"> -->
                                <div *ngIf="submitted && PictureIDF.CountryOfIssue.errors" class="invalid-feedback">
                                    <div *ngIf="PictureIDF.CountryOfIssue.errors.required">{{'Required' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="">{{'Issuing State/Province' | translate}}:</label>
                                <input type="text" class="form-control"
                                    placeholder="{{'Issuing State/Province' | translate}}"
                                    formControlName="IssuingStateProvince"
                                    [ngClass]="{ 'is-invalid': submitted && PictureIDF.IssuingStateProvince.errors }">
                                <div *ngIf="submitted && PictureIDF.IssuingStateProvince.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="PictureIDF.IssuingStateProvince.errors.required">{{'Required' |
                                        translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="">{{'Issuing Authority' | translate}}:</label>
                                <input type="text" class="form-control"
                                    placeholder="{{'Issuing Authority' | translate}}" formControlName="IssuingAuthority"
                                    [ngClass]="{ 'is-invalid': submitted && PictureIDF.IssuingAuthority.errors }">
                                <div *ngIf="submitted && PictureIDF.IssuingAuthority.errors" class="invalid-feedback">
                                    <div *ngIf="PictureIDF.IssuingAuthority.errors.required">{{'Required' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label for="PictureID" class="">{{'Picture' | translate}}:</label>
                                <!-- <input type="file" class="form-control-file m-t-5" [placeholder]="'Enter Picture'"> -->
                                <input #myInput type="file" class="form-control-file m-t-5 col-xs-10"
                                    accept=".png, .jpg, .pdf" style="padding-left: 0px !important"
                                    [placeholder]="'Enter PictureID'" id="file"
                                    (change)="handleFileInput($event.target.files)"
                                    *ngIf="PictureIDF.PictureID.value == null || !fileNameToggler">
                                <!-- [ngClass]="{ 'is-invalid': submitted && (fileToUpload==null && fileName==null)}"> -->
                                <a (click)="toggleFileInput(true)" class="link-a col-xs-1 m-t-5"
                                    *ngIf="(PictureIDF.PictureID.value != null) && fileNameToggler == false">
                                    <i class="fa fa-times"></i>
                                </a>
                                <br>
                                <div class="row"
                                    *ngIf="(PictureIDF.PictureID.value != null) && fileNameToggler == true">
                                    <div class="col-xs-12"><span *ngIf="!showImageHyperlink">{{fileName}}</span>
                                        <a *ngIf="showImageHyperlink" [href]="documentLink" target="_blank"
                                            class="link-a">{{fileName}}</a>&nbsp;
                                        <a (click)="toggleFileInput(false)" class="link-a">
                                            <i class="fa fa-pencil"></i>
                                        </a>
                                    </div>
                                </div>
                                <!-- <div *ngIf="submitted && (fileToUpload==null && fileName==null)"
                                    class="invalid-feedback">
                                    <div *ngIf="submitted && (fileToUpload==null && fileName==null)">Required</div>
                                </div> -->

                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group">
                                <div class="">
                                    <div class="col-12">
                                        <button mat-raised-button type="button" (click)="checkAgainstRegula()"
                                            class="btn btn-secondary">
                                            <span>{{'Validate' | translate}}
                                            </span>
                                        </button>
                                    </div>
                                    <div class="col-12">
                                        Status:
                                        <ng-container [ngSwitch]="overallStatus">
                                            <i class="fa fa-check" style="font-size:24px;color:green"
                                                *ngSwitchCase="1"></i>
                                            <i class="fa fa-close" style="font-size:24px;color:red"
                                                *ngSwitchCase="0"></i>
                                            <i class="fa fa-exclamation-circle" style="font-size:24px;color:orange"
                                                *ngSwitchCase="2"></i>
                                            <span *ngSwitchDefault>Not checked</span>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group">
                                <div class="">
                                    <div class="col-12">
                                        <button mat-raised-button type="button" (click)="showValidatedObject(template)"
                                            class="btn btn-secondary">
                                            <span>{{'Show Validated Object' | translate}}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-xs-12">
                            <button mat-raised-button type="submit" class="pull-right btn-success">
                                <span *ngIf="PictureIDF.PictureID.value == null">{{'Save' | translate}}
                                </span>
                                <span *ngIf="PictureIDF.PictureID.value != null">{{'Update' | translate}}
                                </span>
                            </button>
                            <button mat-raised-button type="button" class="pull-right m-r-5 btn-secondary"
                                (click)="addEditPictureID(null,0)">{{'Cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<!--Confirmation modal pop up-->
<div bsModal #confirmationModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    [config]="{backdrop: 'static'}">
    <bs-modal-backdrop></bs-modal-backdrop>
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="dialog-sizes-name1" class="modal-title pull-left">Confirmation</h4>
                <button type="button" class="close pull-right" (click)="hideConfirmationModal()" aria-label="Close">
                    <span aria-hidden="true" class="text-white">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xs-12">
                        <h5>Are you sure you want to delete.?</h5>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-xs-12">
                        <button mat-raised-button type="submit" (click)="deleteRecord();" class="pull-right"
                            color="warn">Confirm</button>
                        <button mat-raised-button (click)="hideConfirmationModal()"
                            class="pull-right m-r-5 btn-secondary">Decline</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #template>
    <h4 mat-dialog-title>Validated Object
        <button type="button" class="close pull-right" (click)="onNoClick()" aria-label="Close">
            <span aria-hidden="true" class="text-black">&times;</span>
        </button>
    </h4>
    <h1></h1>
    <pre>{{pictureIdValidatedObject | json}}</pre>
</ng-template>
