import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationService } from '../../_services';
import { IbanValidation } from './iban-validation.model';
import { IbanValidationService } from './iban-validation.service';

@Component({
  selector: 'cxr-iban-validation-button',
  templateUrl: './iban-validation-button.component.html',
  styleUrls: ['./iban-validation-button.component.scss']
})
export class IbanValidationButtonComponent implements OnChanges {

  @Input() ibanNumber: string;
  @Input() isValid: boolean | null = null;
  @Input() showValidateButton = true;
  @Input() showValidationIcon = true;
  @Input() isPreview = false;
  @Output() ibanValidation = new EventEmitter<IbanValidation>();
  constructor (private ibanValidationService: IbanValidationService, private notificationService: NotificationService) { }

  ngOnChanges(changes: SimpleChanges): void {

    if (!this.isPreview && !changes['isValid']
      && changes['ibanNumber']
      && !changes['ibanNumber'].firstChange
      && changes['ibanNumber'].previousValue != changes['ibanNumber'].currentValue) {
      this.isValid = null;
    }
  }

  validateIban(): void {
    if (!this.ibanNumber || this.ibanNumber?.length < 5) {
      this.notificationService.error("Enter Minimum 5 characters");
      return;
    }
    this.ibanValidationService.validateIban(this.ibanNumber).subscribe((data: IbanValidation) => {
      this.ibanValidation.emit({ ...data });
    });
  }
}
