<h4 mat-dialog-title>Pick A Customer
    <button type="button" class="close pull-right" (click)="onNoClick()" aria-label="Close">
        <span aria-hidden="true" class="text-black">&times;</span>
    </button>
</h4>

<div mat-dialog-content>
    <div class="row">
        <div class="col-sm-12">
            <pqgrid [GridID]="GridName" #grid [options]="options"></pqgrid>
        </div>
    </div>
</div>