import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap';
import 'rxjs/add/observable/interval';
import { Localisation } from 'src/app/_services/localisation';
import { LoginService } from 'src/app/_services/login.service';
import { ChangePasswordDialogComponent } from 'src/app/shared-dialog/change-password-dialog/change-password-dialog.component';
import { environment } from 'src/environments/environment';
import { ChangeBranchDialogComponent } from '../../../pages/_dialogs/change-branch-dialog/change-branch-dialog.component';
import { GetUserObject } from '../../../pages/_helpers/get-user-object';
import { AdminService } from '../../../pages/_services/admin.service';
import { DataSharingService } from '../../../pages/_services/data-sharing.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  public userImage = environment.deployUrl + 'assets/img/users/default-user.jpg';
  branchCode: string = null;

  isAgent = GetUserObject.isAgent();
  userName = GetUserObject.GetUserFirstName();
  sessionDetails = GetUserObject.GetUserObject();
  isEntered: boolean = false;

  languages = [
    { language: 'English US', value: 'en', disable: false },
    { language: 'Spanish', value: 'es', disable: false },
    { language: 'French', value: 'fr', disable: false },
    { language: 'German', value: 'de', disable: false },
    { language: 'Portuguese', value: 'pt', disable: false },
    { language: 'Italian', value: 'it', disable: false },
    { language: 'Arabic', value: 'ar', disable: false },
    { language: 'Thai', value: 'th', disable: false }
  ];
  @ViewChild('addEditRoleModal', { static: false }) addEditRoleModal: ModalDirective;

  constructor (private _loginService: LoginService, private _router: Router,
    translate: TranslateService, private _adminService: AdminService,
    private _dialog: MatDialog, private _dataSharingService: DataSharingService) {
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
  }
  logout() {
    this._loginService.fnLogOutUser(GetUserObject.GetUserObject()).subscribe(() => {
      GetUserObject.RemoveSession();
      localStorage.removeItem("LinkUniqueID");
      sessionStorage.removeItem("tokenTime");
      sessionStorage.removeItem("ModuleId");
      this._router.navigateByUrl("/login");
    });
    // GetUserObject.RemoveSession();
    // localStorage.removeItem("LinkUniqueID");
    // this._router.navigateByUrl("/login");
  }

  currenctLanguage: string;
  ngOnInit() {
    // this.getUserBranch();
    this.currenctLanguage = GetUserObject.getLanguage() ? GetUserObject.getLanguage() : 'en';
    // Observable.interval(10).subscribe(() => {
    if (!GetUserObject.GetBranchID() && this.isEntered == false && GetUserObject.GetUserObject() && this._router.url.includes('crm') && !(GetUserObject.isAgent() || GetUserObject.isSuperAgent() || GetUserObject.isAdmin() || GetUserObject.isOwner())) {
      this.isEntered = true;
      this.openChangeBranchDialog();
    }
    // });
  }
  addEditRole(flag) {
    if (flag == 1) {
      this.addEditRoleModal.show();
    }
    else
      this.addEditRoleModal.hide();
  }
  getUserBranch() {
    // if (GetUserObject.GetBranchCode() == null || GetUserObject.GetBranchCode() == undefined) {
    //   this.openChangeBranchDialog()
    // }
    // if (GetUserObject.GetBranchCode() != null)
    return GetUserObject.GetBranchCode();
    //this.branchCode = GetUserObject.GetBranchCode();
  }

  getUserName() {
    return (GetUserObject.GetUserFirstName() ? GetUserObject.GetUserFirstName().split('')[0].toUpperCase() : '') +
      (GetUserObject.GetUserLastName() ? GetUserObject.GetUserLastName().split('')[0].toUpperCase() : '');
  }

  openChangeBranchDialog() {
    const dialogRef = this._dialog.open(ChangeBranchDialogComponent, {
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isEntered = true;
      if (result) {
        // GetUserObject.RemoveSession();
        GetUserObject.SetSession(result);
        this._dataSharingService.updateBranchId(result.BranchID);
      }
      //this.getUserBranch();
    });
  }

  //
  openChangePassword() {
    const dialogRef = this._dialog.open(ChangePasswordDialogComponent, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(() => {
    });
  }

  AddInvoice() {
    //  this._invoiceCalculatorService.fnCheckTillLogin(GetUserObject.GetOwnerID()).subscribe(data => {
    //   if (data.Success) {
    //    this._dataSharingService.fnloginInTill(data.Data);
    this._router.navigate(["/crm/transactions/invoice/add-edit-invoice"], { queryParams: { InvoiceID: btoa(unescape(encodeURIComponent((0).toString()))), isSettlement: btoa(unescape(encodeURIComponent((false).toString()))), isSettlementByCash: btoa(unescape(encodeURIComponent((false).toString()))) } });
    //  }
    // });
  }

  min: any;
  setSessionDate(): string {
    let tokenDate: Date = new Date(JSON.parse(sessionStorage.getItem('tokenTime')));
    let currentDate: Date = new Date();

    if (Math.round(((currentDate.getTime() - tokenDate.getTime()) / (1000 * 60)) % 60) > 60) {
      this.logout();
    }
    return (60 - Math.round(((currentDate.getTime() - tokenDate.getTime()) / (1000 * 60)) % 60)) + '';
  }

  changeLanguage(val) {
    let obj = GetUserObject.GetUserObject();
    obj['PreferredLanguage'] = val;
    GetUserObject.SetSession(obj);
    this._adminService.fnUpdateUSerPreferredLagulage(obj).subscribe((data) => {
      window.location.reload();
    });
  }
  change2FASettings() {
    this._adminService.fnUpdate2FASettings().subscribe((data) => {
      if (data.Success) {
        this.sessionDetails.isTwoFactoreEnabled = data.Data;
        GetUserObject.SetSession(this.sessionDetails);
      }
    });
  }
}
