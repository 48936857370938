import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { LoginAsOwnerService } from 'src/app/_services/login-as-owner.service';
import { LoginService } from 'src/app/_services/login.service';
import { ChangePasswordDialogComponent } from 'src/app/shared-dialog/change-password-dialog/change-password-dialog.component';
import { SaLookupOptionsService } from '../../../pages/_services/sa-lookup-options.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  public userImage = '../assets/img/users/user.jpg';



  @ViewChild('addEditRoleModal', { static: false }) addEditRoleModal: ModalDirective;
  constructor (private _loginService: LoginService, private _router: Router, private _dialog: MatDialog,
    private _saLookupOptionsService: SaLookupOptionsService, private _loginAsOwnerService: LoginAsOwnerService) { }
  logout() {
    this._loginService.fnLogOutUser(GetUserObject.GetUserObject()).subscribe(data => {
      GetUserObject.RemoveSession();

      this._router.navigateByUrl("/login");
    });
  }

  ngOnInit() {

  }


  openChangePassword() {
    const dialogRef = this._dialog.open(ChangePasswordDialogComponent, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  addEditRole(flag) {
    if (flag == 1) {
      this.addEditRoleModal.show();
    }
    else
      this.addEditRoleModal.hide();
  }

  loginInAsOwner() {
    this._loginAsOwnerService.loginAsOwner();
  }

}
