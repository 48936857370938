<div class="row">
    <div class="col-xs-12">
        <pqgrid [GridID]="GridName" #gridAddressReq [options]="optionsGridAddress"></pqgrid>
    </div>
</div>


<!--Add edit Address modal pop up-->
<div bsModal #addEditAddressModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-sizes-name1" [config]="{backdrop: 'static'}">
    <form [formGroup]="AddressForm" (ngSubmit)="onAddressFormSubmit()">
        <bs-modal-backdrop></bs-modal-backdrop>
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 id="dialog-sizes-name1" class="modal-title pull-left">
                        <span *ngIf="AddressF.AddressID.value == 0">Add </span>
                        <span *ngIf="AddressF.AddressID.value != 0">Update </span>
                        Address
                    </h4>
                    <button type="button" class="close pull-right" (click)="addEditAddress(null,0)" aria-label="Close">
                        <span aria-hidden="true" class="text-white">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="mandatory" for="Email Type">Address Type:</label>
                                <mat-select placeholder="Select Address Type" class="form-control"
                                    [ngClass]="{ 'is-invalid': addressSubmitted && AddressF.AddressType.errors }"
                                    formControlName="AddressType">
                                    <mat-option [value]="null" selected disabled>Select</mat-option>
                                    <mat-option *ngFor="let item of AddressTypeDD" [value]="item.LookupID">
                                        {{item.LookupName}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="addressSubmitted && AddressF.AddressType.errors" class="invalid-feedback">
                                    <div *ngIf="AddressF.AddressType.errors.required">Required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="">Street:</label>
                                <input type="text" class="form-control" placeholder="Enter Street"
                                    formControlName="Street"
                                    [ngClass]="{ 'is-invalid': addressSubmitted && AddressF.Street.errors }">
                                <div *ngIf="addressSubmitted && AddressF.Street.errors" class="invalid-feedback">
                                    <div *ngIf="AddressF.Street.errors.required">Required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="">City / Town:</label>
                                <input type="text" class="form-control" placeholder="Enter City / Town"
                                    formControlName="City"
                                    [ngClass]="{ 'is-invalid': addressSubmitted && AddressF.City.errors }">
                                <div *ngIf="addressSubmitted && AddressF.City.errors" class="invalid-feedback">
                                    <div *ngIf="AddressF.City.errors.required">Required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="">State / Province:</label>
                                <input type="text" class="form-control" placeholder="Enter State / Province"
                                    formControlName="StateProvince"
                                    [ngClass]="{ 'is-invalid': addressSubmitted && AddressF.StateProvince.errors }">
                                <div *ngIf="addressSubmitted && AddressF.StateProvince.errors" class="invalid-feedback">
                                    <div *ngIf="AddressF.StateProvince.errors.required">Required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="mandatory" for="Country ">Country:</label>
                                <mat-select placeholder="Select Country" class="form-control"
                                    [ngClass]="{ 'is-invalid': addressSubmitted && AddressF.Country.errors }"
                                    formControlName="Country">
                                    <mat-option [value]="null" selected disabled>Select</mat-option>
                                    <mat-option *ngFor="let item of countriesList" [value]="item.name">
                                        {{item.alpha2Code}} - {{item.name}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="addressSubmitted && AddressF.Country.errors" class="invalid-feedback">
                                    <div *ngIf="AddressF.Country.errors.required">Required</div>

                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="mandatory">Zip Code:</label>
                                <input type="text" class="form-control" placeholder="Enter Zip Code"
                                    formControlName="ZipCode"
                                    [ngClass]="{ 'is-invalid': addressSubmitted && AddressF.ZipCode.errors }">
                                <div *ngIf="addressSubmitted && AddressF.ZipCode.errors" class="invalid-feedback">
                                    <div *ngIf="AddressF.ZipCode.errors.required">Required</div>
                                    <div *ngIf="AddressF.ZipCode.errors.maxlength">Shouldn't exceed more than
                                        10 characters</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-xs-12">
                            <button mat-raised-button type="submit" class="pull-right btn-success">
                                <span *ngIf="AddressF.AddressID.value == 0">Save </span>
                                <span *ngIf="AddressF.AddressID.value != 0">Update </span>
                            </button>
                            <button mat-raised-button type="button" class="pull-right m-r-5 btn-secondary"
                                (click)="addEditAddress(null,0)">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>