import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalModule } from 'ngx-bootstrap';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CxrGuard } from 'src/app/_guards/cxr.guard';
import { OwnerLoginGuard } from 'src/app/_guards/owner-login.guard';
import { ReportsGuard } from 'src/app/_guards/reports-guard';
import { NotFoundComponent } from 'src/app/pages/errors/not-found/not-found.component';
import { SharedDialogModule } from 'src/app/shared-dialog/shared-dialog.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppComponent } from './app.component';
import { AppSettings } from './app.settings';
import { ModuleDetails } from './pages/_helpers/module-details';
import { ToolBarResolver } from './pages/_helpers/toolbar.resolver';
import { DataSharingService } from './pages/_services/data-sharing.service';
import { AdminLayoutComponent } from './pages/admin/admin-layout.component';
import { MergeAccountsConfirmationDialogComponent } from './pages/admin/merge-accounts/merge-accounts-confirmation-dialog/merge-accounts-confirmation-dialog.component';
import { MergeAccountsComponent } from './pages/admin/merge-accounts/merge-accounts.component';
import { AmlComponent } from './pages/aml/aml/aml.component';
import { BankNotesComponent } from './pages/bank-notes/bank-notes.component';
import { AddEditAmlAlertDialogComponent } from './pages/compliance/add-edit-aml-alert-dialog/add-edit-aml-alert-dialog.component';
import { ChangeManualRatesComponent } from './pages/currency/change-manual-rates/change-manual-rates.component';
import { CurrencyComponent } from './pages/currency/currency.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DialogModule } from './pages/dialog.module';
import { ManageContactLayoutComponent } from './pages/manage-contact/manage-contact-layout.component';
import { PagesComponent } from './pages/pages.component';
import { TillReportComponent } from './pages/teller/till-report/till-report.component';
import { TransferBetweenTillsDialogComponent } from './pages/teller/transfer-between-tills-dialog/transfer-between-tills-dialog.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { SidenavComponent } from './theme/components/sidenav/sidenav.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { PipesModule } from './theme/pipes/pipes.module';
import { translationUrl } from './translationurl';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, translationUrl, '');
}


const routes: Routes = [
  {
    path: 'crm', canActivate: [OwnerLoginGuard], canActivateChild: [OwnerLoginGuard],
    component: PagesComponent, children: [
      { path: "", redirectTo: "dashboard", pathMatch: 'full' },
      {
        path: 'bank-notes/:currency', canActivate: [CxrGuard],

        component: BankNotesComponent,
        data: { breadcrumb: "", description: "", moduleId: ModuleDetails.dashBoard }

      },
      {
        path: 'dashboard', canActivate: [CxrGuard],
        resolve: {
          Data: ToolBarResolver
        },
        component: DashboardComponent,
        data: { breadcrumb: "Dashboard", description: "", moduleId: ModuleDetails.dashBoard }

      },
      {
        path: 'manage', canActivate: [CxrGuard],
        component: ManageContactLayoutComponent,
        loadChildren: () => import('./pages/manage-contact/manage-contact.module').then(m => m.ManageContactModule),
        data: { breadcrumb: 'Contacts', description: "Manage contact screen.", moduleId: ModuleDetails.contacts }
      },
      {
        path: 'currency',
        component: CurrencyComponent, canActivate: [CxrGuard],
        loadChildren: () => import('./pages/currency/currency.module').then(m => m.CurrencyModule),
        data: { breadcrumb: 'Currency', description: "Currency screen.", moduleId: ModuleDetails.currencies }
      },
      // {
      //   path: 'aml',
      //   component: AmlComponent,
      //   loadChildren: () => import('./pages/aml/aml.module').then(m => m.AmlModule),
      //   data: { breadcrumb: 'AML Alerts', description: "AML Alerts screen." }
      // },
      {
        path: 'transactions', canActivate: [CxrGuard],
        //component: InvoicesComponent,
        resolve: {
          Data: ToolBarResolver
        },
        loadChildren: () => import('./pages/transactions/transactions.module').then(m => m.TransactionsModule),
        data: { breadcrumb: 'Modules', moduleId: ModuleDetails.modules }
      },
      // {
      //   path: 'teller-old', canActivate: [CxrGuard],
      //   //component: InvoicesComponent,
      //   loadChildren: () => import('./pages/teller/teller.module').then(m => m.TellerModule),
      //   data: { breadcrumb: 'Teller', moduleId: ModuleDetails.Teller }
      // },
      {
        path: 'teller', canActivate: [CxrGuard],
        //component: InvoicesComponent,
        loadChildren: () => import('./pages/teller/teller-new.module').then(m => m.TellerNewModule),
        data: { breadcrumb: 'Teller', moduleId: ModuleDetails.teller }
      },
      {
        path: 'reports', canActivate: [CxrGuard, ReportsGuard],
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
        data: { breadcrumb: 'Reports', moduleId: ModuleDetails.reports }
      },
      {
        path: 'admin', canActivate: [CxrGuard],
        component: AdminLayoutComponent,
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
        resolve: {
          Data: ToolBarResolver
        },
        data: { breadcrumb: 'Admin', description: "Admin screen.", moduleId: ModuleDetails.admin }
      },
      // {
      //   path: 'accounts',
      //   component: AccountsComponent,
      //   loadChildren: () => import('./pages/transactions/accounts.module').then(m => m.AccountsModule),
      //   data: { breadcrumb: 'Accounts', description: "Accounts screen." }
      // },
      {
        path: 'compliance', canActivate: [CxrGuard],
        loadChildren: () => import('./pages/compliance/compliance.module').then(m => m.ComplianceModule),
        // data: { breadcrumb: 'Accounts', description: "Accounts screen." }
        data: { breadcrumb: 'AML', moduleId: ModuleDetails.AML }
      },
      {
        path: 'ewires', canActivate: [CxrGuard],
        loadChildren: () => import('./pages/ewire/ewire.module').then(m => m.EwireModule),
        data: { breadcrumb: 'eWire', moduleId: ModuleDetails.eWire }
      },
      {
        path: 'orders', canActivate: [CxrGuard],
        loadChildren: () =>
          import('./pages/orders/orders.module').then((m) => m.OrdersModule),
        data: { breadcrumb: 'Orders', moduleId: ModuleDetails.order },
        resolve: {
          Data: ToolBarResolver
        },
      },
      {
        path: 'bookings', canActivate: [CxrGuard],
        loadChildren: () => import('./pages/booking/booking.module').then(m => m.BookingModule),
        data: { breadcrumb: 'Bookings', moduleId: ModuleDetails.booking },
        resolve: {
          Data: ToolBarResolver
        },
      },
      {
        path: 'products', canActivate: [CxrGuard],
        loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule),
        data: { breadcrumb: 'Products', moduleId: ModuleDetails.products },
        resolve: {
          Data: ToolBarResolver
        },
      },
      { path: "**", component: NotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FlexLayoutModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    SharedDialogModule,
    MatTooltipModule,
    ModalModule,
    PipesModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatOptionModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBNcjxo_35qnEG17dQvvftWa68eZWepYE0'
    // }),
    PerfectScrollbarModule,
    // CalendarModule.forRoot(),
    SharedModule,
    RouterModule.forChild(routes),
    DialogModule,
    MatSlideToggleModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      extend: true
    })
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    FlagsMenuComponent,
    MergeAccountsComponent,
    ChangeManualRatesComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    AdminLayoutComponent,
    ManageContactLayoutComponent,
    TransferBetweenTillsDialogComponent,
    CurrencyComponent,
    // AccountsComponent,
    AddEditAmlAlertDialogComponent,
    AmlComponent,
    TillReportComponent,
    MergeAccountsConfirmationDialogComponent
  ],
  providers: [
    AppSettings, DataSharingService, OwnerLoginGuard,
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export class CxrCrmSharedModule {
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers: [],
    };
  }
}
