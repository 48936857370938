import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { LookupDD, Lookups } from 'projects/cxr-crm/src/app/pages/_models/Lookups/Lookup.model';
import { IndividualModel } from 'projects/cxr-crm/src/app/pages/_models/manage-contact/manage-individual.model';
import { LookupsService } from 'projects/cxr-crm/src/app/pages/_services/lookups.service';
import { ManageContactService } from 'projects/cxr-crm/src/app/pages/_services/manage-contact.service';
import { SanctionListService } from 'projects/cxr-crm/src/app/pages/_services/sanction-list.service';
import { TableNames, TableNamesModel } from 'src/app/_helpers/app.tablenames';

@Component({
  selector: 'cxr-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {

  StaticOwnerID = GetUserObject.GetOwnerID();
  StaticIndividualID = 64;//GetUserObject.GetUserObject()['InternalrecordID'];
  IndividualExID;
  SalutationDD: Array<Lookups> = [];
  GenderDD: Array<Lookups> = [];
  GroupTypeDD: Array<Lookups> = [];
  PhoneTypeDD: Array<Lookups> = [];
  AddressTypeDD: Array<Lookups> = [];
  EmailTypeDD: Array<Lookups> = [];
  SocialHandleTypeDD: Array<Lookups> = [];
  companiesDD: Array<LookupDD> = [];
  submitted: boolean;
  IndividualValue: IndividualModel;
  public TableNames: TableNamesModel;

  CustomerDetails: any;
  CustomerExID = new UntypedFormControl();

  GridName: string = 'SocialHandling' + GetUserObject.GetUserID();


  constructor (private _manageContantService: ManageContactService, private _router: Router, private _route: ActivatedRoute,
    private _lookupsService: LookupsService, private _formBuilder: UntypedFormBuilder, private _sanctionListService: SanctionListService,
    public _tableNames: TableNames) {
    this.TableNames = _tableNames.tablenames;

    // this._route.queryParams.subscribe(params => {
    //   this.IndividualValue = new IndividualModel(){ "IndividualID": 8 };// JSON.parse(decodeURIComponent(escape(atob(params["data"]))));
    // });

    var test = new IndividualModel();
    test.IndividualID = this.StaticIndividualID;
    this.IndividualValue = test;

    // this._route.parent.queryParams.subscribe(params => {
    // });

    var self = this;

  }


  countriesList;
  ngOnInit() {
    this.fetchDD(this.StaticOwnerID);
    this._lookupsService.fnGetCountries().subscribe(data => {
      if (data['Data'] == null) {
      }
      this.countriesList = data['Data'];
    });
  }


  fetchDD(val) {
    this._lookupsService.fnGetDropDownsByOwnerID(val).subscribe(data => {
      this.SalutationDD = data['Data'].filter(x => x.Name == "Salutation").length != 0 ? data['Data'].filter(x => x.Name == "Salutation")[0].Items : null;
      this.GenderDD = data['Data'].filter(x => x.Name == "Gender").length != 0 ? data['Data'].filter(x => x.Name == "Gender")[0].Items : null;
      this.GroupTypeDD = data['Data'].filter(x => x.Name == "Group Type").length != 0 ? data['Data'].filter(x => x.Name == "Group Type")[0].Items : null;
      this.PhoneTypeDD = data['Data'].filter(x => x.Name == "Phone Type").length != 0 ? data['Data'].filter(x => x.Name == "Phone Type")[0].Items : null;
      this.AddressTypeDD = data['Data'].filter(x => x.Name == "Address Type").length != 0 ? data['Data'].filter(x => x.Name == "Address Type")[0].Items : null;
      this.EmailTypeDD = data['Data'].filter(x => x.Name == "Email Type").length != 0 ? data['Data'].filter(x => x.Name == "Email Type")[0].Items : null;
      this.SocialHandleTypeDD = data['Data'].filter(x => x.Name == "Social Handle Type").length != 0 ? data['Data'].filter(x => x.Name == "Social Handle Type")[0].Items : null;
    });
  }

}

