import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { SaLookupOptionsService } from 'projects/cxr-super-admin/src/app/pages/_services/sa-lookup-options.service';
import { PqgridComponent } from 'src/app/pqgrid.component';
import { environment } from 'src/environments/environment';
import pq from 'src/paramquery';

@Component({
  selector: 'cxr-login-as-owner-dialog',
  templateUrl: './login-as-owner-dialog.component.html',
  styleUrls: ['./login-as-owner-dialog.component.scss']
})
export class LoginAsOwnerDialogComponent implements OnInit {

  @HostListener('window:beforeunload', ['$event'])
  saveGridState($event) {
    this.grid.SaveState();
  }
  GridName: string = 'LoginAsOwner' + GetUserObject.GetUserID();
  ngOnDestroy() {
    this.grid.SaveState();
  }
  @ViewChild('grid', { static: false }) grid: PqgridComponent;
  options: pq.gridT.options;
  deleteRowValue: any;

  constructor (
    private _dialogRef: MatDialogRef<LoginAsOwnerDialogComponent>,
    private _router: Router,
    private _saLookupOptionsService: SaLookupOptionsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    var self = this;
    this.options = {
      scrollModel: { autoFit: true },
      title: "Manage Owners",
      height: "300",
      //  flex: { one: false },
      selectionModel: { type: 'row', mode: 'single', toggle: true },
      pageModel: { type: "remote", rPP: environment.pq_grid.default_rPP, curPage: 1, rPPOptions: [5, 10, 20, 50, 100] },
      filterModel: {
        on: true,
        header: true,
        type: 'remote',
        menuIcon: false
      },
      sortModel: {
        type: 'remote',
        single: false,
        sorter: [{ dataIndx: 'OwnerID', dir: 'up' }],
        space: true,
        multiKey: null
      },
      rowDblClick: function (ui, evt) {
        self._dialogRef.close(evt.rowData);
      },
      dataModel:
      {
        location: "remote",
        // postData: { OwnerID:  GetUserObject.GetOwnerID() },
        dataType: "JSON",
        method: "POST",
        url: environment.apiUrl + "get-owners-list",
        getData: function (dataJSON) {
          var data = JSON.parse(dataJSON.Data);
          return { curPage: data.curPage, totalRecords: data.totalRecords, data: data.data };
        }
      },
      colModel: [
        { title: "Owner Code", dataType: "string", dataIndx: "OwnerCode", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Owner Name", dataType: "string", dataIndx: "OwnerName", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Company Name", dataType: "string", dataIndx: "CompanyName", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Base Currency", dataType: "string", dataIndx: "BaseCurrency", filter: { crules: [{ condition: 'contain' }] } },
      ]
    };
  }

  ngOnInit() {
  }

  onNoClick() {
    this._dialogRef.close();
  }
}
