import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { Localisation } from 'src/app/_services/localisation';
import { NotificationService } from 'src/app/shared/_services';
import { SharedService } from '../shared.service';

@Component({
  selector: 'cxr-date-range-dialog',
  templateUrl: './date-range-dialog.component.html',
  styleUrls: ['./date-range-dialog.component.scss']
})
export class DateRangeDialogComponent {
  sessionDetails = GetUserObject.GetUserObject();
  fromDate = new UntypedFormControl('', Validators.required);
  toDate = new UntypedFormControl('', Validators.required);
  selectedValue: boolean = true;
  days: number;
  DateRangeList: Array<any> = [];
  constructor (
    private _dialogref: MatDialogRef<DateRangeDialogComponent>,
    private translate: TranslateService,
    private _notificationService: NotificationService,
    private _sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: { [key: string]: any; },
    private datePipe: DatePipe,
  ) {
    _dialogref.disableClose = true;
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
  }
  ngOnInit() {
    this.fromDate.setValue(this.data?.fromDate);
    this.toDate.setValue(this.data?.toDate);
    // Fetch date ranges for date range picker
    this._sharedService.fnGetDateRangeDD().subscribe(result => {
      this.DateRangeList = result['Data'];
    });

    //To check the value change in start date
    this.fromDate.valueChanges.subscribe(val => {
      this.getDiffDays(val, this.toDate.value);
    });

    //To check the value change in end date
    this.toDate.valueChanges.subscribe(val => {
      this.getDiffDays(this.fromDate.value, val);
    });
  }
  onConfirmClick(): void {
    if (this.compareTwoDates()) {
      if (!this.fromDate.errors && !this.toDate.errors) {
        this._dialogref.close(
          {
            'fromDate': this.fromDate.value,
            'toDate': this.toDate.value
          });
      }
      else {
        this._notificationService.error("Enter All Mandatory Fields");
      }
    }
  }
  exportAll(): void {
    this._dialogref.close({ 'fromDate': undefined, 'toDate': undefined });
  }

  dayMinus(val = false) {
    let date: Date;
    if (val)
      date = this.fromDate.value ? new Date(this.fromDate.value) : new Date();
    else
      date = this.toDate.value ? new Date(this.toDate.value) : new Date();

    date.setDate(date.getDate() - 1);
    if (val)
      this.fromDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
    else
      this.toDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
  }

  dayPlus(val = false) {
    let date: Date;
    if (val)
      date = this.fromDate.value ? new Date(this.fromDate.value) : new Date();
    else
      date = this.toDate.value ? new Date(this.toDate.value) : new Date();

    date.setDate(date.getDate() + 1);
    if (val)
      this.fromDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
    else
      this.toDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
  }

  today() {
    //if (this.selectedValue == true)
    this.fromDate.setValue((GetUserObject.GetCurrentDate()));
    //else
    this.toDate.setValue((GetUserObject.GetCurrentDate()));
  }

  monthMinus() {
    let date: Date;
    if (this.selectedValue == true)
      date = this.fromDate.value ? new Date(this.fromDate.value) : new Date();
    else
      date = this.toDate.value ? new Date(this.toDate.value) : new Date();
    date.setMonth(date.getMonth() - 1);
    if (this.selectedValue == true)
      this.fromDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
    else
      this.toDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
  }

  monthPlus() {
    let date: Date;
    if (this.selectedValue == true)
      date = this.fromDate.value ? new Date(this.fromDate.value) : new Date();
    else
      date = this.toDate.value ? new Date(this.toDate.value) : new Date();
    date.setMonth(date.getMonth() + 1);
    if (this.selectedValue == true)
      this.fromDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
    else
      this.toDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
  }

  beginingOfMonth() {
    let date: Date;
    // if (this.selectedValue == true)
    date = this.fromDate.value ? new Date(this.fromDate.value) : new Date();
    // else
    //   date = this.toDate.value ? new Date(this.toDate.value) : new Date();
    date.setDate(1);
    //if (this.selectedValue == true)
    this.fromDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
    // else
    //   this.toDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
  }

  endOfMonth() {
    let date: Date;
    // if (this.selectedValue == true)
    //   date = this.fromDate.value ? new Date(this.fromDate.value) : new Date();
    // else
    date = this.toDate.value ? new Date(this.toDate.value) : new Date();
    date = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // if (this.selectedValue == true)
    //   this.fromDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
    // else
    this.toDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
  }

  yearMinus() {
    let date: Date;
    if (this.selectedValue == true)
      date = this.fromDate.value ? new Date(this.fromDate.value) : new Date();
    else
      date = this.toDate.value ? new Date(this.toDate.value) : new Date();

    date.setFullYear(date.getFullYear() - 1);
    if (this.selectedValue == true)
      this.fromDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
    else
      this.toDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
  }

  yearPlus() {
    let date: Date;
    if (this.selectedValue == true)
      date = this.fromDate.value ? new Date(this.fromDate.value) : new Date();
    else
      date = this.toDate.value ? new Date(this.toDate.value) : new Date();

    date.setFullYear(date.getFullYear() + 1);
    if (this.selectedValue == true)
      this.fromDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
    else
      this.toDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
  }

  beginingOfYear() {
    let date: Date;
    if (this.selectedValue == true)
      date = this.fromDate.value ? new Date(this.fromDate.value) : new Date();
    else
      date = this.toDate.value ? new Date(this.toDate.value) : new Date();

    date = new Date(date.getFullYear(), 0);
    date.setDate(date.getDate());
    if (this.selectedValue == true)
      this.fromDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
    else
      this.toDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
  }

  endOfYear() {
    let date: Date;
    // if (this.selectedValue == true)
    //   date = this.fromDate.value ? new Date(this.fromDate.value) : new Date();
    // else
    date = this.toDate.value ? new Date(this.toDate.value) : new Date();

    date = new Date(date.getFullYear(), 12, 0);

    // if (this.selectedValue == true)
    //   this.fromDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
    // else
    this.toDate.setValue((this.datePipe.transform(date, "yyyy-MM-dd")));
  }

  thisMonth() {
    const currentDate = new Date();
    // Set the "from date" to the start of the current month
    this.fromDate.setValue(this.datePipe.transform(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), "yyyy-MM-dd"));

    // Set the "to date" to the end of the current month
    this.toDate.setValue(this.datePipe.transform(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0), "yyyy-MM-dd"));

  }

  lastMonth() {
    const currentDate = new Date();

    // Calculate the first day of the last month
    this.fromDate.setValue(this.datePipe.transform(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1), "yyyy-MM-dd"));

    // Calculate the last day of the last month
    this.toDate.setValue(this.datePipe.transform(new Date(currentDate.getFullYear(), currentDate.getMonth(), 0), "yyyy-MM-dd"));

  }

  thisYear() {
    const currentDate = new Date();

    // Set the "from date" to the start of the current year
    this.fromDate.setValue(this.datePipe.transform(new Date(currentDate.getFullYear(), 0, 1), "yyyy-MM-dd"));

    // Set the "to date" to the end of the current year
    this.toDate.setValue(this.datePipe.transform(new Date(currentDate.getFullYear(), 11, 31), "yyyy-MM-dd"));

  }

  onSelectDaterange(event) {
    if (event.value) {
      this.fromDate.setValue((this.datePipe.transform(event.value['StartDate'], "yyyy-MM-dd")));
      this.toDate.setValue((this.datePipe.transform(event.value['EndDate'], "yyyy-MM-dd")));
    }
  }

  //Calculate the diffrence in days
  getDiffDays(sDate, eDate) {
    if (sDate && eDate) {
      let startDate = new Date(sDate);
      let endDate = new Date(eDate);
      let Time = endDate.getTime() - startDate.getTime();
      if (Time)
        this.days = Time / (1000 * 3600 * 24) + 1;
      else {
        this.days = 0 + 1;
      }
    }
  }

  //Error 
  error: any = { isError: false, errorMessage: '' };
  //From date, To date Validator
  compareTwoDates() {
    if (this.fromDate.value != null || this.toDate.value != null) {
      if (new Date(this.toDate.value) < new Date(this.fromDate.value)) {
        this.error = { isError: true, errorMessage: "Enter start date lesser than end date." };
        return false;
      }
    }
    return true;
  }
  focus(id) {
    this.selectedValue = id;
  }
}
