<div fxLayout="row wrap" #resizedDiv>
    <div class="flex-p w-100">
        <div class="row m-b-10">
            <div class="col-sm-12">
                <div class="tabs tabs-style-bar">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>