import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { Localisation } from 'src/app/_services/localisation';

@Component({
  selector: 'cxr-aml-alert-display-dialog',
  templateUrl: './aml-alert-display-dialog.component.html',
  styles: [
  ]
})
export class AmlAlertDisplayDialogComponent {

  constructor (
    private _dialogRef: MatDialogRef<AmlAlertDisplayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public Messages: any,
    translate: TranslateService,) {
    _dialogRef.disableClose = true;
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
  }


  onNoClick(): void {
    this._dialogRef.close();
  }
  onClickContinue() {
    this._dialogRef.close(true);
  }
}
