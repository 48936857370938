import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { Localisation } from 'src/app/_services/localisation';
import { PqgridComponent } from 'src/app/pqgrid.component';
import { NotificationService } from 'src/app/shared/_services/notification.service';
import { environment } from 'src/environments/environment';
import pq from 'src/paramquery';

@Component({
  selector: 'cxr-note-preview-invoice',
  templateUrl: './note-preview-invoice.component.html',
  styleUrls: ['./note-preview-invoice.component.scss']
})
export class NotePreviewInvoiceComponent implements OnInit {


  @HostListener('window:beforeunload', ['$event'])
  saveGridState($event) {
    this.Grid.SaveState();
  }
  GridName: string = 'NotePreviewInvoice' + GetUserObject.GetUserID();
  ngOnDestroy() {
    this.Grid.SaveState();
  }


  @ViewChild('Grid', { static: false }) Grid: PqgridComponent;

  NotesGridOption: pq.gridT.options;
  constructor (
    private _dialogRef: MatDialogRef<NotePreviewInvoiceComponent>,
    private _notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private _dialog: MatDialog
  ) {
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
    _dialogRef.disableClose = true;

    var self = this;
    this.NotesGridOption = {
      title: "Notes",

      scrollModel: { autoFit: true },



      // rowDblClick: function (evt, ui) {
      //   var previewObj: GenericPreviewModel = new GenericPreviewModel();
      //   previewObj.Columns = this.colModel;
      //   previewObj.RowData = ui.rowData;
      //   previewObj.Heading = 'Grid Details Preview'
      //   const dialogRef = self._dialog.open(PreviewGenericRowDataDialogComponent, {
      //     data: previewObj,
      //     width: '1000px'
      //   });
      //   dialogRef.afterClosed().subscribe(result => {
      //   });
      // },
      toolbar: {
        items: [
          {
            type: 'button',
            label: data.route.snapshot.data.Data['Reset filters'],
            cls: 'pq-filter-button',
            listener: function () {
              this.reset({ filter: true });
            }
          }
        ]
      },
      colModel: [
        // {
        //   title: "", editable: false, width: 15, sortable: false, align: "center", menuIcon: false,
        //   render: function (ui) {
        //     return "<a style='color: green;cursor: pointer;' title='Click to edit' class='edit_btn'><span>&#x270F;</span></a>\
        //     <a style='color: red;cursor: pointer;' title='Click to delete' class='delete_btn'><span>&#x274C;</span></a>";
        //   },
        //   postRender: function (ui) {
        //     var rowIndx = ui.rowIndx,
        //       grid = this,
        //       $cell = grid.getCell(ui);
        //     //edit button
        //     $cell.find(".edit_btn")
        //       // .button({ icons: { primary: 'ui-icon-pencil' } })
        //       .off("click")
        //       .on("click", function (evt) {
        //         self.addEditNote(ui.rowData, 1);
        //       });
        //     //delete button
        //     $cell.find(".delete_btn")
        //       // .button({ icons: { primary: 'ui-icon-close' } })
        //       .off("click")
        //       .on("click", function (evt) {
        //         self.OpenConfirmationDialog()
        //         // self.showConfirmationModal();
        //         self.deleteRowValue = ui.rowData;
        //         // self.deleteRowType = 'note';
        //       });
        //   }
        // },
        // { title: "ID", dataType: "string", dataIndx: "NoteID", filter: { crules: [{ condition: 'contain' }] }, hidden: true },
        { title: "Note ID", dataType: "string", dataIndx: "NoteExtID", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Note Type", dataType: "string", dataIndx: "NoteType", filter: { crules: [{ condition: 'contain' }] } },
        {
          title: "Notes", dataType: "string", dataIndx: "Notes", filter: { crules: [{ condition: 'contain' }] },
          render: function (ui) {
            return ui.rowData.Notes;
          }
        },

        // { title: "Show While Invoice", dataType: "string", dataIndx: "isShowWhileInvoice", filter: { crules: [{ condition: 'contain' }] }, hidden: !(self.TableNames.TableNameIndividual == self.InternalTableName || self.TableNames.TableNameCompany == self.InternalTableName) },
        { title: "Created User", dataType: "string", dataIndx: "CreatedUser", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Created Date", dataType: "date", dataIndx: "CreatedDate", exportRender: true, format: 'yy-mm-dd hh:mm:ss', filter: { crules: [{ condition: 'between' }] } }
      ],
      selectionModel: { type: 'row' },

      dataModel: {
        location: "remote",
        postData:
        {
          InternalRecordID: self.data.InternalRecordID,
          InternalTableName: self.data.InternalTableName,
          OwnerID: GetUserObject.GetOwnerID()
        },
        dataType: "JSON",
        method: "POST",
        url: environment.apiUrl + "generic/get-note-in-invoice",
        getData: function (dataJSON) {
          var data = JSON.parse(dataJSON.Data);
          return { curPage: data.curPage, totalRecords: data.totalRecords, data: data.data };
        }
      },
      pageModel: {
        type: "remote", rPP: environment.pq_grid.dialog_rPP, curPage: 1, rPPOptions: [5, 10, 20, 50, 100]
      },
      filterModel: {
        on: false,
        header: true,
        type: 'remote',
        menuIcon: false
      },
      sortModel: {
        type: 'remote',
        single: false,
        sorter: [{ dataIndx: 'NoteID', dir: 'down' }]
      },
    };
  }

  onNoClick() {
    this._dialogRef.close(false);
  }
  Proceed() {
    this._dialogRef.close(true);
  }



  ngOnInit() {
    this.fnTranslate();
  }

  fnTranslate() {
    for (let index = 0; index < this.NotesGridOption.colModel.length; index++) {
      if (this.NotesGridOption.colModel[index].title) {
        this.translate.get(this.NotesGridOption.colModel[index].title.toString()).subscribe(translationValue => {
          this.NotesGridOption.colModel[index].title = translationValue;
        });
      }
    }
    this.translate.get(this.NotesGridOption.title).subscribe(translationValue => {
      this.NotesGridOption.title = translationValue;
    });
  }




}
