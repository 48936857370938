import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LookupsService } from 'projects/cxr-crm/src/app/pages/_services/lookups.service';
import { Observable, of } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { NotificationService } from 'src/app/shared/_services';
import { GenericComponentService } from '../../../../_services/generic-component.service';
import { Item, AutoCompleteAddressResult, AddressAutoCompletetype, AddressAutoCompleteSearchModel } from '../address-auto-complete.model';
import { GenericAddressComponent } from '../generic-address.component';
import { PostGridAddress, PostGridAddressCompleteCAInputModel, PostGridCACompletionModel } from './post-grid-address-auto-complete.model';
import { PostGridAddressAutoCompleteService } from './post-grid-address-auto-complete.service';

@Component({
  selector: 'cxr-post-grid-address-auto-complete',
  templateUrl: './post-grid-address-auto-complete.component.html',
  styleUrls: ['./post-grid-address-auto-complete.component.scss'],
  providers: [PostGridAddressAutoCompleteService]
})
export class PostGridAddressAutoCompleteComponent extends GenericAddressComponent
  implements OnChanges {
  addressAutoCompleteControl = new UntypedFormControl();
  filteredOptions: Observable<PostGridCACompletionModel[]>;
  @Input('country') country: string;

  @Output() address = new EventEmitter<AutoCompleteAddressResult>();
  public get type() {
    return AddressAutoCompletetype;
  }
  constructor (
    private autoAddressCompleteService: PostGridAddressAutoCompleteService,
    formBuilder: UntypedFormBuilder,
    dialog: MatDialog,
    genericComponentService: GenericComponentService,
    lookupsService: LookupsService,
    translate: TranslateService,
    _router: Router,
    _route: ActivatedRoute,
    private notificationService: NotificationService
  ) {
    super(
      formBuilder,
      dialog,
      genericComponentService,
      lookupsService,
      translate,
      _router,
      _route
    );
  }
  ngOnChanges(): void {
    this.filteredOptions = this.addressAutoCompleteControl.valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((val) => {
        return this.filter(val || '');
      })
    );
  }
  filter(val: string): Observable<PostGridCACompletionModel[]> {
    if (val.length < 1) {
      return of(undefined);
    }
    const search: PostGridAddressCompleteCAInputModel = {
      countryFilter: this.countriesList.find((x) => x.name === this.country)?.alpha2Code ?? '',
      partialStreet: val,
    };

    if (search.countryFilter !== '' && search.countryFilter !== 'CA' && search.countryFilter !== 'US') {
      this.notificationService.error("Select Canada OR US as Country");
      return of(undefined);
    }

    // call the service which makes the http-request
    return this.autoAddressCompleteService
      .getPreviews(search)
      .pipe(map((response) => { return Object.entries(response).map(([key, value]) => value); }));
  }

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  inputAutoComplete: MatAutocompleteTrigger;

  addressSelection(option: PostGridAddress, index: number): void {
    const search: PostGridAddressCompleteCAInputModel = {
      partialStreet: this.addressAutoCompleteControl.value,
      cityFilter: option.city,
      countryFilter: option.country
    };

    this.autoAddressCompleteService.getAddressAutoComplete(search, index).pipe().subscribe(response => {
      if (response.Data.address) {
        let address = response.Data.address;
        let selectedAddress: AutoCompleteAddressResult = {
          countryIso2: address.country,
          country: this.countriesList.find((x) => x.alpha2Code === address.country)?.name ?? '',
          street: address.address,
          province: address.prov,
          provinceCode: address.prov,
          city: address.city,
          zipCode: address.pc,
        };
        this.address.emit(selectedAddress);
        this.addressAutoCompleteControl.setValue('');
      }
    });
  }
}

