import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WhiteSpaceValidator } from 'src/app/_helpers/white-space-validator.validator';
import { ConfirmationDialogComponent } from 'src/app/shared-dialog/confirmation-dialog/confirmation-dialog.component';
import { PickCustomerDialogComponent } from 'src/app/shared-dialog/pick-customer-dialog/pick-customer-dialog.component';
import { PickInoiceDialogComponent } from 'src/app/shared-dialog/pick-inoice-dialog/pick-inoice-dialog.component';
import { GetUserObject } from '../../_helpers/get-user-object';
import { AMLAlertModal } from '../../_models/aml/Aml.model';
import { responseModel } from '../../_models/response.model';
import { AmlAlertsService } from '../../_services/aml-alerts.service';
import { LookupsService } from '../../_services/lookups.service';
import { AmlResuloutionNotesDialogComponent } from '../aml-resuloution-notes-dialog/aml-resuloution-notes-dialog.component';

@Component({
  selector: 'cxr-add-edit-aml-alert-dialog',
  templateUrl: './add-edit-aml-alert-dialog.component.html',
  styleUrls: ['./add-edit-aml-alert-dialog.component.scss']
})
export class AddEditAmlAlertDialogComponent implements OnInit {

  constructor (
    public _dialog: MatDialog,
    private _router: Router,
    private _fb: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<AddEditAmlAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _amlAlertService: AmlAlertsService,
    private _lookupOptionsService: LookupsService,

  ) {
    _dialogRef.disableClose = true;
    this.CreateForm(new AMLAlertModal());
  }
  ngOnInit() {
    if (this.data.data) {
      this._amlAlertService.fnGetAMLAlert(this.data.data.AMLAlertExID).subscribe(res => {
        this.CreateForm(res.Data);
        this.InvoiceId = res.Data.InvID;
        this.customerID = res.Data.CustID;
      });
    }

    this.GetDD();
  }
  submitted: boolean = false;
  AMLAlertForm: UntypedFormGroup;
  saveAndAdd = false;
  sessionDetails = GetUserObject.GetUserObject();
  AMLTypes = [];
  customerID: any;
  InvoiceId: number;
  CreateForm(val: AMLAlertModal) {
    this.AMLAlertForm = this._fb.group({
      AMLAlertID: new UntypedFormControl(val.AMLAlertID),
      AMLAlertExID: new UntypedFormControl(val.AMLAlertExID),
      AlertDate: new UntypedFormControl(val.AlertDate),
      CustomerID: new UntypedFormControl(val.CustomerID, [Validators.required, WhiteSpaceValidator.cannotContainSpace]),
      CustomerName: new UntypedFormControl(val.CustomerName),
      InvoiceID: new UntypedFormControl(val.InvoiceID),
      Subject: new UntypedFormControl(val.Subject, [Validators.required, WhiteSpaceValidator.cannotContainSpace]),
      Description: new UntypedFormControl(val.Description, [Validators.required, WhiteSpaceValidator.cannotContainSpace]),
      status: new UntypedFormControl(val.status),
      ResolvedBy: new UntypedFormControl(val.ResolvedBy),
      ResolvedDate: new UntypedFormControl(val.ResolvedDate),
      ResolutionNotes: new UntypedFormControl(val.ResolutionNotes),
      isResolved: new UntypedFormControl(val.isResolved),
      CreatedDate: new UntypedFormControl(val.CreatedDate),
      ModifiedBy: new UntypedFormControl(val.ModifiedBy),
      CreatedBy: new UntypedFormControl(val.CreatedBy),
      OwnerID: this.sessionDetails.OwnerID,
      SanctionListLogID: new UntypedFormControl(val.SanctionListLogID),
      UserID: new UntypedFormControl(GetUserObject.GetUserID()),
      Type: new UntypedFormControl(val.Type),
      RuleID: new UntypedFormControl(val.RuleID),
      RuleName: new UntypedFormControl(val.RuleName),
      RuleDescription: new UntypedFormControl(val.RuleDescription),
    });
  }

  get f() { return this.AMLAlertForm.controls; }

  openDialogPickCustomer(): void {
    const dialogRef = this._dialog.open(PickCustomerDialogComponent, {
      data: { CustomerExtID: this.AMLAlertForm.value.CustomerID, isWalkinShow: false, isForInvoice: false, route: this.data.route }// this.f.Customer.value
    });
    dialogRef.afterClosed().subscribe(result => {
      this.fetchCustomerDetailsByID(result);
    });
  }

  openDialogPickInvoice(): void {
    if (!(this.AMLAlertForm.value.CustomerID) || this.AMLAlertForm.value.AMLAlertID) {
      return;
    }
    const dialogRef = this._dialog.open(PickInoiceDialogComponent, {
      data: { CustomerID: this.AMLAlertForm.value.CustomerID }// this.f.Customer.value
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.InvoiceId = result.InvoiceID;
        this.AMLAlertForm.get('InvoiceID').setValue(result.InvoiceExtID);
      }
    });
  }

  fnSaveAndAdd() {
    this.saveAndAdd = true;
  }

  fetchCustomerDetailsByID(result) {
    if (result != null || result != undefined) {
      this.customerID = result.ID;
      this.AMLAlertForm.get('CustomerID').setValue(result.CustomerID);
      this.AMLAlertForm.get('CustomerName').setValue(result.CustomerName);
    }
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.AMLAlertForm.invalid) {
      return;
    }
    this._amlAlertService.fnAmlAlertUpsert(this.AMLAlertForm.value).subscribe(data => {
      if (data['Success']) {
        if (this.saveAndAdd) {
          this.CreateForm(new AMLAlertModal());
          this.saveAndAdd = false;
          this.submitted = false;
        }
        else {
          this._dialogRef.close();
        }
      }
      else {
        //this._dialogRef.close()
      }
    });
  }
  close() {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: null,
        buttonText: {
          ok: 'Ignore and Continue',
          cancel: 'Back to Form'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this._dialogRef.close(null);
      }
    });
  }
  openNotesDialog() {
    const dialogRef = this._dialog.open(AmlResuloutionNotesDialogComponent, {
      data: {
        data: this.AMLAlertForm.value.ResolutionNotes,
        route: this.data.route
      }
    });

    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.AMLAlertForm.get('ResolutionNotes').setValue(dialogResult.ResolutionNotes ? dialogResult.ResolutionNotes : "");
      }
    });
  }

  changeStatus(val) {
    if (val != null) {
      this.AMLAlertForm.controls["ResolutionNotes"].setValidators(Validators.required);
    }
    else {
      this.AMLAlertForm.controls["ResolutionNotes"].setValidators(null);
    }

    this.AMLAlertForm.controls["ResolutionNotes"].updateValueAndValidity();
  }

  GetDD() {
    this._lookupOptionsService.fnGetDropDownsByOwnerID(GetUserObject.GetOwnerID()).subscribe(
      (data: responseModel) => {
        this.AMLTypes = data.Data.filter(x => x.Name == "Types of AML Alerts").length != 0 ? data.Data.filter(x => x.Name == "Types of AML Alerts")[0].Items : [];
        // 
      });
  }

  // viewCustomerDetails() {

  //   if (!this.AMLAlertForm.value.CustomerID) {
  //     return;
  //   }
  //   if (this.AMLAlertForm.value.CustomerID.includes('-IND-')) {
  //     var IndividualValue = {
  //       IndividualExtID: this.AMLAlertForm.value.CustomerID,
  //       IndividualID: this.customerID

  //     };
  //     this._router.navigate(['/crm/manage/individual/add-edit-individual/profile'], { queryParams: { data: btoa(unescape(encodeURIComponent(JSON.stringify(IndividualValue)))) }, skipLocationChange: false });
  //   }
  //   else if (this.AMLAlertForm.value.CustomerID.includes('-CMP-')) {
  //     var CompanyValue = {
  //       CompanyExtID: this.AMLAlertForm.value.CustomerID,
  //       CompanyID: this.customerID

  //     };


  //     this._router.navigate(['/crm/manage/company/add-edit-company'], { queryParams: { data: btoa(unescape(encodeURIComponent(JSON.stringify(CompanyValue)))) }, skipLocationChange: false });

  //     this._dialogRef.close()
  //     //.navigate(['/crm/manage/individual/add-edit-individual/profile'], { queryParams: { data: btoa(unescape(encodeURIComponent(JSON.stringify(CompanyValue)))) }, skipLocationChange: false });

  //   }


  // }

  emitData(event) {
    this.AMLAlertForm.get('Description').setValue(event.Note);
  }
  openCustomerDetails() {
    if (!this.AMLAlertForm.value.CustomerID) {
      return;
    }
    if (this.AMLAlertForm.value.CustomerID.includes('-IND-')) {
      var IndividualValue = {
        IndividualExtID: this.AMLAlertForm.value.CustomerID,
        IndividualID: this.customerID
      };
      let obj: Object = new Object;
      // obj['IndividualValue'] = {};
      obj['IndividualID'] = this.customerID;
      obj['IndividualExtID'] = this.AMLAlertForm.value.CustomerID;
      let param = btoa(unescape(encodeURIComponent(JSON.stringify(obj))));
      window.open("/crm/manage/individual/add-edit-individual/profile?data=" + param);

    }
    else if (this.AMLAlertForm.value.CustomerID.includes('-CMP-')) {
      var CompanyValue = {
        CompanyExtID: this.AMLAlertForm.value.CustomerID,
        CompanyID: this.customerID
      };
      let param = btoa(unescape(encodeURIComponent(JSON.stringify(CompanyValue))));
      window.open("/crm/manage/company/add-edit-company?data=" + param);
    }

  }
  openInvoiceDetails() {
    if (this.InvoiceId)
      window.open("/crm/transactions/invoice/preview-invoice?InvoiceID=" + btoa(unescape(encodeURIComponent(this.InvoiceId))));
  }
}
