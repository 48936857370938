<h5 mat-dialog-title>{{'AML Rule Alert' | translate}}
    <button type="button" class="close pull-right" (click)="onNoClick()" aria-label="Close">
        <span aria-hidden="true" class="text-black">&times;</span>
    </button>
</h5>


<div mat-dialog-content>
    <div class="row">
        <div class="col-xs-12" *ngFor="let message of Messages.data; let i = index">
            <h5>
                {{(i+1)+ "." + message}}
            </h5>
        </div>
    </div>
</div>
<mat-dialog-actions align="center">
    <button mat-raised-button type="button" class="add-dark-hover btn-default" (click)="onNoClick()">{{'Close' |
        translate}}</button>
    <button *ngIf="Messages.isInvoice" mat-raised-button class="add-hover btn-success" type="submit"
        (click)="onClickContinue()">
        {{'Ignore and continue to Save Invoice' | translate}}</button>
</mat-dialog-actions>
