<mat-card class="mat-card-graph">
    <div class="row col-md-3 col-sm-3">
        <div fxFlex="100" fxFlex.gt-sm fxFlex.sm class="flex-p w-100">
            <div class="tableFixHead table-responsive scroll">
                <div class="col-md-12 col-sm-12 custom-band">
                    {{'Sales Revenue' | translate}}
                    <div [formGroup]="filterForm" class=" col-md-6 col-sm-12 pull-right">
                        <mat-select class=" form-group form-control dropdown" formControlName="TotalRecords"
                            (selectionChange)="Changedays($event)" placeholder="{{'Select' | translate}}">
                            <mat-option *ngFor="let item of DropDownFilter" [value]="item.value">{{item.name |
                                translate}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
                <table class="table table-striped table-bordered table-hover">
                    <thead style="background: #1ba4fc!important;color:#eee">
                        <tr>
                            <th>{{'Month' | translate}}</th>
                            <th>{{'Sales Volume' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of tableList;let i = index">
                            <td>{{item.MonthName | translate}}</td>
                            <td>{{item.TotalSales | numberFormat:2}}</td>
                        </tr>

                        <tr *ngIf="tableList?.length == 0">
                            <td colspan="5" style="text-align:center">{{'No records found' | translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
    <div class="row col-md-9 col-sm-9">
        <div id="largeincomingOrders" [chart]="chart"></div>
    </div>
</mat-card>
