import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { LookupDD, Lookups } from 'projects/cxr-crm/src/app/pages/_models/Lookups/Lookup.model';
import { IndividualModel } from 'projects/cxr-crm/src/app/pages/_models/manage-contact/manage-individual.model';
import { responseModel } from 'projects/cxr-crm/src/app/pages/_models/response.model';
import { LookupsService } from 'projects/cxr-crm/src/app/pages/_services/lookups.service';
import { ManageContactService } from 'projects/cxr-crm/src/app/pages/_services/manage-contact.service';
import { forkJoin } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/shared-dialog/confirmation-dialog/confirmation-dialog.component';
import { NotificationService } from 'src/app/shared/_services/notification.service';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { TableNames, TableNamesModel } from 'src/app/_helpers/app.tablenames';

@Component({
  selector: 'cxr-individual-kyc-review-dialog',
  templateUrl: './individual-kyc-review-dialog.component.html',
  styleUrls: ['./individual-kyc-review-dialog.component.scss']
})
export class IndividualKycReviewDialogComponent implements OnInit {

  public TableNames: TableNamesModel;
  IndividualProfileForm: UntypedFormGroup;
  countriesList: any[] = [];
  ProgressValue: number = 0;
  TotalProgressBarValue: number = 5;

  constructor (
    private _dialogref: MatDialogRef<IndividualKycReviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public IndividualValue: IndividualModel,
    private _formBuilder: UntypedFormBuilder,
    private _manageContactService: ManageContactService,
    private _lookupOptionsService: LookupsService,
    public _tableNames: TableNames,
    private _dialog: MatDialog,
    private _notificationService: NotificationService,
    private translate: TranslateService
  ) {

    this.TableNames = _tableNames.tablenames;
  }

  ngOnInit() {
    this.fetchDD(GetUserObject.GetOwnerID());
    this.formInit(this.IndividualValue);
    this.FetchData();
  }

  SalutationDD: Array<Lookups> = [];
  GenderDD: Array<Lookups> = [];
  GroupTypeDD: Array<Lookups> = [];
  PhoneTypeDD: Array<Lookups> = [];
  AddressTypeDD: Array<Lookups> = [];
  EmailTypeDD: Array<Lookups> = [];
  SocialHandleTypeDD: Array<Lookups> = [];
  companiesDD: Array<LookupDD> = [];
  fetchDD(val) {
    this._lookupOptionsService.fnGetDropDownsByOwnerID(val).subscribe(data => {
      this.SalutationDD = data['Data'].filter(x => x.Name == "Salutation").length != 0 ? data['Data'].filter(x => x.Name == "Salutation")[0].Items : null;
      this.GenderDD = data['Data'].filter(x => x.Name == "Gender").length != 0 ? data['Data'].filter(x => x.Name == "Gender")[0].Items : null;
      this.GroupTypeDD = data['Data'].filter(x => x.Name == "Group Type").length != 0 ? data['Data'].filter(x => x.Name == "Group Type")[0].Items : null;
      this.PhoneTypeDD = data['Data'].filter(x => x.Name == "Phone Type").length != 0 ? data['Data'].filter(x => x.Name == "Phone Type")[0].Items : null;
      this.AddressTypeDD = data['Data'].filter(x => x.Name == "Address Type").length != 0 ? data['Data'].filter(x => x.Name == "Address Type")[0].Items : null;
      this.EmailTypeDD = data['Data'].filter(x => x.Name == "Email Type").length != 0 ? data['Data'].filter(x => x.Name == "Email Type")[0].Items : null;
      this.SocialHandleTypeDD = data['Data'].filter(x => x.Name == "Social Handle Type").length != 0 ? data['Data'].filter(x => x.Name == "Social Handle Type")[0].Items : null;
    });
    this._lookupOptionsService.fnGetCompaniesDD(val).subscribe(data => {
      this.companiesDD = data['Data'];
    });

  }
  PictureIDTypeDD: any[] = [];
  FetchData() {
    forkJoin([
      this._manageContactService.fnFetchIndividualDataForm(this.IndividualValue),
      this._lookupOptionsService.fnGetCountries(),
      this._lookupOptionsService.fnGetDropDownsByOwnerID(GetUserObject.GetOwnerID())
    ]).subscribe(data => {
      this.IndividualValue = data[0].Data;
      this.formInit(this.IndividualValue);
      this.countriesList = data[1]['Data'];
      this.PictureIDTypeDD = data[2]['Data'].filter(x => x.Name == "Document Type").length != 0 ? data[2]['Data'].filter(x => x.Name == "Document Type")[0].Items : [];
    });
  }

  formInit(val: IndividualModel) {
    this.IndividualProfileForm = this._formBuilder.group({
      IndividualID: new UntypedFormControl(val.IndividualID),
      Salutation: new UntypedFormControl(val.Salutation),
      FirstName: new UntypedFormControl(val.FirstName, [Validators.required, Validators.pattern('^[a-zA-Z0-9\.\ ]*$')]),
      LastName: new UntypedFormControl(val.LastName, [Validators.required, Validators.pattern('^[a-zA-Z0-9\.\ ]*$')]),
      Gender: new UntypedFormControl(val.Gender),
      DateOfBirth: new UntypedFormControl(val.DateOfBirth),
      Nationality: new UntypedFormControl(val.Nationality),
      CountryOfResidence: new UntypedFormControl(val.CountryOfResidence),
      CompanyID: new UntypedFormControl(val.CompanyID),
      EthnicSpelling: new UntypedFormControl(val.EthnicSpelling),
      Occupation: new UntypedFormControl(val.Occupation),
      JobPosition: new UntypedFormControl(val.JobPosition),
      GroupType: new UntypedFormControl(val.GroupType),
      NationalID: new UntypedFormControl(val.NationalID, [Validators.pattern('^[a-zA-Z0-9\.\ ]*$')]),
      isActive: new UntypedFormControl(val.isActive),
      isDeleted: new UntypedFormControl(val.isDeleted),
      EmailAddress: new UntypedFormControl(val.EmailAddress, [emailValidator]),
      // EmailID: new FormControl(val.EmailID),
      CompanyName: new UntypedFormControl(val.CompanyName),
      Phone: new UntypedFormControl(val.Phone),
      // isInsider: new FormControl(val.isInsider),
      // PhoneID: new FormControl(val.PhoneID),
      OwnerID: new UntypedFormControl(GetUserObject.GetOwnerID()),
      isCompanyOccupationVerified: new UntypedFormControl(null, [Validators.required]),
      isPhoneEmailVerified: new UntypedFormControl(null, [Validators.required]),
      isPictureIDVerifired: new UntypedFormControl(null, [Validators.required]),
      isAddressVerified: new UntypedFormControl(null, [Validators.required]),
      isAllVerified: new UntypedFormControl(false),
      ReviewNotes: new UntypedFormControl(null, [Validators.required])
    });
    this.FetchTypeAheadDD();
    // if (val.IndividualID == 0 || val.IndividualID == null) {
    //   this.f.EmailAddress.setValidators([Validators.required]);
    //   this.f.Phone.setValidators([Validators.required]);
    // } else {
    //   this.f.EmailAddress.setValidators(null);
    //   this.f.Phone.setValidators(null);
    // }
    // this.f.EmailAddress.updateValueAndValidity();
    // this.f.Phone.updateValueAndValidity();
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.IndividualProfileForm.controls;
  }
  OccupationDataTypeAhead: any[] = [];
  CompanyNameTypeAhead: any[] = [];
  FetchTypeAheadDD() {
    forkJoin([
      this._manageContactService.fnFetchOccupationDD(GetUserObject.GetOwnerIDObject()),
      this._manageContactService.fnFetchCompanyNameDD(GetUserObject.GetOwnerIDObject())
    ]).subscribe((data: responseModel[]) => {
      this.OccupationDataTypeAhead = data[0].Data;
      this.CompanyNameTypeAhead = data[1].Data;
    });
  }
  CheckProgressValue() {
    let count: number = 0;
    if (this.f.isCompanyOccupationVerified.value == true) {
      count++;
    }
    if (this.f.isPhoneEmailVerified.value == true) {
      count++;
    }
    if (this.f.isPictureIDVerifired.value == true) {
      count++;
    }
    if (this.f.isAddressVerified.value == true) {
      count++;
    }
    if (this.f.isAllVerified.value == true) {
      count++;
    }
    this.ProgressValue = Math.round((count / 5) * 100);
  }

  selectionChange($event) {
    if ($event.selectedIndex == 2) {
    }
  }


  OpenConfirmationDialog() {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: null,
        buttonText: {
          ok: 'Ignore and Continue',
          cancel: 'Back to Form'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this._dialogref.close(null);
      }
    });
  }

  onNoClick(): void {
    this._dialogref.close(null);
  }
  submitted = false;
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.IndividualProfileForm.invalid) {
      this.translate.get("Enter All Mandatory Fields").subscribe(res => {
        this._notificationService.error(res);
      });
      return;
    }

    let Company = this.companiesDD.find(x => x.Name === this.f.CompanyName.value);
    if (Company != null) {
      this.f.CompanyID.setValue(Company.Value);
    }
    else {
      this.f.CompanyID.setValue(null);
    }

    this._manageContactService.fnIndividualKYCReview(this.IndividualProfileForm.value)
      .subscribe(data => {
        if (data.Success == true) {
          this._dialogref.close(true);
        }
      });
  }
  emitData(event) {
    this.IndividualProfileForm.get('ReviewNotes').setValue(event.Note);
  }
}
