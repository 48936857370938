<h4 mat-dialog-title>{{'Notes Preview' | translate}}
    <button type="button" class="close pull-right" (click)="onNoClick()" aria-label="Close">
        <span aria-hidden="true" class="text-black">&times;</span>
    </button>
</h4>
<div mat-dialog-content>
    <div class="row">
        <div class="col-xs-12 sunny">
            <pqgrid [GridID]="GridName" #Grid [options]="NotesGridOption"></pqgrid>
        </div>
    </div>
</div>

<mat-dialog-actions align="end">
    <button mat-raised-button type="button" class="add-dark-hover btn-default" (click)="onNoClick()">{{'Go To Pick
        Customer' | translate}}</button>
    <button mat-raised-button class="add-hover btn-success" type="button" (click)="Proceed()">{{'Ignore and
        Continue' | translate}}</button>
</mat-dialog-actions>