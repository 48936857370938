<form [formGroup]="Form" (ngSubmit)="onSubmit()">

    <h4 mat-dialog-title>{{'Transfer between Tills' | translate}}
        <button type="button" class="close pull-right" (click)="onNoClick()" aria-label="Close">
            <span aria-hidden="true" class="text-black">&times;</span>
        </button>
    </h4>
    <div mat-dialog-content class="min-width">
        <div class="row">
            <div class="col-xs-12">
                <div class="row">
                    <div class="col-xs-12 col-sm-6">
                        <div class="form-group-height form-group">
                            <label for="" class="mandatory">{{'From' | translate}}:</label>
                            <mat-select placeholder="{{'Select Till' | translate}}" class="form-control"
                                [disabled]="disableFromTillDropDown" (selectionChange)="onSelectFromTill($event.value)"
                                formControlName="FromTill">
                                <mat-option *ngFor="let till of fromTills" [value]="till.TillID">
                                    {{till.TillExtID}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="submitted && FromTill.errors" class="invalid-feedback">
                                <div *ngIf="FromTill.errors.required">{{'Required' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6">
                        <div class="form-group form-group-height">
                            <label class="mandatory">{{'To' | translate}}:</label>
                            <mat-select placeholder="{{'Select Till' | translate}}" class="form-control"
                                formControlName="ToTill">
                                <mat-option *ngFor="let till of toTills" [value]="till.TillID">
                                    {{till.TillExtID}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="submitted && ToTill.errors" class="invalid-feedback">
                                <div *ngIf="ToTill.errors.required">{{'Required' | translate}}</div>
                            </div>
                            <!-- <div *ngIf="submitted && f.ToTill.errors" class="invalid-feedback">
                                <div *ngIf="f.ToTill.errors.required">Required</div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <mat-checkbox (change)="onSelectFullAmountForAllCurrencies($event)"
                            *ngIf="tillCashAccounts.length" class="pull-right">
                            {{'Select Full Amount for all Currencies' | translate}} ?
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row" *ngIf="tillCashAccounts.length">
                    <div class="col-xs-3">
                        <label class="mandatory">{{'Currency' | translate}}:</label>
                    </div>
                    <div class="col-xs-3">

                        <label class="mandatory">{{'Balance' | translate}}:</label>
                    </div>
                    <div class="col-xs-3">
                        <label class="mandatory">{{'Amount' | translate}}:</label>
                    </div>
                    <div class="col-xs-3">

                    </div>
                </div>
                <div class="row" *ngFor="let item of tillCashAccounts; let i = index">
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <div class="m-t-5">
                            <input type="" class="form-control" placeholder="" readonly [value]="item.Currency">
                            <!-- <div *ngIf="submitted && f.RegisterDate.errors" class="invalid-feedback">
                                <div *ngIf="f.RegisterDate.errors.required">Required</div>
                                <div *ngIf="f.RegisterDate.hasError('pattern')">Enter Aplhabet only</div>
                                <div
                                    *ngIf="!f.RegisterDate.hasError('required') && f.RegisterDate.hasError('cannotContainSpace')">
                                    Can not contain white space.</div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <div class="m-t-5">
                            <input type="" readonly class="form-control" placeholder=""
                                [value]="item.Balance  |  numberFormat: 2">

                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <div class="m-t-5">
                            <cxr-generic-number-input [(ngModel)]="item.Amount" numberType="1" name="Amount"
                                [ngModelOptions]="{standalone: true}" [max]="item.Balance">
                            </cxr-generic-number-input>

                            <div *ngIf="submitted && item.Amount > item.Balance" class="invalid-feedback">
                                {{'Amount cannot be more than Balance' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <button mat-button class="btn-success" type="button" (click)="AddDenomination(item)">{{'Add
                            Denomination' |
                            translate}}</button>
                        <mat-checkbox [(ngModel)]="item.isSelectedFullAmount" (change)="onSelectFullAmount(item)"
                            [ngModelOptions]="{standalone: true}">
                            {{'Select Full Amount' | translate}} ?
                        </mat-checkbox>
                    </div>
                    <!-- <div class="col-xs-12 col-sm-6 col-md-3">
                        <div class="m-t-5">
                           
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end">
        <button mat-raised-button type="button" class="add-dark-hover btn-default" (click)="onNoClick()">{{'Cancel' |
            translate}}</button>
        <button mat-raised-button class="add-hover btn-success" type="submit">{{'Save' | translate}}</button>
    </mat-dialog-actions>
</form>
