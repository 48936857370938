import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Localisation } from 'src/app/_services/localisation';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { Observable } from 'rxjs';
import { NotificationService } from '../shared/_services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class SuperOwnerLoginGuard implements CanActivate, CanActivateChild {
  constructor(
    private _router: Router,
    private translate: TranslateService,
    private _notificationService: NotificationService
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (localStorage.getItem("AuthToken") == null || localStorage.getItem("AuthToken") == undefined) {
      this._router.navigateByUrl("/login");
    }

    if (GetUserObject.isSuperAdmin()) {
      return true;
    } else {
      if (GetUserObject.GetUserObject() == null) {

        this.translate.get("Session Expired").subscribe(res => {
          this._notificationService.error(res)
        })
        // this._snackBar.open("Session Expired", '', {
        //   duration: 3000,
        //   panelClass: 'redSnackBar'
        // });
        this._router.navigate(['/login']);
      }
      // this._router.navigate(['/login']);
      return false;
    }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (localStorage.getItem("AuthToken") == null || localStorage.getItem("AuthToken") == undefined) {
      this._router.navigateByUrl("/login");
    }

    if (GetUserObject.isSuperAdmin()) {
      return true;
    } else {
      if (GetUserObject.GetUserObject() == null) {

        this.translate.get("Session Expired").subscribe(res => {
          this._notificationService.error(res)
        })
        // this._snackBar.open("Session Expired", '', {
        //   duration: 3000,
        //   panelClass: 'redSnackBar'
        // });
        this._router.navigate(['/login']);
      }
      //this._router.navigate(['/login']);
      return false;
    }
  }

}
