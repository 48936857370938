<div class="row ">
    <div class="col-xs-12 col-md-6 ">

        <h1>fdfd</h1>
        <cxr-request-address [countriesList]="countriesList" [InternalTableName]="TableNames.TableNameIndividual"
            [InternalRecordID]="StaticIndividualID" [AddressTypeDD]="AddressTypeDD">
        </cxr-request-address>
    </div>
    <div class="col-xs-12 col-md-6 m-b-10">
        <cxr-request-email [InternalTableName]="TableNames.TableNameIndividual" [InternalRecordID]="StaticIndividualID"
            [EmailTypeDD]="EmailTypeDD"></cxr-request-email>
    </div>
    <div class="col-xs-12 col-md-6 ">
        <cxr-request-phone-numbers [InternalTableName]="TableNames.TableNameIndividual"
            [InternalRecordID]="StaticIndividualID" [PhoneTypeDD]="PhoneTypeDD">
        </cxr-request-phone-numbers>
    </div>
    <div class="col-xs-12 col-md-6 m-b-10">
        <cxr-request-picture-id [InternalTableName]="TableNames.TableNameIndividual" [countriesList]="countriesList"
            [InternalRecordID]="IndividualValue.IndividualID">
        </cxr-request-picture-id>
    </div>
</div>