// const TableNameIndividual: string = "Individuals";
// const TableNameCompany: string = "Company";
// const TableNameLink: string = "Link";


import { Injectable } from '@angular/core';

export class TableNamesModel {
    constructor (
        public TableNameIndividual: string,
        public TableNameCompany: string,
        public TableNameLink: string,
        public TableNameBankInfo: string,
        public TableNameAgent: string,
        public TableNameThirdParties: string
    ) { }
}
@Injectable()
export class TableNames {
    public tablenames = new TableNamesModel("Individuals", "Company", "Link", "BankInfo", "Agent", "ThirdParties");
}

