<mat-card class="mat-card-graph">
    <div class="row">
        <div fxFlex="100" fxFlex.gt-sm fxFlex.sm class="flex-p w-100">
            <div class="tableFixHead table-responsive scroll">
                <div class="col-md-12 col-sm-12 custom-band">
                    {{'Till Summary' | translate}}
                    <span class="pull-right">
                        <mat-checkbox (change)="isShardTill($event)" [(ngModel)]="isIncludeSharedTills">
                            {{'Include Shared Tills' | translate}}
                        </mat-checkbox>
                    </span>
                    <span class="pull-right m-r-5">
                        <mat-checkbox (change)="activeAccounts($event)" [(ngModel)]="ActiveNow">{{'Active Now' |
                            translate}}
                        </mat-checkbox>
                    </span>
                </div>

                <table class="table table-striped table-bordered table-hover ">
                    <thead style="background: #1ba4fc!important;color:#eee">
                        <tr>
                            <th>{{'Account Name' | translate}}</th>
                            <th>{{'Opening' | translate}}</th>
                            <th>{{'Transfer In / Out' | translate}}</th>
                            <th>{{'Bought' | translate}}</th>
                            <th>{{'Sold' | translate}}</th>
                            <th>{{'Balance' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of tableList;let i = index">
                            <td>{{item.AccountName}}</td>
                            <td>{{item.Opening | numberFormat:2}}</td>
                            <td>{{item.TransferInOut | numberFormat:2}}</td>
                            <td>{{item.Buy | numberFormat:2}}</td>
                            <td>{{item.Sell | numberFormat:2}}</td>
                            <td>{{item.Closing | numberFormat:2}}</td>
                        </tr>
                        <tr *ngIf="tableList?.length == 0">
                            <td colspan="6" style="text-align:center">{{'No records found' | translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</mat-card>
