import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'src/app/shared-dialog/confirmation-dialog/confirmation-dialog.component';
import { AdminService } from '../../_services/admin.service';
import { MergeAccountsConfirmationDialogComponent } from './merge-accounts-confirmation-dialog/merge-accounts-confirmation-dialog.component';

export interface MergeAccountModel {
  AccountID: number;
  AccountName: string;
  MainAccountName: string;
  Currency: string;
}

@Component({
  selector: 'cxr-merge-accounts',
  templateUrl: './merge-accounts.component.html',
  styleUrls: ['./merge-accounts.component.scss']
})
export class MergeAccountsComponent implements OnInit {
  AccountsDD: Array<MergeAccountModel> = [];
  FromAccountsDD: Array<MergeAccountModel> = [];
  ToAccountsDD: Array<MergeAccountModel> = [];
  Currency: string;
  submitted: boolean = false;
  constructor (
    private _fb: UntypedFormBuilder,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<MergeAccountsComponent>,
    private translate: TranslateService,
    private _adminService: AdminService,
  ) {
    _dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.formInit();
    this.getAccounts();
    this.fromControl.valueChanges.subscribe(val => {
      if (val) {
        this.FromAccountsDD = this.AccountsDD.filter(x =>
          x.AccountName.match(new RegExp(val, "i")));
      }
      else {
        this.FromAccountsDD = this.AccountsDD;
      }
    });

    this.toControl.valueChanges.subscribe(val => {
      if (val) {
        this.ToAccountsDD = this.AccountsDD.filter(x => (x.Currency == this.Currency) &&
          x.AccountName.match(new RegExp(val, "i")));
      }
      else {
        this.ToAccountsDD = this.AccountsDD.filter(x => x.Currency == this.Currency &&
          x.AccountID != this.f.FromAccountID.value);
      }
    });
  }
  fromControl = new UntypedFormControl();
  toControl = new UntypedFormControl();

  //Form Group
  Form: UntypedFormGroup;
  formInit() {
    this.Form = this._fb.group({
      FromAccountID: new UntypedFormControl('', [Validators.required]),
      ToAccountID: new UntypedFormControl('', [Validators.required]),
    });
  }
  get f() {
    return this.Form.controls;
  }

  onNoClick(): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: null,
        buttonText: {
          ok: 'Ignore and Continue',
          cancel: 'Back to Form'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this._dialogRef.close("");
      }
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.Form.invalid) {
      return;
    }
    const dialogRef = this._dialog.open(MergeAccountsConfirmationDialogComponent, {

    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this._adminService.fnMergeAccounts(this.Form.value).subscribe(
          data => {
            if (data['Success']) {
              this._dialogRef.close(data);
            }
          });
      } else { this._dialogRef.close(); }
    });
  }
  getAccounts() {
    this._adminService.fnGetAccountsDD().subscribe(data => {
      if (data.Success) {
        this.AccountsDD = data.Data;
        this.FromAccountsDD = data.Data;
      }
    });
  }



  onSelectFromAccount(value) {
    let account = this.AccountsDD.find(x => x.AccountID == value);
    this.Currency = account.Currency;
    this.fromControl.setValue(null);
    this.FromAccountsDD = this.AccountsDD;
    this.ToAccountsDD = this.AccountsDD.filter(x => x.Currency == account.Currency && x.AccountID != value);
  }

  onSelectToAccount() {
    this.toControl.setValue(null);
  }
}
