<form [formGroup]="Form" (ngSubmit)="onSubmit()">

    <h4 mat-dialog-title>{{'Merge Accounts' | translate}}
        <button type="button" class="close pull-right" (click)="onNoClick()" aria-label="Close">
            <span aria-hidden="true" class="text-black">&times;</span>
        </button>
    </h4>
    <div mat-dialog-content class="min-width">
        <div class="row">
            <div class="col-xs-12">
                <div class="row">
                    <div class="col-xs-12" *ngIf="Currency">
                        {{'Currency' | translate}} : <b class="text-primary">{{Currency}}</b>
                    </div>
                </div>
                <div class="row m-t-10">
                    <div class="col-xs-12 col-sm-6">
                        <div class="form-group-height form-group">
                            <label for="" class="mandatory">{{'From' | translate}}:</label>
                            <mat-select placeholder="{{'Select' | translate}}" class="form-control"
                                (selectionChange)="onSelectFromAccount($event.value)" formControlName="FromAccountID">
                                <span><input type="text" class="form-control" placeholder="{{'Search' | translate}}"
                                        [formControl]="fromControl"></span>
                                <mat-option *ngFor="let item of FromAccountsDD" [value]="item.AccountID"
                                    [disabled]="item.AccountID == Form.value?.ToAccountID">
                                    <b>{{item.AccountName}}</b>&nbsp;-&nbsp; {{item.MainAccountName}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="submitted &&  f.FromAccountID.errors" class="invalid-feedback">
                                <div *ngIf="f.FromAccountID.errors.required">{{'Required' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6">
                        <div class="form-group form-group-height">
                            <label class="mandatory">{{'To' | translate}}:</label>
                            <mat-select placeholder="{{'Select' | translate}}" class="form-control"
                                (selectionChange)="onSelectToAccount()" formControlName="ToAccountID">
                                <span><input type="text" class="form-control" placeholder="{{'Search' | translate}}"
                                        [formControl]="toControl"></span>
                                <mat-option *ngFor="let item of ToAccountsDD" [value]="item.AccountID"
                                    [disabled]="item.FromAccountID == Form.value?.FromAccountID">
                                    <b>{{item.AccountName}}</b>&nbsp;-&nbsp; {{item.MainAccountName}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="submitted && f.ToAccountID.errors" class="invalid-feedback">
                                <div *ngIf=" f.ToAccountID.errors.required">{{'Required' | translate}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div align="end">
        <button mat-raised-button type="button" class="add-dark-hover btn-default" (click)="onNoClick()">{{'Cancel' |
            translate}}</button>
        <button mat-raised-button class="add-hover btn-success" type="submit">{{'Merge' | translate}}</button>
    </div>
</form>
