<div fxLayout="row margin-top-20">
    <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="col-md-offset-4 col-md-4 col-sm-offset-2 col-sm-8 col-xs-12 margin-top-20">
            <label class="pull-right">
                {{ 'Change Language' | translate }}:
                <select #langSelect (change)="changeLanguage(langSelect.value)">
                    <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                        [selected]="lang === translate.currentLang">{{ lang }}</option>
                </select>
            </label>
        </div>
        <div class="col-md-offset-4 col-md-4 col-sm-offset-2 col-sm-8 col-xs-12 margin-top-20"
            *ngIf="actionCompleted == false">
            <span class="pull-left">{{'From Till ID' | translate}} : {{fromTill}}</span>
            <span class="pull-right">{{'To Till ID' | translate}} : {{toTill}}</span>
        </div>
        <div class="table-responsive  col-md-offset-4 col-md-4 col-sm-offset-2 col-sm-8 col-xs-12 ">
            <table class="table table-striped table-bordered table-hover" *ngIf="actionCompleted == false">
                <thead class="t-head">
                    <tr>
                        <th>
                            {{'Currency' | translate}}</th>
                        <th style="text-align: right;">{{'Amount' | translate}}</th>
                    </tr>
                </thead>
                <tbody *ngIf="tableData?.length">
                    <tr *ngFor="let item of tableData; let i = index;">
                        <td>
                            {{item.Currency}}</td>
                        <td style="text-align: right;">
                            {{item.Amount}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="!tableData?.length" [style.background]="GridBG">
                    <tr>
                        <td colspan="7" style="text-align:center"> {{'errorMessage'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-md-offset-4 col-md-4 col-sm-offset-2 col-sm-8 col-xs-12" *ngIf="actionCompleted == false">
            <button class="btn-success pull-right  m-l-5" mat-raised-button (click)="fnApprove()">{{'Approve' |
                translate}}</button>
            <button class="pull-right " mat-raised-button color="primary" (click)="fnDecline()">{{'Decline' |
                translate}}</button>
        </div>
    </div>
    <div class="col-xs-12 col-sm-12 " *ngIf="actionCompleted">
        <h2 align="center" class="red">
            {{errorMessage}}
        </h2>
        <h2 align="center" class="green">
            {{message}}
        </h2>
    </div>
</div>