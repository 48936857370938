<div class="row">
    <div id="invoice-print">
        <div class="header">
            <div class="">
                <img [src]="OwnerDetails.LogoPath" alt="" class="owner-logo" />
            </div>
            <div class="summary"><b>{{OwnerCompanyName}}</b></div>

        </div>
        <div class="main">
            <div class="invoice-box">
                <h3 class="margin-0">{{bookingData.BookingExtID}}</h3>
                <!-- <h4 class="margin-0 red">{{'is Transfer' | translate}}: {{isTransfer ? 'Yes' : 'No'}}</h4> -->
                <h4>
                    {{'Booking Date' | translate}} : <span>{{bookingData.BookingDate}}</span>
                </h4>
                <h3>
                    {{'Creation Date' | translate}} : <span>{{bookingData.CreatedDate}}</span>
                </h3>

                <h3 class="bg-ash margin-0 m-t-10 m-b-10 text-center">
                    <b>{{'Boooking Details' | translate}}</b>
                </h3>
                <div class="row">
                    <div class="col-xs-6 col-sm-4 col-md-4">
                        {{'Customer ID' | translate}} : <strong>{{bookingData.CustomerExtID}}</strong>
                    </div>
                    <div class="col-xs-6 col-sm-4 col-md-4">
                        {{'Currency' | translate}} : <strong>{{bookingData.Currency}}</strong>
                    </div>
                    <div class="col-xs-6 col-sm-4 col-md-4">
                        {{'Cash Deposit Received' | translate}} : <strong>{{bookingData.AdvancePaid}}</strong>
                    </div>
                    <div class="col-xs-6 col-sm-4 col-md-4">
                        {{'Amount' | translate}} : <strong>{{bookingData.Amount}}</strong>
                    </div>
                    <div class="col-xs-6 col-sm-4 col-md-4">
                        {{'Rate' | translate}} : <strong>{{bookingData.Rate}}</strong>
                    </div>
                    <div class="col-xs-6 col-sm-4 col-md-4">
                        {{'Inverse Rate' | translate}} : <strong>{{bookingData.InverseRate}}</strong>
                    </div>
                    <div class="col-xs-6 col-sm-4 col-md-4">
                        {{'Local Amount' | translate}} : <strong>{{bookingData.LocalAmount}}</strong>
                    </div>
                    <div class="col-xs-6 col-sm-4 col-md-4">
                        {{'Expiry Date' | translate}} : <strong>{{bookingData.ValidTill}}</strong>
                    </div>
                    <div class="col-xs-6 col-sm-4 col-md-4">
                        {{'Notes' | translate}} : <strong>{{bookingData.Notes}}</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
