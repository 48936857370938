<!-- <div class="col-xs-12 flex-p">
    <div class="row">
        <div class="col-sm-12">
            <button class="btn btn-primary pull-right" (click)="CustomerType = !CustomerType">Change Cust Type</button>
        </div>
    </div>
    <div class="row">
        <div *ngIf="CustomerType">
            <app-individual></app-individual>
        </div>
        <div *ngIf="!CustomerType">
            <app-company></app-company>
        </div>
    </div>
</div> -->

<div fxLayout="row wrap" #resizedDiv>
    <div class="flex-p w-100">
        <div class="row m-b-10">
            <div class="col-sm-12">
                <a mat-raised-button color="primary" routerLink="/customer/requests">Requests</a>
                <!-- <button class="btn btn-primary pull-right" (click)="CustomerType = !CustomerType">Change Cust
                    Type</button> -->
            </div>
        </div>
        <div class="row">
            <app-individual *ngIf="!CustomerType"></app-individual>
            <app-company *ngIf="CustomerType"></app-company>
        </div>
    </div>
</div>