import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { PictureIdentityModel } from 'projects/cxr-crm/src/app/pages/_models/generic-models/generic-model';
import { AdminService } from 'projects/cxr-crm/src/app/pages/_services/admin.service';
import { FieldNames, RegulaModel, TextFieldList } from 'projects/cxr-crm/src/app/pages/manage-contact/individual/regula-dialog/regula.model';
import { RegulaService } from 'projects/cxr-crm/src/app/pages/manage-contact/individual/regula-dialog/regula.service';
import { keyValueTypes } from 'src/app/_services/constants';
import { Localisation } from 'src/app/_services/localisation';
import { PqgridComponent } from 'src/app/pqgrid.component';
import { ConfirmationDialogComponent } from 'src/app/shared-dialog/confirmation-dialog/confirmation-dialog.component';
import { NotificationService } from 'src/app/shared/_services/notification.service';
import { environment } from 'src/environments/environment';
import pq from 'src/paramquery';
import { GenericComponentService } from '../../../_services/generic-component.service';

@Component({
  selector: 'app-generic-picture-id',
  templateUrl: './generic-picture-id.component.html',
  styleUrls: ['./generic-picture-id.component.scss']
})
export class GenericPictureIdComponent implements OnInit {
  document: string | ArrayBuffer;
  // create: function () {
  //   //restore state of grid.
  //   var StateVal = localStorage.getItem('pq-grid' + self.GridName);
  //   this.loadState({ refresh: false, state: StateVal });
  // },
  @HostListener('window:beforeunload', ['$event'])
  saveGridState() {
    this.grid.SaveState();
  }
  ngOnDestroy() {
    this.grid.SaveState();
  }
  GridName: string = 'GenericPictureID' + GetUserObject.GetUserID();

  @Input('InternalTableName') InternalTableName: string;
  @Input('InternalRecordID') InternalRecordID: number;
  @Input('PictureIDTypeDD') PictureIDTypeDD: any[] = [];
  @Input('countriesList') countriesList: any[] = [];
  countriesListDD: any[] = [];
  countryControl = new FormControl(); // filter variable for country 
  @Input('isForCustomerReport') isForCustomerReport: boolean;
  @Input('hideAddButton') hideAddButton: boolean = false;
  @ViewChild('grid', { static: false }) grid: PqgridComponent;
  optionsGrid: pq.gridT.options;
  fileToUpload: File;
  fileName: string = null;
  fileNameToggler: boolean = true;
  error: any = { isError: false, errorMessage: '' };
  submitted: boolean;
  clickToEdit = 'Click to edit';
  clickToDelete = 'Click to delete';
  today: any;
  overallStatus: number | null;
  pictureIdValidatedObject: object = {};
  constructor (
    private _formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private _genericComponentService: GenericComponentService,
    private _notificationService: NotificationService,
    private _dialog: MatDialog,
    private datePipe: DatePipe,
    private regulaService: RegulaService,
    private _adminService: AdminService,

  ) {
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
    this.today = this.datePipe.transform(GetUserObject.GetCurrentDate(), 'yyyy-MM-dd');
  }
  // picture id form

  @ViewChild('addEditPictureIDModal', { static: false }) addEditPictureIDModal: ModalDirective;
  @ViewChild('gridPictureIds', { static: false }) gridPictureIds: PqgridComponent;
  PictureIDSubmitted: boolean;
  PictureIDForm: UntypedFormGroup;
  toggleFileInput(val) {
    this.fileNameToggler = val;
  }

  public showImageHyperlink: boolean;
  public documentLink: string;

  pictureIDFormInit(val: PictureIdentityModel) {
    this.PictureIDForm = this._formBuilder.group({
      PictureID: new UntypedFormControl(val.PictureID),
      InternalRecordID: new UntypedFormControl(this.InternalRecordID, [Validators.required]),
      InternalTableName: new UntypedFormControl(this.InternalTableName, [Validators.required]),
      PictureIDType: new UntypedFormControl(val.PictureIDType, Validators.required),
      PictureIDNumber: new UntypedFormControl(val.PictureIDNumber, Validators.required),
      IssueDate: new UntypedFormControl(val.IssueDate?.split("T")[0]),
      ExpiryDate: new UntypedFormControl(val.ExpiryDate?.split("T")[0]),
      CountryOfIssue: new UntypedFormControl(val.CountryOfIssue),
      IssuingStateProvince: new UntypedFormControl(val.IssuingStateProvince),
      IssuingAuthority: new UntypedFormControl(val.IssuingAuthority),
      PicturePath: new UntypedFormControl(val.PicturePath),
      OwnerID: GetUserObject.GetOwnerID(),
      isActive: new UntypedFormControl(val.isActive),
      isDeleted: new UntypedFormControl(val.isDeleted),
      RegulaStatus: new UntypedFormControl(val.RegulaStatus),
      RegulaObject: new UntypedFormControl(val.RegulaObject),
    });
    this.fileToUpload = null;
  }

  // convenience getter for easy access to form fields
  get PictureIDF() {
    return this.PictureIDForm.controls;
  }

  compareTwoDates() {
    if (this.PictureIDF['IssueDate'].value != null || this.PictureIDF['ExpiryDate'].value != null) {
      if (new Date(this.PictureIDF['IssueDate'].value) > new Date(this.PictureIDF['ExpiryDate'].value)) {
        this.error = { isError: true, errorMessage: 'Date of Issue should be less than Expiry Date.' };
        return false;
      }
    }
    return true;
  }

  onPictureIDSubmit() {
    this.submitted = true;
    this.error.isError = false;
    if (this.compareTwoDates()) {

      if (this.PictureIDForm.invalid) {
        return;
      }
      // stop here if form is invalid
      //if (this.PictureIDForm.invalid && (this.fileToUpload == null || this.fileName == null)) {

      // Mandatory File availability
      // if ((this.PictureIDF.PictureID.value == null && this.fileToUpload == null) || (this.PictureIDF.PictureID.value != null && this.fileName == null)) {
      //   return;
      // }


      let formData: FormData = new FormData();
      if (this.fileToUpload != null) {
        formData.append('file', this.fileToUpload, this.fileToUpload.name);
      }
      formData.append('pictureIDData', JSON.stringify(this.PictureIDForm.value));

      this._genericComponentService.fnUpsertPictureID(formData)
        .subscribe(data => {
          if (data.Success) {
            this.addEditPictureID(null, 0);
            this.grid.refreshDataAndView();
            this.toggleFileInput(true);
            this.fileName = null;
          }
        });
    }
  }
  addEditPictureID(val: PictureIdentityModel, flag) {
    this.overallStatus = null;
    this.submitted = false;
    if (flag == 1) {
      if (val.PicturePath?.toLowerCase()?.indexOf('https') !== -1) {
        this.showImageHyperlink = true;
        this.documentLink = val.PicturePath;
      }
      this._genericComponentService.fnGetPictureIDData(val).subscribe(data => {
        this.pictureIDFormInit(data.Data);

        if (this.PictureIDF.PictureID.value != null) {
          this.document = data.Data['Base64Img'];
          this.overallStatus = data.Data['RegulaStatus'];
        }
        this.addEditPictureIDModal.show();
        if (this.countriesList) {
          this.countriesListDD = this.countriesList;
        }
      });
    }
    else {
      this.addEditPictureIDModal.hide();
      this.fileName = null;
    }
  }

  handleFileInput(files: FileList) {
    if (files != null) {
      var type = files.item(0)?.type;
      var size = files.item(0)?.size;
      if (size <= 5000000) {
        this.fileToUpload = files.item(0);
        this.getBase64(files);
      }
      else {
        this.reset();
        this._notificationService.error("File size must be less than 5 MB");
        // this._snackBar.open('File size must be less than 5 MB', '', {
        //   duration: 3000,
        //   panelClass: 'redSnackBar'
        // });
      }
    }
  }

  getBase64(files) {
    let me = this;
    let file = files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.document = reader.result;
    };
    reader.onerror = function () { };
  }

  async checkAgainstRegula() {
    // this.existingPicId = false;
    if (!this.document) {
      this._notificationService.error('Image not available to validate');
      return;
    }
    var credits = await this.regulaService.checkCredits().toPromise();
    if (credits.Data == false) {
      return;
    }
    var apiKey = (await this._adminService.fnGetValue({ Key: keyValueTypes.regulaApiSubscriptionKey }).toPromise()).Data;
    if (apiKey == null) {
      this._notificationService.error('Contact Admin.Regula API-Key not found');
      return;
    }
    try {
      await this.regulaService.decreaseCredits().toPromise();
      var data: RegulaModel = await this.regulaService
        .postPicId(apiKey, {
          processParam: {
            scenario: 'FullProcess',
            doublePageSpread: true,
            measureSystem: 0,
            dateFormat: 'M/d/yyyy',
          },
          List: [
            {
              ImageData: {
                image: this.document,
              },
              light: 6,
              page_idx: 0,
            },
          ],
        })
        .toPromise();


      var status = data.ContainerList.List.find((x) => x.Status).Status;
      this.overallStatus = status.overallStatus;
      this.PictureIDF.RegulaObject.patchValue(JSON.stringify(data));
      this.PictureIDF.RegulaStatus.patchValue(this.overallStatus);
      this.settingRegulaValues(data);

    } catch {
      this._notificationService.error('Error occured with uploaded Image');
    }
  }

  showValidatedObject(templateRef: any) {
    if (!this.document && !this.overallStatus) {
      this._notificationService.error('No Data Available to show.');
      return;
    }
    const dialogRef = this._dialog.open(templateRef, {
      width: '800px',
      height: '500px'
    });
    this.pictureIdValidatedObject = JSON.parse(this.PictureIDF.RegulaObject.value);
  }
  onNoClick() {
    this._dialog.closeAll();
  }

  private settingRegulaValues(data: RegulaModel) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Do you want to change the form values?",
        isDelete: true,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        var fields = data.ContainerList.List.find((x) => x.Text)?.Text.fieldList ?? [];
        const patchValueToField = (field, fieldName: FieldNames) => {
          this.PictureIDF[field].patchValue(this.getFieldsValues(fields, fieldName));
        };
        patchValueToField('PictureIDNumber', 'Document Number');
        patchValueToField('CountryOfIssue', 'Issuing State Name');
        patchValueToField('IssueDate', 'Date of Issue');
        patchValueToField('ExpiryDate', 'Date of Expiry');
        patchValueToField('IssuingStateProvince', 'Place of Issue');
      }
    });

  }
  getFieldsValues(fields: TextFieldList[], name: FieldNames): string {
    return fields.find((x) => x.fieldName == name)?.value ?? '';
  }

  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;
  reset() {
    this.myInputVariable.nativeElement.value = "";
  }
  @ViewChild('textInput', { static: false }) textInput;
  setFocus() {
    this.textInput.nativeElement.focus();
  }
  removeFocus() {
    this.textInput.nativeElement.blur();
  }


  // grid helper code

  // grid helper code ends here



  ngOnInit() {
    this.pictureIDFormInit(new PictureIdentityModel());
    this.GridInit();
    this.fnTranslate();
    this.countryControl.valueChanges.subscribe(val => {
      if (val) {
        this.countriesListDD = this.countriesList.filter(x => x['name'].toLowerCase().includes(val.toLowerCase()) ||
          x['alpha2Code'].toLowerCase().includes(val.toLowerCase()));
      }
      else {
        this.countriesListDD = this.countriesList;
      }
    });

  }

  GridInit() {
    var self = this;
    this.optionsGrid = {
      title: "Supporting Document",

      scrollModel: { autoFit: true },
      // create: function () {
      //   //restore state of grid.
      //   var StateVal = localStorage.getItem('pq-grid' + self.GridName);
      //   this.loadState({ refresh: false, state: StateVal });
      // },
      toolbar:


      {
        items: this.hideAddButton ? [] : [
          // {
          //   type: 'button',
          // label: self._route.snapshot.data.Data['Reset filters'],
          //   listener: function () {
          //     this.reset({ filter: true });
          //   }
          // },
          {
            type: 'button',
            icon: 'ui-icon-plus',
            cls: 'pq-add-button',
            label: '',
            listener: function () {
              self.addEditPictureID(new PictureIdentityModel(), 1);
            }
          }
        ]
      },
      // {
      //   items: [
      //     // {
      //     //   type: 'button',
      //     // label: self._route.snapshot.data.Data['Reset filters'],
      //     //   listener: function () {
      //     //     this.reset({ filter: true });
      //     //   }
      //     // },
      //     {
      //       type: 'button',
      //       icon: 'ui-icon-plus',
      //       label: '',
      //       listener: function () {
      //         self.addEditPictureID(new PictureIdentityModel(), 1);
      //       }
      //     }
      //   ]
      // },
      pageModel: {
        type: "remote", rPP: environment.pq_grid.default_rPP, curPage: 1, rPPOptions: [5, 10, 20, 50, 100],
        layout: []
        // layout: ['prev', 'next', "|", "strPage", "|", "refresh", "|", "strRpp"]
        // layout: ['prev', 'next', "|", "strPage"]
        // layout: ['first', 'prev', 'next', 'last', "|", "strPage"]
      },
      filterModel: {
        on: false,
        header: false,
        type: 'remote',
        menuIcon: false
      },
      sortModel: {
        type: 'remote',
        single: false,
        sorter: [{ dataIndx: 'PictureID', dir: 'down' }],
        space: true,
        multiKey: null
      },
      colModel: [

        {
          title: "", editable: false, width: 25, sortable: false, align: "center", menuIcon: false,
          cls: this.isForCustomerReport ? "hide" : "",
          render: function (ui) {
            if (ui.rowData.isInvoiceAttachment)
              return '';
            return "<a style='color: green;cursor: pointer;' title=  " + self.clickToEdit + "     class='edit_btn'><span>&#x270F;</span></a>\
            <a style='color: red;cursor: pointer;' title=  "+ self.clickToEdit + "     class='delete_btn'>&#x274C;</a>";
          },
          postRender: function (ui) {
            var rowIndx = ui.rowIndx,
              grid = this,
              $cell = grid.getCell(ui);
            //edit button
            $cell.find(".edit_btn")
              .off("click")
              .on("click", function (evt) {
                self.fileName = ui.rowData.PictureName;
                self.addEditPictureID(ui.rowData, 1);
              });
            //delete button
            $cell.find(".delete_btn")
              .on("click", function (evt) {
                self.showConfirmationModal();
                self.deleteRowValue = ui.rowData;
                self.deleteRowType = 'pictureID';
              });
          }
        },
        {
          title: "View Document", dataType: "string", dataIndx: 'PicturePath', editable: false, minWidth: 300, cls: 'height-auto',
          sortable: false, align: "center",
          render: function (ui) {
            var rowIndx = ui.rowIndx,
              grid = this,
              $cell = grid.getCell(ui);
            if (ui.rowData.PictureName?.toLowerCase().endsWith('.png') || ui.rowData.PictureName?.toLowerCase().endsWith('.jpg') || ui.rowData.PictureName?.toLowerCase().endsWith('.svg') || ui.rowData.PictureName?.toLowerCase().endsWith('.jpeg') || ui.rowData.PictureName?.toLowerCase().endsWith('.gif')) {
              return "<a class = 'thumbnail-button'> <img style='max-width: 150px !important; height : 150px' class='' src='" + ui.rowData.PicturePath + "'/> </a>";
            }

            else if (ui.rowData.PicturePath) {
              return "<a class = 'thumbnail-button' style = 'cursor: pointer'>View</a>";
            }
            else return '<span>&nbsp</span>';

          }, postRender: function (ui) {
            var rowIndx = ui.rowIndx,
              grid = this,
              $cell = grid.getCell(ui);
            //edit button
            $cell.find(".thumbnail-button")
              .off("click")
              .on("click", function (evt) {
                window.open(ui.rowData.PicturePath);
              });
          }
        },
        { title: "Picture ID Type", dataType: "string", dataIndx: "PictureIDType", filter: { crules: [{ condition: 'range' }] } },
        { title: "Picture ID", dataType: "string", dataIndx: "PictureIDNumber", filter: { crules: [{ condition: 'contain' }] } },
        {
          title: "Date of Issue", dataType: "date", dataIndx: "IssueDate", filter: { crules: [{ condition: 'between' }] }, format: function (val) {
            if (val) {
              return self.datePipe.transform((val), GetUserObject.GetDisplayFormat());
            }
          }
        },
        {
          title: "Expiry Date", dataType: "date", dataIndx: "ExpiryDate", filter: { crules: [{ condition: 'between' }] }, format: function (val) {
            if (val) {
              return self.datePipe.transform(val, GetUserObject.GetDisplayFormat());
            }
          }
        },
        { title: "Country of Issue", dataType: "string", dataIndx: "CountryOfIssue", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Issuing State/Province ", dataType: "string", dataIndx: "IssuingStateProvince", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Issuing Authority", dataType: "string", dataIndx: "IssuingAuthority", filter: { crules: [{ condition: 'contain' }] } },
        {
          title: "Invoice Attachment", dataType: "string", align: "center", dataIndx: "isInvoiceAttachment", filter: { crules: [{ condition: 'contain' }] }, render: function (ui) {
            if (ui.rowData.isInvoiceAttachment) {
              return "<a style='color: green;' title='' class=''><span>&#x2714;</span></a>";
            }
            return "";
          }
        }
        , {
          title: "Status",
          sortable: false, align: "center", menuIcon: false,
          render: function (ui) {
            var str = "";
            var status = ui.rowData.RegulaStatus;
            switch (status) {
              case 0:
                str = "<span style='color: red;font-size:20px;'>&#x274C;</span>";
                break;
              case 1:
                str = "<span style='color: green;font-size:20px;'>&#x2713;</span>";
                break;
              case 2:
                str = "<span style='color: orange;font-size:20px;'>&#x24D8;</span>";
                break;
              default:
                str = "<span>Not checked</span>";
                break;
            }
            return str;
          },
        }


      ],
      rowDblClick: function (evt, ui) {
        if (ui.rowData.isInvoiceAttachment) {
          return;
        }
        // var previewObj: GenericPreviewModel = new GenericPreviewModel();
        // previewObj.Columns = this.colModel;
        // previewObj.RowData = ui.rowData;
        // previewObj.Heading = 'Grid Details Preview';
        // const dialogRef = self._dialog.open(PreviewGenericRowDataDialogComponent, {
        //   data: previewObj,
        //   width: '1000px'
        // });
        // dialogRef.afterClosed().subscribe(result => {
        // });
        self.fileName = ui.rowData.PictureName;
        self.addEditPictureID(ui.rowData, 1);
      },
      selectionModel: { type: 'row' },

      // sort: function (evt, ui) {
      //   this.onSort(evt, ui);
      // },
      dataModel: {
        location: "remote",
        postData:
        {
          InternalRecordID: self.InternalRecordID,
          InternalTableName: self.InternalTableName
        },
        dataType: "JSON",
        method: "POST",
        url: environment.apiUrl + "generic/get-picture-ID-list",
        getData: function (dataJSON) {
          var data = JSON.parse(dataJSON.Data);
          return { curPage: data.curPage, totalRecords: data.totalRecords, data: data.data };
        }
      }
    };
  }

  // get Modal Pop-up data
  fnGetDropDownData() {

  }

  // get Modal Pop-up data ends here


  // Confirmation modal pop over
  @ViewChild('confirmationModal', { static: false }) confirmationModal: ModalDirective;
  deleteRowValue: any;
  deleteRowType: string;
  showConfirmationModal(): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Are you sure you want to delete this record?",
        isDelete: true,
        buttonText: {
          ok: 'Confirm',
          cancel: 'Decline'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteRecord();
      }
    });
  }

  hideConfirmationModal(): void {
    this.confirmationModal.hide();
  }
  // Confirmation modal pop over end here
  // Picture ID code starts here

  deleteRecord() {
    this._genericComponentService.fnDeletePictureID(this.deleteRowValue).subscribe(data => {
      if (data.Success) {
        this.grid.refreshDataAndView();
        this.hideConfirmationModal();
      }
      this.deleteRowValue = null;
      this.deleteRowType = null;
    });
  }
  // Picture ID code ends here

  fnTranslate() {
    for (let index = 0; index < this.optionsGrid.colModel.length; index++) {
      if (this.optionsGrid.colModel[index].title) {
        this.translate.get(this.optionsGrid.colModel[index].title.toString()).subscribe(translationValue => {
          this.optionsGrid.colModel[index].title = translationValue;
        });
      }
    }
    this.translate.get(this.optionsGrid.title).subscribe(translationValue => {
      this.optionsGrid.title = translationValue;
    });

    this.translate.get(this.clickToEdit).subscribe(translationValue => {
      this.clickToEdit = translationValue;
    });
    this.translate.get(this.clickToDelete).subscribe(translationValue => {
      this.clickToDelete = translationValue;
    });
  }

}
