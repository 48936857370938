import { Component, OnInit } from '@angular/core';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {

  CustomerType: boolean = GetUserObject.GetUserObject()['isCompany'];
  constructor() { }

  ngOnInit() {
  }

}
