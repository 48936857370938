export class DecimalValidation {
    static countDecimalPlaces(value: number): number {
        // Check if the number is finite
        if (!isFinite(value)) return 0;

        // Convert the number to a string in fixed-point notation
        const valueStr = value.toString();

        // If the number is in scientific notation, convert it to a fixed-point string
        if (valueStr.includes('e') || valueStr.includes('E')) {
            const fixedValueStr = value.toFixed(20); // Convert to fixed-point with a large number of decimals
            // Remove trailing zeroes
            const fixedValueStrTrimmed = fixedValueStr.replace(/\.?0+$/, '');
            const decimalIndex = fixedValueStrTrimmed.indexOf('.');
            return decimalIndex === -1 ? 0 : (fixedValueStrTrimmed.length - decimalIndex - 1);
        }

        // Find the position of the decimal point
        const decimalIndex = valueStr.indexOf('.');

        // If there is no decimal point, return 0
        if (decimalIndex === -1) return 0;

        // Calculate the number of decimal places
        return valueStr.length - decimalIndex - 1;
    }

    static isDecimalNumberKey(evt) {
        // var charCode = (evt.which) ? evt.which : evt.keyCode
        // var value = evt.target.value;
        // var dotcontains = value.indexOf(".") != -1;
        // if (charCode == 45 && value.length == 0)
        //     return true;

        // if (charCode == 8)
        //     return true;

        // if (dotcontains)
        //     if (charCode == 46) return false;
        // if (charCode == 46) return true;
        // if (charCode > 31 && (charCode < 48 || charCode > 57))
        //     return false;
        // return true;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        var value = evt.target.value;
        var dotcontains = value.indexOf(".") != -1;
        if (charCode == 45 && value.length == 0)
            return true;
        else if (charCode == 45 && value.length > 0) {
            if (value.split("-").length > 1) {
                return false;
            }
            else if (evt.target.selectionStart == 0) {
                return true;
            }
            else {
                return false;
            }
        }
        if (charCode == 8)
            return true;

        if (dotcontains)
            if (charCode == 46) return false;
        if (charCode == 46) return true;
        if (charCode > 31 && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }

    static isNumberKey(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (evt.target.value.length == 0 && evt.which == 48) {
            return false;
        }
        if (charCode == 8)
            return true;
        if (charCode > 31 && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }

    static isWholeNumberKey(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode;

        // if (evt.target.value.length == 0 && evt.which == 48) {
        //     return false;
        // }
        if (charCode == 8)
            return true;
        if (charCode > 31 && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }

    static isIntegerKey(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        var value = evt.target.value;
        if (charCode == 45 && value.length == 0)
            return true;
        else if (charCode == 45 && value.length > 0) {
            if (value.split("-").length > 1) {
                return false;
            }
            else if (evt.target.selectionStart == 0) {
                return true;
            }
            else {
                return false;
            }
        }
        if (charCode == 8)
            return true;
        if (charCode > 31 && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }

    static isIntegerKeyDecimal(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        var value = evt.target.value;
        var dotcontains = value.indexOf(".") != -1;
        if (charCode == 45 && value.length == 0)
            return true;
        else if (charCode == 45 && value.length > 0) {
            if (value.split("-").length > 1) {
                return false;
            }
            else if (evt.target.selectionStart == 0) {
                return true;
            }
            else {
                return false;
            }
        }
        if (dotcontains)
            if (charCode == 46) return false;

        if (charCode == 8)
            return true;
        if (charCode == 46) return true;
        if (charCode > 31 && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }
}

export class NullNumberToNumber {
    static toNumber(value) {
        if (value == null || value == undefined || value == "" || value == 0 || isNaN(value) || value == "NaN" || Number.isNaN(value)) {
            return 0;
        } else {
            return Number(value);
        }
    }
}
