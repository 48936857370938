<div class="row " [style.background]="CurrencyRateBoardSettings.BackGroundColor">
    <div class="flex-p padding-botton-8">
        <div class="col-sm-12 ">

            <div class="col-sm-12 padding-top-8" *ngIf="CurrencyRateBoardSettings.displayLogo">
                <div class="row">
                    <div class="col-sm-4 col-xs-4">
                        <div class="pull-left">
                            <img [src]="LogoUrl" *ngIf="CurrencyRateBoardSettings.LogoPosition == false" [alt]="'Logo'"
                                [style.width]="CurrencyRateBoardSettings.LogoWidth +'px'" style="height: auto;">
                        </div>
                    </div>
                    <div class="col-sm-4 col-xs-4" style="text-align: center;">
                        <div style="margin-left: auto;margin-right: auto;">
                            <img [src]="LogoUrl" *ngIf="CurrencyRateBoardSettings.LogoPosition == null" [alt]="'Logo'"
                                [style.width]="CurrencyRateBoardSettings.LogoWidth +'px'"
                                style="height: auto;;width: 80px">
                        </div>
                    </div>
                    <div class="col-sm-4 col-xs-4">
                        <div class="pull-right">
                            <img [src]="LogoUrl" *ngIf="CurrencyRateBoardSettings.LogoPosition == true" [alt]="'Logo'"
                                [style.width]="CurrencyRateBoardSettings.LogoWidth +'px'" style="height: auto;">
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-sm-12 padding-top-8" *ngIf="CurrencyRateBoardSettings.dispayCompanyName">
                <div class="row">
                    <div class="col-sm-4 col-xs-4">
                        <div class="pull-left">
                            <div *ngIf="CurrencyRateBoardSettings.companyNamePosition == true">
                                <span [style.font-family]="CurrencyRateBoardSettings.companyNameFontFamily"
                                    [style.font-size]="CurrencyRateBoardSettings.companyNameFontSize+'px'"
                                    [style.color]="CurrencyRateBoardSettings.companyNameColor">
                                    {{CurrencyRateBoardSettings.CompanyName}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 col-xs-4" style="text-align: center !important;">
                        <div *ngIf="CurrencyRateBoardSettings.companyNamePosition == null">
                            <span [style.font-family]="CurrencyRateBoardSettings.companyNameFontFamily"
                                [style.font-size]="CurrencyRateBoardSettings.companyNameFontSize+'px'"
                                [style.color]="CurrencyRateBoardSettings.companyNameColor">
                                {{CurrencyRateBoardSettings.CompanyName}}
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-4 col-xs-4">
                        <div class="pull-right">
                            <div *ngIf="CurrencyRateBoardSettings.companyNamePosition == false">
                                <span [style.font-family]="CurrencyRateBoardSettings.companyNameFontFamily"
                                    [style.font-size]="CurrencyRateBoardSettings.companyNameFontSize+'px'"
                                    [style.color]="CurrencyRateBoardSettings.companyNameColor">
                                    {{CurrencyRateBoardSettings.CompanyName}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 padding-top-8"
                *ngIf="CurrencyRateBoardSettings.displayTimeStamp && CurrencyRateBoardSettings.timeStampPosition">
                <span [style.font-family]="CurrencyRateBoardSettings.TimeStampFontFamily"
                    [style.font-size]="CurrencyRateBoardSettings.TimeStampFontSize+'px'"
                    [style.color]="CurrencyRateBoardSettings.TimeStampFontColor">
                    {{'Rates updated on' | translate}} {{timeStamp}}
                </span>
            </div>
            <div class="col-sm-12 table-responsive padding-top-8">
                <table class="table table-hover" [style.font-family]="CurrencyRateBoardSettings.FontFamily"
                    [style.font-size]="CurrencyRateBoardSettings.FontSize +'px'">
                    <thead class="t-head" [style.background]="CurrencyRateBoardSettings.GridHeaderBG"
                        [style.color]="CurrencyRateBoardSettings.GridHeaderColor">
                        <tr [style.background]="CurrencyRateBoardSettings.GridHeaderBG" style="border-bottom: none;">
                            <th class="text-align-center" *ngIf="CurrencyRateBoardSettings.displayFlag">
                                {{'Flag' | translate}}</th>
                            <th *ngIf="CurrencyRateBoardSettings.displayCode">{{'Code' | translate}}</th>
                            <th *ngIf="CurrencyRateBoardSettings.displayCountry">{{'Country' | translate}}</th>
                            <th *ngIf="CurrencyRateBoardSettings.displayName">{{'Name' | translate}}</th>
                            <th align="center" class="text-align-right" *ngIf="CurrencyRateBoardSettings.displayWeBuy">
                                {{'We Buy' | translate}}
                            </th>
                            <th align="center" class="text-align-right" *ngIf="CurrencyRateBoardSettings.displayWeSell">
                                {{'We Sell' | translate}}
                            </th>
                            <th align="center" class="text-align-right"
                                *ngIf="CurrencyRateBoardSettings.displayWeBuyInverse">{{'We Buy Inverse'|
                                translate}}</th>
                            <th align="center" class="text-align-right"
                                *ngIf="CurrencyRateBoardSettings.displayWeSellInverse">{{'We Sell
                                Inverse' |
                                translate}}</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="tableList?.length > 1">
                        <tr *ngFor="let item of tableList; let i = index;"
                            [style.background]="CurrencyRateBoardSettings.GridBG"
                            [style.color]="CurrencyRateBoardSettings.GridBodyColor">
                            <td *ngIf="CurrencyRateBoardSettings.displayFlag" class="text-align-center">
                                <img [src]="item.FromCurrencyFlag" [alt]="item.FromCurrency"
                                    style="width:80px;height: auto;">
                            </td>
                            <td *ngIf="CurrencyRateBoardSettings.displayCode" style="text-align: left;">
                                {{item.FromCurrency}}</td>
                            <td *ngIf="CurrencyRateBoardSettings.displayCountry" style="text-align: left;">
                                {{item.Country}}</td>
                            <td *ngIf="CurrencyRateBoardSettings.displayName" class="">{{item.CurrencyAlias}}</td>
                            <td align="end" *ngIf="CurrencyRateBoardSettings.displayWeBuy">
                                {{item.BuyRate}}</td>
                            <td align="end" *ngIf="CurrencyRateBoardSettings.displayWeSell">
                                {{item.SellRate}}
                            </td>
                            <td align="end" *ngIf="CurrencyRateBoardSettings.displayWeBuyInverse">
                                {{item.InverseBuyRate}}
                            </td>
                            <td align="end" *ngIf="CurrencyRateBoardSettings.displayWeSellInverse">
                                {{item.InverseSellRate}}
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!tableList?.length" [style.background]="GridBG">
                        <tr>
                            <td colspan="7" style="text-align:center"> {{'No Records Found'| translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-sm-12 padding-top-8"
                *ngIf="CurrencyRateBoardSettings.displayTimeStamp && !CurrencyRateBoardSettings.timeStampPosition">
                <span [style.font-family]="CurrencyRateBoardSettings.TimeStampFontFamily"
                    [style.font-size]="CurrencyRateBoardSettings.TimeStampFontSize+'px'"
                    [style.color]="CurrencyRateBoardSettings.TimeStampFontColor">
                    {{'Rated Update on' | translate}} {{timeStamp}}
                </span>
            </div>
        </div>
    </div>
</div>
