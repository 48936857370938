import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LookupsService } from 'projects/cxr-crm/src/app/pages/_services/lookups.service';
import { Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { GenericComponentService } from '../../../_services/generic-component.service';
import {
  AddressAutoCompleteSearchModel,
  AddressAutoCompletetype,
  AutoCompleteAddressResult,
  Item,
} from './address-auto-complete.model';
import { AutoAddressCompleteService } from './auto-address-complete.service';
import { GenericAddressComponent } from './generic-address.component';

@Component({
  selector: 'cxr-address-auto-complete',
  template: `
    <div class="form-group form-group-height">
      <label class="">{{ 'Address lookup' | translate }}:</label>
      <input
        type="text"
        class="form-control input-style"
        placeholder="{{ 'Type atleast 8 chars' | translate }}"
        matInput
        [formControl]="addressAutoCompleteControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option.text"
          (onSelectionChange)="addressSelection(option)"
        >
          <span> {{ option.text }}</span
          ><br />
          <small> {{ option.description }}</small>
          <ng-container>
            <small
              *ngIf="option.next === type.Find; else Retrieve"
              class="pull-right color-blue"
              >{{ 'Search further' | translate }}</small
            >
            <ng-template #Retrieve>
              <small class="pull-right text-green">{{
                'Fetch' | translate
              }}</small>
            </ng-template>
          </ng-container>
          <div class="divider"></div>
        </mat-option>
      </mat-autocomplete>
      <small>{{
        'Note: Please select country for refined search' | translate
      }}</small>
    </div>
  `,
  styles: [
    `
      .mat-option {
        height: 60px;
        line-height: 20px;
      }
      .mat-option-text {
        margin-top: 10px;
      }
      .input-style{
        border: 1px solid #ccc;
        padding: 8px;
      }
    `,
  ],
  providers: [AutoAddressCompleteService],
})
export class AddressAutoCompleteComponent
  extends GenericAddressComponent
  implements OnChanges {
  addressAutoCompleteControl = new UntypedFormControl();
  filteredOptions: Observable<Item[]>;
  @Input('country') country: string;

  @Output() address = new EventEmitter<AutoCompleteAddressResult>();
  public get type() {
    return AddressAutoCompletetype;
  }
  constructor (
    private autoAddressCompleteService: AutoAddressCompleteService,
    formBuilder: UntypedFormBuilder,
    dialog: MatDialog,
    genericComponentService: GenericComponentService,
    lookupsService: LookupsService,
    translate: TranslateService,
    _router: Router,
    _route: ActivatedRoute,
  ) {
    super(
      formBuilder,
      dialog,
      genericComponentService,
      lookupsService,
      translate,
      _router,
      _route
    );
  }
  ngOnChanges(): void {
    this.filteredOptions = this.addressAutoCompleteControl.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((val) => {
        return this.filter(val || '');
      })
    );
  }
  filter(val: string): Observable<Item[]> {
    if (val.length < 8) {
      return of([]);
    }
    const search: AddressAutoCompleteSearchModel = {
      country:
        this.countriesList.find((x) => x.name === this.country)?.alpha2Code ??
        '',
      searchTerm: val,
    };
    // call the service which makes the http-request
    return this.autoAddressCompleteService
      .getAddressAutoCompleteFind(search)
      .pipe(
        map((response) =>
          response.items?.filter((option) => {
            return option;
          })
        )
      );
  }

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  inputAutoComplete: MatAutocompleteTrigger;

  addressSelection(option: Item): void {
    const search: AddressAutoCompleteSearchModel = {
      country:
        this.countriesList.find((x) => x.name === this.country)?.alpha2Code ??
        '',
      searchTerm: '',
    };
    this.autoAddressCompleteService
      .getAddressAutoCompleteRetrieve(option, search)
      .pipe()
      .subscribe((response) => {
        switch (option.next) {
          case AddressAutoCompletetype.Find:
            this.filteredOptions = of(response.Data.items);
            this.inputAutoComplete.openPanel();
            break;
          case AddressAutoCompletetype.Retrieve:
            const data = response.Data.items;
            const address =
              data.find((item) => item.language === 'ENG') ?? data[0];
            let selectedAddress = {
              countryIso2: address.countryIso2,
              countryIso3: address.countryIso3,
              country: address.countryName,
              type: address.type,
              street:
                address.line1 +
                ' ' +
                address.line2 +
                ' ' +
                address.line3 +
                ' ' +
                address.line4 +
                ' ' +
                address.line5,
              province: address.provinceName,
              provinceCode: address.provinceCode,
              city: address.city,
              zipCode: address.postalCode,
            };
            this.address.emit(selectedAddress);
            this.addressAutoCompleteControl.setValue('');
            break;
        }
      });
  }
}
