<h4 mat-dialog-title>{{'Pick A Customer' | translate}}
    <button type="button" class="close pull-right" (click)="onNoClick()" aria-label="Close">
        <span aria-hidden="true" class="text-black">&times;</span>
    </button>
</h4>
<div mat-dialog-content>
    <!-- <div class="row">
        <div class="col-xs-12">s
            <div class=" form-group-height form-group">
                <label for="">Customer:</label>
                <input type="text" class="form-control" placeholder="Customer">
            </div>
        </div>
    </div> -->
    <div class="col-xs-12">
        <div class="row">
            <div class="col-sm-4">
                <input type="text" placeholder="{{'Filter' | translate}}" class="form-control" [(ngModel)]="keyWord"
                    (keydown.enter)="search()">
            </div>
            <div class="col-sm-4">
                <mat-slide-toggle class="pull-right" [(ngModel)]="isIndividual" (change)="onChangeCustomerType()">
                    <span *ngIf="isIndividual">{{'Individual' | translate}}</span>
                    <span *ngIf="!isIndividual">{{'Company' | translate}}</span>
                </mat-slide-toggle>
            </div>
            <div class="col-sm-4">
                <button mat-button class="btn-success" (click)="search()">{{'Search' | translate}}</button>
            </div>
        </div>

    </div>
    <div class="row m-t-20">
        <div class="col-xs-12 m-t-10" [ngClass]="isIndividual ? '' : 'hide'">
            <pqgrid [GridID]="GridNameInd" #gridInd [options]="gridOptionsInd"></pqgrid>
        </div>
        <div class="col-xs-12 col-md-12 m-t-10" [ngClass]="isIndividual ? 'hide' : ''">
            <pqgrid [GridID]="GridNameCmp" #gridCmp [options]="gridOptionsCmp"></pqgrid>
        </div>
    </div>
</div>
