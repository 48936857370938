<!-- <div class="col-sm-12">
    <div class="col-lg-2 form-group-height form-group">
        <label for="">number:</label>
        <input type="text" class="form-control" placeholder="number" [(ngModel)]="number">
    </div>
    <button class="btn btn-primary pull-right" (click)="sendSMS()"> test sms</button>
</div> -->


<div class="col-sm-12 text-center">
    <img src="https://upload.wikimedia.org/wikipedia/en/thumb/1/1d/Page_Under_Construction.png/1000px-Page_Under_Construction.png"
        alt="Page under construction">
</div>