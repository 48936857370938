import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from "SubSink";
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { TableNames, TableNamesModel } from 'src/app/_helpers/app.tablenames';
import { Localisation } from 'src/app/_services/localisation';
import { PqgridComponent } from 'src/app/pqgrid.component';
import { NotificationService } from 'src/app/shared/_services/notification.service';
import { environment } from 'src/environments/environment';
import pq from 'src/paramquery';
@Component({
  selector: 'cxr-pick-inoice-dialog',
  templateUrl: './pick-inoice-dialog.component.html',
  styleUrls: ['./pick-inoice-dialog.component.scss']
})
export class PickInoiceDialogComponent implements OnInit {


  @HostListener('window:beforeunload', ['$event'])
  saveGridState($event) {
    this.grid.SaveState();
  }
  GridName: string = 'PickInvoiceDialog' + GetUserObject.GetUserID();
  ngOnDestroy() {
    this.grid.SaveState();
  }


  subs = new SubSink();

  @ViewChild('grid', { static: false }) grid: PqgridComponent;
  gridOptions: pq.gridT.options;
  SelectedCustomer: any;
  public TableNames: TableNamesModel;

  constructor (
    public _tableNames: TableNames,
    private _dialogRef: MatDialogRef<PickInoiceDialogComponent>,
    private _notificationService: NotificationService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router,
    private _dialog: MatDialog
  ) {
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
    _dialogRef.disableClose = true;
    this.TableNames = _tableNames.tablenames;

    var ToolbarItem = [];

    ToolbarItem = [

      // {
      //   type: 'textbox',
      //   label: 'Filter: ',
      //   attr: 'placeholder="Enter your keyword" ' + (data.CustomerID == null ? "" : "value=" + data.CustomerID),
      //   cls: "filterValue hide",
      //   listener: {
      //     timeout: function (ui) {
      //       var filterRules = this.getColModel().map(function (column) {
      //         return { dataIndx: column.dataIndx, condition: "contain", value: ui.target.value };
      //       });
      //       this.filter({
      //         oper: 'replace',
      //         rules: filterRules
      //       });
      //       ui.target.value = ui.target.value;
      //     }
      //   }
      // }
    ];

    var self = this;
    this.gridOptions = {
      title: "Pick An Invoice",
      height: "300",
      pageModel: { type: "remote", rPP: environment.pq_grid.dialog_rPP, curPage: 1, rPPOptions: [5, 10, 20, 50, 100] },
      sortModel: {
        type: 'remote',
        single: false,
        sorter: [{ dataIndx: 'InvoiceDate', dir: 'up' }],
        space: true,
        multiKey: null
      },
      dataModel: {
        location: "remote",
        postData: { OwnerID: GetUserObject.GetOwnerID() },
        dataType: "JSON",
        method: "POST",
        url: environment.apiUrl + "invoice/get-invoices",
        getData: function (dataJSON) {
          var APIdata = JSON.parse(dataJSON.Data);

          APIdata['data'] = APIdata['data'].filter(x => x['CustomerExtID'] == data.CustomerID);
          return { curPage: data.curPage, totalRecords: data.totalRecords, data: APIdata.data };
        }
      },
      selectionModel: { type: 'row', mode: 'single', toggle: true },

      toolbar: {
        items: ToolbarItem
      },
      filterModel: {
        on: true,
        mode: "AND",
        header: true,
        type: 'remote',
        menuIcon: false //show filter row icon initially.
      },

      colModel: [
        { title: "Invoice ID", dataType: "string", dataIndx: "InvoiceExtID", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Invoice Date", dataType: "date", dataIndx: "InvoiceDate", filter: { crules: [{ condition: 'between' }] } },
        { title: "Branch Code", dataType: "string", dataIndx: "BranchCode", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Branch Name", dataType: "string", dataIndx: "BranchName", filter: { crules: [{ condition: 'contain' }] } },

      ],


      rowDblClick: function (ui, evt) {
        self.pickInvoice(evt.rowData);
      },
      rowClick: function (ui, evt) {
        if (evt['addList'] != undefined && evt['addList'] != null) {
          self.SelectedCustomer = evt['rowData'];
        } else {
          self.SelectedCustomer = null;
        }
      }
    };
  }

  ngOnInit() {
  }


  onNoClick(): void {

    this._dialogRef.close();

  }


  pickInvoice(val) {
    this._dialogRef.close(val);
  }

  CloseDialog(val) {

    this._dialogRef.close(val);

  }




}
