<button mat-raised-button type="button" *ngIf="showValidateButton"
    class="add-dark-hover btn-secondary btn pull-right mb-5" (click)="validateIban()">{{'Validate' |
    translate}}</button>

<ng-container *ngIf="showValidationIcon">
    <ng-container *ngIf="isValid == null;else conditionCheck"></ng-container>
    <ng-template #conditionCheck>
        <ng-container *ngIf="isValid;else Invalid"><span
                class="text-green font-weight-bold validation-icon">&#x2714;</span>
        </ng-container>
    </ng-template>
    <ng-template #Invalid><span class="text-red font-weight-bold validation-icon">&#x2716;</span>
    </ng-template>
</ng-container>