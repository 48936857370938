<div class="row">
    <div class="col-xs-12 cupertino">
        <pqgrid [GridID]="GridName" #grid [options]="optionsGrid"></pqgrid>
    </div>
</div>

<!--Add edit phone modal pop up-->
<div bsModal #addEditPhoneModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-sizes-name1" [config]="{backdrop: 'static'}">
    <form [formGroup]="PhoneForm" (ngSubmit)="onPhoneFormSubmit()">
        <bs-modal-backdrop></bs-modal-backdrop>
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 id="dialog-sizes-name1" class="modal-title pull-left">
                        <span *ngIf="PhoneF.PhoneID.value == 0">{{'Add Phone Number' | translate}}</span>
                        <span *ngIf="PhoneF.PhoneID.value != 0">{{'Update Phone Number' | translate}}</span>

                    </h4>
                    <button type="button" class="close pull-right" (click)="addEditPhone(null,0)" aria-label="Close">
                        <span aria-hidden="true" class="text-white">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="mandatory" for="Phone Type">{{'Phone Type' | translate}}:</label>
                                <mat-select placeholder="{{'Select Phone Type' | translate}}" class="form-control"
                                    [ngClass]="{ 'is-invalid': phoneSubmitted && PhoneF.PhoneType.errors }"
                                    formControlName="PhoneType">
                                    <mat-option [value]="null" selected disabled>{{'Select' | translate}}</mat-option>
                                    <mat-option *ngFor="let item of PhoneTypeDD" [value]="item.LookupID">
                                        {{item.LookupName}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="phoneSubmitted && PhoneF.PhoneType.errors" class="invalid-feedback">
                                    <div *ngIf="PhoneF.PhoneType.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="mandatory">{{'Phone Number' | translate}}:</label>
                                <input type="text" class="form-control" placeholder="{{'Phone Number' | translate}}"
                                    formControlName="Phone"
                                    [ngClass]="{ 'is-invalid': phoneSubmitted && PhoneF.Phone.errors }">
                                <div *ngIf="phoneSubmitted && PhoneF.Phone.errors" class="invalid-feedback">
                                    <div *ngIf="PhoneF.Phone.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="mandatory">Phone Number:</label>
                                <input type="text" ng2TelInput class="form-control" placeholder="Phone Number"
                                    (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event)"
                                    (intlTelInputObject)="telInputObject($event)"
                                    (countryChange)="onCountryChange($event)" formControlName="Phone"
                                    [ngClass]="{ 'is-invalid': phoneSubmitted && PhoneF.Phone.errors }" />
                                <div *ngIf="phoneSubmitted && PhoneF.Phone.errors" class="invalid-feedback">
                                    <div *ngIf="PhoneF.Phone.errors.required">Required</div>
                                    <div *ngIf="PhoneF.Phone.hasError('pattern')">Enter Valid Phone Number</div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-xs-12">
                            <button mat-raised-button type="submit" class="pull-right btn-success">
                                <span *ngIf="PhoneF.PhoneID.value == 0">{{'Save' | translate}} </span>
                                <span *ngIf="PhoneF.PhoneID.value != 0">{{'Update' | translate}} </span>
                            </button>
                            <button mat-raised-button type="button" class="pull-right m-r-5 btn-secondary"
                                (click)="addEditPhone(null,0)">{{'Cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>


<!--Confirmation modal pop up-->
<div bsModal #confirmationModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    [config]="{backdrop: 'static'}">
    <bs-modal-backdrop></bs-modal-backdrop>
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="dialog-sizes-name1" class="modal-title pull-left">Confirmation</h4>
                <button type="button" class="close pull-right" (click)="hideConfirmationModal()" aria-label="Close">
                    <span aria-hidden="true" class="text-white">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xs-12">
                        <h5>Are you sure you want to delete.?</h5>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-xs-12">
                        <button mat-raised-button type="submit" (click)="deleteRecord();" class="pull-right"
                            color="warn">Confirm</button>
                        <button mat-raised-button (click)="hideConfirmationModal()"
                            class="pull-right m-r-5 btn-secondary">Decline</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
