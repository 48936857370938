import { UntypedFormControl } from '@angular/forms';


export class WhiteSpaceValidator {
    static cannotContainSpace(control: UntypedFormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'cannotContainSpace': true };
    }
}
