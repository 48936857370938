export const ModuleDetails = {
    dashBoard: 1,
    contacts: 2,
    currencies: 3,
    modules: 4,
    teller: 5,
    reports: 6,
    AML: 7,
    eWire: 8,
    admin: 10,
    order: 9,
    booking: 11,
    products: 12,

};
