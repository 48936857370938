import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { SaLookupOptionsService } from 'projects/cxr-super-admin/src/app/pages/_services/sa-lookup-options.service';
import { UserRedirectService } from '../pages/login/login-test/user-redirect.service';
import { LoginAsOwnerDialogComponent } from '../shared-dialog/login-as-owner-dialog/login-as-owner-dialog.component';
import { PickUserDialogComponent } from '../shared-dialog/pick-user-dialog/pick-user-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class LoginAsOwnerService {

    constructor (private _dialog: MatDialog, private _saLookupOptionsService: SaLookupOptionsService, private _userRedirectService: UserRedirectService) {
    }



    loginAsOwner() {
        const dialogRef = this._dialog.open(LoginAsOwnerDialogComponent, {
            width: '800px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loginAsUser(result.OwnerID);
            }
        });
    }


    loginAsUser(OwnerId) {
        const dialogRefUsers = this._dialog.open(PickUserDialogComponent, {
            width: '800px',
            data: { ownerId: OwnerId, showOwner: true }
        });

        dialogRefUsers.afterClosed().subscribe(user => {
            if (user) {
                this._saLookupOptionsService.fnLoginAsOwner(user.ID).subscribe(data => {
                    try {
                        if (data.Success) {
                            GetUserObject.RemoveSession();
                            localStorage.setItem("AuthToken", data.Data['token']);
                            GetUserObject.SetSession(data.Data['LoginDetails']);
                            if ((GetUserObject.GetBranchID() == null || GetUserObject.GetBranchID() == 0 || GetUserObject.GetBranchID() == undefined) && GetUserObject.isAgent() == false) {
                                //  this.openChangeBranchDialog();
                            }
                            this._userRedirectService.redirectCRMUser();
                        }
                    } catch (error) {
                        console.log(error);
                    }

                });
            }
        });
    }

}
