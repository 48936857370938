import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChangeBranchDialogComponent } from './_dialogs/change-branch-dialog/change-branch-dialog.component';

import { HttpClient } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChartModule } from 'angular-highcharts';
import { BsDatepickerModule, ModalModule } from 'ngx-bootstrap';
import { SharedDialogModule } from 'src/app/shared-dialog/shared-dialog.module';
import { translationUrl } from '../translationurl';
import { FavoriteCurrencyComponent } from './dashboard/favorite-currency/favorite-currency.component';
import { MonthlySalesComponent } from './dashboard/monthly-sales/monthly-sales.component';
import { RecentAmlAlertsComponent } from './dashboard/recent-aml-alerts/recent-aml-alerts.component';
import { RecentCustomerComponent } from './dashboard/recent-customer/recent-customer.component';
import { TillSummaryComponent } from './dashboard/till-summary/till-summary.component';
import { BankNotesComponent } from './bank-notes/bank-notes.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, translationUrl, '');
}

@NgModule({
    declarations: [
        ChangeBranchDialogComponent,
        DashboardComponent,
        RecentCustomerComponent,
        RecentAmlAlertsComponent,
        FavoriteCurrencyComponent,
        MonthlySalesComponent,
        TillSummaryComponent,
        BankNotesComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        MatSelectModule,
        SharedDialogModule,
        ChartModule,
        FlexLayoutModule,
        BsDatepickerModule,
        ModalModule,
        MatIconModule,
        MatButtonModule, MatCheckboxModule,
        MatOptionModule,
        TranslateModule,
        MatCardModule,
        FormsModule, ReactiveFormsModule,
        //  TranslateModule.forChild({ extend: true })
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            extend: true
        })
    ],
    exports: [
        ChangeBranchDialogComponent
    ]
})
export class DialogModule { }
