import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { LoginService } from 'src/app/_services/login.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  public userImage =  environment.deployUrl + 'assets/img/users/user.jpg';



  @ViewChild('addEditRoleModal', { static: false }) addEditRoleModal: ModalDirective;
  constructor(private _loginService: LoginService, private _router: Router) { }
  logout() {
    this._router.navigateByUrl("/login");
  }

  ngOnInit() {
  }


  addEditRole(flag) {
    if (flag == 1) {
      this.addEditRoleModal.show();
    }
    else
      this.addEditRoleModal.hide();
  }

}
