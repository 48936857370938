<h4 mat-dialog-title>{{'Pick An Invoice' | translate}}
    <button type="button" class="close pull-right" (click)="onNoClick()" aria-label="Close">
        <span aria-hidden="true" class="text-black">&times;</span>
    </button>
</h4>
<div mat-dialog-content>
    <!-- <div class="row">
        <div class="col-xs-12">s
            <div class=" form-group-height form-group">
                <label for="">Customer:</label>
                <input type="text" class="form-control" placeholder="Customer">
            </div>
        </div>
    </div> -->
    <div class="row">
        <div class="col-xs-12">
            <pqgrid [GridID]="GridName" #grid [options]="gridOptions"></pqgrid>
        </div>
    </div>

</div>
<!--
<mat-dialog-actions align="end">
    <button mat-raised-button type="button" class="add-dark-hover btn-default" (click)="onNoClick()">Cancel</button>
    <button mat-raised-button class="add-hover btn-success" type="button" (click)="pickCustomer(SelectedCustomer)">Pick</button>
</mat-dialog-actions> -->