<mat-dialog-content>
    <h4 align="center">
        <div>
            {{'Are you sure you want to merge these accounts together?' | translate}}
            <br>
            {{'This action cannot be undo' | translate}}
        </div>
    </h4>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-raised-button class="btn btn-success m-r-5" color="primary" (click)="confirm()">{{'Confirm' |
        translate}}</button>
    <button mat-raised-button class="btn-default" (click)="close()">{{'Cancel' | translate}}</button>
</mat-dialog-actions>
