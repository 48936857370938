<mat-card class="mat-card-graph">
    <div class="row">
        <div fxFlex="100" fxFlex.gt-sm fxFlex.sm class="flex-p w-100">
            <div class="tableFixHead table-responsive scroll">
                <div class="col-md-12 col-sm-12 custom-band">
                    {{'Recently Added Customers (7 Days)' | translate}}
                </div>
                <table class="table table-striped table-bordered table-hover">
                    <thead style="background: #1ba4fc!important;color:#eee">
                        <tr>
                            <th>{{'Customer ID' | translate}}</th>
                            <th>{{'Customer Name' | translate}}</th>
                            <th>{{'Country' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let customer of customerList;let i = index">
                            <td>{{customer.ID}}</td>
                            <td>{{customer.CustomerName}}</td>
                            <td>{{customer.country}}</td>

                        </tr>
                        <tr *ngIf="customerList?.length == 0">
                            <td colspan="5" style="text-align:center">{{'No records found' | translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</mat-card>
