
export class CurrenctRateBoardSettingsModel {
    GridHeaderBG: string;
    GridBG: string;
    FontSize: number;
    FontFamily: string;
    LogoWidth: number;
    GridHeaderColor: string;
    GridBodyColor: string;
    displayLogo: boolean;
    displayFlag: boolean;
    displayCode: boolean;
    displayName: boolean;
    displayWeBuy: boolean;
    displayWeSell: boolean;
    displayCountry: boolean;
    displayWeBuyInverse: boolean;
    displayWeSellInverse: boolean;
    displayTimeStamp: boolean;
    timeStampPosition: boolean;
    TimeStampFontSize: number;
    TimeStampFontColor: string;
    TimeStampFontFamily: string;
    LogoPosition: boolean;
    BackGroundColor: string;
    Language: string;
    dispayCompanyName: boolean;
    companyNamePosition: boolean;
    companyNameFontSize: number;
    companyNameFontFamily: string;
    companyNameColor: string;
}
