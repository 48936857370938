import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { responseModel } from '../_models/response.model';
import { ProductModel } from '../products/product.model';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  constructor (private _http: HttpClient) { }

  fnGetCurrencyConversionRate(val) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'get-currency-conversion-rate',
      val
    );
  }

  fnUpsertCurrencyConversionRate(val) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'upsert-currency-rate',
      val
    );
  }
  fnGetCurrencyRate(val) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'get-currency-rate',
      val
    );
  }
  fnDeleteCurrencyRate(val) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'delete-currency-rate',
      val
    );
  }

  fnAutoUpdateCurrencyRate(val) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'auto-update-currency-rate',
      val
    );
  }

  fnGetCurrencyListDD() {
    return this._http.get<responseModel>(
      environment.apiUrl + 'get-currency-list-dd'
    );
  }

  fnGetCurrencyRateList(val) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'get-currency-rate-list',
      val
    );
  }

  fnSaveCurrencyUpdateFrequency(val) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'save-currency-update-frequency',
      val
    );
  }
  fnUpdateCurrencyRates(val) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'update-currency-rates',
      val
    );
  }

  fnUpdateCurrencySequenceOrder(val) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'update-currency-sequence-order',
      val
    );
  }

  fnUpdateCurrencyManualRates(val) {
    return this._http.post<responseModel>(
      `${ environment.apiUrl }update-manual-rates`,
      val
    );
  }
  fnGetLowestDenominationOfCurrency(val) {
    let params = new HttpParams();
    params = params.set('currency', val);
    return this._http.get<responseModel>(
      `${ environment.apiUrl }get-lowest-denomination`,
      { params: params }
    );
  }
  fnSaveTaxDeclaration(values) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'upsert-tax-declaration',
      values
    );
  }
  fnGetTaxDeclaration(val) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'get-TaxDeclaration-details',
      val
    );
  }
  fnDeleteTaxDeclaration(values) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'delete-taxDeclaration',
      values
    );
  }
  fnTaxDeclarationUpsert(values) {
    return this._http.post(
      environment.apiUrl + 'upsert-tax-declaration',
      values
    );
  }
  fnGetAccountsDD() {
    return this._http.get<responseModel>(environment.apiUrl + 'get-account-dd');
  }

  fnSaveProduct(values: ProductModel): Observable<responseModel> {
    return this._http.post<responseModel>(
      `${ environment.apiUrl }upsert-product`,
      values
    );
  }

  fnGetProduct(val: any): Observable<responseModel> {
    return this._http.post<responseModel>(
      `${ environment.apiUrl }get-product-details`,
      val
    );
  }

  fnDeleteProduct(values: ProductModel): Observable<responseModel> {
    return this._http.post<responseModel>(
      `${ environment.apiUrl }delete-product`,
      values
    );
  }

  fnProductUpsert(values): Observable<any> {  // Assuming response model can vary
    return this._http.post<responseModel>(
      `${ environment.apiUrl }upsert-product`,
      values
    );
  }

  fnGetProductTaxes(productId: number, currency) {
    let params = new HttpParams();
    params = params.set('productId', productId);
    params = params.set('currency', currency);

    return this._http.get<responseModel>(
      `${ environment.apiUrl }get-product-taxes`,
      { params: params }
    );
  }
}
