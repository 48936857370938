<div>
    <h4>{{'Select Date Range' | translate}}</h4>
</div>
<!-- <mat-dialog-content > -->
<div class="m-t-10">
    <div class="row">
        <div class="">
            <div class="co-sm-12">
                <!-- <mat-slide-toggle [(ngModel)]="selectedValue" class="col-sm-12">
                    {{(selectedValue ? 'Start Date' : 'End Date' ) | translate}}</mat-slide-toggle> -->
            </div>
            <!-- <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedValue"> -->
            <div class="col-sm-6">
                <div class=" form-group-height form-group">
                    <label for="" class="mandatory">{{'Start Date' | translate}}:</label>


                    <div class="input-group">
                        <cxr-prime-calendar [formControl]="fromDate">
                        </cxr-prime-calendar>

                        <div class="input-group-btn">
                            <span class="btn btn-default" style="height: 34px; pointer-events: none;">
                                <i class="fa fa-angle-up" (click)="dayPlus(true)"
                                    style="margin-top: -6px; cursor: pointer; pointer-events: all;"></i>

                                <i (click)="dayMinus(true)" class="fa fa-angle-down"
                                    style="margin-top: 4px;cursor: pointer; pointer-events: all;"></i>
                            </span>
                        </div>
                    </div>

                </div>
                <div *ngIf=" error.isError" [ngClass]="'red'">
                    {{ error.errorMessage | translate }}
                </div>
            </div>
            <div class="col-sm-6">
                <div class=" form-group-height form-group">
                    <label for="" class="mandatory">{{'End Date' | translate}}:</label>
                    <div class="input-group">
                        <cxr-prime-calendar [formControl]="toDate">
                        </cxr-prime-calendar>
                        <div class="input-group-btn">
                            <span class="btn btn-default" style="height: 34px; pointer-events: none;">
                                <i class="fa fa-angle-up" (click)="dayPlus()"
                                    style="margin-top: -6px; cursor: pointer; pointer-events: all;"></i>

                                <i (click)="dayMinus()" class="fa fa-angle-down"
                                    style="margin-top: 4px;cursor: pointer; pointer-events: all;"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </mat-radio-group> -->
        </div>
        <div class="col-sm-12 m-t-10">
            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <div class="form-group form-group-height">
                        <label class="">{{'Date Range' | translate}}:</label>
                        <mat-select class="form-control" placeholder="{{'Pick Date Range' | translate}}"
                            (selectionChange)="onSelectDaterange($event)">
                            <mat-option *ngFor="let item of DateRangeList" [value]="item">
                                {{item.FilterName}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="col-xs-12 col-md-6">


                    <span> {{'Number of days' | translate}} : <span
                            [ngClass]="days < 0 ? 'red' : '  ' ">{{days}}</span></span> <br>
                    <div class="row m-t-10">
                        <div class="col-xs-6 col-md-6">
                            <button mat-button class="btn-default  form-control" (click)="today()">{{'Today' |
                                translate}}</button>
                        </div>
                        <div class="col-xs-6 col-md-6">
                            <button mat-button class="btn-default  form-control" (click)="thisMonth()">{{'This month' |
                                translate}}</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 m-t-10">
            <div class="row">
                <!-- <div class="col-xs-6 col-md-3">
                    <button mat-button class="btn-default form-control" (click)="dayMinus()">{{'Day' | translate}}
                        -</button>
                </div>
                <div class="col-xs-6 col-md-3">
                    <button mat-button class="btn-default  form-control" (click)="dayPlus()">{{'Day' | translate}}
                        +</button>
                </div> -->
                <div class="col-xs-12 col-md-3">
                    <button mat-button class="btn-default  form-control" (click)="lastMonth()">{{'Last month' |
                        translate}}
                    </button>
                </div>

                <div class="col-xs-12 col-md-3">
                    <button mat-button class="btn-default  form-control" (click)="thisYear()">{{'This Year' |
                        translate}}
                    </button>
                </div>


                <div class="col-xs-6 col-md-3">
                    <button mat-button class="btn-default  form-control" (click)="beginingOfMonth()">{{'BOMonth' |
                        translate}}</button>
                </div>
                <div class="col-xs-6 col-md-3">
                    <button mat-button class="btn-default  form-control" (click)="endOfMonth()">{{'EOMonth' |
                        translate}}</button>
                </div>
            </div>
        </div>
        <!-- <div class="col-sm-12 m-t-10">
            <div class="row">
                <div class="col-xs-6 col-md-3">
                    <button mat-button class="btn-default form-control" (click)="monthMinus()">{{'Month' | translate}}
                        -</button>
                </div>
                <div class="col-xs-6 col-md-3">
                    <button mat-button class="btn-default  form-control" (click)="monthPlus()">{{'Month' | translate}}
                        +</button>
                </div>
                <div class="col-xs-6 col-md-3">
                    <button mat-button class="btn-default  form-control" (click)="beginingOfMonth()">{{'BOMonth' |
                        translate}}</button>
                </div>
                <div class="col-xs-6 col-md-3">
                    <button mat-button class="btn-default  form-control" (click)="endOfMonth()">{{'EOMonth' |
                        translate}}</button>
                </div>
            </div>
        </div> -->

        <div class="col-sm-12 m-t-10">
            <div class="row">
                <!-- <div class="col-xs-6 col-md-3">
                    <button mat-button class="btn-default form-control" (click)="yearMinus()">{{'Year' | translate}}
                        -</button>
                </div>
                <div class="col-xs-6 col-md-3">
                    <button mat-button class="btn-default  form-control" (click)="yearPlus()">{{"Year" | translate}}
                        +</button>
                </div> -->
                <div class="col-xs-6 col-md-3">
                    <button mat-button class="btn-default  form-control" (click)="beginingOfYear()">{{"BOYear" |
                        translate}}</button>
                </div>
                <div class="col-xs-6 col-md-3">
                    <button mat-button class="btn-default  form-control" (click)="endOfYear()">{{'EOYear' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- </mat-dialog-content> -->
<mat-dialog-actions align="end" class="m-t-10">
    <button mat-raised-button *ngIf="data?.isExportAllEnable" class="btn btn-success" color="primary"
        (click)="exportAll()">{{'Export All' |
        translate}}</button>
    <button mat-raised-button class="btn btn-success" color="primary" (click)="onConfirmClick()">{{'Confirm' |
        translate}}</button>
    <button class="btn-default" mat-raised-button mat-dialog-close>{{'Close' | translate}}</button>
</mat-dialog-actions>
