import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Localisation } from 'src/app/_services/localisation';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { responseModel } from 'projects/cxr-crm/src/app/pages/_models/response.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cxr-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  constructor(
    private _dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    translate: TranslateService,
    private _http: HttpClient) {
    _dialogRef.disableClose = true;
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
  }


  signin: UntypedFormGroup = new UntypedFormGroup({
    OldPassword: new UntypedFormControl('', [Validators.required]),
    NewPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
    ConfirmPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
    OwnerID: new UntypedFormControl(GetUserObject.GetOwnerID()),
    UserID: new UntypedFormControl(GetUserObject.GetUserID()),
  });

  OldPasswordHide = true;
  NewPasswordHide = true;
  ConfirmPasswordHide = true;
  get OldPasswordValue() { return this.signin.get('OldPassword'); }
  get NewPasswordValue() { return this.signin.get('NewPassword'); }
  get ConfirmPasswordValue() { return this.signin.get('ConfirmPassword'); }

  ngOnInit() {
  }


  onNoClick(): void {
    this._dialogRef.close();
  }
  submitted: boolean = false;
  strength: number;
  checkStrength(e) {
    this.strength = e;
  }
  onSubmit() {

    this.submitted = true;
    if (this.signin.invalid) {
      return;
    }
    if (this.NewPasswordValue.value != this.ConfirmPasswordValue.value) {
      return;
    }
    if (this.strength < 3) {
      return;
    }
    this._http.post<responseModel>(`${environment.apiUrl}change-password`, this.signin.value).subscribe(data => {
      if (data.Success == true) {
        this.onNoClick();
        this.submitted = false;
      }
    });
  }

  // checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  //   let pass = group.get('NewPassword').value;
  //   let confirmPass = group.get('ConfirmPassword').value;

  //   return pass === confirmPass ? null : { notSame: true }
  // }
}
