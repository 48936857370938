import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class GenericPreviewModel
{
  Columns: any[] = [];
  RowData: any;
  Heading: string = 'Preview';
}

export class GenericPreviewDisplayModel
{
  Title: String
  Value: string
  Type: string
}
@Component({
  selector: 'app-preview-generic-row-data-dialog',
  templateUrl: './preview-generic-row-data-dialog.component.html',
  styleUrls: ['./preview-generic-row-data-dialog.component.scss']
})
export class PreviewGenericRowDataDialogComponent implements OnInit
{

  ItemsToDisplay: GenericPreviewDisplayModel[] = [];
  LongerTextItems: GenericPreviewDisplayModel[] = []
  constructor(
    private _dialogRef: MatDialogRef<PreviewGenericRowDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericPreviewModel)
  {
    _dialogRef.disableClose = true;
  }

  ngOnInit()
  {
    this.data.Columns = this.data.Columns.filter(x => x.title != "Actions" && x.title != null && x.title != "" && x.hidden != true);
    this.ItemsToDisplay = [];
    this.data.Columns.forEach(element =>
    {
      var item = new GenericPreviewDisplayModel();
      item.Title = element['title'];
      item.Value = this.checkValue(this.data.RowData[element['dataIndx']], element['dataType']);
      item.Type = element['dataType'];
      if (item.Value?.length > 100)
        this.LongerTextItems.push(item)
      else
        this.ItemsToDisplay.push(item)
    });
  }

  checkValue(val, type)
  {
    if (val == null || val == undefined || val == "")
    {
      if (type == "number")
      {
        return 0;
      } else if (type == "boolean")
      {
        return false;
      }
      return null;
    }
    else
    {
      return val;
    }
  }

  onCloseDialog(): void
  {
    this._dialogRef.close();
  }
  openImgInNewtab(srcPath)
  {
    window.open(srcPath, "_blank");
  }

}
