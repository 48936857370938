<h4 mat-dialog-title>{{'Choose Branch' | translate}}
    <button type="button" class="close pull-right" (click)="onNoClick()" aria-label="Close">
        <span aria-hidden="true" class="text-black">&times;</span>
    </button>
</h4>

<div class="row">
    <div class="col-sm-12">
        <pqgrid [GridID]="GridName" #grid [options]="options"></pqgrid>
    </div>
</div>

<!--
<form (ngSubmit)="saveChangeOfBranch()" #heroForm="ngForm">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-xs-12">
                <div class="form-group">
                    <label [ngClass]="{ 'mandatory': !sessionDetails.isAdmin }" for="Branch">Branch:</label>
                    <mat-select placeholder="Select Branch" class="form-control" id="branch" required
                        [(ngModel)]="branch" name="branch">
                        <mat-option *ngFor="let item of BranchesList" [value]="item.BranchID">
                            {{item.BranchCode}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end">
        <button mat-raised-button type="button" class="add-dark-hover btn-default" (click)="onNoClick()">Cancel</button>
        <button mat-raised-button class="add-hover btn-success" type="submit">Save</button>
    </mat-dialog-actions>
</form> -->
