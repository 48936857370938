import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'highcharts';
import { DashboardService } from '../../_services/dashboard.service';

@Component({
  selector: 'cxr-monthly-sales',
  templateUrl: './monthly-sales.component.html',
  styleUrls: ['./monthly-sales.component.scss']
})
export class MonthlySalesComponent implements OnInit {
  tableList: any = [];
  filterForm: UntypedFormGroup;
  chart: any;
  salesDataFetchError: boolean = true;

  DropDownFilter = [{ name: "Last 6 month", value: 6 },
  { name: "Last 12 month", value: 12 },
  { name: "Last Year", value: 24 }];
  Defaultrecords = localStorage.getItem("SalesValue") ? JSON.parse(localStorage.getItem("SalesValue")) : 6;

  constructor (
    private _fb: UntypedFormBuilder,
    private translate: TranslateService,
    private _dashboardService: DashboardService) {

    this.filterFormInit();
  }

  ngOnInit(): void {
    this.fnGetMonthlySales();
    this.filterForm.get('TotalRecords').setValue(this.Defaultrecords);

  }
  // fetch table data
  fnGetMonthlySales() {
    this._dashboardService.fnGetMonthlySales(this.filterForm.value).subscribe(result => {
      if (result['Data']) {
        this.tableList = result['Data'];
        this.init();
      }
    });
  }

  //filter form initialization
  filterFormInit() {
    this.filterForm = this._fb.group({
      TotalRecords: (this.Defaultrecords),
      branch: null,
    });
  }
  // getter for form
  get f() { return this.filterForm.controls; }


  init() {

    var pieChart = [];
    var labels: any = [];
    if (this.tableList != null && this.tableList != "") {
      for (let i = 0; i < this.tableList.length; i++) {
        pieChart.push(this.tableList[i].TotalSales);
        labels.push(this.translate.instant(this.tableList[i].MonthName) + this.tableList[i].Year);
      }
    }

    let chart = new Chart({

      chart: {
        type: 'line',
        renderTo: "largeincomingOrders",
      },
      title: {
        text: this.translate.instant("Sales Volume")
      },
      credits: {
        enabled: false
      },

      yAxis: {

        title: {
          text: this.translate.instant("Sales")
        },
        gridLineWidth: 0,
        labels: {
          formatter: function () {
            return '$' + this.value;
          }
        }

      },

      xAxis: {

        title: {
          text: this.translate.instant("Sales")
        },
        gridLineWidth: 0,
        labels:
        {
          formatter: function () {
            return labels[this.pos];
          }
        }

      },
      legend: {
        y: 25,
      },
      series: [{
        name: 'Months',
        data: pieChart,
        type: undefined,
      }]
    });
    this.chart = chart;
    this.salesDataFetchError = false;


  }

  Changedays(data) {
    this.filterForm.get('TotalRecords').setValue(data.value);
    localStorage.setItem('SalesValue', data.value);
    this.fnGetMonthlySales();
  }




}
