import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  number: string;

  constructor(private _http: HttpClient) { }

  ngOnInit() {
  }

  sendSMS() {
    this._http
      .post(environment.apiUrl + "customer/test-sms", { "number": this.number })
      .subscribe(data => {
      });
  }
}
