import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppSettings } from 'projects/cxr-customer/src/app/app.settings';
import { ApplicationsComponent } from 'projects/cxr-customer/src/app/theme/components/applications/applications.component';
import { BreadcrumbComponent } from 'projects/cxr-customer/src/app/theme/components/breadcrumb/breadcrumb.component';
import { FlagsMenuComponent } from 'projects/cxr-customer/src/app/theme/components/flags-menu/flags-menu.component';
import { FullScreenComponent } from 'projects/cxr-customer/src/app/theme/components/fullscreen/fullscreen.component';
import { HorizontalMenuComponent } from 'projects/cxr-customer/src/app/theme/components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from 'projects/cxr-customer/src/app/theme/components/menu/vertical-menu/vertical-menu.component';
import { MessagesComponent } from 'projects/cxr-customer/src/app/theme/components/messages/messages.component';
import { SidenavComponent } from 'projects/cxr-customer/src/app/theme/components/sidenav/sidenav.component';
import { UserMenuComponent } from 'projects/cxr-customer/src/app/theme/components/user-menu/user-menu.component';
import { NotFoundComponent } from 'src/app/pages/errors/not-found/not-found.component';
import { HttpLoaderFactory, SharedDialogModule } from 'src/app/shared-dialog/shared-dialog.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppComponent } from './app.component';
import { CompanyComponent } from './customer-login-layout/customer-details/company/company.component';
import { CustomerDetailsComponent } from './customer-login-layout/customer-details/customer-details.component';
import { IndividualComponent } from './customer-login-layout/customer-details/individual/individual.component';
import { NewRequestsComponent } from './customer-login-layout/customer-details/new-requests/new-requests.component';
import { RequestsComponent } from './customer-login-layout/customer-details/requests/requests.component';
import { CustomerLoginLayoutComponent } from './customer-login-layout/customer-login-layout.component';
import { DashboardComponent } from './customer-login-layout/dashboard/dashboard.component';
import { PipesModule } from './theme/pipes/pipes.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};
const routes: Routes = [
  {
    path: 'customer',
    component: CustomerLoginLayoutComponent, children: [

      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      {
        path: 'dashboard', component: DashboardComponent,
        data: { breadcrumb: 'Dashboard', description: "Dashboard screen." }
      },
      {
        path: 'details', component: CustomerDetailsComponent,
        data: { breadcrumb: 'Customer Details', description: "Customer Details Screen." }
      },
      {
        path: 'requests', component: RequestsComponent,
        data: { breadcrumb: 'Customer Requests', description: "Customer Requests Screen." }
      },
      {
        path: 'new-requests', component: NewRequestsComponent,
        data: { breadcrumb: 'Customer Requests', description: "Customer Requests Screen." }
      },
      {
        path: 'transactions',
        loadChildren: () => import('./customer-login-layout/customer-transactions/customer-transactions.module').then(m => m.CustomerTransactionsModule)
      },
      { path: "**", component: NotFoundComponent }
    ]
  }
];
@NgModule({
  declarations: [
    AppComponent,
    CustomerLoginLayoutComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    DashboardComponent,
    CustomerDetailsComponent,
    IndividualComponent,
    CompanyComponent,
    RequestsComponent,
    NewRequestsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FlexLayoutModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    SharedDialogModule,
    MatTooltipModule,
    ModalModule,
    PipesModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatOptionModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBNcjxo_35qnEG17dQvvftWa68eZWepYE0'
    // }),
    PerfectScrollbarModule,
    // CalendarModule.forRoot(),
    SharedModule,
    RouterModule.forChild(routes),
    DialogModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      extend: true
    })
  ],
  providers: [
    AppSettings,
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export class CXRCustomerSharedModule {
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers: []
    };
  }
}
