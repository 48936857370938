import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { responseModel } from '../_models/response.model';

@Injectable({
  providedIn: 'root'
})
export class AmlAlertsService {
  headers: { headers: any; };
  constructor (private _http: HttpClient) { }
  // For Sending headers to API
  // getHeaders() {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   };
  //   return this.headers = httpOptions;
  // }

  // function to usert aml alerst
  fnAmlAlertUpsert(values) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-aml-alert', values);
  }

  // function to delete the user into the database
  fnAmlAlertdelete(values) {
    return this._http.post(environment.apiUrl + 'delete-aml-alert', values);
  }

  // function to fetch previous aml alert notes
  fnFetchAMLResolutinNotes() {
    return this._http.post(environment.apiUrl + 'fetch-aml-alert-resolution-notes', null);
  }

  fnApplyAMLRules(fromDate: string, toDate: string) {
    let params = new HttpParams();
    params = params.set('fromDate', fromDate ? fromDate : '');
    params = params.set('toDate', toDate ? toDate : '');
    return this._http.get(`${ environment.apiUrl }apply-aml-rules`, { params: params });
  }

  fnGetAMLAlert(amlAlertId) {
    let params = new HttpParams();
    params = params.set('amlAlertId', amlAlertId);
    return this._http.get<responseModel>(`${ environment.apiUrl }get-aml-alert-details`, { params: params });
  }



}
