import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { responseModel } from '../_models/response.model';

@Injectable({
  providedIn: 'root'
})
export class SanctionListService {

  headers: { headers: any; };
  constructor (private _http: HttpClient) { }
  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }
  fnManageSantionList(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'populate-sanction-list?ownerID=' + val, null);
  }
  fnChangeSanctionListStatus(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'change-sanction-list-status', val);
  }
  fnCheckAgainstSanctionList(val) {
    let headers = new HttpHeaders();
    headers = headers.set('isCheckAgainstSanctionList', 'true');
    return this._http.post<responseModel>(environment.apiUrl + 'check-against-sanction-list', val, { headers: headers });
  }
  fnCheckAgainstParticularSanctionList(val) {
    let headers = new HttpHeaders();
    headers = headers.set('isCheckAgainstSanctionList', 'true');
    return this._http.post<responseModel>(environment.apiUrl + 'check-against-particular-sanction-list', val);
  }
  fnEditSanctionList(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'edit-sanction-list', val);
  }
  fnGetPresentSanctionList(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'get-present-sanction-list', val);
  }
  fnUpsertKYCAML(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-KYCAML', val);

  }
  // fnUpsertCompanyKYCAML(val) {
  //   return this._http.post<responseModel>(environment.apiUrl + 'upsert-company-KYCAML', val);

  // }


}
