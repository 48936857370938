import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export class loggedinTill {
  isLoggedIn: boolean
  TillID: any;
  TIllExtID: any;
}
@Injectable({
  providedIn: 'root'
})
export class DataSharingService {


  private tillLoginSource = new BehaviorSubject<loggedinTill>(null);
  currentLoggedInTill = this.tillLoginSource.asObservable();

  private CustomerID = new BehaviorSubject<string>(null);
  CurrentCustomerID = this.CustomerID.asObservable();


  private branchId: number
  newBranchId: Subject<number> = new Subject<number>();

  constructor() {
    this.newBranchId.subscribe(val => this.branchId = val)
  }

  fnloginInTill(tillDetails: loggedinTill) {
    this.tillLoginSource.next(tillDetails)
  }

  fnSetCustomerID(CustomerExtID: string) {
    this.CustomerID.next(CustomerExtID)
  }
  updateBranchId(branchId) {
    this.newBranchId.next(this.branchId = branchId)
  }
}
