import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppSettings } from 'projects/cxr-super-admin/src/app/app.settings';
import { AdministratorComponent } from 'projects/cxr-super-admin/src/app/pages/administrator/administrator.component';
import { PagesComponent } from 'projects/cxr-super-admin/src/app/pages/pages.component';
import { ApplicationsComponent } from 'projects/cxr-super-admin/src/app/theme/components/applications/applications.component';
import { BreadcrumbComponent } from 'projects/cxr-super-admin/src/app/theme/components/breadcrumb/breadcrumb.component';
import { FlagsMenuComponent } from 'projects/cxr-super-admin/src/app/theme/components/flags-menu/flags-menu.component';
import { FullScreenComponent } from 'projects/cxr-super-admin/src/app/theme/components/fullscreen/fullscreen.component';
import { HorizontalMenuComponent } from 'projects/cxr-super-admin/src/app/theme/components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from 'projects/cxr-super-admin/src/app/theme/components/menu/vertical-menu/vertical-menu.component';
import { MessagesComponent } from 'projects/cxr-super-admin/src/app/theme/components/messages/messages.component';
import { SidenavComponent } from 'projects/cxr-super-admin/src/app/theme/components/sidenav/sidenav.component';
import { UserMenuComponent } from 'projects/cxr-super-admin/src/app/theme/components/user-menu/user-menu.component';
import { NotFoundComponent } from 'src/app/pages/errors/not-found/not-found.component';
import { SharedDialogModule } from 'src/app/shared-dialog/shared-dialog.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SuperOwnerLoginGuard } from 'src/app/_guards/super-owner-login.guard';
import { AppComponent } from './app.component';
import { PipesModule } from './theme/pipes/pipes.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};
const routes: Routes = [
  {
    path: 'super-admin', canActivate: [SuperOwnerLoginGuard], canActivateChild: [SuperOwnerLoginGuard],
    component: PagesComponent, children: [
      { path: '', redirectTo: 'administrator', pathMatch: 'full' },
      {
        path: 'administrator',
        component: AdministratorComponent,
        loadChildren: () => import('./pages/administrator/administrator.module').then(m => m.AdministratorModule),
        data: { breadcrumb: 'Admininstrator', description: "Admininstrator screen." }
      },
      { path: "**", component: NotFoundComponent }
    ]
  }
];
@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    AdministratorComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FlexLayoutModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTabsModule,
    SharedDialogModule,
    MatTooltipModule,
    ModalModule,
    PipesModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    MatDialogModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBNcjxo_35qnEG17dQvvftWa68eZWepYE0'
    // }),
    PerfectScrollbarModule,
    // CalendarModule.forRoot(),
    SharedModule,
    RouterModule.forChild(routes),
    DialogModule,

  ],
  providers: [
    AppSettings, SuperOwnerLoginGuard,
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export class CXRSuperAdminSharedModule {
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers: []
    };
  }
}
