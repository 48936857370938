<div>
    <!-- <div class="row">
        <div class="col-sm-12">
            <div fxFlex="100" fxFlex.gt-sm fxFlex.sm class="flex-p w-100">
                <div class="row">
                    <div class="col-sm-12">
                        <button mat-raised-button color="primary" class="pull-right filter-hover pull-right"
                            (click)="fnFilterToggle()">
                            <mat-icon>filter_list</mat-icon> {{'Filter' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="row">
        <div class="col-sm-12">
            <div fxFlex="100" fxFlex.gt-sm fxFlex.sm class="flex-p w-100" *ngIf="filterToggle">
                <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
                    <div class="filter">
                        <div class="row">

                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group form-group-height">
                                    <label class=""> {{'From Date' | translate}}: </label>
                                    <cxr-prime-calendar formControlName="fromDate">
                                    </cxr-prime-calendar>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group form-group-height">
                                    <label class=""> {{'To Date' | translate}}: </label>
                                    <cxr-prime-calendar formControlName="toDate">
                                    </cxr-prime-calendar>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group form-group-height">
                                    <label class=""> {{'Week (For Performance by Weekdays)' | translate}}: </label>
                                    <input type="week" formControlName="WeekNumber" class="form-control col-sm-8"
                                        placeholder="{{'Select week' | translate}}" onkeydown="return false">
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group form-group-height">
                                    <label class=""> {{'Year (For Monthly Summary)' | translate}}:</label>
                                    <mat-select class="form-control col-sm-8" placeholder="{{'Select' | translate}}"
                                        formControlName="year">
                                        <mat-option [value]="item" *ngFor="let item of YearArray">{{item}}</mat-option>
                                        <!-- <mat-option [value]="2020">2020</mat-option>
                                        <mat-option [value]="2019">2019</mat-option>
                                        <mat-option [value]="2018">2018</mat-option>
                                        <mat-option [value]="2017">2017</mat-option>
                                        <mat-option [value]="2016">2016</mat-option>
                                        <mat-option [value]="2015">2015</mat-option>
                                        <mat-option [value]="2014">2014</mat-option> -->
                                    </mat-select>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group form-group-height">
                                    <label class=""> {{'Type' | translate}}:</label>
                                    <mat-select class="form-control col-sm-8" placeholder="{{'Select' | translate}}"
                                        formControlName="isType">
                                        <mat-option [value]="true">{{'By Transaction' | translate}}</mat-option>
                                        <mat-option [value]="false">{{'By Volume' | translate}}</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group form-group-height">
                                    <label class=""> {{'Show' | translate}}:</label>
                                    <mat-select class="form-control col-sm-8" placeholder="Select"
                                        formControlName="isShow">
                                        <mat-option [value]="true">{{'Active' | translate}}</mat-option>
                                        <mat-option [value]="false">{{'Local Currency' | translate}}</mat-option>
                                    </mat-select>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group form-group-height">
                                    <label class="" for="Branch">{{'Branch' | translate}}:</label>
                                    <mat-select class="form-control col-sm-8" placeholder="{{'Select' | translate}}"
                                        formControlName="branch" multiple>
                                        <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">{{'Select
                                            All' | translate}}
                                        </mat-option>
                                        <mat-option (click)="tosslePerOne(allSelected.viewValue)"
                                            *ngFor="let branch of branchList" [value]="branch.BranchID">
                                            {{branch.BranchCode}}</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group">
                                    <mat-checkbox color="warn" formControlName="isBuy">{{'Display Buys' | translate}}
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3">
                                <div class="form-group">
                                    <mat-checkbox color="warn" formControlName="isSell">{{'Display Sells' | translate}}
                                    </mat-checkbox>
                                </div>
                            </div>

                            <div class="col-md-3 col-xs-12 col-sm-6 pull-right">
                                <cxr-date-range-pick-button class="pull-left" [fromDate]="filterForm.value?.fromDate"
                                    [toDate]="filterForm.value?.toDate" (dateRange)="emitData($event)">
                                </cxr-date-range-pick-button>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-lg-12 m-t-10" style="display: table;">
                                <button type="button" (click)="resetFilter()"
                                    class="btn btn-default pull-right">{{'Reset' | translate}}</button>
                                <button type="submit" class="btn btn-success pull-right m-r-5">{{'GO' |
                                    translate}}</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <span *ngIf="error.isError"
                                    style="color:red;display: table-cell;vertical-align: middle;">
                                    {{ error.errorMessage }}
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row">
        <div fxFlex="100" fxFlex.gt-sm fxFlex.sm class="flex-p w-100">
            <div class="col-md-6 col-sm-12">
                <cxr-till-summary></cxr-till-summary>
            </div>

            <div class="col-md-6 col-sm-12">
                <cxr-favorite-currency></cxr-favorite-currency>
            </div>
        </div>
    </div>
    <div class="row">
        <div fxFlex="100" fxFlex.gt-sm fxFlex.sm class="flex-p w-100">
            <!-- <div class="col-md-5">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover">
                        <thead style="background: #333!important;color:#eee">
                            <tr>
                                <th>Currency</th>
                                <th style="text-align:right;">Cost</th>
                                <th style="text-align:right;">Sales</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="!fetchingError">
                            <tr *ngFor="let currency of currencyList;let i = index"
                                [class.total_row]="(currencyList.length-1) == i ">
                                <td>{{currency.Currency}}</td>
                                <td class="currency-align">{{currency.TotalDebitLC | numberFormat:2}}</td>
                                <td class="currency-align">{{currency.TotalCreditLC | numberFormat:2}}</td>
                            </tr>
                            <tr *ngIf="currencyList?.length == 0">
                                <td colspan="3" style="text-align:center">No records found</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="fetchingError">
                            <tr>
                                <td colspan="3" style="text-align: center">{{'Error Message' | translate}}: {{errorMessage}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->
            <div class="col-md-6 col-xs-12 ">
                <mat-card class="mat-card-graph ">
                    <mat-card-header fxLayoutAlign="center center">
                        <mat-card-subtitle>
                            <h2></h2>
                        </mat-card-subtitle>
                    </mat-card-header>
                    <div class="col-sm-12" *ngIf="!TopTenSalesfetchingError">
                        <div [chart]="TopTenSalesChart" class="col-sm-12"></div>
                    </div>
                    <div class="col-sm-12" style="text-align:center" *ngIf="!TopTenSalesfetchingError">
                        <span *ngIf="currency?.length == 0"> {{'No data found' | translate}}</span>
                    </div>
                    <div *ngIf="TopTenSalesfetchingError">
                        <h5 style="text-align:center">{{'Error Message' | translate}}: {{errorMessage}}</h5>
                    </div>
                </mat-card>
            </div>
            <div class="col-md-6 col-xs-12 ">
                <mat-card class="mat-card-graph ">
                    <mat-card-header fxLayoutAlign="center center">
                        <mat-card-subtitle>
                            <h2></h2>
                        </mat-card-subtitle>
                    </mat-card-header>
                    <div class="col-sm-12" *ngIf="!TopTenSalesfetchingError">
                        <div [chart]="TopTenUsersChart" class="col-sm-12"></div>
                    </div>
                    <div class="col-sm-12" style="text-align:center" *ngIf="!TopTenSalesfetchingError">
                        <span *ngIf="userList?.length == 0"> {{'No data found' | translate}}</span>
                    </div>
                    <div *ngIf="TopTenSalesfetchingError">
                        <h5 style="text-align:center">{{'Error Message' | translate}}: {{errorMessage}}</h5>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

    <div class="row">
        <div fxFlex="100" fxFlex.gt-sm fxFlex.sm class="flex-p w-100">
            <div class="col-md-6 col-xs-12 ">
                <mat-card class="mat-card-graph ">
                    <mat-card-header fxLayoutAlign="center center">
                        <mat-card-subtitle>
                            <h2></h2>
                        </mat-card-subtitle>
                    </mat-card-header>
                    <div class="col-sm-12" *ngIf="!MonthlySummaryfetchingError">
                        <div [chart]="MonthlySummaryChart" class="col-sm-12"></div>
                    </div>
                    <div class="col-sm-12" style="text-align:center" *ngIf="!MonthlySummaryfetchingError">
                        <span *ngIf="tableListMonthly?.length == 0"> {{'No data found' | translate}}</span>
                    </div>
                    <div *ngIf="MonthlySummaryfetchingError">
                        <h5 style="text-align:center">{{'Error Message' | translate}}: {{errorMessage}}</h5>
                    </div>
                </mat-card>
            </div>
            <div class="col-md-6 col-xs-12">
                <mat-card class="mat-card-graph ">
                    <mat-card-header fxLayoutAlign="center center">
                        <mat-card-subtitle>
                            <h2></h2>
                        </mat-card-subtitle>
                    </mat-card-header>
                    <div class="col-sm-12" *ngIf="!WeeklySummaryfetchingError">
                        <div [chart]="WeeklySummaryChart" class="col-sm-12"></div>
                    </div>
                    <div class="col-sm-12" style="text-align:center" *ngIf="!WeeklySummaryfetchingError">
                        <span *ngIf="tableListWeekly?.length == 0"> {{'No data found' | translate}}</span>
                    </div>
                    <div *ngIf="WeeklySummaryfetchingError">
                        <h5 style="text-align:center">{{'Error Message' | translate}}: {{errorMessage}}</h5>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

    <div class="row">
        <div fxFlex="100" fxFlex.gt-sm fxFlex.sm class="flex-p w-100">
            <div class="col-md-12 col-xs-12">
                <mat-card class="mat-card-graph ">
                    <mat-card-header fxLayoutAlign="center center">
                        <mat-card-subtitle>
                            <h2></h2>
                        </mat-card-subtitle>
                    </mat-card-header>
                    <div class="col-sm-12">
                        <div [chart]="BusyTradingHoursChart" class="col-sm-12"></div>
                    </div>
                    <div class="col-sm-12" style="text-align:center">
                        <span *ngIf="busyTradingHours?.length == 0"> {{'No data found' | translate}}</span>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="row">
        <div fxFlex="100" fxFlex.gt-sm fxFlex.sm class="flex-p w-100">
            <div class="col-md-12 col-xs-12 col-sm-12">
                <cxr-monthly-sales></cxr-monthly-sales>
            </div>
        </div>
    </div>

    <div class="row">
        <div fxFlex="100" fxFlex.gt-sm fxFlex.sm class="flex-p w-100">

            <div class="col-md-4 col-sm-12">
                <cxr-recent-customer></cxr-recent-customer>
            </div>

            <div class="col-md-4 col-sm-12">
                <cxr-recent-aml-alerts></cxr-recent-aml-alerts>
            </div>
        </div>
    </div>

</div>
