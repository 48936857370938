import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
import { forkJoin } from 'rxjs';
import { Localisation } from 'src/app/_services/localisation';
import { AppSettings } from 'src/app/app.settings';
import { GetUserObject } from '../_helpers/get-user-object';
import { LookupsService } from '../_services/lookups.service';
import { ManagementReportsService } from '../reports/_service/management-reports.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {



  //Filter Form
  filterForm: UntypedFormGroup;

  currencyList: any = [];
  userList: any = [];
  busyTradingHours: any = [];
  branchList = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  filterToggle: boolean = false;
  fetchingError: boolean = false;
  WeeklySummaryfetchingError: boolean = false;
  MonthlySummaryfetchingError: boolean = false;
  TopTenSalesfetchingError: boolean = false;
  topTenSalesChartName = "Top 10 Selling Currency by volume";
  topTenUserChartName = "Top 10 performing users by volume";
  monthlySummaryChartName = "Monthly Summary";
  weaklySummaryChartName = "Performance by Weekdays";
  busyWorkHoursChartName = "Busy Trading hours of the day (Based on past 30 days)";
  amount = "Amount";
  total = "Total";
  noOfTransactions = "No of Transactions";
  average = "Average";
  hours = "Hours";
  errorMessage: any;
  public single: any[];
  public currency: any[];
  public user: any[];
  public showLegend = true;
  public gradient = true;
  public colorScheme = {
    domain: ['#2F3E9E', '#D22E2E', '#378D3B', '#0096A6', '#F47B00', '#606060'],


  };
  public showLabels = true;
  public explodeSlices = false;
  public doughnut = false;
  filterOpenCount: number = 0;

  @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
  chart: Chart;
  TopTenSalesChart: Chart;
  TopTenUsersChart: Chart;
  BusyTradingHoursChart: Chart;
  MonthlySummaryChart: Chart;
  WeekList: any[];
  tableListWeekly: any[];
  WeeklySummaryChart: Chart;


  ngOnInit() {
    this.GetYears();
    this.filterFormInit();
    this.fetchData();

  }

  YearArray: any[] = [];
  GetYears() {
    var d = new Date();
    for (var i = 2014; i <= d.getFullYear(); i++) {
      this.YearArray.push(i);
    }
    this.YearArray = this.YearArray.reverse();
  }


  constructor (
    private formBuilder: UntypedFormBuilder,
    public appSettings: AppSettings,
    public translate: TranslateService,
    private _lookupsService: LookupsService,
    private _managementReportsService: ManagementReportsService
  ) {
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
    translate.get(this.topTenSalesChartName).subscribe(data => {
      this.topTenSalesChartName = data;
    });
    translate.get(this.topTenUserChartName).subscribe(data => {
      this.topTenUserChartName = data;
    });
    translate.get(this.monthlySummaryChartName).subscribe(data => {
      this.monthlySummaryChartName = data;
    });
    translate.get(this.weaklySummaryChartName).subscribe(data => {
      this.weaklySummaryChartName = data;
    });
    translate.get(this.busyWorkHoursChartName).subscribe(data => {
      this.busyWorkHoursChartName = data;
    });
    translate.get(this.amount).subscribe(data => {
      this.amount = data;
    });
    translate.get(this.total).subscribe(data => {
      this.total = data;
    });
    translate.get(this.noOfTransactions).subscribe(data => {
      this.noOfTransactions = data;
    });
    translate.get(this.average).subscribe(data => {
      this.average = data;
    });
    translate.get(this.hours).subscribe(data => {
      this.hours = data;
    });
  }

  datePipeEn: DatePipe = new DatePipe('en-US');

  fetchData() {

    forkJoin([
      this._managementReportsService.fnGetTopTensales(this.filterForm.value),
      this._managementReportsService.fnGetMonthlySummary(this.filterForm.value),
      this._managementReportsService.fnGetWeeklySummary(this.filterForm.value),
      this._managementReportsService.fnGetTopTenUsers(this.filterForm.value),
      this._managementReportsService.GetMostBusyTradningHoursOfTheDay(this.filterForm.value)
    ]).subscribe(result => {
      //Top 10 Selling Currencies
      this.currencyList = [];
      this.currencyList = result[0].Data;
      this.userList = result[3].Data;
      this.busyTradingHours = result[4].Data;
      //
      this.getCountriesPie();
      this.getUserPie();

      //
      this.getBusyWorkHoursPie();


      //annual summary
      this.MonthList = this.tableListMonthly = [];
      this.MonthList = result[1].Data['Months'];
      this.tableListMonthly = result[1].Data['Data'];
      this.fnMonthlySummaryChart();

      //Performance by Weekdays
      this.WeekList = this.tableListWeekly = [];
      this.tableListWeekly = result[2].Data['WeekData'];
      this.tableListWeekly = result[2].Data['Data'];
      this.fnWeeklySummaryChart();
    });
  }

  fnTranslate() {

  }
  fnWeeklySummaryChart() {

    let weeks: Array<string> = ['Monday', "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(x => this.translate.instant(x));
    var name = this.weaklySummaryChartName + " " + (this.filterForm.value.weekNo != null ? this.filterForm.value.weekNo : "").toString();
    var finalArray = [];
    if (this.tableListWeekly.length > 0) {
      finalArray = this.tableListWeekly.find(x => x.Currency == "Total") == null ? [] : this.tableListWeekly.find(x => x.Currency == "Total").Data.map(function (obj) {
        return obj.Value;
      });
    }
    this.WeeklySummaryChart = new Chart({
      chart: {
        type: 'column'
      }
      ,
      title: {
        text: name
      },
      credits: {
        enabled: false
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },
      xAxis: {
        title: {
          text: this.translate.instant('Weekdays')
        },
        categories: weeks,
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: this.amount
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: <Array<Highcharts.SeriesOptionsType>>[
        {
          name: this.total,
          data: finalArray
        }
      ]
    });
  }

  fetchBranchesDD() {
    this._lookupsService.fnGetBranchesDD(GetUserObject.GetUserObject()).subscribe(result => {
      this.branchList = result.Data;
    });
  }

  getCountriesPie() {
    this.currency = [];
    if (this.currencyList != null && this.currencyList != "") {
      for (let i = 0; i < this.currencyList.length - 1; i++) {
        this.currency.push({
          value: this.currencyList[i].TotalCreditLC,
          name: this.currencyList[i].Currency
        });
      }
    }

    var pieChart = [];
    if (this.currencyList != null && this.currencyList != "") {
      for (let i = 0; i < this.currencyList.length - 1; i++) {
        pieChart.push({
          y: this.currencyList[i].TotalCreditLC,
          name: this.currencyList[i].Currency
        });
      }
    }
    this.TopTenSalesChart = new Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      credits: {
        enabled: false,
      },
      title: {
        text: this.topTenSalesChartName // 'Top 10 Selling Currencies Pie chart'
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">' + this.topTenSalesChartName + '</span><br>',
        pointFormat: '{point.name} <b>{point.y}</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true
          },
          showInLegend: true
        }
      },
      series: <Array<Highcharts.SeriesOptionsType>>[{
        colorByPoint: true,
        data: pieChart
      }]
    });
  }

  getUserPie() {
    var pieChart = [];
    if (this.userList != null && this.userList != "") {
      for (let i = 0; i < this.userList.length; i++) {
        pieChart.push({
          y: this.userList[i].TotalLC,
          name: this.userList[i].UserName
        });
      }
    }
    this.TopTenUsersChart = new Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      credits: {
        enabled: false,
      },
      title: {
        text: this.topTenUserChartName
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">' + this.topTenUserChartName + '</span><br>',
        pointFormat: '{point.name} <b>{point.y}</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true
          },
          showInLegend: true
        }
      },
      series: <Array<Highcharts.SeriesOptionsType>>[{
        colorByPoint: true,
        data: pieChart
      }]
    });
  }

  getBusyWorkHoursPie() {


    var pieChart = [];
    var labels = [];
    if (this.busyTradingHours != null && this.busyTradingHours != "") {
      for (let i = 0; i < this.busyTradingHours.length; i++) {
        // pieChart.push({
        //   y: this.busyTradingHours[i].TimeRange,
        //   name: this.busyTradingHours[i].Currency
        // });
        pieChart.push(this.busyTradingHours[i].AverageTransactions);
        labels.push(this.busyTradingHours[i].TimeRange,);
      }
    }


    var finalArray = this.tableListMonthly.find(x => x.Currency == "Total") == null ? [] : this.tableListMonthly.find(x => x.Currency == "Total").Data.map(function (obj) {
      return obj.Value;
    });
    this.BusyTradingHoursChart = new Chart({
      chart: {
        type: 'column'
      }
      ,
      title: {
        text: this.busyWorkHoursChartName
      },
      credits: {
        enabled: false
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },
      xAxis: {
        categories: labels,
        crosshair: true,
        title: {
          text: this.hours
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: this.noOfTransactions
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: <Array<Highcharts.SeriesOptionsType>>[
        {
          name: this.average,
          data: pieChart
        }
      ]
    });
  }

  public onSelect(event) {
  }
  //get current week
  getThisWeek() {
    const d: Date = new Date();
    let weekNumber = d.getWeekYear() + "-W" + d.getWeek();
    return weekNumber;
  }
  //filter form initialization
  filterFormInit() {
    this.filterForm = this.formBuilder.group({
      OwnerID: GetUserObject.GetOwnerID(),
      fromDate: null,
      toDate: null,
      branch: null,
      keywordSearch: [''],
      year: [(new Date()).getFullYear()],
      isType: true,
      isShow: true,
      isBuy: false,
      isSell: false,
      WeekNumber: this.getThisWeek(),
      ClientLocalTime: new Date().toLocaleTimeString() // this.datePipeEn.transform(new Date(), 'yyyy-MM-dd HH:MM:SS')
    });
  }
  get f() { return this.filterForm.controls; }
  //On filter submit
  onSubmit() {
    if (this.filterForm.value.branch != null && (this.branchList.length < this.filterForm.value.branch.length))
      this.filterForm.value.branch.pop();
    this.error.isError = false;
    // stop here if form is invalid
    if (this.compareTwoDates()) {
      if (this.filterForm.invalid) {
        return;
      } else {
        if (this.filterForm.value.fromDate != null && this.filterForm.value.toDate == null) {
          this.filterForm.value.toDate = (new Date()).toISOString().split('T').shift();;
        }
        this.fetchData();
      }
    }
  }

  tableListMonthly: any[] = [];
  MonthList: any[] = [];
  fnMonthlySummaryChart() {
    var finalArray = this.tableListMonthly.find(x => x.Currency == "Total") == null ? [] : this.tableListMonthly.find(x => x.Currency == "Total").Data.map(function (obj) {
      return obj.Value;
    });
    let months: Array<string> = this.MonthList.map(x => this.translate.instant(x.MonthName));
    this.MonthlySummaryChart = new Chart({
      chart: {
        type: 'column'
      }
      ,
      title: {
        text: this.filterForm.value.year + ' ' + this.monthlySummaryChartName
      },
      credits: {
        enabled: false
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },
      xAxis: {
        categories: months,
        crosshair: true,
        title: {
          text: this.translate.instant('Months')
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: this.amount
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: <Array<Highcharts.SeriesOptionsType>>[
        {
          name: this.translate.instant("Total"),
          data: finalArray
        }
      ]
    });
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.filterForm.controls.branch
        .patchValue([...this.branchList.map(item => item.BranchID), 0]);
    } else {
      this.filterForm.controls.branch.patchValue([]);
    }
  }
  tosslePerOne(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.filterForm.controls.branch.value.length == this.branchList.length)
      this.allSelected.select();

  }
  error: any = { isError: false, errorMessage: '' };
  compareTwoDates() {
    if (this.filterForm.controls['toDate'].value != null || this.filterForm.controls['toDate'].value != null) {
      if (new Date(this.filterForm.controls['toDate'].value) < new Date(this.filterForm.controls['fromDate'].value)) {
        this.error = { isError: true, errorMessage: "Enter to date lesser than from date." };
        return false;
      }
    }
    return true;
  }
  validateForm() {
    let valid = true;
    if (this.filterForm.value.fromDate == null || this.filterForm.value.fromDate == "") {
      valid = false;
    }
    if (this.filterForm.value.toDate == null || this.filterForm.value.toDate == "") {
      valid = false;
    }
    return valid;
  }
  resetFilter() {
    this.filterForm.reset();
    this.filterFormInit();
    this.fetchData();
    this.error.isError = false;
  }
  fnFilterToggle() {
    if (this.filterOpenCount == 0) {
      this.fetchBranchesDD();
      this.filterOpenCount++;
    }
    this.filterToggle = !this.filterToggle;
  }

  emitData(event) {
    this.filterForm.get('toDate').setValue(event.toDate);
    this.filterForm.get('fromDate').setValue(event.fromDate);
  }

}
