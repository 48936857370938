import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DecimalValidation } from 'src/app/_helpers/decimal.validation';
import { Localisation } from 'src/app/_services/localisation';
import { PqgridComponent } from 'src/app/pqgrid.component';
import { NotificationService } from 'src/app/shared/_services/notification.service';
import pq from 'src/paramquery';
import { GetUserObject } from '../../_helpers/get-user-object';
import { LookupsService } from '../../_services/lookups.service';

@Component({
  selector: 'app-change-branch-dialog',
  templateUrl: './change-branch-dialog.component.html',
  styleUrls: ['./change-branch-dialog.component.scss']
})
export class ChangeBranchDialogComponent implements OnInit {
  BranchesList: any = [];
  branch: any;
  GridName: string = 'ChangeBranch' + GetUserObject.GetUserID();
  pleaseSelectBranch: string;
  @ViewChild('grid', { static: false }) grid: PqgridComponent;
  options: pq.gridT.options;
  constructor (
    private _dialogRef: MatDialogRef<ChangeBranchDialogComponent>,
    private _lookupsService: LookupsService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _notificationService: NotificationService) {
    _dialogRef.disableClose = true;
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);

    //this.pleaseSelectBranch = .
    //translate.get('Please select Branch').toPromise.toString()
    // this.getSomething().then(s => {
    //   
    // })
  }

  sessionDetails = GetUserObject.GetUserObject();

  ngOnInit() {
    this.GridInit();
    this.fetchBranchesDD();
    this.fnTranslate();
  }
  onDecimalNumberFieldKeyPress($evt) {
    return DecimalValidation.isDecimalNumberKey($evt);
  }

  onNoClick(): void {
    if (this.data?.isForCurrencyRateBoard) {
      this._dialogRef.close();
    }
    else if (this.branch != null) {
      this.sessionDetails.BranchCode = this.BranchesList.find(x => x.BranchID == this.branch).BranchCode;
      this.sessionDetails.BranchID = this.branch;
      this._dialogRef.close(this.sessionDetails);
    } else if (!GetUserObject.isAdmin()) {
      this._notificationService.error("Please select Branch");
    }
    else {
      this._dialogRef.close();
    }

  }
  fetchBranchesDD() {
    this._lookupsService.fnGetBranchesDD(this.sessionDetails).subscribe(result => {
      this.BranchesList = result.Data;
      if (this.BranchesList.length == 0) {
        this._dialogRef.close(this.sessionDetails);
      }
      else if (this.BranchesList.length == 1 && !this.data?.isForCurrencyRateBoard) {
        this.sessionDetails.BranchCode = this.BranchesList[0].BranchCode;
        this.sessionDetails.BranchID = this.BranchesList[0].BranchID;
        this._dialogRef.close(this.sessionDetails);
      }
      this.options.dataModel.data = result.Data;
      this.branch = this.sessionDetails.BranchID;
    });
  }
  saveChangeOfBranch() {
    if (this.branch != null) {
      this.sessionDetails.BranchCode = this.BranchesList.find(x => x.BranchID == this.branch).BranchCode;
      this.sessionDetails.BranchID = this.branch;
      this._dialogRef.close(this.sessionDetails);
    } else {

      this._notificationService.error("Please select Branch");
      // GetUserObject.RemoveSession();
      // if (this.sessionDetails.isOwner) {
      //   this.sessionDetails.BranchID = null;
      //   this.sessionDetails.BranchCode = "Owner";
      // } else if (this.sessionDetails.isAdmin) {
      //   this.sessionDetails.BranchID = null;
      //   this.sessionDetails.BranchCode = "Admin";
      // }
    }

  }

  GridInit() {
    var self = this;

    var toolBar = [];

    this.options = {
      scrollModel: { autoFit: true },
      title: "Choose Branch",
      height: "300",
      selectionModel: { type: 'row', mode: 'single', toggle: true },
      // create: function () {
      //   //restore state of grid.
      //   var StateVal = localStorage.getItem('pq-grid' + self.GridName);
      //   this.loadState({ refresh: false, state: StateVal });
      // },
      toolbar: {
        items: self.data?.isForCurrencyRateBoard ? [
          {
            type: 'button',
            icon: '',
            cls: "pull-right pq-add-button",
            label: self.data?.pickNonBranch,
            listener: function () {
              self._dialogRef.close('all');
            }
          }

        ] : []
      },

      rowDblClick: function (evt, ui) {
        self.sessionDetails.BranchCode = ui.rowData.BranchCode;
        self.sessionDetails.BranchID = ui.rowData.BranchID;
        self._dialogRef.close(self.sessionDetails);
      },
      colModel: [
        { title: "Code", dataType: "string", dataIndx: "BranchCode", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Name", dataType: "string", dataIndx: "BranchName", filter: { crules: [{ condition: 'contain' }] } },
        { title: "City", dataType: "string", dataIndx: "City", filter: { crules: [{ condition: 'contain' }] } },

      ],
      filterModel: {
        on: true,
        mode: "AND",
        header: true,
        menuIcon: false //show filter row icon initially.
      },
      // pageModel: { type: 'local' },
      // pageModel: { type: "local", rPP: 10, curPage: 1, rPPOptions: [5, 10, 20, 50, 100] },

      dataModel: {
        data: []
      }
    };
  }

  fnTranslate() {
    for (let index = 0; index < this.options.colModel.length; index++) {
      if (this.options.colModel[index].title) {
        this.options.colModel[index].title = this.translate.instant(this.options.colModel[index].title.toString());
      }
    }
    this.options.title = this.translate.instant(this.options.title);
  }
}
