import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { EmailModel } from 'projects/cxr-crm/src/app/pages/_models/generic-models/generic-model';
import { Localisation } from 'src/app/_services/localisation';
import { PqgridComponent } from 'src/app/pqgrid.component';
import { ConfirmationDialogComponent } from 'src/app/shared-dialog/confirmation-dialog/confirmation-dialog.component';
import { GenericPreviewModel, PreviewGenericRowDataDialogComponent } from 'src/app/shared-dialog/preview-generic-row-data-dialog/preview-generic-row-data-dialog.component';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { environment } from 'src/environments/environment';
import pq from 'src/paramquery';
import { GenericComponentService } from '../../../_services/generic-component.service';
import { EmailValidation } from '../email-validation/email-validation.model';

@Component({
  selector: 'app-generic-email',
  templateUrl: './generic-email.component.html',
  styleUrls: ['./generic-email.component.scss']
})
export class GenericEmailComponent implements OnInit {
  @HostListener('window:beforeunload')
  saveGridState() {
    this.grid?.SaveState();
  }
  GridName: string = 'EmailAddress' + GetUserObject.GetUserID();

  @Input('InternalTableName') InternalTableName: string;
  @Input('InternalRecordID') InternalRecordID: number;
  @Input('EmailTypeDD') EmailTypeDD: any[] = [];
  @Input('isForCustomerReport') isForCustomerReport: boolean;
  @Input('hideAddButton') hideAddButton: boolean = false;
  @ViewChild('grid', { static: false }) grid: PqgridComponent;
  optionsGrid: pq.gridT.options;
  clickToEdit = 'Click to edit';
  clickToDelete = 'Click to delete';
  showValidateButton: boolean = false;
  constructor (
    private _formBuilder: UntypedFormBuilder,
    private _dialog: MatDialog,
    private translate: TranslateService,
    private _genericComponentService: GenericComponentService
  ) {
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
  }

  ngOnInit() {
    this.emailFormInit(new EmailModel());
    this.GridInit();
    this.fnTranslate();
  }
  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    //Write your code here
    this.GridInit();
  }
  ngOnDestroy() {
    this.grid.SaveState();
  }
  isValidEmailAddress(data: EmailValidation): void {
    this.EmailF.isValidEmail.setValue(data?.isValid);
    this.EmailF.statusValue.setValue(data?.statusValue);
  }

  GridInit() {
    var self = this;
    this.optionsGrid = {
      title: "Email Address",
      scrollModel: { autoFit: true },
      toolbar: {
        items: this.hideAddButton ? [] : [
          // {
          //   type: 'button',
          // label: self._route.snapshot.data.Data['Reset filters'],
          //   listener: function () {
          //     this.reset({ filter: true });
          //   }
          // },
          {
            type: 'button',
            icon: 'ui-icon-plus',
            cls: 'pq-add-button',
            label: '',
            listener: function () {
              self.addEditEmail(new EmailModel(), 1);
            }
          }
        ]
      },
      pageModel: {
        type: "remote", rPP: environment.pq_grid.dialog_rPP, curPage: 1, rPPOptions: [5, 10, 20, 50, 100],
        layout: []
      },
      filterModel: {
        on: false,
        header: false,
        type: 'remote',
        menuIcon: false
      },
      sortModel: {
        type: 'remote',
        single: false,
        sorter: [{ dataIndx: 'EmailID', dir: 'down' }],
        space: true,
        multiKey: null
      },
      rowDblClick: function (evt, ui) {
        var previewObj: GenericPreviewModel = new GenericPreviewModel();
        previewObj.Columns = this.colModel;
        previewObj.RowData = ui.rowData;
        previewObj.Heading = 'Grid Details Preview';
        const dialogRef = self._dialog.open(PreviewGenericRowDataDialogComponent, {
          data: previewObj,
          width: '1000px'
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      },
      colModel: [
        {
          title: "", editable: false, width: 25, sortable: false, align: "center", menuIcon: false,
          cls: this.isForCustomerReport ? "hide" : "",
          render: function (ui) {
            return "<a style='color: green;cursor: pointer;' title= " + self.clickToEdit + "    class='edit_btn'><span>&#x270F;</span></a>\
          <a style='color: red;cursor: pointer;' title= "+ self.clickToDelete + "    class='delete_btn'><span>&#x274C;</span></a>";
          },
          postRender: function (ui) {
            var rowIndx = ui.rowIndx,
              grid = this,
              $cell = grid.getCell(ui);
            //edit button
            $cell.find(".edit_btn").off("click")
              .on("click", function (evt) {
                self.addEditEmail(ui.rowData, 1);
              });
            //delete  button
            $cell.find(".delete_btn").off("click")
              .on("click", function (evt) {
                self.showConfirmationModal();
                self.deleteRowValue = ui.rowData;
                self.deleteRowType = 'email';
              });
          }
        },
        { title: "Type", dataType: "string", dataIndx: "EmailType", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Email", dataType: "string", dataIndx: "EmailAddress", filter: { crules: [{ condition: 'contain' }] } },

        // this.isForCustomerReport ? this.tset() : {}

      ],
      selectionModel: { type: 'row' },

      // sort: function (evt, ui) {
      //   this.onSort(evt, ui);
      // },
      dataModel: {
        location: "remote",
        postData:
        {
          InternalRecordID: self.InternalRecordID,
          InternalTableName: self.InternalTableName
        },
        dataType: "JSON",
        method: "POST",
        url: environment.apiUrl + "generic/get-email-list",
        getData: function (dataJSON) {
          var data = JSON.parse(dataJSON.Data);
          return { curPage: data.curPage, totalRecords: data.totalRecords, data: data.data };
        }
      }
    };
  }

  EmailForm: UntypedFormGroup;
  emailSubmitted: boolean;
  EmailValue: EmailModel;
  @ViewChild('addEditEmailModal', { static: false }) addEditEmailModal: ModalDirective;
  emailFormInit(val: EmailModel) {
    this.EmailForm = this._formBuilder.group({
      EmailID: new UntypedFormControl(val.EmailID),
      InternalRecordID: new UntypedFormControl(this.InternalRecordID, [Validators.required]),
      InternalTableName: new UntypedFormControl(this.InternalTableName, [Validators.required]),
      EmailType: new UntypedFormControl(val.EmailType, Validators.required),
      EmailAddress: new UntypedFormControl(val.EmailAddress, [Validators.required, emailValidator]),
      isDeleted: new UntypedFormControl(false),
      isValidEmail: new UntypedFormControl(val.isValidEmail),
      statusValue: new UntypedFormControl(val.statusValue),
    });
  }
  // convenience getter for easy access to form fields
  get EmailF() {
    return this.EmailForm.controls;
  }
  onEmailFormSubmit() {
    this.emailSubmitted = true;
    // stop here if form is invalid
    if (this.EmailForm.invalid) {
      return;
    }
    this._genericComponentService.fnUpsertEmail(this.EmailForm.value)
      .subscribe(data => {
        if (data.Success) {
          this.EmailValue = new EmailModel();
          this.emailSubmitted = false;
          this.addEditEmail(new EmailModel(), 0);
          this.grid.refreshDataAndView();
        }
      });
  }
  addEditEmail(val: EmailModel, flag) {
    this.emailSubmitted = false;
    if (flag == 1) {
      this._genericComponentService.fnGetEmailData(val).subscribe(data => {
        this.emailFormInit(data.Data);
        this.addEditEmailModal.show();
        this.showValidateButton = true;
      });
    }
    else {
      this.showValidateButton = false;
      this.addEditEmailModal.hide();
    }
  }
  //email code ends here
  // get Modal Pop-up data
  fnGetDropDownData() {

  }

  // get Modal Pop-up data ends here


  // Confirmation modal pop over
  @ViewChild('confirmationModal', { static: false }) confirmationModal: ModalDirective;
  deleteRowValue: any;
  deleteRowType: string;
  showConfirmationModal(): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Are you sure you want to delete this record?",
        isDelete: true,
        buttonText: {
          ok: 'Confirm',
          cancel: 'Decline'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteRecord();
      }
    });
  }

  hideConfirmationModal(): void {
    this.confirmationModal.hide();
  }
  // Confirmation modal pop over end here
  // Address code starts here

  deleteRecord() {
    this._genericComponentService.fnDeleteEmail(this.deleteRowValue).subscribe(data => {
      if (data.Success) {
        this.grid.refreshDataAndView();
      }
      this.hideConfirmationModal();
      this.deleteRowValue = null;
      this.deleteRowType = null;
    });
  }
  // Address code ends here


  tset() {
    let obj = {
      title: "", editable: false, width: 25, sortable: false, align: "center", menuIcon: false,
      cls: this.isForCustomerReport ? "hide" : "",
      render: function (ui) {
        return "<a style='color: green;cursor: pointer;' title='Click to edit' class='edit_btn'><span>&#x270F;</span></a>\
      <a style='color: red;cursor: pointer;' title='Click to delete' class='delete_btn'><span>&#x274C;</span></a>";
      },
      postRender: function (ui) {
        var rowIndx = ui.rowIndx,
          grid = this,
          $cell = grid.getCell(ui);
        //edit button
        $cell.find(".edit_btn").off("click")
          .on("click", function (evt) {
            this.addEditEmail(ui.rowData, 1);
          });
        //delete button
        $cell.find(".delete_btn").off("click")
          .on("click", function (evt) {
            this.showConfirmationModal();
            this.deleteRowValue = ui.rowData;
            this.deleteRowType = 'email';
          });
      }
    };

    return obj;
  }

  fnTranslate() {
    for (let index = 0; index < this.optionsGrid.colModel.length; index++) {
      if (this.optionsGrid.colModel[index].title) {
        this.translate.get(this.optionsGrid.colModel[index].title.toString()).subscribe(translationValue => {
          this.optionsGrid.colModel[index].title = translationValue;
        });
      }
    }
    this.translate.get(this.optionsGrid.title).subscribe(translationValue => {
      this.optionsGrid.title = translationValue;
    });

    this.translate.get(this.clickToEdit).subscribe(translationValue => {
      this.clickToEdit = translationValue;
    });
    this.translate.get(this.clickToDelete).subscribe(translationValue => {
      this.clickToDelete = translationValue;
    });
  }
}
