<div class="row">
    <div class="col-xs-12 cupertino">
        <pqgrid [GridID]="GridName" #gridAddress [options]="optionsGridAddress"></pqgrid>
    </div>
</div>

<!--Add edit Address modal pop up-->
<div bsModal #addEditAddressModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-sizes-name1" [config]="{backdrop: 'static'}">
    <form [formGroup]="AddressForm" (ngSubmit)="onAddressFormSubmit()">
        <bs-modal-backdrop></bs-modal-backdrop>
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 id="dialog-sizes-name1" class="modal-title pull-left">
                        <span *ngIf="AddressF.AddressID.value == 0">{{'Add Address' | translate}} </span>
                        <span *ngIf="AddressF.AddressID.value != 0">{{'Update Address' | translate}}</span>
                    </h4>
                    <button type="button" class="close pull-right" (click)="addEditAddress(null,0)" aria-label="Close">
                        <span aria-hidden="true" class="text-white">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class=" col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="mandatory" for="Email Type">{{'Address Type' | translate}}:</label>
                                <mat-select placeholder="{{'Select Address Type' | translate}}" class="form-control"
                                    [ngClass]="{ 'is-invalid': addressSubmitted && AddressF.AddressType.errors }"
                                    formControlName="AddressType">
                                    <mat-option [value]="null" selected disabled>{{'Select' | translate}}</mat-option>
                                    <mat-option *ngFor="let item of AddressTypeDD" [value]="item.LookupID">
                                        {{item.LookupName}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="addressSubmitted && AddressF.AddressType.errors" class="invalid-feedback">
                                    <div *ngIf="AddressF.AddressType.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="mandatory" for="Country ">{{'Country' | translate}}:</label>
                                <mat-select placeholder="{{'Select Country' | translate}}" class="form-control"
                                    [ngClass]="{ 'is-invalid': addressSubmitted && AddressF.Country.errors }"
                                    formControlName="Country">
                                    <input type="text" class="form-control" placeholder="Search"
                                        [formControl]="countryControl">
                                    <mat-option [value]="null" selected>{{'Select' | translate}}</mat-option>
                                    <mat-option *ngFor="let item of countriesListDD" [value]="item.name">
                                        {{item.alpha2Code}} - {{item.name}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="addressSubmitted && AddressF.Country.errors" class="invalid-feedback">
                                    <div *ngIf="AddressF.Country.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12">
                            <cxr-post-grid-address-auto-complete *ngIf="AutoCompletetype == 2"
                                (address)="selectedAddress($event)" [country]="AddressF.Country.value">
                            </cxr-post-grid-address-auto-complete>
                            <cxr-address-auto-complete *ngIf="AutoCompletetype == 1" (address)="selectedAddress($event)"
                                [country]="AddressF.Country.value">
                            </cxr-address-auto-complete>
                        </div>

                        <div class="divider"></div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="mandatory">{{'Street' | translate}}:</label>
                                <input type="text" class="form-control" placeholder="{{'Street' | translate}}"
                                    formControlName="Street"
                                    [ngClass]="{ 'is-invalid': addressSubmitted && AddressF.Street.errors }">
                                <div *ngIf="addressSubmitted && AddressF.Street.errors" class="invalid-feedback">
                                    <div *ngIf="AddressF.Street.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="mandatory">{{'City / Town' | translate}}:</label>
                                <input type="text" class="form-control" placeholder="{{'City / Town' | translate}}"
                                    formControlName="City"
                                    [ngClass]="{ 'is-invalid': addressSubmitted && AddressF.City.errors }">
                                <div *ngIf="addressSubmitted && AddressF.City.errors" class="invalid-feedback">
                                    <div *ngIf="AddressF.City.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="">{{'State / Province' | translate}}:</label>
                                <input type="text" class="form-control" placeholder="{{'State / Province' | translate}}"
                                    formControlName="StateProvince"
                                    [ngClass]="{ 'is-invalid': addressSubmitted && AddressF.StateProvince.errors }">
                                <div *ngIf="addressSubmitted && AddressF.StateProvince.errors" class="invalid-feedback">
                                    <div *ngIf="AddressF.StateProvince.errors.required">{{'Required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="">{{'Zip Code' | translate}}:</label>
                                <input type="text" class="form-control" placeholder="{{'Zip Code' | translate}}"
                                    formControlName="ZipCode"
                                    [ngClass]="{ 'is-invalid': addressSubmitted && AddressF.ZipCode.errors }">
                                <div *ngIf="addressSubmitted && AddressF.ZipCode.errors" class="invalid-feedback">
                                    <div *ngIf="AddressF.ZipCode.errors.required">{{'Required' | translate}}</div>
                                    <div *ngIf="AddressF.ZipCode.errors.maxlength">
                                        {{'Should not exceed more than 10 characters' | translate}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-xs-12">
                            <button mat-raised-button type="submit" class="pull-right btn-success">
                                <span *ngIf="AddressF.AddressID.value == 0">{{'Save' | translate}} </span>
                                <span *ngIf="AddressF.AddressID.value != 0">{{'Update' | translate}} </span>
                            </button>
                            <button mat-raised-button type="button" class="pull-right m-r-5 btn-secondary"
                                (click)="addEditAddress(null,0)">{{'Cancel' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<!--Confirmation modal pop up-->
<div bsModal #confirmationModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    [config]="{backdrop: 'static'}">
    <bs-modal-backdrop></bs-modal-backdrop>
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="dialog-sizes-name1" class="modal-title pull-left">Confirmation</h4>
                <button type="button" class="close pull-right" (click)="hideConfirmationModal()" aria-label="Close">
                    <span aria-hidden="true" class="text-white">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xs-12">
                        <h5>Are you sure you want to delete.?</h5>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-xs-12">
                        <button mat-raised-button type="submit" (click)="deleteRecord();" class="pull-right"
                            color="warn">Confirm</button>
                        <button mat-raised-button (click)="hideConfirmationModal()"
                            class="pull-right m-r-5 btn-secondary">Decline</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
