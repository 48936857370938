<div class="form-group form-group-height">
    <label class="">{{ 'Address lookup' | translate }}:</label>
    <input type="text" class="form-control input-style" placeholder="{{ 'Type atleast 1 chars' | translate }}" matInput
        [formControl]="addressAutoCompleteControl" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async; let i = index" [value]="option?.preview?.address"
            (onSelectionChange)="addressSelection(option?.preview,i)">
            <span> {{ option?.preview?.address }}</span><br />
            <small> {{ option?.preview?.city }} &nbsp;,&nbsp; </small>
            <small> {{ option?.preview?.prov }}</small>
            <small> {{ option?.preview?.pc }}</small>
            <div class="divider"></div>
        </mat-option>
    </mat-autocomplete>
    <small>{{'Note: Please select country for refined search' | translate}}</small>
</div>