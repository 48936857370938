import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiResponse } from 'projects/cxr-crm/src/app/pages/_models/response.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoaderService } from 'src/app/_services';
import { environment } from 'src/environments/environment';
import { PostGridAddressCompleteCAInputModel, PostGridCACompletionModel } from './post-grid-address-auto-complete.model';

@Injectable()
export class PostGridAddressAutoCompleteService {
  constructor (private http: HttpClient, private loaderService: LoaderService) { }

  getPreviews(
    searchParams: PostGridAddressCompleteCAInputModel
  ): Observable<PostGridCACompletionModel[]> {
    this.loaderService.setDisableLoader();

    let params = new HttpParams();
    params = params.set('cityFilter', searchParams.cityFilter ?? '');
    params = params.set('countryFilter', searchParams.countryFilter ?? '');
    params = params.set('partialStreet', searchParams.partialStreet ?? '');
    params = params.set('pcFilter', searchParams.pcFilter ?? '');
    params = params.set('stateFilter', searchParams.stateFilter ?? '');

    return this.http
      .get(`${ environment.apiUrl }postgridaddress/previews`, { params })
      .pipe(
        map((res: apiResponse<PostGridCACompletionModel[]>) => {
          return { ...res.Data };
        })
      );
  }

  getAddressAutoComplete(value: PostGridAddressCompleteCAInputModel, index: number): Observable<apiResponse<PostGridCACompletionModel>> {
    this.loaderService.setDisableLoader();
    let params = new HttpParams();
    params = params.set('index', index?.toString() ?? '0');

    return this.http.post<apiResponse<PostGridCACompletionModel>>(
      `${ environment.apiUrl }postgridaddress/previews`,
      value,
      { params }
    );
  }
}
