import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GenericComponentService } from '../../../_services/generic-component.service';
import { EmailValidation } from './email-validation.model';

@Component({
  selector: 'cxr-email-validation',
  templateUrl: './email-validation.component.html'
})
export class EmailValidationComponent implements OnChanges {

  @Input() emailAddress: string;
  @Input() isValid: boolean | null = null;
  @Input() status: string | null = null;
  @Input() showValidateButton = true;
  @Input() showValidationIcon = true;
  @Input() isPreview = false;
  @Output() isValidEmail = new EventEmitter<boolean>();
  @Output() email = new EventEmitter<EmailValidation>();
  emailValue: EmailValidation;
  constructor (
    private genericComponentService: GenericComponentService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['status']?.firstChange && this.status) || (changes['status']?.previousValue !== changes['status']?.currentValue)) {
      this.emailValue = { status: this.status };
    }
    if (!this.isPreview && !changes['isValid']
      && changes['emailAddress']
      && !changes['emailAddress'].firstChange
      && changes['emailAddress'].previousValue != changes['emailAddress'].currentValue) {
      this.isValid = null;
      this.emailValue = null;
      this.email.emit(null);
    }
  }

  validate(): void {
    if (!this.emailAddress) {
      return;
    }
    this.genericComponentService.validateEmailAddress(this.emailAddress)
      .subscribe((data: EmailValidation) => {
        this.emailValue = data;
        this.isValidEmail.emit(data.isValid);
        this.email.emit(data);
        this.isValid = data.isValid;
      });
  }
}
