<div *ngFor="let menu of parentMenu" class="menu-item">
    <a *ngIf="menu.routerLink && !menu.hasSubMenu" mat-button fxLayout="row"
        [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
        [routerLink]="[menu.routerLink]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}"
        [matTooltip]="menu.title" matTooltipPosition="after"
        [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'" (click)="onClick(menu.id)"
        [id]="'menu-item-'+menu.id">
        <!-- <span class="m-r-5"><i [class]="menu.icon" aria-hidden="true"></i></span> -->
        <span class="menu-title">{{menu.title | translate}}</span>
    </a>
    <a *ngIf="menu.href && !menu.subMenu" mat-button fxLayout="row" [routerLink]="[menu.routerLink]"
        [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
        [attr.href]="menu.href || ''" [attr.target]="menu.target || ''" [matTooltip]="menu.title"
        matTooltipPosition="after" [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
        (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
        <!-- <span class="m-r-5"><i [class]="menu.icon" aria-hidden="true"></i></span> -->
        <span class="menu-title">{{menu.title | translate}}</span>
    </a>
    <a *ngIf="menu.hasSubMenu" mat-button fxLayout="row" [routerLink]="[menu.routerLink]"
        [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'" [matTooltip]="menu.title"
        matTooltipPosition="after" [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
        (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
        <!-- <span class="m-r-5"><i [class]="menu.icon" aria-hidden="true"></i></span> -->
        <span class="menu-title">{{menu.title | translate}}</span>
        <mat-icon class="menu-expand-icon transition-2">arrow_drop_down</mat-icon>
    </a>

    <div *ngIf="menu.hasSubMenu" class="sub-menu" [id]="'sub-menu-'+menu.id">
        <app-vertical-menu [menuItems]="menuItems" [menuParentId]="menu.id"></app-vertical-menu>
    </div>
</div>


<script src="https://cdn.jsdelivr.net/npm/lodash@4.17.10/lodash.min.js"></script>
