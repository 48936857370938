import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ExportPdfHttpService } from 'src/app/_services/export-pdf-http.service';
import { Localisation } from 'src/app/_services/localisation';
import { SharedService } from 'src/app/shared-dialog/shared.service';
import { NotificationService } from 'src/app/shared/_services/notification.service';
import { GetUserObject } from '../../_helpers/get-user-object';
import { AccountsService } from '../../_services/accounts.service';
import { LookupsService } from '../../_services/lookups.service';

@Component({
  selector: 'cxr-till-report',
  templateUrl: './till-report.component.html',
  styleUrls: ['./till-report.component.scss']
})
export class TillReportComponent implements OnInit {

  constructor (
    private _fb: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<TillReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _accountsService: AccountsService,
    private _notificationService: NotificationService,
    private _dialog: MatDialog,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private _sharedService: SharedService,
    private _lookupService: LookupsService,
    private _exportPdfHttpService: ExportPdfHttpService,
  ) {
    translate.addLangs(Localisation.supportLanguages);
    translate.setDefaultLang('en');
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
    _dialogRef.disableClose = true;
  }

  ReportDate;
  isZeroBalanceAccounts: boolean = false;
  isEodTellerProof: boolean = false;
  isIncludeSharedTills: boolean = false;
  isReportByUser: boolean = false;
  UserId = null;
  BranchId;
  UsersList: Array<any>;
  BranchesList: Array<any>;
  isAdminLoggedIn: boolean = GetUserObject.isAdmin();
  isAgent: boolean = GetUserObject.isAgent();
  tillsList: Array<any> = [];
  TillId: number;

  ngOnInit() {
    if (GetUserObject.GetUserObject().TimeZone) {
      this.ReportDate = GetUserObject.GetCurrentDate();
    }
    else {
      this.ReportDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
    if (this.isAdminLoggedIn && !this.isAgent) {
      this._sharedService.fnGetUsersDD().subscribe(data => { this.UsersList = data.Data; });
      this._lookupService.fnGetBranchesDD(GetUserObject.GetUserObject()).subscribe(data => { this.BranchesList = data.Data; });
    }

    this._accountsService.fnGetTillList(GetUserObject.GetUserObject()).subscribe(data => {
      if (data.Success) {
        this.tillsList = data.Data;
      }
    });

  }

  generateTillReport(): void {
    let val = {
      isZeroBalanceAccounts: this.isZeroBalanceAccounts,
      isInclueEodTellerProof: this.isEodTellerProof,
      isIncludeSharedTills: this.isIncludeSharedTills,
      OwnerID: GetUserObject.GetOwnerID(),
      ReportDate: this.ReportDate,
      BranchID: this.BranchId || GetUserObject.GetBranchID(),
      UserID: this.UserId,
      isReportByUser: this.isReportByUser,
      TillID: this.TillId
    };
    this._accountsService.fnGenerateTillReport(val).subscribe(data => {
      if (data['Success']) {
        if (data['Data'].length > 0) {
          let shortId = 'rJgGFPUz_';
          let reportName = "Till_Report" + ((new Date()).getTime()) + ".pdf";
          // let request = {
          //   'data': {
          //     'items': data['Data'],
          //     'tellerName': data['Data'][0]['TillName'],
          //     'reportDate': this.ReportDate,
          //     'title': 'Till Report',
          //     'ReportDate': new Date().toDateString() + ' ' + new Date().toLocaleTimeString()
          //   },
          //   'template': { 'shortid': shortId }
          // };

          let request = {
            'data': {
              'items': data['Data'],
              'tellerName': data['Data'][0]['TillName'],
              //'reportDate': this.ReportDate,
              'title': 'Till Report',
              'reportDate': this.ReportDate
            },
            'TemplateID': 'rJgGFPUz_'
          };


          ///   JsReportPrint.globalPrintAndExportPdf(request, reportName, 1);


          // calling pdf export API
          this._exportPdfHttpService.fnGeneratePdf(request, "Till_Summary" + ((new Date()).getTime()));

        }
        else {
          this._notificationService.error("No Records found");
        }

      }
    });
    this._dialogRef.close();
  }

  onNoClick() {
    this._dialogRef.close();
  }

}
