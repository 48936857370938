import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotePickerDialogComponent } from 'src/app/shared-dialog/note-picker-dialog/note-picker-dialog.component';
import { NoteModel } from './note-model';

@Component({
  selector: 'cxr-note-pick-button',
  templateUrl: './note-pick-button.component.html',
  styleUrls: ['./note-pick-button.component.scss']
})
export class NotePickButtonComponent implements OnInit {
  //Input variable
  @Input() NoteCode: string;
  @Input() Note: string;

  //Output variable 
  @Output() note = new EventEmitter<NoteModel>();

  constructor (
    private _dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }
  openDateRangeDialog(): void {
    const dialogRef = this._dialog.open(NotePickerDialogComponent, {
      width: "600px",
      data: {
        NoteCode: this.NoteCode,
        Note: this.Note
      }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.note.emit(data);
      }
    });
  }
}
