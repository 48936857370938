import { ChangeDetectorRef, Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Spinkit } from 'ng-http-loader';
import { setTheme } from 'ngx-bootstrap/utils';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { Subscription } from 'rxjs';
import { LoaderService, loaderModel } from './_services/loader.service';
import { Localisation } from './_services/localisation';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';

export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public settings: Settings;
  public spinkit = Spinkit;
  loaderMessage$: loaderModel = new loaderModel();
  subscription: Subscription;
  constructor (public appSettings: AppSettings, private _router: Router, private _loaderService: LoaderService,
    private translate: TranslateService,
    private _cdRef: ChangeDetectorRef) {
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);

    setTheme('bs4');
    this.settings = this.appSettings.settings;

    this.subscription = _router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !_router.navigated;
      }
    });

  }

  ngOnInit() {
    // this.printpath('', this._router.config);
    this._loaderService.message$.subscribe(
      data => {
        this.loaderMessage$ = data;
      }
    );

  }
  ngAfterContentChecked(): void {
    this._cdRef.detectChanges();
  }

  ngAfterViewChecked() {
    this._cdRef.detectChanges();
    // setTimeout(() => {
    //   this._loaderService.message$
    //     .pipe(
    //       startWith(null),
    //       delay(0),
    //       tap(() => {
    //         this.loaderMessage$ = null;
    //       })
    //     ).subscribe();
    // });
  }

  // printpath(parent: String, config: Route[]) {
  //   for (let i = 0; i < config.length; i++) {
  //     const route = config[i];
  //     if (route.children) {
  //       const currentPath = route.path ? parent + '/' + route.path : parent;
  //       this.printpath(currentPath, route.children);
  //     }
  //   }
  // }
}



// This script is released to the public domain and may be used, modified and
// distributed without restrictions. Attribution not necessary but appreciated.
// Source: https://weeknumber.net/how-to/javascript

// Returns the ISO week of the date.
Date.prototype.getWeek = function () {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
    - 3 + (week1.getDay() + 6) % 7) / 7);
};

// Returns the four-digit year corresponding to the ISO week of the date.
Date.prototype.getWeekYear = function () {
  var date = new Date(this.getTime());
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  return date.getFullYear();
};

declare global {
  interface Date {
    getWeek: () => number;
    getWeekYear: () => number;
  }
}
