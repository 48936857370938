import jsreport from 'jsreport-browser-client-dist/jsreport';
import { environment } from 'src/environments/environment';

export class JsReportPrint {
    static globalPrintAndExportPdf(request: any, reportName: string, documentType: any): any {
        jsreport.serverUrl = environment.jsreportServerUrl;
        //add custom headers to ajax calls (renderAsync)
        jsreport.headers['Authorization'] = "Basic " + btoa(unescape(encodeURIComponent("tiran@clearviewsys.com:okidoki2JSR1234!!")));
        if (documentType == 0) {
            jsreport.renderAsync(request).then(function (res) {
                let html = '<html>' +
                    '<style>html,body {padding:0;margin:0;} iframe {width:100%;height:100%;border:0}</style>' +
                    '<body>' +
                    '<iframe type="application/pdf" src="' + res.toDataURI() + '"></iframe>' +
                    '</body></html>';
                let a = window.open('about:blank', 'Report');
                if (a == null) {
                    alert('Pop ups are blocked, please make sure to enable them in order to check report preview');
                } else {
                    a.document.write(html);
                    a.document.close();
                }
            });
        } else if (documentType == 1) {
            request['options'] = { 'Content-Disposition': 'attachment; filename=' + reportName };
            jsreport.renderAsync(request).then(function (res) {
                // open download dialog
                res.download(reportName);
            });
        } else if (documentType == 2) {
            request['options'] = { 'Content-Disposition': 'attachment; filename=' + reportName };
            jsreport.renderAsync(request).then(function (res) {
                // open download dialog

                res.download(reportName);
            });
        } else if (documentType == 3) {
            //display report in the new tab
            jsreport.render('_blank', request);
        }
    }
}
