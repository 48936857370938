import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Localisation } from 'src/app/_services/localisation';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';

@Component({
  selector: 'cxr-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  message: string = "Are you sure you want to perform this action?"
  confirmButtonText = "Yes"
  cancelButtonText = "No"
  deleteBtn: boolean = false
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private translate: TranslateService,
    private _dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
    _dialogRef.disableClose = true;
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
    if (data) {
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
      if (data.isDelete == null || data.isDelete == undefined || data.isDelete == false) {
        this.deleteBtn = false

      }
      else {
        this.deleteBtn = true
      }
    }
  }
  ngOnInit() {

  }

  onConfirmClick(): void {
    this._dialogRef.close(true);
  }
}
