import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TableNamesModel } from 'src/app/_helpers/app.tablenames';
import { Localisation } from 'src/app/_services/localisation';
import { PqgridComponent } from 'src/app/pqgrid.component';
import { environment } from 'src/environments/environment';
import pq from 'src/paramquery';
import { GetUserObject } from '../../_helpers/get-user-object';

@Component({
  selector: 'cxr-aml-resuloution-notes-dialog',
  templateUrl: './aml-resuloution-notes-dialog.component.html',
  styleUrls: ['./aml-resuloution-notes-dialog.component.scss']
})
export class AmlResuloutionNotesDialogComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  saveGridState() {
    this.grid.SaveState();
  }
  GridName: string = 'PickResolutionNaotes' + GetUserObject.GetUserID();
  ngOnDestroy() {
    this.grid.SaveState();
  }

  //  subs = new SubSink();

  @ViewChild('grid', { static: false }) grid: PqgridComponent;
  gridOptions: pq.gridT.options;
  SelectedCustomer: any;
  // filter = 'Filter'
  public TableNames: TableNamesModel;
  enterKeyWord = "Enter your keyword";

  constructor (
    private _dialogRef: MatDialogRef<AmlResuloutionNotesDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
    _dialogRef.disableClose = true;
    var ToolbarItem = [];
    ToolbarItem = [
      {
        type: 'textbox',
        label: this.data.route.snapshot.data.Data['Filter'] + ': ',
        attr: 'placeholder=' + this.data.route.snapshot.data.Data['Enter your keyword'] + (data.data == null ? "" : "value=" + data.data),
        cls: "filterValue",
        listener: {
          timeout: function (ui) {
            var filterRules = this.getColModel().map(function (column) {
              return { dataIndx: column.dataIndx, condition: "contain", value: ui.target.value };
            });
            this.filter({
              oper: 'replace',
              rules: filterRules
            });
            ui.target.value = ui.target.value;
          }
        }
      },
    ];

    var self = this;
    this.gridOptions = {
      title: "Pick A Note",
      //  height: "300",
      pageModel: { type: "remote", rPP: environment.pq_grid.dialog_rPP, curPage: 1, rPPOptions: [5, 10, 20, 50, 100] },
      sortModel: {
        type: 'remote',
        single: false,
        sorter: [{ dataIndx: 'AlertDate', dir: 'up' }],
        space: true,
        multiKey: null
      },
      dataModel: {
        location: "remote",
        postData: { OwnerID: GetUserObject.GetOwnerID() },
        dataType: "JSON",
        method: "POST",
        url: environment.apiUrl + "fetch-aml-alert-resolution-notes",
        getData: function (dataJSON) {
          var data = JSON.parse(dataJSON.Data);
          return { curPage: data.curPage, totalRecords: data.totalRecords, data: data.data };
        }
      },
      selectionModel: { type: 'row', mode: 'single', toggle: true },
      filterModel: {
        on: true,
        mode: "OR",
        header: false,
        type: 'remote',
        menuIcon: false //show filter row icon initially.
      },

      toolbar: {
        items: ToolbarItem
      },
      colModel: [
        {
          title: "Resolution Notes", dataType: "string", dataIndx: "ResolutionNotes", filter: {
            crules: [{
              condition: 'contain'
            }]
          }
        }
      ],
      rowDblClick: function (ui, evt) {
        self.pickNotes(evt.rowData);
      },
      rowClick: function () {
      }
    };
  }

  ngOnInit() {
    this.fnTranslate();
  }

  fnTranslate() {
    for (let index = 0; index < this.gridOptions.colModel.length; index++) {
      if (this.gridOptions.colModel[index].title.toString()) {
        this.translate.get(this.gridOptions.colModel[index].title.toString()).subscribe(translationValue => {
          this.gridOptions.colModel[index].title = translationValue;
        });
      }
    }
    this.translate.get(this.gridOptions.title).subscribe(translationValue => {
      this.gridOptions.title = translationValue;
    });
  }

  onNoClick(): void {
    this._dialogRef.close();
  }

  pickNotes(val) {
    this._dialogRef.close(val);
  }

}

