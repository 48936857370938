import { Component, OnInit } from '@angular/core';
import { ManagementReportsService } from '../../reports/_service/management-reports.service';

export interface LatestCustomerModel {
  ID: string;
  CustomerName: string;
}
@Component({
  selector: 'cxr-recent-customer',
  templateUrl: './recent-customer.component.html',
  styleUrls: ['./recent-customer.component.scss']
})
export class RecentCustomerComponent implements OnInit {

  customerList: Array<LatestCustomerModel> = [];
  constructor (
    private _managementReportsService: ManagementReportsService
  ) { }

  ngOnInit(): void {
    this._managementReportsService.fnGetLatestCustomers().subscribe(data => {
      this.customerList = data.Data;
    });
  }

}
