import { DatePipe } from '@angular/common';
import moment from 'moment';
import { DateFormats } from 'src/app/_services/constants';


export class GetUserObject {
    static PreferredLang: string;
    static GetUserObject() {
        return this.DecodeUserLogin();
    }
    static GetOwnerName() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.OwnerCompanyName;
        } else {
            return null;
        }
    }

    static GetUserFirstName() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.FirstName;
        } else {
            return null;
        }
    }

    static isManager() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.isManager;
        } else {
            return false;
        }
    }

    static isAllowToViewProfitsAndLosses() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return (result.isAdmin || result.isOwner || result.isAgent || result.isAllowToViewProfitsAndLosses);
        } else {
            return false;
        }
    }
    static isAllowToViewGain() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return (result.isAdmin || result.isOwner || result.isAgent || result.ShowGainDuringInvoicing);
        } else {
            return false;
        }
    }



    static isAllowToPrintReports() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return (result.isAdmin || result.isOwner || result.isAgent || result.isAllowToPrintReports);
        } else {
            return false;
        }
    }


    static getPrintFormat() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.DefaultPrintFormat;
        } else {
            return false;
        }
    }

    static getLanguage() {
        if (this.PreferredLang) {
            return this.PreferredLang;
        }
        var result = this.DecodeUserLogin();
        if (result != null) {
            if (result.PreferredLanguage) {
                this.PreferredLang = result.PreferredLanguage;
                return result.PreferredLanguage;
            }
            else { return 'en'; }
        } else {
            return 'en';
        }
    }

    static GetUserLastName() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.LastName;
        } else {
            return null;
        }
    }
    static GetOwnerID() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.OwnerID;
        } else {
            return null;
        }
    }

    static getPermissions() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.UserPermissions;
        } else {
            return null;
        }
    }

    static getOwnerPermissions() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.OwnerPermissions;
        } else {
            return null;
        }
    }

    static GetBranchID() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.BranchID;
        } else {
            return null;
        }
    }

    static GetOwnerIDObject() {
        return { OwnerID: GetUserObject.GetOwnerID() };
    }

    static GetUserID() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.UserID;
        } else {
            return null;
        }
    }


    static GetOwnerCode() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.OwnerCode;
        } else {
            return null;
        }
    }
    static GetBranchCode() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.BranchCode;
        } else {
            return null;
        }
    }
    static GetBaseCurrency() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.BaseCurrency;
        } else {
            return null;
        }
    }
    static isAgent() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.isAgent;
        } else {
            return false;
        }
    }

    static enablePoweBI() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.enablePowerBI;
        } else {
            return false;
        }
    }

    static isComplianceOfficer() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            if (result.isOwner) {
                return true;
            }
            return result.isComplianceOfficer || result.isAdmin;
        } else {
            return false;
        }
    }

    static isSuperAgent() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.isSuperAgent;
        } else {
            return false;
        }
    }
    static isOwner() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.isOwner;
        } else {
            return false;
        }
    }
    static isSuperAdmin() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.isSuperAdmin;
        } else {
            return false;
        }
    }

    static isUser() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.isUser;
        } else {
            return false;
        }
    }

    static isAdmin() {
        var result = this.DecodeUserLogin();
        if (result != null) {
            return result.isAdmin || result.isAgent || result.isOwner;
        } else {
            return false;
        }
    }

    static getTimeZone(): string {
        return this.DecodeUserLogin()?.TimeZone;
    }

    static DecodeUserLogin() {
        if (this.GetSession() != null) {
            return JSON.parse(decodeURIComponent(escape(atob(this.GetSession()))));
        } else {
            return null;
        }
    }
    static GetSession() {
        return localStorage.getItem('loginSession');
    }
    static RemoveSession() {
        localStorage.removeItem('loginSession');
        localStorage.removeItem('AuthToken');
    }
    static SetSession(sessionVariable) {
        localStorage.setItem('loginSession', btoa(unescape(encodeURIComponent(JSON.stringify(sessionVariable)))));

        let dateFormat = GetUserObject.GetPickerDateFormat();

        localStorage.setItem('dateFormat', dateFormat);
    }

    public static GetPickerDateFormat(): string {
        let userSession = GetUserObject.GetUserObject();
        return (DateFormats.list.find(x => x.id == userSession.DateFormatID)?.pickerFormat) || "mm/dd/yy";
    }
    public static GetDateFormat() {
        let userSession = GetUserObject.GetUserObject();
        return (DateFormats.list.find(x => x.id == userSession.DateFormatID)?.name) || "MM/DD/YYYY";
    }

    public static GetDisplayFormat(): string {
        let userSession = GetUserObject.GetUserObject();
        return (DateFormats.list.find(x => x.id == userSession.DateFormatID)?.displayFormat) || "mm/DD/yyyy";
    }
    public static GetCurrentDate() {
        return moment().tz(GetUserObject.GetUserObject().TimeZone).format("YYYY-MM-DD");
    }

    public static GetTomorrowDate() {
        return moment().tz(GetUserObject.GetUserObject().TimeZone).add(1, 'days').format("YYYY-MM-DD");
    }
    public static transformDate(inputDate: string): string {
        const date = this.parseDate(inputDate);
        return (new DatePipe('en-US')).transform(date, 'yy-MM-dd');
    }

    private static parseDate(inputDate: string,): Date {
        let day: number, month: number, year: number;
        let inputFormat = GetUserObject.GetPickerDateFormat();
        switch (inputFormat) {
            case 'dd/mm/yy':
                [day, month, year] = inputDate.split('/').map(Number);
                break;
            case 'mm/dd/yy':
                [month, day, year] = inputDate.split('/').map(Number);
                break;
            case 'yy/mm/dd':
                [year, month, day] = inputDate.split('/').map(Number);
                break;
            case 'yy-mm-dd':
                [year, month, day] = inputDate.split('-').map(Number);
                break;
        }

        // Adjust the year if it's a two-digit year (e.g., 90 -> 1990)
        if (year < 100) {
            year += 2000;
        }

        return new Date(year, month - 1, day); // Month is 0-based in JavaScript Date
    }
}
