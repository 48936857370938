import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { LookupDD, Lookups } from 'projects/cxr-crm/src/app/pages/_models/Lookups/Lookup.model';
import { IndividualAddressModel, IndividualEmailModel, IndividualModel, IndividualNoteModel, IndividualPhoneModel, IndividualSocialHandleModel } from 'projects/cxr-crm/src/app/pages/_models/manage-contact/manage-individual.model';
import { LookupsService } from 'projects/cxr-crm/src/app/pages/_services/lookups.service';
import { ManageContactService } from 'projects/cxr-crm/src/app/pages/_services/manage-contact.service';
import { SanctionListService } from 'projects/cxr-crm/src/app/pages/_services/sanction-list.service';
import { TableNames, TableNamesModel } from 'src/app/_helpers/app.tablenames';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import pq from 'src/paramquery';

@Component({
  selector: 'cxr-new-requests',
  templateUrl: './new-requests.component.html',
  styleUrls: ['./new-requests.component.scss']
})
export class NewRequestsComponent implements OnInit {

  StaticOwnerID = GetUserObject.GetOwnerID();
  CustomerID = GetUserObject.GetUserObject()['InternalrecordID'];

  IndividualExID;
  IndividualProfileForm: UntypedFormGroup;

  optionsGridAddress: pq.gridT.options;
  optionsGridMailIDs: pq.gridT.options;
  optionsGridPhoneNumbers: pq.gridT.options;
  optionsGridSocialHandle: pq.gridT.options;
  notesoptions: pq.gridT.options;

  warning: boolean = null;
  SalutationDD: Array<Lookups> = [];
  GenderDD: Array<Lookups> = [];
  GroupTypeDD: Array<Lookups> = [];
  PhoneTypeDD: Array<Lookups> = [];
  AddressTypeDD: Array<Lookups> = [];
  EmailTypeDD: Array<Lookups> = [];
  SocialHandleTypeDD: Array<Lookups> = [];
  companiesDD: Array<LookupDD> = [];
  submitted: boolean;
  IndividualValue: IndividualModel;
  public TableNames: TableNamesModel;

  CustomerDetails: any;
  CustomerExID = new UntypedFormControl();

  GridName: string = 'SocialHandling' + GetUserObject.GetUserID();

  // ngOnDestroy() {
  // }
  constructor (private _manageContantService: ManageContactService, private _router: Router, private _route: ActivatedRoute,
    private _lookupsService: LookupsService, private _formBuilder: UntypedFormBuilder, private _sanctionListService: SanctionListService,
    public _tableNames: TableNames) {
    this.TableNames = _tableNames.tablenames;


    // this._route.queryParams.subscribe(params => {
    //   this.IndividualValue = new IndividualModel(){ "IndividualID": 8 };// JSON.parse(decodeURIComponent(escape(atob(params["data"]))));
    // });

    var test = new IndividualModel();
    test.IndividualID = this.CustomerID;
    this.IndividualValue = test;

    // this._route.parent.queryParams.subscribe(params => {
    // });

    var self = this;

  }


  countriesList;
  ngOnInit() {
    try {
      this.fetchDD(this.StaticOwnerID);
      this.allFormsInit();
      // this.fetchData();
      this._lookupsService.fnGetCountries().subscribe(data => {
        if (data['Data'] == null) {
          this._router.navigateByUrl("/crm/manage/individual/individual-grid");
        }
        this.countriesList = data['Data'];
      });
    } catch (error) {
      console.error(error);
    }


  }

  allFormsInit() {
  }

  fetchData() {
    if (this.f.IndividualID.value != 0) {
      this._manageContantService.fnFetchIndividualData(this.IndividualValue.IndividualID)
        .toPromise()
        .then(data => {
          this.IndividualValue = data['Data'];
          this.formInit(this.IndividualValue);

          this.IndividualExID = this.IndividualValue.IndividualExtID;
          this.optionsGridAddress.dataModel.data = data['Data'].IndividualAddressList;
          this.optionsGridMailIDs.dataModel.data = data['Data'].IndividualEmailList;
          this.optionsGridPhoneNumbers.dataModel.data = data['Data'].IndividualPhoneList;
          this.optionsGridSocialHandle.dataModel.data = data['Data'].IndividualSocialHandleList;
          this.notesoptions.dataModel.data = data['Data'].IndividualNoteList;
        });
    }
  }

  fnCheckAgainstSanctionList() {
    this._sanctionListService.fnCheckAgainstSanctionList(this.IndividualProfileForm.value).subscribe(data => {
      this.warning = data['Data']['Warning'];
    });
  }
  fnGoToSanctionList() {
    this._router.navigate(['/crm/manage/individual/add-edit-individual/kycaml'], { queryParams: { data: btoa(unescape(encodeURIComponent(JSON.stringify(this.IndividualValue)))) }, skipLocationChange: false });
  }
  formInit(val: IndividualModel) {
    this.IndividualProfileForm = this._formBuilder.group({
      IndividualID: new UntypedFormControl(val.IndividualID),
      Salutation: new UntypedFormControl(val.Salutation, [Validators.required]),
      FirstName: new UntypedFormControl(val.FirstName, [Validators.required, Validators.pattern('^[a-zA-Z0-9\.\ ]*$')]),
      LastName: new UntypedFormControl(val.LastName),
      Gender: new UntypedFormControl(val.Gender, [Validators.required]),
      DateOfBirth: new UntypedFormControl(val.DateOfBirth, [Validators.required]),
      Nationality: new UntypedFormControl(val.Nationality, [Validators.required]),
      CountryOfResidence: new UntypedFormControl(val.CountryOfResidence, [Validators.required]),
      CompanyID: new UntypedFormControl(val.CompanyID),
      EthnicSpelling: new UntypedFormControl(val.EthnicSpelling),
      Occupation: new UntypedFormControl(val.Occupation, [Validators.pattern('^[a-zA-Z\ ]*$')]),
      JobPosition: new UntypedFormControl(val.JobPosition, [Validators.pattern('^[a-zA-Z\ ]*$')]),
      GroupType: new UntypedFormControl(val.GroupType),
      NationalID: new UntypedFormControl(val.NationalID, [Validators.pattern('^[a-zA-Z0-9\.\ ]*$')]),
      OwnerID: this.StaticOwnerID,
      isActive: new UntypedFormControl(val.isActive),
      isDeleted: new UntypedFormControl(val.isDeleted)
    });
    this.IndividualProfileForm.disable();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.IndividualProfileForm.controls;
  }


  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.IndividualProfileForm.invalid) {
      return;
    }
    this._manageContantService.fnIndividualUpsert(this.IndividualProfileForm.value).toPromise()
      .then(data => {
        this.IndividualValue = data['Data'];
        this.formInit(this.IndividualValue);
      }).then(data2 => {
        this._sanctionListService.fnCheckAgainstSanctionList(this.IndividualProfileForm.value)
          .subscribe(data => {
            this._router.navigate(['/crm/manage/individual/add-edit-individual/profile'],
              { queryParams: { data: btoa(unescape(encodeURIComponent(JSON.stringify(this.IndividualValue)))) }, skipLocationChange: false });
            this.warning = data['Data']['Warning'];
            if (this.warning) {
              this.fnGoToSanctionList();
            }
          });
      });
  }

  //phone grid code
  IndividualPhoneForm: UntypedFormGroup;
  phoneSubmitted: boolean;
  IndividualPhoneValue: IndividualPhoneModel;
  @ViewChild('addEditPhoneModal', { static: false }) addEditPhoneModal: ModalDirective;
  phoneFormInit(val: IndividualPhoneModel) {
    this.IndividualPhoneForm = this._formBuilder.group({
      PhoneID: new UntypedFormControl(val.PhoneID),
      IndividualID: new UntypedFormControl(this.f.IndividualID.value, Validators.required),
      PhoneType: new UntypedFormControl(val.PhoneType, Validators.required),
      Phone: new UntypedFormControl(val.Phone, Validators.required),
      isDeleted: new UntypedFormControl(false),
    });
  }
  // convenience getter for easy access to form fields
  get IndividualPhoneF() {
    return this.IndividualPhoneForm.controls;
  }
  onIndividualPhoneFormSubmit() {
    this.phoneSubmitted = true;
    // stop here if form is invalid
    if (this.IndividualPhoneForm.invalid) {
      return;
    }
    this._manageContantService.fnIndividualPhoneUpsert(this.IndividualPhoneForm.value)
      .subscribe(data => {
        this.IndividualPhoneValue = new IndividualPhoneModel();
        this.fetchData();
      });
    this.addEditPhone(new IndividualPhoneModel(), 0);
  }
  addEditPhone(val: IndividualPhoneModel, flag) {
    this.phoneSubmitted = false;
    if (val != null)
      this.phoneFormInit(val);
    if (flag == 1) {
      this.addEditPhoneModal.show();
    }
    else
      this.addEditPhoneModal.hide();
  }
  //phone grid code ends here


  // Email code starts here
  IndividualEmailForm: UntypedFormGroup;
  emailSubmitted: boolean;
  IndividualEmailValue: IndividualEmailModel;
  @ViewChild('addEditEmailModal', { static: false }) addEditEmailModal: ModalDirective;
  emailFormInit(val: IndividualEmailModel) {
    this.IndividualEmailForm = this._formBuilder.group({
      EmailID: new UntypedFormControl(val.EmailID),
      IndividualID: new UntypedFormControl(this.f.IndividualID.value, Validators.required),
      EmailType: new UntypedFormControl(val.EmailType, Validators.required),
      EmailAddress: new UntypedFormControl(val.EmailAddress, [Validators.required, emailValidator]),
      isDeleted: new UntypedFormControl(false),
    });
  }
  // convenience getter for easy access to form fields
  get IndividualEmailF() {
    return this.IndividualEmailForm.controls;
  }
  onIndividualEmailFormSubmit() {
    this.emailSubmitted = true;
    // stop here if form is invalid
    if (this.IndividualEmailForm.invalid) {
      return;
    }
    this._manageContantService.fnIndividualEmailUpsert(this.IndividualEmailForm.value)
      .subscribe(data => {
        this.IndividualEmailValue = new IndividualEmailModel();
        this.fetchData();
      });
    this.addEditEmail(new IndividualEmailModel(), 0);
  }
  addEditEmail(val: IndividualEmailModel, flag) {
    this.emailSubmitted = false;
    if (val != null)
      this.emailFormInit(val);
    if (flag == 1) {
      this.addEditEmailModal.show();
    }
    else
      this.addEditEmailModal.hide();
  }
  //email code ends here


  // Social Handle code starts here
  IndividualSocialHandleForm: UntypedFormGroup;
  socialHandleSubmitted: boolean;
  IndividualSocialHandleValue: IndividualSocialHandleModel;
  @ViewChild('addEditSocialModal', { static: false }) addEditSocialModal: ModalDirective;
  socialHandleFormInit(val: IndividualSocialHandleModel) {
    this.IndividualSocialHandleForm = this._formBuilder.group({
      IndividualSocialHandleID: new UntypedFormControl(val.IndividualSocialHandleID),
      IndividualID: new UntypedFormControl(this.f.IndividualID.value, Validators.required),
      SocialHandleType: new UntypedFormControl(val.SocialHandleType, Validators.required),
      SocialHandleURL: new UntypedFormControl(val.SocialHandleURL, Validators.required),
      isDeleted: new UntypedFormControl(false),
    });
  }
  // convenience getter for easy access to form fields
  get IndividualSocialHandleF() {
    return this.IndividualSocialHandleForm.controls;
  }
  onIndividualSocialHandleFormSubmit() {
    this.socialHandleSubmitted = true;
    // stop here if form is invalid
    if (this.IndividualSocialHandleForm.invalid) {
      return;
    }
    this._manageContantService.fnIndividualSocialHandleUpsert(this.IndividualSocialHandleForm.value)
      .subscribe(data => {
        this.IndividualSocialHandleValue = new IndividualSocialHandleModel();
        this.fetchData();
      });
    this.addEditSocialHandle(new IndividualSocialHandleModel(), 0);
  }
  addEditSocialHandle(val: IndividualSocialHandleModel, flag) {
    this.socialHandleSubmitted = false;
    if (val != null)
      this.socialHandleFormInit(val);
    if (flag == 1) {
      this.addEditSocialModal.show();
    }
    else
      this.addEditSocialModal.hide();
  }
  // Social Handle code ends here



  // Address code starts here
  IndividualAddressForm: UntypedFormGroup;
  addressSubmitted: boolean;
  IndividualAddressValue: IndividualAddressModel;
  @ViewChild('addEditAddressModal', { static: false }) addEditAddressModal: ModalDirective;
  addressFormInit(val: IndividualAddressModel) {
    this.IndividualAddressForm = this._formBuilder.group({
      AddressID: new UntypedFormControl(val.AddressID),
      IndividualID: new UntypedFormControl(this.f.IndividualID.value, Validators.required),
      AddressType: new UntypedFormControl(val.AddressType, Validators.required),
      Street: new UntypedFormControl(val.Street),
      City: new UntypedFormControl(val.City),
      StateProvince: new UntypedFormControl(val.StateProvince),
      Country: new UntypedFormControl(val.Country, [Validators.required]),
      ZipCode: new UntypedFormControl(val.ZipCode, [Validators.required, Validators.maxLength(10)]),
      isDeleted: new UntypedFormControl(false),
    });
  }
  // convenience getter for easy access to form fields
  get IndividualAddressF() {
    return this.IndividualAddressForm.controls;
  }
  onIndividualAddressFormSubmit() {
    this.addressSubmitted = true;
    // stop here if form is invalid
    if (this.IndividualAddressForm.invalid) {
      return;
    }
    this._manageContantService.fnIndividualAddressUpsert(this.IndividualAddressForm.value)
      .subscribe(data => {
        this.IndividualAddressValue = new IndividualAddressModel();
        this.fetchData();
      });
    this.addEditAddress(new IndividualAddressModel(), 0);
  }
  addEditAddress(val: IndividualAddressModel, flag) {
    this.addressSubmitted = false;
    if (val != null)
      this.addressFormInit(val);
    if (flag == 1) {
      this.addEditAddressModal.show();
    }
    else
      this.addEditAddressModal.hide();
  }
  // Address code ends here


  // Notes code starts here
  IndividualNoteForm: UntypedFormGroup;
  noteSubmitted: boolean;
  IndividualNoteValue: IndividualNoteModel;
  @ViewChild('addEditNoteModal', { static: false }) addEditNoteModal: ModalDirective;
  noteFormInit(val: IndividualNoteModel) {
    this.IndividualNoteForm = this._formBuilder.group({
      NoteID: new UntypedFormControl(val.NoteID),
      IndividualID: new UntypedFormControl(this.f.IndividualID.value, Validators.required),
      Notes: new UntypedFormControl(val.Notes),
      // BranchID: new FormControl(val.BranchID),
      isDeleted: new UntypedFormControl(false),
    });
  }
  // convenience getter for easy access to form fields
  get IndividualNoteF() {
    return this.IndividualNoteForm.controls;
  }
  onIndividualNoteFormSubmit() {
    this.noteSubmitted = true;
    // stop here if form is invalid
    if (this.IndividualNoteForm.invalid) {
      return;
    }
    this._manageContantService.fnIndividualNoteUpsert(this.IndividualNoteForm.value)
      .subscribe(data => {
        this.IndividualNoteValue = new IndividualNoteModel();
        this.fetchData();
      });
    this.addEditNote(new IndividualNoteModel(), 0);
  }
  addEditNote(val: IndividualNoteModel, flag) {
    this.noteSubmitted = false;
    if (val != null)
      this.noteFormInit(val);
    if (flag == 1) {
      this.addEditNoteModal.show();
    }
    else
      this.addEditNoteModal.hide();
  }
  // Notes code ends here
  PictureIDTypeDD = [];
  fetchDD(val) {


    this._lookupsService.fnGetDropDownsByOwnerID(val).subscribe(data => {
      this.SalutationDD = data['Data'].filter(x => x.Name == "Salutation").length != 0 ? data['Data'].filter(x => x.Name == "Salutation")[0].Items : null;
      this.GenderDD = data['Data'].filter(x => x.Name == "Gender").length != 0 ? data['Data'].filter(x => x.Name == "Gender")[0].Items : null;
      this.PictureIDTypeDD = data['Data'].filter(x => x.Name == "Document Type").length != 0 ? data['Data'].filter(x => x.Name == "Document Type")[0].Items : null;
      this.GroupTypeDD = data['Data'].filter(x => x.Name == "Group Type").length != 0 ? data['Data'].filter(x => x.Name == "Group Type")[0].Items : null;
      this.PhoneTypeDD = data['Data'].filter(x => x.Name == "Phone Type").length != 0 ? data['Data'].filter(x => x.Name == "Phone Type")[0].Items : null;
      this.AddressTypeDD = data['Data'].filter(x => x.Name == "Address Type").length != 0 ? data['Data'].filter(x => x.Name == "Address Type")[0].Items : null;
      this.EmailTypeDD = data['Data'].filter(x => x.Name == "Email Type").length != 0 ? data['Data'].filter(x => x.Name == "Email Type")[0].Items : null;
      this.SocialHandleTypeDD = data['Data'].filter(x => x.Name == "Social Handle Type").length != 0 ? data['Data'].filter(x => x.Name == "Social Handle Type")[0].Items : null;
    });
    this._lookupsService.fnGetCompaniesDD(val).subscribe(data => {
      this.companiesDD = data['Data'];
    });
  }



  @ViewChild('confirmationModal', { static: false }) confirmationModal: ModalDirective;
  deleteRowValue: any;
  deleteRowType: string;
  showConfirmationModal(): void {
    this.confirmationModal.show();
  }

  hideConfirmationModal(): void {
    this.confirmationModal.hide();
  }


  deleteRecord() {
    this.deleteRowValue.isDeleted = true;
    this._manageContantService.fnIndividualDataDelete(this.deleteRowValue, this.deleteRowType)
      .subscribe(data => {
        this.fetchData();
      });
    this.deleteRowValue = null;
    this.deleteRowType = null;
  }

}

