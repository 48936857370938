import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { responseModel } from '../../../_models/response.model';
import { RegulaModel } from './regula.model';

@Injectable({
  providedIn: 'root',
})
export class RegulaService {
  headers: { headers: any; };
  constructor (private httpClient: HttpClient) { }
  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return (this.headers = httpOptions);
  }

  fnFetchIndividualData(val) {
    return this.httpClient.get<responseModel>(
      environment.apiUrl + 'get-individual-data?IndividualID=' + val
    );
  }
  getIndividualsByName(name: string) {
    return this.httpClient.get<responseModel>(
      environment.apiUrl + 'regula/getIndividualsByName?name=' + name
    );
  }

  checkCredits() {
    return this.httpClient.get<responseModel>(
      environment.apiUrl + 'regula/checkCredits'
    );
  }
  decreaseCredits() {
    return this.httpClient.get<responseModel>(
      environment.apiUrl + 'regula/decreaseCredits'
    );
  }

  postPicId(apiKey: string, val) {
    let headers = {
      'Ocp-Apim-Subscription-Key': apiKey,
      'Content-Type': 'application/json',
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
    };
    return this.httpClient.post<RegulaModel>(
      'https://cvsapi.clearviewsys.com/regula/api/process',
      val,
      { headers }
    );
  }
  checkexistingPicId(pictureIdName, pictureIdNumber) {
    return this.httpClient.get<responseModel>(
      environment.apiUrl +
      `regula/checkexistingPicId?name=${ pictureIdName }&pictureId=${ pictureIdNumber }`
    );
  }
  IndividualPictureIdDetails(pictureIdName, pictureIdNumber) {
    return this.httpClient.get(
      environment.apiUrl +
      `regula/IndividualPictureIdDetails?name=${ pictureIdName }&pictureId=${ pictureIdNumber }`
    );
  }
}

