import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { NullNumberToNumber } from './decimal.validation';

export class seperator {
    static getSeparator(locale, separatorType) {
        const numberWithGroupAndDecimalSeparator = 1000.1;
        return Intl.NumberFormat(locale)
            .formatToParts(numberWithGroupAndDecimalSeparator)
            .find(part => part.type === separatorType)
            .value;
    }

    static GetFormat() {
        // let Locale = (new Intl.NumberFormat()).resolvedOptions().locale;
        // let DecimalSeperator = seperator.getSeparator(Locale, 'decimal');
        // let ThousandSeperator = seperator.getSeparator(Locale, 'group');
        // let format = "#" + ThousandSeperator + '###' + DecimalSeperator + "00";
        // return format;

        let format = GetUserObject.GetUserObject().NumberFormat || 1;
        if (format == 1) {
            return "#,###.00";
        }
        else if (format == 2) {
            return "#`###,00";
        }
        else if (format == 3) {
            return "# ###,00";
        }
    }



    static GetNumberFormat(value: number) {
        if (isNaN(value)) return '';
        let format = GetUserObject.GetUserObject().NumberFormat || 1;

        if (format == 1) {
            // Convert the number to a string
            let formattedNumber = value.toFixed(2).toString();

            // Split the number into integer and decimal parts
            const parts = formattedNumber.split('.');
            let integerPart = parts[0];
            let decimalPart = parts.length > 1 ? parts[1] : '';

            // Add comma as thousands separator
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            // Combine the parts with the dot as decimal separator
            formattedNumber = integerPart + '.' + decimalPart;

            return formattedNumber;
        }
        else if (format == 2) {


            // Convert the number to a string
            let formattedNumber = value.toFixed(2).toString();

            // Split the number into integer and decimal parts
            const parts = formattedNumber.split('.');
            let integerPart = parts[0];
            let decimalPart = parts.length > 1 ? parts[1] : '';

            // Add apostrophes as thousands separator
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, "`");

            // Combine the parts with the comma as decimal separator
            formattedNumber = integerPart + ',' + decimalPart.padEnd(2, '0'); // Pad decimal part with zeros if needed

            return formattedNumber;
        }
        else if (format == 3) {
            // Return empty string if value is not a number

            // Convert the number to a string
            let formattedNumber = value.toFixed(2).toString();

            // Split the number into integer and decimal parts
            const parts = formattedNumber.split('.');
            let integerPart = parts[0];
            let decimalPart = parts.length > 1 ? parts[1] : '';

            // Add space as thousand separator
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

            // Combine the parts with the comma as decimal separator
            formattedNumber = integerPart + ',' + decimalPart;

            return formattedNumber;
        }
    }


    static GetNumberFormatWithoutRound(value: any) {
        if (isNaN(value)) return '';
        value = NullNumberToNumber.toNumber(value);
        let format = GetUserObject.GetUserObject().NumberFormat || 1;

        if (format == 1) {
            // Convert the number to a string
            let formattedNumber = value.toString();

            // Split the number into integer and decimal parts
            const parts = formattedNumber.split('.');
            let integerPart = parts[0];
            let decimalPart = parts.length > 1 ? parts[1] : '';

            // Add comma as thousands separator
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            // Combine the parts with the dot as decimal separator
            formattedNumber = integerPart + '.' + decimalPart;

            return formattedNumber;
        }
        else if (format == 2) {
            // Convert the number to a string
            let formattedNumber = value.toString();

            // Split the number into integer and decimal parts
            const parts = formattedNumber.split('.');
            let integerPart = parts[0];
            let decimalPart = parts.length > 1 ? parts[1] : '';

            // Add apostrophes as thousands separator
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, "`");

            // Combine the parts with the comma as decimal separator
            formattedNumber = integerPart + ',' + decimalPart.padEnd(2, '0'); // Pad decimal part with zeros if needed

            return formattedNumber;
        }
        else if (format == 3) {
            // Return empty string if value is not a number

            // Convert the number to a string
            let formattedNumber = value.toString();

            // Split the number into integer and decimal parts
            const parts = formattedNumber.split('.');
            let integerPart = parts[0];
            let decimalPart = parts.length > 1 ? parts[1] : '';

            // Add space as thousand separator
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

            // Combine the parts with the comma as decimal separator
            formattedNumber = integerPart + ',' + decimalPart;

            return formattedNumber;
        }
    }

    static GetCommaSeparated() {
        return "#,###";
    }
}
