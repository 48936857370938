export class IndividualModel {
    IndividualID: any;
    Salutation: any;
    FirstName: any;
    LastName: any;
    Gender: any;
    DateOfBirth: any;
    Nationality: any;
    CountryOfResidence: any;
    CompanyID: any;
    EthnicSpelling: any;
    Occupation: any;
    OccupationID: any;
    JobPosition: any;
    GroupType: any;
    NationalID: any;
    OwnerID: any;
    isActive: boolean;
    isDeleted: boolean;
    CompanyName: string;
    IndividualExtID: string;
    EmailAddress: string;
    EmailID: string;
    isInsider: boolean;
    Phone: any;
    Status: number;
    isTerminated: boolean;
    TerminationDate: Date;
    TerminationReason: string;
    isValidEmail: string;
    statusValue: string;
    sameNameExist?: boolean;
}

export class IndividualPhoneModel {
    PhoneID: any;
    IndividualID: any;
    PhoneType: any;
    Phone: any;
    isActive: boolean;
    isDeleted: boolean;
}

export class IndividualEmailModel {
    EmailID: any;
    IndividualID: any;
    EmailType: any;
    EmailAddress: any;
    isActive: boolean;
    isDeleted: boolean;
}

export class IndividualSocialHandleModel {
    IndividualSocialHandleID: any;
    IndividualID: any;
    SocialHandleType: any;
    SocialHandleURL: any;
    isActive: boolean;
    isDeleted: boolean;
}

export class IndividualAddressModel {

    AddressID: any;
    IndividualID: any;
    AddressType: any;
    Street: any;
    City: any;
    StateProvince: any;
    Country: any;
    ZipCode: any;
    isActive: boolean;
    isDeleted: boolean;
}

export class IndividualNoteModel {
    NoteID: any;
    IndividualID: any;
    BranchID: any;
    Notes: any;
    isActive: boolean;
    isDeleted: boolean;
}


export class IndividualPictureIDModel {
    PictureID: number;
    IndividualID: number;
    DocumentType: any;
    PictureIDNumber: any;
    DateOfIssue: any;
    ExpiryDate: any;
    CountryOfIssue: any;
    IssuingStateProvince: any;
    IssuingAuthority: any;
    DocumentPath: any;
    isActive: boolean;
    isDeleted: boolean;
}


export class IndividualBankInfoModel {
    BankInfoID: number;
    IndividualID: number;
    AccountNumber: any;
    BankName: any;
    BankCountry: any;
    BankStateProvince: any;
    BankCity: any;
    BankZipCode: any;
    Currency: any;
    InstitutionNumber: any;
    BankTransit: any;
    RoutingCode: any;
    SwiftCode: any;
    FurtherCodeNumber: any;
    BeneficiaryFullName: any;
    BeneficiaryPhone: any;
    BeneficiaryCountry: any;
    BeneficiaryState: any;
    BeneficiaryCity: any;
    BeneficiaryZipCode: any;
    Relationships: any;
    isActive: boolean;
    isDeleted: boolean;
}

export class IndividualBetaModel extends IndividualModel {
    PictureID: any;
    PictureIDType: any;
    PictureIDNumber: any;
    CountryOfIssue: any;
    IssueDate: any;
    ExpiryDate: any;
    IssuingStateProvince: any;
    IssuingAuthority: any;
    PicturePath: any;
    PictureName: any;
    PictureFormat: any;
    InternalRecordID: any;
    InternalTableName: any;
    RegulaStatus: number | null = null;
    RegulaObject: string | null = null;
    AddressID: any;
    AddressType: any;
    Street: any;
    City: any;
    StateProvince: any;
    Country: any;
    ZipCode: any;
    isActive: boolean;
    isDeleted: boolean;
}
