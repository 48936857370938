<form [formGroup]="Form" (ngSubmit)="onSubmit()">

    <h4 mat-dialog-title>{{'Change Manual Rates' | translate}}
        <button type="button" class="close pull-right" (click)="onNoClick()" aria-label="Close">
            <span aria-hidden="true" class="text-black">&times;</span>
        </button>
    </h4>
    <div mat-dialog-content class="min-width">
        <div class="row">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <div class="form-group form-group-height">
                            <label class="mandatory">{{'Currency' | translate}}:</label>
                            <mat-select class="form-control" placeholder="{{'Select' | translate}}"
                                formControlName="Currency" (selectionChange)="onSelectCurrency($event.value)">
                                <mat-option *ngFor="let item of currencies" [value]="item">
                                    {{item}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <mat-slide-toggle [checked]="isMainCurrency" (change)="onChangeMainCurrency($event)">{{'Main
                            Currency'}}
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="col-sm-12" *ngIf="currencySeleted">
                <div class="">

                    <table class="table table-striped table-bordered table-hover table-scrollbar">
                        <thead style="background: #1ba4fc!important;color:#eee">
                            <tr>
                                <th>{{'Currency Alias' | translate}}</th>
                                <th>{{'Buy Rate' | translate}}</th>
                                <th>{{'Market Rate' | translate}}</th>
                                <th>{{'Sell Rate' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of currencySeleted;let i = index">
                                <td> {{item.CurrencyAlias}}</td>
                                <td>{{item.BuyRate
                                    | numberFormat}}</td>
                                <td>{{item.MarketRate
                                    | numberFormat}}</td>
                                <td>{{item.SellRate
                                    | numberFormat}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="row">
                    <div class="col-xs-5">
                        <div class="form-group form-group-height">
                            <label class="mandatory">{{'Buy Amount Increase' | translate}}:</label>
                            <cxr-generic-number-input formControlName="BuyAmount" numberType="1" name="Buy Amount">
                            </cxr-generic-number-input>

                        </div>
                    </div>
                    <div class="col-xs-5">
                        <div class="form-group form-group-height">
                            <label class="mandatory">{{'Sell Amount Increase' | translate}}:</label>
                            <cxr-generic-number-input formControlName="SellAmount" numberType="1" name="Sell Amount">
                            </cxr-generic-number-input>
                        </div>
                    </div>
                    <div class="col-xs-2">
                        <button (click)="applyRates()" type="button" mat-raised-button class="btn-success">{{'Apply' |
                            translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end">
        <button mat-raised-button type="button" class="add-dark-hover btn-default" (click)="onNoClick()">{{'Cancel' |
            translate}}</button>
        <button mat-raised-button class="add-hover btn-success" type="submit">{{'Confirm' | translate}}</button>
    </mat-dialog-actions>
</form>
