import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyModel } from 'projects/cxr-crm/src/app/pages/_models/manage-contact/manage-company.model';
import { LookupsService } from 'projects/cxr-crm/src/app/pages/_services/lookups.service';
import { ManageContactService } from 'projects/cxr-crm/src/app/pages/_services/manage-contact.service';
import { forkJoin } from 'rxjs';
import { emailValidator } from 'src/app/theme/utils/app-validators';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  StaticCompanyID = 3;
  StaticOwnerID = 5;

  warning: boolean = null;

  companyValue: CompanyModel;

  companyAddEditForm: UntypedFormGroup;
  submitted: boolean;

  constructor (private _fb: UntypedFormBuilder, private _route: ActivatedRoute,
    private _router: Router, private _changeDetectorRefs: ChangeDetectorRef,
    private _manageContactService: ManageContactService, private _lookupOptionsService: LookupsService, private _snackBar: MatSnackBar) {
    // this._route.queryParams.subscribe(params => {
    //   this.companyValue = JSON.parse(decodeURIComponent(escape(atob(params["data"]))));
    // });
    this.formInit(new CompanyModel());
    var StaticCompany = new CompanyModel();
    StaticCompany.CompanyID = this.StaticCompanyID;
    this.companyValue = StaticCompany;

  }

  countriesList: any;
  ngOnInit() {

    forkJoin([this._lookupOptionsService.fnGetCountries(), this._manageContactService.fnFetchCompanyDetails(this.companyValue)]).subscribe(data => {
      this.countriesList = data[0]['Data'];
      this.companyValue = data[1].Data;
      this.formInit(this.companyValue);
    });
  }

  formInit(val: CompanyModel) {
    this.companyAddEditForm = this._fb.group({
      CompanyID: new UntypedFormControl(val.CompanyID),
      CompanyName: new UntypedFormControl(val.CompanyName, Validators.required),
      TypeOfBusiness: new UntypedFormControl(val.TypeOfBusiness, Validators.required),
      IncorporationNumber: new UntypedFormControl(val.IncorporationNumber, Validators.required),
      IncorporationDate: new UntypedFormControl(val.IncorporationDate, Validators.required),
      RegistrationStateProvince: new UntypedFormControl(val.RegistrationStateProvince, [Validators.required]),
      Country: new UntypedFormControl(val.Country, Validators.required),
      MSBRegistrationNumber: new UntypedFormControl(val.MSBRegistrationNumber, Validators.required),
      MSBRegistrationDate: new UntypedFormControl(val.MSBRegistrationDate, Validators.required),
      WorkPhone: new UntypedFormControl(val.WorkPhone, Validators.required),
      WorkFax: new UntypedFormControl(val.WorkFax, Validators.required),
      NatureOfBusiness: new UntypedFormControl(val.NatureOfBusiness, Validators.required),
      isActive: new UntypedFormControl(val.isActive, Validators.required),
      EmailAddress: new UntypedFormControl(val.EmailAddress, [Validators.required, emailValidator]),
      EmailID: new UntypedFormControl(val.EmailID),
      OwnerID: this.StaticOwnerID
    });
    this.companyAddEditForm.disable();
  }

  // convenience getter for easy access to form fields
  get f() { return this.companyAddEditForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.companyAddEditForm.invalid) {
      return;
    }
    // this._manageContactService.fnCompanyUpsert(this.companyAddEditForm.value).toPromise().then(
    //   data => {
    //     this.formInit(data['Data']);
    //     this.fnAutomateInputFields();
    //     this.companyValue = data['Data']
    //   }).then(data1 => {
    //     this.fnCheckAgainstSanctionList()
    //   });
  }

}
