import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'cxr-bank-notes',
  templateUrl: './bank-notes.component.html',
  styleUrls: ['./bank-notes.component.scss']
})
export class BankNotesComponent {

  constructor (private _route: ActivatedRoute) {
    this.currency = this._route.snapshot.paramMap.get('currency');
  }

  public currency: string;


}
