import { Injectable } from '@angular/core';
import { LinkModel } from 'projects/cxr-crm/src/app/pages/manage-contact/links/links-grid/links-grid.component';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {

  public static translationData: any

  constructor() {
    this.linkAdded.subscribe(value => this.link = value)
  }

  link: LinkModel;
  linkAdded: Subject<LinkModel> = new Subject<LinkModel>();


  updateLink(link: LinkModel) {
    this.linkAdded.next(this.link = link);
  }


}
