import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { DashboardService } from '../../_services/dashboard.service';

@Component({
  selector: 'cxr-favorite-currency',
  templateUrl: './favorite-currency.component.html',
  styleUrls: ['./favorite-currency.component.scss']
})
export class FavoriteCurrencyComponent implements OnInit {
  branchList = [];
  tableList = [];
  DefaultCurrenciesValue = localStorage.getItem("CurrencyValue") ? JSON.parse(localStorage.getItem("CurrencyValue")) : 30;

  FilterDropdown = [
    { name: "1 Day", value: 1 },
    { name: "7 Days", value: 7 },
    { name: "30 Days", value: 30 },
    { name: "This month", value: 31 },
    { name: "YTD", value: 365 },
  ];

  //Filter Form
  @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
  filterForm: UntypedFormGroup;
  constructor (
    private _fb: UntypedFormBuilder,
    private _dashboardService: DashboardService,
  ) {
    this.filterFormInit();
  }

  ngOnInit(): void {
    this.fnGetFavoriteCurrency();
  }


  // fetch table data
  fnGetFavoriteCurrency() {
    this._dashboardService.fnGetFavoriteCurrency(this.filterForm.value).subscribe(result => {
      this.tableList = result['Data'];
    });
  }

  //filter form initialization
  filterFormInit() {
    this.filterForm = this._fb.group({
      TotalRecords: (this.DefaultCurrenciesValue),
      branch: null,
    });
  }
  // getter for form
  get f() { return this.filterForm.controls; }

  Changedays(data) {
    this.filterForm.get('TotalRecords').setValue(data.value);
    localStorage.setItem('CurrencyValue', data.value);
    this.fnGetFavoriteCurrency();
  }
  // select branches 
  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.filterForm.controls.branch
        .patchValue([...this.branchList.map(item => item.BranchID), 0]);
    } else {
      this.filterForm.controls.branch.patchValue([]);
    }
  }
  tosslePerOne(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.filterForm.controls.branch.value.length == this.branchList.length)
      this.allSelected.select();

  }

  // reset filter
  resetFilter() {
    this.filterForm.reset();
    this.filterFormInit();
    this.fnGetFavoriteCurrency();
  }
}
