import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Localisation } from 'src/app/_services/localisation';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { CompanyModel } from 'projects/cxr-crm/src/app/pages/_models/manage-contact/manage-company.model';
import { LookupsService } from 'projects/cxr-crm/src/app/pages/_services/lookups.service';
import { ManageContactService } from 'projects/cxr-crm/src/app/pages/_services/manage-contact.service';
import { forkJoin } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/shared-dialog/confirmation-dialog/confirmation-dialog.component';
import { NotificationService } from 'src/app/shared/_services/notification.service';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { TableNamesModel, TableNames } from 'src/app/_helpers/app.tablenames';


@Component({
  selector: 'cxr-company-kyc-review-dialog',
  templateUrl: './company-kyc-review-dialog.component.html',
  styleUrls: ['./company-kyc-review-dialog.component.scss']
})
export class CompanyKycReviewDialogComponent implements OnInit {

  public TableNames: TableNamesModel;
  companyAddEditForm: UntypedFormGroup;
  countriesList: any[] = [];
  ProgressValue: number = 0;
  TotalProgressBarValue: number = 5;

  constructor(
    private _dialogref: MatDialogRef<CompanyKycReviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public companyValue: any,
    private _fb: UntypedFormBuilder,
    private _manageContactService: ManageContactService,
    private _lookupOptionsService: LookupsService,
    public _tableNames: TableNames,
    private translate: TranslateService,
    private _notificationService: NotificationService,
    private _dialog: MatDialog
  ) {
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
    this.TableNames = _tableNames.tablenames;
  }

  ngOnInit() {
    this.formInit(this.companyValue);
    this.FetchData();
  }

  PictureIDTypeDD: any[] = [];
  FetchData() {
    forkJoin([
      this._manageContactService.fnFetchCompanyDetails(this.companyValue),
      this._lookupOptionsService.fnGetCountries(),
      this._lookupOptionsService.fnGetDropDownsByOwnerID(GetUserObject.GetOwnerID())
    ]).subscribe(data => {
      this.companyValue = data[0].Data;
      this.formInit(this.companyValue);
      this.countriesList = data[1]['Data'];
      this.PictureIDTypeDD = data[2]['Data'].filter(x => x.Name == "Document Type").length != 0 ? data[2]['Data'].filter(x => x.Name == "Document Type")[0].Items : [];
    });
  }

  formInit(val: CompanyModel) {
    this.companyAddEditForm = this._fb.group({
      CompanyID: new UntypedFormControl(val.CompanyID),
      CompanyName: new UntypedFormControl(val.CompanyName, Validators.required),
      TypeOfBusiness: new UntypedFormControl(val.TypeOfBusiness),
      IncorporationNumber: new UntypedFormControl(val.IncorporationNumber),
      IncorporationDate: new UntypedFormControl(val.IncorporationDate),
      RegistrationStateProvince: new UntypedFormControl(val.RegistrationStateProvince,),
      Country: new UntypedFormControl(val.Country),
      MSBRegistrationNumber: new UntypedFormControl(val.MSBRegistrationNumber),
      MSBRegistrationDate: new UntypedFormControl(val.MSBRegistrationDate),
      WorkPhone: new UntypedFormControl(val.WorkPhone),
      WorkFax: new UntypedFormControl(val.WorkFax),
      NatureOfBusiness: new UntypedFormControl(val.NatureOfBusiness),
      isActive: new UntypedFormControl(val.isActive, Validators.required),
      EmailAddress: new UntypedFormControl(val.EmailAddress, [emailValidator]),// Validators.required]),
      EmailID: new UntypedFormControl(val.EmailID),
      Phone: new UntypedFormControl(val.Phone),// [Validators.required]),
      PhoneID: new UntypedFormControl(val.PhoneID),
      OwnerID: new UntypedFormControl(GetUserObject.GetOwnerID()),
      isCompanyOccupationVerified: new UntypedFormControl(null, [Validators.required]),
      isPhoneEmailVerified: new UntypedFormControl(null, [Validators.required]),
      isPictureIDVerifired: new UntypedFormControl(null, [Validators.required]),
      isAddressVerified: new UntypedFormControl(null, [Validators.required]),
      isAllVerified: new UntypedFormControl(false),
      ReviewNotes: new UntypedFormControl(null, [Validators.required])
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.companyAddEditForm.controls; }

  CheckProgressValue() {
    let count: number = 0;
    if (this.f.isCompanyOccupationVerified.value == true) {
      count++;
    }
    if (this.f.isPhoneEmailVerified.value == true) {
      count++;
    }
    if (this.f.isPictureIDVerifired.value == true) {
      count++;
    }
    if (this.f.isAddressVerified.value == true) {
      count++;
    }
    if (this.f.isAllVerified.value == true) {
      count++;
    }

    this.ProgressValue = Math.round((count / 5) * 100);





  }

  selectionChange($event) {
    if ($event.selectedIndex == 2) {
    }
  }

  onNoClick(): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: null,
        buttonText: {
          ok: 'Ignore and Continue',
          cancel: 'Back to Form'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this._dialogref.close(null);
      }
    });
  }
  submitted = false;
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.companyAddEditForm.invalid) {
      this.translate.get("Enter All Mandatory Fields").subscribe(res => {
        this._notificationService.error(res)
      })
      return;
    }
    this._manageContactService.fnCompanyKYCReview(this.companyAddEditForm.value)
      .subscribe(data => {
        if (data.Success == true) {
          this._dialogref.close(true);
        }
      });
  }
}
