
export class CompanyModel {
    CompanyID: number = 0;
    CompanyName: string;
    TypeOfBusiness: any;
    IncorporationNumber: any;
    IncorporationDate: any;
    RegistrationStateProvince: any;
    Country: any;
    MSBRegistrationNumber: any;
    MSBRegistrationDate: any;
    WorkPhone: any;
    WorkFax: any;
    NatureOfBusiness: any;
    OwnerID: any;
    isActive: boolean;
    isDeleted: boolean;
    EmailAddress: any;
    Phone: any;
    PhoneID: any;
    EmailID: any;
    CompanyExtID: any;
    isInsider: boolean = false;
    Status: number;
    isTerminated: boolean;
    TerminationDate: Date;
    TerminationReason: string;
    isValidEmail: string;
    statusValue: string;
    sameNameExist: boolean;
    isMSBS: boolean;
    isBank: boolean;
    isWholesaler: boolean;
}

