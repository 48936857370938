<h4 mat-dialog-title>{{'Change Password' | translate}}
    <button type="button" class="close pull-right" (click)="onNoClick()" aria-label="Close">
        <span aria-hidden="true" class="text-black">&times;</span>
    </button>
</h4>

<form (ngSubmit)="onSubmit()" [formGroup]="signin">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-xs-12">
                <mat-form-field>
                    <input matInput placeholder="{{'Old Password' | translate}}"
                        [type]="OldPasswordHide ? 'password' : 'text'" formControlName="OldPassword" required
                        autocomplete="off">
                    <mat-icon matSuffix (click)="OldPasswordHide = !OldPasswordHide" style="cursor: pointer;">
                        {{OldPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-hint *ngIf="!OldPasswordValue.value">{{'Old Password' | translate}}</mat-hint>
                    <mat-error *ngIf="OldPasswordValue.invalid">{{'Invalid' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-xs-12">
                <mat-form-field>
                    <input matInput placeholder="{{'New Password' | translate}}"
                        [type]="NewPasswordHide ? 'password' : 'text'" formControlName="NewPassword"
                        (cut)="$event.preventDefault()" (copy)="$event.preventDefault()"
                        (paste)="$event.preventDefault()" required autocomplete="off">
                    <mat-icon matSuffix (click)="NewPasswordHide = !NewPasswordHide" style="cursor: pointer;">
                        {{NewPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-hint *ngIf="!NewPasswordValue.value">{{'New Password' | translate}}</mat-hint>
                    <mat-error *ngIf="NewPasswordValue.invalid">{{'Invalid' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-xs-12">
                <mat-form-field>
                    <input matInput placeholder="C{{'onfirm Password' | translate}}" (cut)="$event.preventDefault()"
                        (copy)="$event.preventDefault()" (paste)="$event.preventDefault()"
                        [type]="ConfirmPasswordHide ? 'password' : 'text'" formControlName="ConfirmPassword" required
                        autocomplete="off">
                    <mat-icon matSuffix (click)="ConfirmPasswordHide = !ConfirmPasswordHide" style="cursor: pointer;">
                        {{ConfirmPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-hint *ngIf="!ConfirmPasswordValue.value">{{'Confirm Password' | translate}}</mat-hint>
                    <mat-error *ngIf="ConfirmPasswordValue.invalid">{{'Invalid' | translate}}</mat-error>

                    <mat-error *ngIf="ConfirmPasswordValue.value != NewPasswordValue.value">{{"Password Doesn't Match" |
                        translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-xs-12 m-t-10">
                <password-strength-meter (strengthChange)="checkStrength($event)" [enableFeedback]="true"
                    [password]="NewPasswordValue.value">
                </password-strength-meter>
                <span *ngIf="submitted == true && strength < 3" class="invalid-feedback">
                    {{'Strength should be more' | translate}}</span>

            </div>
            <!-- <div class="col-xs-12">
                <div class="form-group">
                    <label [ngClass]="{ 'mandatory': !sessionDetails.isAdmin }" for="Branch">Branch:</label>
                    <mat-select placeholder="Select Branch" class="form-control" id="branch" required
                        [(ngModel)]="branch" name="branch">
                        <mat-option [value]="null" selected>Select</mat-option>
                        <mat-option *ngFor="let item of BranchesList" [value]="item.BranchID">
                            {{item.BranchCode}}
                        </mat-option>
                    </mat-select>

                </div>
            </div> -->
        </div>
    </div>
    <mat-dialog-actions align="end">
        <button mat-raised-button type="button" class="add-dark-hover btn-default" (click)="onNoClick()">{{'Cancel' |
            translate}}</button>
        <button mat-raised-button class="add-hover btn-success" type="submit">{{'Save' | translate}}</button>
    </mat-dialog-actions>
</form>