import { Component, Input } from '@angular/core';

@Component({
  selector: 'cxr-stamps',
  templateUrl: './stamps.component.html',
  styleUrls: ['./stamps.component.scss']
})


export class StampsComponent {
  @Input() message: string;
  @Input() color: string;

  @Input() transform: string;
  msg: string;

  constructor () {

  }



}
