import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DecimalValidation } from 'src/app/_helpers';

@Component({
  selector: 'cxr-generic-number-input',
  templateUrl: './generic-number-input.component.html',
  styleUrls: ['./generic-number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GenericNumberInputComponent),
      multi: true,
    }
  ]
})
export class GenericNumberInputComponent implements ControlValueAccessor, OnChanges {
  @ViewChild('amountInput', { static: false })
  set input(element: ElementRef<HTMLInputElement>) {
    if (element) {
      element.nativeElement.focus();
    }
  }

  constructor () { }

  @Input("isReadOnly") public isReadOnly: boolean;
  @Input("name") public name: string;
  @Input("numberType") public numberType: number;
  @Input("max") public maxValue: number;
  @Input("min") public minValue: number;

  @Output("onClickInputEvent") public onClickInputEvent: EventEmitter<string> = new EventEmitter<string>();

  public Amount: any;
  public AmountDuplicate;
  AmountFocusOut: boolean = true;
  autoFocusField: boolean = false;
  ngOnChanges(changes: SimpleChanges): void {

  }

  private onChange = (value: any) => { };
  private onTouched = () => { };



  onNumbeKeyPress($event) {
    if (this.numberType == 1) {
      return DecimalValidation.isDecimalNumberKey($event);
    }
    if (this.numberType == 2) {
      return DecimalValidation.isIntegerKeyDecimal($event);
    }
    if (this.numberType == 3) { // accepts Zero
      return DecimalValidation.isWholeNumberKey($event);
    }
    else if (this.numberType == 4) {
      return DecimalValidation.isNumberKey($event);
    }
    else if (this.numberType == 5) {
      return DecimalValidation.isIntegerKey($event);
    }
  }

  onEnterAmountField() {
    if (isNaN(this.Amount) || this.Amount === 0) {
      this.Amount = null;
    }
    this.onClickInputEvent.emit('changed');

  }


  writeValue(value: any) {
    if (value != null) {
      this.Amount = value;
    }
    else {
      this.Amount = null;
    }
  }

  setDisabledState(disabled: boolean) {
    // this.disabled = disabled;
  }

  onValueChange(event) {
    this.onChange(event);
    this.AmountDuplicate = event;
    this.Amount = event;
    this.onTouched();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onClickInput() {
    this.autoFocusField = true;
  }
}
