import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiResponse, responseModel } from 'projects/cxr-crm/src/app/pages/_models/response.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EmailValidation } from '../_components/email/email-validation/email-validation.model';

@Injectable({
  providedIn: 'root'
})
export class GenericComponentService {
  headers: { headers: any; };
  constructor (private _http: HttpClient) { }
  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }


  //function to update and insert generic address into the application
  fnUpsertAddress(val) {
    return this._http.post<responseModel>(environment.apiUrl + "generic/upsert-address", val);
  }
  //function to update and insert generic email into the application
  fnUpsertEmail(val) {
    return this._http.post<responseModel>(environment.apiUrl + "generic/upsert-email", val);
  }
  //function to update and insert generic phone into the application
  fnUpsertPhone(val) {
    return this._http.post<responseModel>(environment.apiUrl + "generic/upsert-phone", val);
  }
  //function to update and insert generic pictureID into the application
  fnUpsertPictureID(val) {
    return this._http.post<responseModel>(environment.apiUrl + "generic/upsert-pictureID", val);
  }
  //function to update and insert generic Bank Info into the application
  fnUpsertBankInfo(val) {
    return this._http.post<responseModel>(environment.apiUrl + "generic/upsert-bank-info", val);
  }
  //function to delete generic address into the application
  fnDeleteAddress(val) {
    return this._http.post<responseModel>(environment.apiUrl + "generic/delete-address", val);
  }
  //function to  delete generic email into the application
  fnDeleteEmail(val) {
    return this._http.post<responseModel>(environment.apiUrl + "generic/delete-email", val);
  }
  //function to  delete generic phone into the application
  fnDeletePhone(val) {
    return this._http.post<responseModel>(environment.apiUrl + "generic/delete-phone", val);
  }
  //function to  delete generic pictureID into the application
  fnDeletePictureID(val) {
    return this._http.post<responseModel>(environment.apiUrl + "generic/delete-pictureID", val);
  }
  //function to  delete generic Bank Info into the application
  fnDeleteBankInfo(val) {
    return this._http.post<responseModel>(environment.apiUrl + "generic/delete-bank-info", val);
  }

  //function to get generic address data into the application
  fnGetAddressData(val) {
    return this._http.post<responseModel>(environment.apiUrl + "generic/get-address-data", val);
  }
  //function to  get generic email data into the application
  fnGetEmailData(val) {
    return this._http.post<responseModel>(environment.apiUrl + "generic/get-email-data", val);
  }
  //function to  get generic phone data into the application
  fnGetPhoneData(val) {
    return this._http.post<responseModel>(environment.apiUrl + "generic/get-phone-data", val);
  }
  //function to  get generic pictureID data into the application
  fnGetPictureIDData(val) {
    return this._http.post<responseModel>(environment.apiUrl + "generic/get-pictureID-data", val);
  }

  //function to  get generic pictureID data into the application
  fnGetBankInfoData(val) {
    return this._http.post<responseModel>(environment.apiUrl + "generic/get-bank-info-data", val);
  }


  //
  fnCheckAgainstParticularSanctionList(val) {
    let headers = new HttpHeaders();
    headers = headers.set('isCheckAgainstSanctionList', 'true');
    return this._http.post<responseModel>(environment.apiUrl + "generic/check-against-particular-sanctionlist", val, { headers: headers });
  }


  // Wire Template
  fnDeleteWireTemplate(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }generic/delete-wire-template`, val);
  }
  fnGetWireTemplate(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }generic/get-wire-template`, val);
  }
  fnUpsertWireTemplate(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }generic/upsert-wire-template`, val);
  }
  fnUpsertNote(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }generic/upsert-note`, val);
  }
  fnDeleteNote(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }generic/delete-note`, val);
  }
  fnFetchNote(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }generic/get-note`, val);
  }

  //function to update and insert generic address request into the application
  fnUpsertAddressRequest(val) {
    return this._http.post<responseModel>(environment.apiUrl + "customer/upsert-address-request", val);
  }
  //function to update and insert generic email request into the application
  fnUpsertEmailRequest(val) {
    return this._http.post<responseModel>(environment.apiUrl + "customer/upsert-email-request", val);
  }
  //function to update and insert generic phone request into the application
  fnUpsertPhoneRequest(val) {
    return this._http.post<responseModel>(environment.apiUrl + "customer/upsert-phone-request", val);
  }
  //function to update and insert generic pictureID request into the application
  fnUpsertPictureIDRequest(val) {
    return this._http.post<responseModel>(environment.apiUrl + "customer/upsert-pictureID-request", val);
  }

  //function to  delete generic email request into the application
  fnDeleteAddressRequests(val) {
    return this._http.post<responseModel>(environment.apiUrl + "customer/delete-address-request", val);
  }
  //function to  delete generic email request into the application
  fnDeleteEmailRequests(val) {
    return this._http.post<responseModel>(environment.apiUrl + "customer/delete-email-request", val);
  }
  //function to  delete generic phone request into the application
  fnDeletePhoneRequests(val) {
    return this._http.post<responseModel>(environment.apiUrl + "customer/delete-phone-request", val);
  }
  //function to  delete generic pictureID request into the application
  fnDeletePictureIDRequests(val) {
    return this._http.post<responseModel>(environment.apiUrl + "customer/delete-pictureID-request", val);
  }

  //function to get generic address data into the application
  fnGetRequestsAddressData(val) {
    return this._http.post<responseModel>(environment.apiUrl + "customer/get-request-address-data", val);
  }
  //function to  get generic email data into the application
  fnGetRequestsEmailData(val) {
    return this._http.post<responseModel>(environment.apiUrl + "customer/get-request-email-data", val);
  }
  //function to  get generic phone data into the application
  fnGetRequestsPhoneData(val) {
    return this._http.post<responseModel>(environment.apiUrl + "customer/get-request-phone-data", val);
  }
  //function to  get generic pictureID data into the application
  fnGetRequestsPictureIDData(val) {
    return this._http.post<responseModel>(environment.apiUrl + "customer/get-request-pictureID-data", val);
  }

  validateEmailAddress(emailAddress: string): Observable<EmailValidation> {
    let params = new HttpParams();
    params = params.set('emailAddress', emailAddress ? emailAddress : '');
    return this._http.get(`${ environment.apiUrl }zerobounce/validate`, { params: params }).pipe(
      map((res: apiResponse<EmailValidation>) => res.Data));
  }


  // Method to upsert a Crypto Wallet
  fnUpsertCryptoWallet(val: any): Observable<responseModel> {
    return this._http.post<responseModel>(`${ environment.apiUrl }upsert-crypto-wallet`, val);
  }

  // Method to delete a Crypto Wallet
  fnDeleteCryptoWallet(walletId: number): Observable<responseModel> {
    return this._http.get<responseModel>(`${ environment.apiUrl }delete-crypto-wallet`, {
      params: { walletId: walletId.toString() }
    });
  }

  // Method to get all Crypto Wallets
  fnGetAllCryptoWallets(customerId: string): Observable<responseModel> {
    let params = new HttpParams();
    params = params.set('customerId', customerId);
    return this._http.get<responseModel>(`${ environment.apiUrl }get-crypto-wallets`, { params: params });
  }

  // Method to get a specific Crypto Wallet by ID
  fnGetCryptoWallet(id: number): Observable<responseModel> {
    return this._http.get<responseModel>(`${ environment.apiUrl }get-crypto-wallet/${ id }`);
  }
}
