import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiResponse } from 'projects/cxr-crm/src/app/pages/_models/response.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoaderService } from 'src/app/_services';
import { environment } from 'src/environments/environment';
import
  {
    AddressAutoComplete,
    AddressAutoCompleteSearchModel,
  } from './address-auto-complete.model';

@Injectable()
export class AutoAddressCompleteService
{
  constructor(private http: HttpClient, private loaderService: LoaderService) { }

  getAddressAutoCompleteFind(
    searchParams: AddressAutoCompleteSearchModel
  ): Observable<AddressAutoComplete>
  {
    this.loaderService.setDisableLoader();
    return this.http
      .get(`${ environment.apiUrl }addressautocomplete/find`, {
        params: this.setParams(searchParams),
      })
      .pipe(
        map((res: apiResponse<AddressAutoComplete>) =>
        {
          return { ...res.Data };
        })
      );
  }

  getAddressAutoCompleteRetrieve(
    value: any,
    searchParams: AddressAutoCompleteSearchModel
  ): Observable<apiResponse<AddressAutoComplete>>
  {
    this.loaderService.setDisableLoader();
    return this.http.post<apiResponse<AddressAutoComplete>>(
      `${ environment.apiUrl }addressautocomplete/retrieve`,
      value,
      { params: this.setParams(searchParams) }
    );
  }

  private setParams(searchParams: AddressAutoCompleteSearchModel): HttpParams
  {
    let params = new HttpParams();
    params = params.set('SearchTerm', searchParams.searchTerm ?? '');
    params = params.set('Country', searchParams.country ?? '');
    return params;
  }
}
