import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { responseModel } from '../_models/response.model';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  headers: { headers: any; };
  constructor(private _http: HttpClient) { }
  // For Sending headers to API to web server
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  //#region Tills start
  fnUpsertTill(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-till', val);
  }

  fnGetTillList(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'get-tills-list', val);
  }


  fnDeleteTill(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'delete-till', val);
  }


  fnCreateCashAccounts(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'create-cash-accounts', val);
  }

  fnGenerateTillReport(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'generate-till-report', val);
  }

  fnLoginTill(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'login-till', val);
  }
  fnLogoutTill(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'logout-till', val);
  }
  //#endregion

  //#region Main Accounts
  fnUpsertMainAccount(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-main-account', val);
  }

  fnDeleteMainAccount(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'delete-main-account', val);
  }

  //fn fetch main account type dd
  fnFetchMainAccountTypeDD(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'get-main-account-type-dd', val);
  }
  //#endregion


  //#region Accounts
  fuGetMainAccountsDD(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'get-main-account-DD', val);
  }
  fuGetCurrencyAliasesDDAccounts(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'get-currency-aliases-DD-accounts', val);
  }
  fnGetCurrencyListDD() {
    return this._http.get<responseModel>(environment.apiUrl + 'get-currency-list-dd');
  }
  fnUpsertAccount(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-account', val);
  }
  fnDeleteAccount(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'delete-account', val);
  }

  fnGetAccount(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'get-account', val);
  }

  fnFetchTillCashAccountsBalance(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'fetch-till-cash-account-balance', val);
  }

  fnTransferCashBetweenTills(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'trasnsfer-cash-between-tills', val);
  }
  //#endregion
}
