export class AMLAlertModal {
    public AMLAlertID: any;
    public AlertDate: any;
    public CustomerID: any;
    public CustomerName: any;
    public InvoiceID: any;
    public Subject: any;
    public Description: any;
    public status: any;
    public ResolvedBy: any;
    public ResolvedDate: any;
    public ResolutionNotes: any;
    public isResolved: boolean = null;
    public CreatedDate: any;
    public ModifiedBy: any;
    public CreatedBy: any;
    public DeletedBy: any;
    public UserID: any;
    public OwnerID: any;
    public AMLAlertExID: string;
    public SanctionListLogID: string;
    public Type: string;
    public RuleDescription: string;
    public RuleName: string;
    public RuleID: string;
}
