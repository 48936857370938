import { DatePipe } from '@angular/common';
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { PqgridComponent } from 'src/app/pqgrid.component';
import { GenericPreviewModel, PreviewGenericRowDataDialogComponent } from 'src/app/shared-dialog/preview-generic-row-data-dialog/preview-generic-row-data-dialog.component';
import { environment } from 'src/environments/environment';
import pq from 'src/paramquery';

@Component({
  selector: 'cxr-kyc-review-log',
  templateUrl: './kyc-review-log.component.html',
  styleUrls: ['./kyc-review-log.component.scss']
})
export class KYCReviewLogComponent implements OnInit {
  // create: function () {
  //   //restore state of grid.
  //   var StateVal = localStorage.getItem('pq-grid' + self.GridName);
  //   this.loadState({ refresh: false, state: StateVal });
  // },
  @HostListener('window:beforeunload', ['$event'])
  saveGridState($event) {
    this.grid.SaveState();
  }
  ngOnDestroy() {
    this.grid.SaveState();
  }
  GridName: string = 'GenericKYCLog' + GetUserObject.GetUserID();

  @Input('InternalTableName') InternalTableName: string;

  @Input('CustomerExtID') CustomerExtID: string = null;
  @Input('InternalRecordID') InternalRecordID: number;

  @ViewChild('grid', { static: false }) grid: PqgridComponent;
  optionsGrid: pq.gridT.options;

  CustomerSpecificColumns = [];

  columns = [];
  constructor (
    private _dialog: MatDialog,
    private _datePipe: DatePipe,
    private translate: TranslateService
  ) {
    var self = this;
    this.columns = [
      {
        title: "Review Date", dataType: "string", dataIndx: "ReviewDate", filter: { crules: [{ condition: 'range' }] }, format: function (val) {
          if (val) {
            return self._datePipe.transform((val), GetUserObject.GetDisplayFormat());
          }
        }
      },
      { title: "ID", dataType: "string", dataIndx: "CustomerID", filter: { crules: [{ condition: 'contain' }] } },
      { title: "Customer", dataType: "string", dataIndx: "CustomerName", filter: { crules: [{ condition: 'contain' }] } },
      { title: "Reviewer", dataType: "string", dataIndx: "ReviewerName", filter: { crules: [{ condition: 'contain' }] } },
      { title: "Notes", dataType: "string", dataIndx: "ReviewNotes", filter: { crules: [{ condition: 'contain' }] } },
      {
        dataIndx: "isCompanyOccupationVerified", align: "center", editable: false,
        title: "Occupation",
        // menuIcon: false,
        // cls: 'ui-state-default',
        // maxWidth: 50,
        // sortable: false,
        type: 'checkbox', //required property.
      },
      {
        dataIndx: "isPhoneEmailVerified", align: "center", editable: false,
        title: "Phone & Email",
        //   menuIcon: false,
        //  // maxWidth: 50,
        //   cls: 'ui-state-default',
        //   sortable: false,
        type: 'checkbox', //required property.
      },
      {
        dataIndx: "isPictureIDVerifired", align: "center", editable: false,
        title: "Picture ID",
        // menuIcon: false,
        //  maxWidth: 50,
        // cls: 'ui-state-default',
        // sortable: false,
        type: 'checkbox', //required property.
      },
      {
        dataIndx: "isAddressVerified", align: "center", editable: false,
        title: "Address",
        menuIcon: false,
        // maxWidth: 50,
        // cls: 'ui-state-default',
        // sortable: false,
        type: 'checkbox', //required property.
      },
      {
        dataIndx: "isAllVerified", align: "center", editable: false,
        title: "100%",
        maxWidth: 50,
        // menuIcon: false,
        // cls: 'ui-state-default',
        // sortable: false,
        type: 'checkbox', //required property.
      }
    ];
    this.CustomerSpecificColumns = [
      {
        title: "Review Date", dataType: "string", dataIndx: "ReviewDate", filter: { crules: [{ condition: 'range' }] }, format: function (val) {
          if (val) {
            return self._datePipe.transform((val), GetUserObject.GetDisplayFormat());
          }
        }
      },
      // { title: "ID", dataType: "string", hidden: true, dataIndx: "CustomerID", filter: { crules: [{ condition: 'contain', value: this.CustomerExtID }] } },
      // { title: "Customer", hidden: true, dataType: "string", dataIndx: "CustomerName", filter: { crules: [{ condition: 'contain' }] } },
      { title: "Reviewer", dataType: "string", dataIndx: "ReviewerName", filter: { crules: [{ condition: 'contain' }] } },
      { title: "Notes", dataType: "string", dataIndx: "ReviewNotes", filter: { crules: [{ condition: 'contain' }] } },
      {
        dataIndx: "isCompanyOccupationVerified", align: "center", editable: false,
        title: "Occupation",
        // menuIcon: false,
        // cls: 'ui-state-default',
        // maxWidth: 50,
        // sortable: false,
        type: 'checkbox', //required property.
      },
      {
        dataIndx: "isPhoneEmailVerified", align: "center", editable: false,
        title: "Phone & Email",
        // menuIcon: false,
        // maxWidth: 50,
        // cls: 'ui-state-default',
        // sortable: false,
        type: 'checkbox', //required property.
      },
      {
        dataIndx: "isPictureIDVerifired", align: "center", editable: false,
        title: "Picture ID",
        // menuIcon: false,
        // maxWidth: 50,
        // cls: 'ui-state-default',
        // sortable: false,
        type: 'checkbox', //required property.
      },
      {
        dataIndx: "isAddressVerified", align: "center", editable: false,
        title: "Address",
        // menuIcon: false,
        // maxWidth: 50,
        // cls: 'ui-state-default',
        // sortable: false,
        type: 'checkbox', //required property.
      },
      {
        dataIndx: "isAllVerified", align: "center", editable: false,
        title: "100%",
        // maxWidth: 50,
        // menuIcon: false,
        // cls: 'ui-state-default',
        // sortable: false,
        type: 'checkbox', //required property.
      }
    ];
  }
  ngOnInit() {
    this.GridInit();
    if (this.CustomerExtID == null) {
      this.optionsGrid.colModel = this.columns;
    } else {
      this.optionsGrid.colModel = this.CustomerSpecificColumns;
    }
    this.fnTranslate();

  }

  GridInit() {
    var self = this;
    this.optionsGrid = {
      title: "KYC Review Log",
      // create: function () {
      //   //restore state of grid.
      //   var StateVal = localStorage.getItem('pq-grid' + self.GridName);
      //   this.loadState({ refresh: false, state: StateVal });
      // },
      // flex: { one: true },
      scrollModel: { autoFit: true },

      rowDblClick: function (evt, ui) {
        var previewObj: GenericPreviewModel = new GenericPreviewModel();
        previewObj.Columns = this.colModel;
        previewObj.RowData = ui.rowData;
        previewObj.Heading = 'Grid Details Preview';
        const dialogRef = self._dialog.open(PreviewGenericRowDataDialogComponent, {
          data: previewObj,
          width: '1000px'
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      },
      pageModel: {
        type: "remote", rPP: environment.pq_grid.default_rPP, curPage: 1, rPPOptions: [5, 10, 20, 50, 100],
      },
      filterModel: {
        on: true,
        header: true,
        type: 'remote',
        menuIcon: false
      },
      sortModel: {
        type: 'remote',
        single: false,
        sorter: [{ dataIndx: 'KYCReviewLogID', dir: 'down' }],
        space: true,
        multiKey: null
      },
      colModel: self.CustomerExtID != null ? self.columns : self.CustomerSpecificColumns,
      selectionModel: { type: 'row' },

      dataModel: {
        location: "remote",
        postData:
        {
          InternalRecordID: self.InternalRecordID,
          InternalTableName: self.InternalTableName,
          OwnerID: GetUserObject.GetOwnerID()
        },
        dataType: "JSON",
        method: "POST",
        url: environment.apiUrl + "generic/get-kyc-review-log",
        getData: function (dataJSON) {
          var data = JSON.parse(dataJSON.Data);
          return { curPage: data.curPage, totalRecords: data.totalRecords, data: data.data };
        }
      }
    };
  }


  // grid helper code

  // grid helper code ends here


  fnTranslate() {
    for (let index = 0; index < this.optionsGrid.colModel.length; index++) {
      if (this.optionsGrid.colModel[index].title.toString()) {
        this.translate.get(this.optionsGrid.colModel[index].title.toString()).subscribe(translationValue => {
          this.optionsGrid.colModel[index].title = translationValue;
        });
      }
    }

    // for (let index = 0; index < this.optionsGrid.toolbar.items.length; index++) {
    //   if (this.optionsGrid.toolbar.items[index].label) {
    //     this.translate.get(this.optionsGrid.toolbar.items[index].label).subscribe(translationValue => {
    //       this.optionsGrid.toolbar.items[index].label = translationValue;
    //     });
    //   }
    // }

    this.translate.get(this.optionsGrid.title).subscribe(translationValue => {
      this.optionsGrid.title = translationValue;
    });
  }


}
