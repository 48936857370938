export class PhoneModel {
    PhoneID: any;
    InternalRecordID: Number;
    InternalTableName: any;
    PhoneType: any;
    Phone: any;
    isActive: boolean;
    isDeleted: boolean;
}
export class EmailModel {
    EmailID: any;
    InternalRecordID: Number;
    InternalTableName: any;
    EmailType: any;
    EmailAddress: any;
    isActive: boolean;
    isDeleted: boolean;
    isValidEmail: boolean;
    statusValue: string;
}
export class AddressModel {
    AddressID: any;
    InternalRecordID: Number;
    InternalTableName: any;
    AddressType: any;
    Street: any;
    City: any;
    StateProvince: any;
    Country: any;
    ZipCode: any;
    isActive: boolean;
    isDeleted: boolean;
}
export class PictureIdentityModel {
    PictureID: any;
    PictureIDType: any;
    PictureIDNumber: any;
    CountryOfIssue: any;
    IssueDate: any;
    ExpiryDate: any;
    IssuingStateProvince: any;
    IssuingAuthority: any;
    PicturePath: any;
    PictureName: any;
    PictureFormat: any;
    OwnerID: any;
    InternalRecordID: any;
    InternalTableName: any;
    isActive: any;
    isDeleted: any;
    RegulaStatus: number | null = null;
    RegulaObject: string | null = null;
}
export class BankInfoModel {
    BankInfoID: any;
    BankName: any;
    AccountNumber: any;
    SwiftBICTransit: any;
    BankingDetails: any;
    BankingAddress: any;
    OwnerID: any;
    InternalRecordID: any;
    InternalTableName: any;
    isDeleted: any;
    // address model properties
    AddressID: any;
    AddressType: any;
    isActive: any;
    Street: any;
    City: any;
    StateProvince: any;
    Country: any;
    ZipCode: any;
    isValidIban: boolean | null;
}
