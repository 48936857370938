import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DecimalValidation } from 'src/app/_helpers';
import { GenericHttpService } from 'src/app/_services/generic-http.service';

@Component({
  selector: 'cxr-currency-notes',
  templateUrl: './currency-notes.component.html',
  styleUrls: ['./currency-notes.component.scss']
})
export class CurrencyNotesComponent implements OnChanges {

  constructor (private _service: GenericHttpService, private sanitizer: DomSanitizer, private router: Router) { }

  @Input()
  public iso: string;;

  public value: number;
  public showExpired: boolean = false;

  resultView: any[];
  isCashTellerProof: boolean = false;

  ngOnChanges(): void {
    if (this.iso) {
      this.getBankNotes();
    }
  }

  getBankNotes() {
    this._service.fnGetBankNotes(this.iso, this.value, this.showExpired).subscribe((data: any) => {
      this.isCashTellerProof = this.router.url.indexOf('new-cash-tellerproof') != -1;
      // this.resultView = ('<section class="card ">');
      this.resultView = data.Data.sort((a, b) => (a.Value - b.Value) ? -1 : 1);
      // data.Data.forEach(row => {
      //   // let row = data[pos];
      //   if (row.Image.length == 0)
      //     return;
      //   console.log(this.router.url);




      //   this.resultView = this.resultView + ('<img class="card-img-top" style = "width : 100%; height : auto; margin: auto" src=' + row.Image[0].Url + ' />');
      //   this.resultView = this.resultView + ('<h2 class="cart-title">' + row.Name);

      //   let data = ('<ul class="list-group list-group-flush>">');
      //   this.resultView = this.resultView + (data);
      //   this.resultView = this.resultView + ('<li class="list-group-item"> Value: $' + row.Value);
      //   this.resultView = `${ this.resultView }<li class="list-group-item"><small> <b> Version: ${ row.Version }</b></small> `;
      //   this.resultView = this.resultView + ('<li class="list-group-item"> ISO: ' + row.ISO);
      //   this.resultView = this.resultView + ('</ul>');

      //   this.resultView = this.resultView + "</div>";


      // });
      // this.resultView = this.resultView + ('</section>');
      // this.resultView = this.sanitizer.bypassSecurityTrustHtml(this.resultView);
    });
  }

  onNumberFieldKeyPress($evt) {
    return DecimalValidation.isNumberKey($evt);
  }
}
