import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { ModuleDetails } from 'projects/cxr-crm/src/app/pages/_helpers/module-details';

@Injectable({
  providedIn: 'root'
})
export class UserRedirectService {

  constructor (public _router: Router) { }

  redirectCRMUser() {
    let ownerPermissions = GetUserObject.getOwnerPermissions();
    let userPermissions = GetUserObject.getPermissions();
    let isAdmin = GetUserObject.isAdmin();
    this._router.navigate(['/crm/dashboard']);
    if ((userPermissions?.find(x => x.ModuleID == ModuleDetails.dashBoard)?.CanRead || isAdmin) && !ownerPermissions.find(x => x.ModuleID == ModuleDetails.dashBoard)?.isHide) {
      this._router.navigate(['/crm/dashboard']);
    }
    else if ((userPermissions?.find(x => x.ModuleID == ModuleDetails.contacts)?.CanRead || isAdmin) && !ownerPermissions.find(x => x.ModuleID == ModuleDetails.contacts)?.isHide) {
      this._router.navigate(['/crm/manage']);
    }

    else if ((userPermissions?.find(x => x.ModuleID == ModuleDetails.currencies)?.CanRead || isAdmin) && !ownerPermissions.find(x => x.ModuleID == ModuleDetails.currencies)?.isHide) {
      this._router.navigate(['/crm/currency']);
    }

    else if ((userPermissions?.find(x => x.ModuleID == ModuleDetails.modules)?.CanRead || isAdmin) && !ownerPermissions.find(x => x.ModuleID == ModuleDetails.modules)?.isHide) {
      this._router.navigate(['/crm/transactions']);
    }
    else if ((userPermissions?.find(x => x.ModuleID == ModuleDetails.eWire)?.CanRead || isAdmin) && !ownerPermissions.find(x => x.ModuleID == ModuleDetails.eWire)?.isHide) {
      this._router.navigate(['/crm/ewires']);
    }
    else if ((userPermissions?.find(x => x.ModuleID == ModuleDetails.teller)?.CanRead || isAdmin) && !ownerPermissions.find(x => x.ModuleID == ModuleDetails.teller)?.isHide) {
      this._router.navigate(['/crm/teller']);
    }
    else if ((userPermissions?.find(x => x.ModuleID == ModuleDetails.reports)?.CanRead || isAdmin) && !ownerPermissions.find(x => x.ModuleID == ModuleDetails.reports)?.isHide) {
      this._router.navigate(['/crm/reports']);
    }
    else if ((userPermissions?.find(x => x.ModuleID == ModuleDetails.AML)?.CanRead || isAdmin) && !ownerPermissions.find(x => x.ModuleID == ModuleDetails.AML)?.isHide) {
      this._router.navigate(['/crm/compliance']);
    }
    else {
      this._router.navigate(['/crm/dashboard']);
    }

  }
}


