import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../shared/_services/notification.service';
import { LoaderService } from '../_services/loader.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor (
        private _notificationService: NotificationService,
        private _loaderService: LoaderService,
        private _router: Router,
        private _dialog: MatDialog,
        private translate: TranslateService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((err) => {
            if (localStorage.getItem("AuthToken") == null || localStorage.getItem("AuthToken") == undefined) {
                this._router.navigateByUrl("/login");
                this._dialog.closeAll();
            }
            // if (err.status == 0) { //or whatever condition you like to put
            //     this._router.navigateByUrl("/login");
            //     this._dialog.closeAll();
            //     this._notificationService.error("Contact Administrator");
            // }

            if (err == 'Unauthorized' || err.status == 401) {
                this._dialog.closeAll();
                this._notificationService.error("Authentication Required");
                this._router.navigateByUrl("/login");
            } else if (err == 'Bad Request' || err.status == 400) {
                // this.translate.get("Bad Request").subscribe(res => {
                this._notificationService.error(err.error.Message);
                // })
            }
            else if (err == 'Forbidden' || err.status == 403) {
                this._dialog.closeAll();
                this._notificationService.error("Forbidden");
            }
            else if (err.status == 500) {
                this._notificationService.error("Internal Server Error");
            }
            else {
                this._notificationService.error("Internal Server Error");
            }
            this.removeLoader();
            const error = (err.error != null ? err.error.message : null) || err.statusText;
            // this._loaderService.hideLoader();

            return throwError(error);
        }));
    }
    public removeLoader() {
        this._loaderService.hideLoader();
        this._loaderService.Count = this._loaderService.ApiDoneCount = 0;
    }
}
