import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { responseModel } from '../../_models/response.model';

@Injectable({
  providedIn: 'root'
})
export class ManagementReportsService {
  headers: { headers: any; };

  constructor (private _http: HttpClient) { }
  fnGetTopTensales(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-top-ten-sales", val);
  }

  fnGetTopTenUsers(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-top-ten-users", val);
  }
  GetMostBusyTradningHoursOfTheDay(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-most-busy-trading-hours", val);
  }
  fnGetTopTenCustomer(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-top-ten-Customers", val);
  }

  fnGetCurrencyBuySell(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-currency-buy-sell", val);
  }
  fnBranchPerformanceSummary(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-branch-performance-summary", val);
  }
  fnGetInventoryByBranch(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-inventory-by-branch", val);
  }
  fnGetMonthlySummary(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-monthly-summary", val);
  }
  fnGetWeeklySummary(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-weekly-summary", val);
  }

  fnGetZReport(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-z-report", val);
  }

  fnGetSumOfTransaction(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-sum-transaction", val);
  }
  fnGetYReport(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-y-report", val);
  }
  fnGetExpenseReport(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-expense-report", val);
  }
  fnGetAgentsAccountReport(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-agents-account-report", val);
  }
  fnGetCustomerInvoices(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-customer-latest-invoices", val);
  }

  fnGetLatestCustomers() {
    return this._http.get<responseModel>(environment.apiUrl + "reporting/management/get-latest-customer");
  }
  fnGetLatestAMLAlerts() {
    return this._http.get<responseModel>(environment.apiUrl + "reporting/management/get-latest-aml-alerts");
  }

  fnGetUsersPerformance(val) {
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-users-performance", val);
  }

  fnGetHoldingReport(val: boolean) {
    let param = new HttpParams();
    param.set('isCash', val);
    return this._http.post<responseModel>(environment.apiUrl + "reporting/management/get-holding-report", { param: param });
  }
}
