import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiResponse } from 'projects/cxr-crm/src/app/pages/_models/response.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IbanValidation } from '.';

@Injectable()
export class IbanValidationService {

  constructor(private http: HttpClient) { }

  validateIban(iban: string): Observable<IbanValidation> {
    let params = new HttpParams();
    params = params.set('iban', iban ? iban : '');
    return this.http.get(`${environment.apiUrl}iban/validate`, { params: params }).pipe(
      map((res: apiResponse<IbanValidation>) => ({ ...res.Data })));
  }
}
