import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent {
  public settings: Settings;

  constructor (public appSettings: AppSettings, public router: Router,
    public translate: TranslateService,
    private _location: Location
  ) {
    this.settings = this.appSettings.settings;
  }

  goHome(): void {
    this.router.navigate(['/crm']);
  }

  ngAfterViewInit() {
    this.settings.loadingSpinner = false;
  }

}
