import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { GenericHttpService } from 'src/app/_services/generic-http.service';
import { Localisation } from 'src/app/_services/localisation';


export class TransferRowsModel {
  public Currency: string;
  public Amount: number;
}

@Component({
  selector: 'cxr-teller-approval',
  templateUrl: './teller-approval.component.html',
  styleUrls: ['./teller-approval.component.scss']
})
export class TellerApprovalComponent implements OnInit {

  tableData: Array<TransferRowsModel>;
  message: string;
  actionCompleted: boolean = null;
  fromTill: string;
  toTill: string;
  errorMessage: string;
  constructor (
    private _genericHttpService: GenericHttpService,
    private _route: ActivatedRoute,
    public translate: TranslateService,
  ) {

    translate.addLangs(Localisation.supportLanguages);
    translate.setDefaultLang('en');
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
    let params: any = this._route.snapshot.paramMap.get('key');
    if (params == undefined || params == null || params == 0) {
      params = null;
      // window.close();
    }
    _genericHttpService.fnGetFundTransferDetails(params).subscribe(data => {
      if (data.Success) {
        this.actionCompleted = false;
        this.tableData = data.Data.Rows;
        this.fromTill = data.Data.fromTill;
        this.toTill = data.Data.toTill;
      }
      else {
        this.actionCompleted = true;
        this.errorMessage = data.Message;
      }
    });
  }

  ngOnInit(): void {

  }

  fnApprove(): void {
    let params: any = this._route.snapshot.paramMap.get('key');
    this._genericHttpService.fnApproveFundTransfer({ VerificationKey: params, approve: true }).subscribe(data => {
      if (data.Success)
        this.message = data.Message;
      else
        this.errorMessage = data.Message;

      this.actionCompleted = true;
    });
  }

  fnDecline(): void {
    let params: any = this._route.snapshot.paramMap.get('key');
    this._genericHttpService.fnApproveFundTransfer({ VerificationKey: params, approve: false }).subscribe(data => {
      if (data.Success)
        this.message = data.Message;
      else
        this.errorMessage = data.Message;

      this.actionCompleted = true;
    });
  }

  changeLanguage(val: string): void {
    this.translate.use(val);
  }
}
