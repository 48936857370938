import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiResponse, responseModel } from 'projects/cxr-crm/src/app/pages/_models/response.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CurrencyRateBoardService {

    constructor(private _http: HttpClient) { }

    fnGetCurrencyRateBoard(ownerCode: string, branchCode: string): Observable<any> {
        let params = new HttpParams();
        params = params.set('ownerCode', ownerCode);
        if (branchCode)
            params = params.set('branchCode', branchCode);
        return this._http.get<responseModel>(`${environment.apiUrl}currencyRateBoard/get`, { params: params });
    }
}