import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'cxr-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: ['./country-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryDropdownComponent),
      multi: true,
    }

  ]
})
export class CountryDropdownComponent implements OnInit {
  value: string;
  /* Decorators that allows us to pass data from the parent component to the child component. */
  @Input('countriesListDD') countriesListDD: any = [];
  @Input('countriesList') countriesList: any = [];
  @Input('isInvalid') isInvalid: boolean = false;
  @Input('disabled') disabled: boolean = false;
  @Input('Country') Country: string;

  countryControl = new FormControl(); // Creating a new instance of FormControl

  /* Defining the onChange and onTouched methods. */
  private onChange: (val: string) => void;
  private onTouched: () => void;

  constructor () { }

  ngOnInit(): void {
    /* This is a subscription to the valueChanges observable. */
    /* this is used for filtering dropdown. */
    this.countryControl.valueChanges.subscribe(val => {
      if (val) {
        this.countriesListDD = this.countriesList.filter(x => x['name'].toLowerCase().includes(val.toLowerCase()) ||
          x['alpha2Code'].toLowerCase().includes(val.toLowerCase()));
      }
      else {
        this.countriesListDD = this.countriesList;
      }
    });
  }

  /**
   * The writeValue function is called by the Angular framework when the value of the input element
   * changes
   * @param {any} value - any - The value of the control.
   */
  writeValue(value: any) {
    this.value = null;
    this.Country = null;
    this.value = value !== null ? value : this.Country;
  }

  /**
   * "When the value of the input changes, call the onChange function."
   * 
   * The onChange function is a function that we will write later. It will be called whenever the value
   * of the input changes
   * @param {any} fn - The function to call when the control receives a change event.
   */
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  /**
   * When the user interacts with the input, call the onTouched function.
   * @param {any} fn - The function to register.
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * The function takes a value as an argument, it is called dwhen value of the dropdown changes
   * and if the value is not null, it sets the Country
   * property to the value, and calls the onChange and onTouched functions
   * @param value - The value of the selected option.
   */
  selectCcountry(value) {
    if (value) {
      this.onChange(this.Country = value);
      this.onTouched();
    }
    else {
      this.onChange(this.Country = null);
      this.onTouched();
    }

  }

  /**
   * "When the user clicks out of the country filter, reset the country filter to null and reset the
   * country dropdown to the full list of countries."
   */
  focusOutCountryFilter() {
    this.countryControl.setValue(null);
    this.countriesListDD = this.countriesList;
  }
}
