import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { responseModel } from 'projects/cxr-crm/src/app/pages/_models/response.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor (private _http: HttpClient) { }
  // currency aliases  
  fnFetchCurrencyAliases(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'pick-currency-rates-DD', val);
  }
  // Get Currency DD 
  fetchCurrencyDD(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }invoice/get-currency-of-accounts`, val);
  }
  // Get Accounts using currency and owner id
  fetchAccountsDDByCurrency(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }invoice/get-account-dd-by-currency`, val);
  }

  fnGetDenominations(val): Observable<responseModel> {

    let params = new HttpParams();
    params = params.set('currency', val.Currency);

    return this._http.get<responseModel>(`${ environment.apiUrl }invoice/get-denominations`, { params: params });
  }

  // get-ewires-accounts-dd
  fnFetchEwireAccount(val) {
    return this._http.post(`${ environment.apiUrl }get-ewires-accounts-dd`, val);
  }
  fnFetchBankInfoLocalList(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }generic/get-bank-info-list-local`, val);
  }
  fnFetchWireTemplateLocalList(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }generic/get-wire-templates-local`, val);
  }
  fnGetAMLReviewDetails(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }generic/get-AML-Review-details`, val);
  }
  fnSaveAMLReview(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }generic/save-AML-Review`, val);
  }
  //Get Date Range DD
  fnGetDateRangeDD() {
    return this._http.get<responseModel>(environment.apiUrl + 'get-daterange-dd');
  }

  fnGetUsersDD(ownerId?: number) {
    let params = new HttpParams();
    params = params.set('ownerId', ownerId);
    return this._http.get<responseModel>(`${ environment.apiUrl }generic/get-users-dd`, { params: params });
  }
  fnGetBranchesDD() {
    return this._http.post<responseModel>(environment.apiUrl + 'get-branches-DD', {});
  }

  verifyUserPin(UserID: number, Pin: string) {
    let params = new HttpParams();
    params = params.set('userId', UserID);
    params = params.set('pin', Pin);
    return this._http.get<responseModel>(`${ environment.apiUrl }verify-user-pin`, { params: params });
  }
}
