import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { orderBy } from 'lodash-es';
import { ModalDirective } from 'ngx-bootstrap';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { AddressModel } from 'projects/cxr-crm/src/app/pages/_models/generic-models/generic-model';
import { LookupsService } from 'projects/cxr-crm/src/app/pages/_services/lookups.service';
import { WhiteSpaceValidator } from 'src/app/_helpers';
import { Localisation } from 'src/app/_services/localisation';
import { PqgridComponent } from 'src/app/pqgrid.component';
import { ConfirmationDialogComponent } from 'src/app/shared-dialog/confirmation-dialog/confirmation-dialog.component';
import {
  GenericPreviewModel,
  PreviewGenericRowDataDialogComponent
} from 'src/app/shared-dialog/preview-generic-row-data-dialog/preview-generic-row-data-dialog.component';
import { environment } from 'src/environments/environment';
import pq from 'src/paramquery';
import { GenericComponentService } from '../../../_services/generic-component.service';
import { AutoCompleteAddressResult } from './address-auto-complete.model';

enum addressAutoCompleteType {
  CanadaPost = 'Canada Post',
  PostGrid = 'Post Grid'
}
@Component({
  selector: 'app-generic-address',
  templateUrl: './generic-address.component.html',
  styleUrls: ['./generic-address.component.scss'],
})
export class GenericAddressComponent implements OnInit {
  // create: function () {
  //   //restore state of grid.
  //   var StateVal = localStorage.getItem('pq-grid' + self.GridName);
  //   this.loadState({ refresh: false, state: StateVal });
  // },
  @HostListener('window:beforeunload')
  saveGridState() {
    this.gridAddress?.SaveState();
  }
  ngOnDestroy() {
    this.gridAddress?.SaveState();
  }
  GridName: string = 'GenericAddress' + GetUserObject.GetUserID();
  countryControl = new FormControl(); // filter variable for country 
  @Input('InternalTableName') InternalTableName: string;
  @Input('InternalRecordID') InternalRecordID: number;
  @Input('countriesList') countriesList: any[] = [];
  @Input('countriesList') countriesListDD: any[] = [];
  @Input('AddressTypeDD') AddressTypeDD: any[] = [];
  @Input('hideAddButton') hideAddButton: boolean;
  clickToEdit = 'Click to edit';
  clickToDelete = 'Click to delete';
  @ViewChild('gridAddress', { static: false }) gridAddress: PqgridComponent;
  optionsGridAddress: pq.gridT.options;

  constructor (
    private _formBuilder: UntypedFormBuilder,
    private _dialog: MatDialog,
    private _genericComponentService: GenericComponentService,
    private _lookupsService: LookupsService,
    private translate: TranslateService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(
      browserLang.match(Localisation.matchLanguageExpression)
        ? browserLang
        : Localisation.defaultLanguage
    );
  }

  // grid helper code

  // grid helper code ends here
  ngOnInit() {
    this.getDD();
    this.addressFormInit(new AddressModel());
    this.GridInit();
    this.fnTranslate();
    this.getAddressAutoCompleteType();
    this.countryControl.valueChanges.subscribe(val => {
      if (val) {
        this.countriesListDD = this.countriesList.filter(x => x['name'].toLowerCase().includes(val.toLowerCase()) ||
          x['alpha2Code'].toLowerCase().includes(val.toLowerCase()));
      }
      else {
        this.countriesListDD = this.countriesList;
      }
    });
  }
  AutoCompletetype: number | undefined = undefined;
  async getAddressAutoCompleteType(): Promise<void> {
    var res = await this._lookupsService.getAddressAutoCompleteTypes();
    switch (res) {
      case addressAutoCompleteType.CanadaPost:
        this.AutoCompletetype = 1;
        break;
      case addressAutoCompleteType.PostGrid:
        this.AutoCompletetype = 2;
        break;
      default: this.AutoCompletetype = undefined;
    }
  }
  getDD(): void {
    if (this.AddressTypeDD.length === 0) {
      this._lookupsService
        .fnGetDropDownsByOwnerID(GetUserObject.GetOwnerID())
        .subscribe((data) => {
          const addData = data['Data'].filter((x) => x.Name == 'Address Type');
          this.AddressTypeDD = addData.length != 0 ? addData[0].Items : null;
        });
    }
    if (this.countriesList.length === 0) {
      this._lookupsService.fnGetCountries().subscribe((data) => {
        if (data['Data'] == null) {
          this.countriesList = [];
        }
        this.countriesListDD = this.countriesList = orderBy(data['Data'], ['alpha2Code'], ['asc']);
      });
    }
  }

  GridInit() {
    var self = this;
    this.optionsGridAddress = {
      title: 'Address',
      scrollModel: { autoFit: true },
      // create: function () {
      //   //restore state of grid.
      //   var StateVal = localStorage.getItem('pq-grid' + self.GridName);
      //   this.loadState({ refresh: false, state: StateVal });
      // },
      toolbar: {
        items: this.hideAddButton
          ? []
          : [
            // {
            //   type: 'button',
            // label: self._route.snapshot.data.Data['Reset filters'],
            //   listener: function () {
            //     this.reset({ filter: true });
            //   }
            // },
            {
              type: 'button',
              icon: 'ui-icon-plus',
              label: '',
              cls: 'pq-add-button',
              listener: function () {
                self.addEditAddress(new AddressModel(), 1);
              },
            },
          ],
      },
      pageModel: {
        type: 'remote',
        rPP: environment.pq_grid.default_rPP,
        curPage: 1,
        rPPOptions: [5, 10, 20, 50, 100],
        layout: [],
        //layout: ['prev', 'next', "|", "strPage"]
        // layout: ['first', 'prev', 'next', 'last', "|", "strPage"]
      },
      filterModel: {
        on: false,
        header: false,
        type: 'remote',
        menuIcon: false,
      },
      sortModel: {
        type: 'remote',
        single: false,
        sorter: [{ dataIndx: 'AddressID', dir: 'down' }],
        space: true,
        multiKey: null,
      },
      colModel: [
        {
          title: '',
          editable: false,
          width: 25,
          sortable: false,
          align: 'center',
          menuIcon: false,
          cls: this.hideAddButton ? 'hide' : '',
          render: function (ui) {
            //   if (this.isForCustomerReport) {
            //     let str = ""
            //     if (ui.rowData.Status == null) {
            //       return "<a style='color: green;cursor: pointer;' title='Click to edit' class='edit_btn'><span>&#x270F;</span></a>\
            //       <a style='color: red;cursor: pointer;' title='Click to delete' class='delete_btn'><span>&#x274C;</span></a>";
            //     }
            //     else {
            //       return ""
            //     }
            //   }
            //   else {
            //     if (ui.rowData.Status != null) {
            //       return "<button style='color: green;cursor: pointer;' title='Click to Approve' class='approve_btn'>Approve</button>\
            //       <button style='color: green;cursor: pointer;' title='Click to Decline' class='decline_btn'>Decline</button>";
            //     }
            //     else {
            //       return ""
            //     }
            //   }
            // },
            return (
              "<a style='color: green;cursor: pointer;' title= " +
              self.clickToEdit +
              "   + class='edit_btn'><span>&#x270F;</span></a>\
            <a style='color: red;cursor: pointer;' title=  " +
              self.clickToEdit +
              "    class='delete_btn'><span>&#x274C;</span></a>"
            );
          },
          postRender: function (ui) {
            var rowIndx = ui.rowIndx,
              grid = this,
              $cell = grid.getCell(ui);
            //edit button
            $cell
              .find('.edit_btn')
              .off('click')
              .on('click', function (evt) {
                self.addEditAddress(ui.rowData, 1);
              });
            //delete button
            $cell
              .find('.delete_btn')
              .off('click')
              .on('click', function (evt) {
                self.showConfirmationModal();
                self.deleteRowValue = ui.rowData;
                self.deleteRowType = 'address';
              });
          },
        },
        {
          title: 'Type',
          dataType: 'string',
          dataIndx: 'AddressType',
          filter: { crules: [{ condition: 'range' }] },
        },
        {
          title: 'Street',
          dataType: 'string',
          dataIndx: 'Street',
          filter: { crules: [{ condition: 'contain' }] },
        },
        {
          title: 'City / Town',
          dataType: 'string',
          dataIndx: 'City',
          filter: { crules: [{ condition: 'contain' }] },
        },
        {
          title: 'State / Province',
          dataType: 'string',
          dataIndx: 'StateProvince',
          filter: { crules: [{ condition: 'contain' }] },
        },
        {
          title: 'Country',
          dataType: 'string',
          dataIndx: 'Country',
          filter: { crules: [{ condition: 'contain' }] },
        },
        {
          title: 'Zip Code',
          dataType: 'string',
          dataIndx: 'ZipCode',
          filter: { crules: [{ condition: 'contain' }] },
        },
        // {
        //   title: "Actions", hidden: true, editable: false, sortable: false, align: "center",
        //   render: function (ui) {
        //     return "<button type='button' class='edit_btn m-r-5'></button> <button type='button' class='delete_btn'></button>";
        //   },
        //   postRender: function (ui) {
        //     var rowIndx = ui.rowIndx,
        //       grid = this,
        //       $cell = grid.getCell(ui);
        //     //edit button
        //     $cell.find(".edit_btn").button({ icons: { primary: 'ui-icon-pencil' } }).off("click")
        //       .on("click", function (evt) {
        //         self.addEditAddress(ui.rowData, 1);
        //       });
        //     //delete button
        //     $cell.find(".delete_btn")
        //       .button({ icons: { primary: 'ui-icon-close' } }).off("click")
        //       .on("click", function (evt) {
        //         self.showConfirmationModal();
        //         self.deleteRowValue = ui.rowData;
        //         self.deleteRowType = 'address';
        //       });
        //   }
        // }
      ],
      selectionModel: { type: 'row' },

      rowDblClick: function (evt, ui) {
        var previewObj: GenericPreviewModel = new GenericPreviewModel();
        previewObj.Columns = this.colModel;
        previewObj.RowData = ui.rowData;
        previewObj.Heading = 'Grid Details Preview';
        const dialogRef = self._dialog.open(
          PreviewGenericRowDataDialogComponent,
          {
            data: previewObj,
            width: '1000px',
          }
        );
        dialogRef.afterClosed().subscribe((result) => { });
      },
      // sort: function (evt, ui) {
      //   this.onSort(evt, ui);
      // },
      dataModel: {
        location: 'remote',
        postData: {
          InternalRecordID: self.InternalRecordID,
          InternalTableName: self.InternalTableName,
        },
        dataType: 'JSON',
        method: 'POST',
        url: environment.apiUrl + 'generic/get-address-list',
        getData: function (dataJSON) {
          var data = JSON.parse(dataJSON.Data);
          return {
            curPage: data.curPage,
            totalRecords: data.totalRecords,
            data: data.data,
          };
        },
      },
    };
  }
  selectedAddress(address: AutoCompleteAddressResult): void {
    this.AddressF.Street.setValue(address.street);
    this.AddressF.City.setValue(address.city);
    this.AddressF.StateProvince.setValue(address.province);
    this.AddressF.Country.setValue(address.country);
    this.AddressF.ZipCode.setValue(address.zipCode);
  }
  // get Modal Pop-up data
  fnGetDropDownData() { }

  // get Modal Pop-up data ends here

  // Confirmation modal pop over
  @ViewChild('confirmationModal', { static: false })
  confirmationModal: ModalDirective;
  deleteRowValue: any;
  deleteRowType: string;
  showConfirmationModal(): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this record?',
        isDelete: true,
        buttonText: {
          ok: 'Confirm',
          cancel: 'Decline',
        },
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteRecord();
      }
    });
  }

  hideConfirmationModal(): void {
    this.confirmationModal.hide();
  }
  // Confirmation modal pop over end here
  // Address code starts here
  AddressForm: UntypedFormGroup;

  addressSubmitted: boolean;
  AddressValue: AddressModel;
  @ViewChild('addEditAddressModal', { static: false })
  addEditAddressModal: ModalDirective;
  addressFormInit(val: AddressModel) {
    this.AddressForm = this._formBuilder.group({
      AddressID: new UntypedFormControl(val.AddressID),
      InternalRecordID: new UntypedFormControl(this.InternalRecordID, [
        Validators.required,
      ]),
      InternalTableName: new UntypedFormControl(this.InternalTableName, [
        Validators.required,
      ]),
      // LinkID: new FormControl(null, Validators.required),
      AddressType: new UntypedFormControl(val.AddressType, Validators.required),
      Street: new UntypedFormControl(val.Street, [Validators.required, WhiteSpaceValidator.cannotContainSpace]),
      City: new UntypedFormControl(val.City, [Validators.required, WhiteSpaceValidator.cannotContainSpace]),
      StateProvince: new UntypedFormControl(val.StateProvince),
      Country: new UntypedFormControl(val.Country, [Validators.required]),
      ZipCode: new UntypedFormControl(val.ZipCode, []),
      isDeleted: new UntypedFormControl(false),
    });
  }
  // convenience getter for easy access to form fields
  get AddressF() {
    return this.AddressForm.controls;
  }
  onAddressFormSubmit() {
    this.addressSubmitted = true;
    // stop here if form is invalid
    if (this.AddressForm.invalid) {
      return;
    }
    this._genericComponentService
      .fnUpsertAddress(this.AddressForm.value)
      .subscribe((data) => {
        if (data.Success) {
          this.AddressValue = new AddressModel();
          this.addressSubmitted = false;
          this.gridAddress.refreshDataAndView();
          this.addEditAddress(new AddressModel(), 0);
          // this._route.queryParams.subscribe(params =>
          // {
          //   this._router.navigate(['/crm/manage/individual/add-edit-individual/profile'], { queryParams: { data: params.data }, skipLocationChange: false });
          // });

        }
      });
  }
  addEditAddress(val: AddressModel, flag: number) {
    this.addressSubmitted = false;
    // if (val != null) {
    //   this._genericComponentService.fnGetAddressData(val).subscribe(data => {
    //     this.addressFormInit(data.Data);
    //   });
    // }
    if (flag == 1) {
      this._genericComponentService.fnGetAddressData(val).subscribe((data) => {
        this.addressFormInit(data.Data);
        this.addEditAddressModal.show();
      });
    } else this.addEditAddressModal.hide();
  }

  deleteRecord() {
    this._genericComponentService
      .fnDeleteAddress(this.deleteRowValue)
      .subscribe((data) => {
        if (data.Success) {
          this.gridAddress.refreshDataAndView();
          this.hideConfirmationModal();
        }
        this.deleteRowValue = null;
        this.deleteRowType = null;
      });
  }
  // Address code ends here

  fnTranslate() {
    for (
      let index = 0;
      index < this.optionsGridAddress.colModel.length;
      index++
    ) {
      if (this.optionsGridAddress.colModel[index].title) {
        this.translate
          .get(this.optionsGridAddress.colModel[index].title.toString())
          .subscribe((translationValue) => {
            this.optionsGridAddress.colModel[index].title = translationValue;
          });
      }
    }
    this.translate
      .get(this.optionsGridAddress.title)
      .subscribe((translationValue) => {
        this.optionsGridAddress.title = translationValue;
      });

    this.translate.get(this.clickToEdit).subscribe((translationValue) => {
      this.clickToEdit = translationValue;
    });
    this.translate.get(this.clickToDelete).subscribe((translationValue) => {
      this.clickToDelete = translationValue;
    });
  }
}
