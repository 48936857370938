import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CurrenctRateBoardSettingsModel } from 'projects/cxr-crm/src/app/pages/admin/currency-rate-board-settings/currency-rate-board-settings.model';
import { NullNumberToNumber } from 'src/app/_helpers';
import { CurrencyRateBoardService } from 'src/app/_services/currency-rate-board.service';
import { NumberFormatPipe } from 'src/app/shared/_pipes/number-format.pipe';

export class MoneyDashboardModel {
  BuyRate: number;
  InverseBuyRate: number;
  InverseSellRate: number;
  SellRate: number;
  FromCurrency: string;
  ToCurrency: string;
  FromCurrencyFlag: string;
  ToCurrencyFlag: string;
  CurrencyAlias: string;
  OwnerCode: string;
}

@Component({
  selector: 'cxr-currency-rate-board',
  templateUrl: './currency-rate-board.component.html',
  styleUrls: ['./currency-rate-board.component.scss']
})
export class CurrencyRateBoardComponent implements OnInit {
  constructor (
    // private _router: Router,
    private _route: ActivatedRoute,
    private _currencyRateBoardService: CurrencyRateBoardService,
    public translate: TranslateService,
    public numberFormat: NumberFormatPipe
  ) {

  }

  timeStamp: Date;
  tableList: Array<MoneyDashboardModel> = [];
  errorMessage: string;
  CurrencyRateBoardSettings: CurrenctRateBoardSettingsModel = new CurrenctRateBoardSettingsModel();
  LogoUrl: string;
  baseUrl: string = window.location.origin + "/Content/CountryFlags";
  ngOnInit(): void {
    this._route.queryParamMap.subscribe(params => {
      let ownerCode = params['params']["ownerCode"];
      let branchCode = params['params']["branchCode"] ? params['params']["branchCode"] : null;
      this._currencyRateBoardService.fnGetCurrencyRateBoard(ownerCode, branchCode).subscribe(data => {
        if (data.Success) {
          this.tableList = data.Data?.Values;
          this.CurrencyRateBoardSettings = data.Data?.Settings;
          this.timeStamp = data.Data?.LastUpdatedDate;
          this.LogoUrl = data.Data?.LogoUrl;
          this.translate.use(data.Data?.Settings.Language);
        }
        else
          this.errorMessage = data.Message;
      });
    });
  }

  public GetNumberFormated(value, round) {

    return (this.numberFormat.transform(NullNumberToNumber.toNumber(value), round));
  }
}
