<mat-dialog-content>
    <h4 align="center">
        <div [innerHtml]="message | translate"></div>
    </h4>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button *ngIf="deleteBtn == false" mat-raised-button class="btn btn-success" color="primary"
        (click)="onConfirmClick()">{{confirmButtonText | translate}}</button>
    <button *ngIf="deleteBtn == true" mat-raised-button class="btn" id="confirm" color="warn"
        (click)="onConfirmClick()">{{confirmButtonText | translate}}</button>
    <button mat-raised-button mat-dialog-close>{{cancelButtonText | translate}}</button>
</mat-dialog-actions>
