import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  headers: { headers: any; };
  constructor (private _http: HttpClient) { }


  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  // function to get favorite currency data on dashboard
  fnGetFavoriteCurrency(values) {
    return this._http.post(environment.apiUrl + 'reporting/management/get-favorit-currencies', values);
  }

  // function to get favorite currency data on dashboard
  fnGetMonthlySales(values) {
    return this._http.post(environment.apiUrl + 'reporting/management/get-total-sales', values);
  }

  // function to get favorite currency data on dashboard
  fnGetAssignedtillSummary(data) {
    return this._http.post(environment.apiUrl + 'reporting/management/get-assigned-till-summary', data);
  }
}
