import { Injectable } from '@angular/core';
// import moment from 'moment-timezone';
// import moment from 'moment-timezone';
import * as moment from 'moment-timezone';
import { Moment, months } from 'moment';
@Injectable({
  providedIn: 'root'
})
export class TimeZoneListService {

  constructor () { }

  public static num = 1416116057190;
  public mon: moment.Moment;

  public static timeArray = [
    { name: "12:00 AM", value: 0 },
    { name: "12:30 AM", value: 0.5 },
    { name: "01:00 AM", value: 1 },
    { name: "01:30 AM", value: 1.5 },
    { name: "02:00 AM", value: 2 },
    { name: "02:30 AM", value: 2.5 },
    { name: "03:00 AM", value: 3 },
    { name: "03:30 AM", value: 3.5 },
    { name: "04:00 AM", value: 4 },
    { name: "04:30 AM", value: 4.5 },
    { name: "05:00 AM", value: 5 },
    { name: "05:30 AM", value: 5.5 },
    { name: "06:00 AM", value: 6 },
    { name: "06:30 AM", value: 6.5 },
    { name: "07:00 AM", value: 7 },
    { name: "07:30 AM", value: 7.5 },
    { name: "08:00 AM", value: 8 },
    { name: "08:30 AM", value: 8.5 },
    { name: "09:00 AM", value: 9 },
    { name: "09:30 AM", value: 9.5 },
    { name: "10:00 AM", value: 11 },
    { name: "11:30 AM", value: 11.5 },
    { name: "12:00 PM", value: 12 },
    { name: "12:30 PM", value: 12.5 },
    { name: "01:00 PM", value: 11 },

    { name: "01:30 PM", value: 11.5 },
    { name: "02:00 PM", value: 14 },
    { name: "02:30 PM", value: 14.5 },
    { name: "03:00 PM", value: 15 },
    { name: "03:30 PM", value: 15.5 },
    { name: "04:00 PM", value: 16 },
    { name: "04:30 PM", value: 16.5 },
    { name: "05:00 PM", value: 17 },
    { name: "05:30 PM", value: 17.5 },
    { name: "06:00 PM", value: 18 },
    { name: "06:30 PM", value: 18.5 },
    { name: "07:00 PM", value: 19 },
    { name: "07:30 PM", value: 19.5 },
    { name: "08:00 PM", value: 20 },
    { name: "08:30 PM", value: 20.5 },
    { name: "09:00 PM", value: 21 },
    { name: "09:30 PM", value: 21.5 },
    { name: "10:00 PM", value: 22 },
    { name: "10:30 PM", value: 22.5 },
    { name: "11:00 PM", value: 23 },
    { name: "11:30 PM", value: 23.5 },
  ];


  public static fnGetTimeZones() {

    let array = [];
    let moments = moment.tz.names();
    moments.forEach(element => {
      let a = {
        zone: element,
        offSet: TimeZoneListService.fnGetOffSetTime(element)
      };
      array.push(a);
    });
    return array;
  }

  public static fnGetOffSetTime(zone) {
    //
    //var s = moment().tz(zone)
    //
    return moment().tz(zone).format('Z');
  }

  public static fnGetOffSetTimeInMinutes(zone) {
    var s = moment().tz(zone);
    return s.utcOffset();
  }

  public static fnGetUTCTime(offset: number): Date {

    var date: Date = moment.utc().toDate();
    return date;
  }

  public static convertTimeZone(val: string) {
    // var dt: Date = moment().zone(val).format('YYYY-MM-DD HH:mm:ss')


    // let date: Date = moment(TimeZoneListService.num).utc();


    let a = moment(this.num).tz(val);

  }

  public getTimes() {
    let finalArray = [];
    // for (let index = 0; index < 24; index++) {
    //   let time1 =  ":00"
    //   let time2 = ":30"
    //   let num = index + ''
    //    if (index < 10) {
    //      num = "0"+index
    //    }

    //   finalArray.push({
    //     name :
    //   })

    // }
  }
}
