import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from "SubSink";
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { TableNames, TableNamesModel } from 'src/app/_helpers/app.tablenames';
import { Localisation } from 'src/app/_services/localisation';
import { PqgridComponent } from 'src/app/pqgrid.component';
import { NotificationService } from 'src/app/shared/_services/notification.service';
import { environment } from 'src/environments/environment';
import pq from 'src/paramquery';
import { CompanyKycReviewDialogComponent } from '../company-kyc-review-dialog/company-kyc-review-dialog.component';
import { IndividualKycReviewDialogComponent } from '../individual-kyc-review-dialog/individual-kyc-review-dialog.component';
import { NotePreviewInvoiceComponent } from '../note-preview-invoice/note-preview-invoice.component';

@Component({
  selector: 'app-pick-customer-dialog',
  templateUrl: './pick-customer-dialog.component.html',
  styleUrls: ['./pick-customer-dialog.component.scss']
})
export class PickCustomerDialogComponent implements OnInit {

  @HostListener('window:beforeunload', ['$event'])
  saveGridState($event) {
    this.gridInd.SaveState();
    this.gridCmp.SaveState();
  }
  GridNameInd: string = 'PickCustomerDialogInd' + GetUserObject.GetUserID();
  GridNameCmp: string = 'PickCustomerDialogCmp' + GetUserObject.GetUserID();
  ngOnDestroy() {
    this.gridInd.SaveState();
    this.gridCmp.SaveState();
  }
  subs = new SubSink();
  @ViewChild('gridInd', { static: false }) gridInd: PqgridComponent;
  @ViewChild('gridCmp', { static: false }) gridCmp: PqgridComponent;
  keyWord: string;
  gridOptionsInd: pq.gridT.options;
  gridOptionsCmp: pq.gridT.options;
  SelectedCustomer: any;
  public TableNames: TableNamesModel;
  public isIndividual: boolean = true;
  ToolbarItem = [];
  constructor (
    public _tableNames: TableNames,
    private _dialogRef: MatDialogRef<PickCustomerDialogComponent>,
    private _notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router,
    private translate: TranslateService,
    private _dialog: MatDialog,
    private _changeDetection: ChangeDetectorRef,
  ) {
    _dialogRef.disableClose = true;
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
    this.TableNames = _tableNames.tablenames;
    var self = this;
    if (data.isWalkinShow) {
      this.ToolbarItem = [
        {
          type: 'button',
          icon: 'ui-icon-grip-diagonal-se',
          label: data.route.snapshot.data.Data['KYC Review'],
          cls: 'pull-right',
          listener: function () {
            if (self.SelectedCustomer != null) {
              self.KYCReview();
            } else {
              self.translate.get("Select row to do KYC Review").subscribe(res => {
                self._notificationService.error(res);
              });
            }

          }
        },

        {
          type: 'button',
          icon: 'ui-icon-refresh',
          cls: 'pq-filter-button',
          label: data.route.snapshot.data.Data['Reset filters'],
          listener: function () {
            this.reset({ filter: true });
            $(".filterValue").val(null);
          }
        },
        {
          type: 'button',
          label: data.route.snapshot.data.Data['New Customer'],
          cls: 'pq-add-button',
          listener: function () {
            // self.onNoClick();
            // self._dialog.closeAll();
            // self._router.navigateByUrl("/crm/manage");
            let obj: Object = new Object;
            if (self.isIndividual) {

              // obj['IndividualValue'] = {};
              obj['IndividualID'] = 0;
              let param = btoa(unescape(encodeURIComponent(JSON.stringify(obj))));
              window.open("/crm/manage/individual/add-edit-individual/profile?data=" + param);
            }
            else {
              self.onNoClick();
              self._dialog.closeAll();
              self._router.navigateByUrl("/crm/manage");

              obj['CompanyID'] = 0;
              let param = btoa(unescape(encodeURIComponent(JSON.stringify(obj))));
              window.open("/crm/manage/company/add-edit-company-details/company-profile?data=" + param);
            }

          }
        }, {
          type: 'button',
          label: 'Pick Walkin',
          //  cls: data['isForInvoice'] ? "" : "hide",


          listener: function () {
            var WalkinCustomerData = {
              "CustomerID": GetUserObject.GetOwnerCode() + "-Walkin",
              "CustomerName": "Walkin",
              "isWalkin": true,
              "OwnerID": GetUserObject.GetOwnerID()
            };
            self.pickCustomer(WalkinCustomerData);
          }
        }
      ];
    } else {
      this.ToolbarItem = [
        {
          type: 'button',
          icon: 'ui-icon-grip-diagonal-se',
          label: 'KYC Review',
          cls: 'pull-right',
          listener: function () {
            if (self.SelectedCustomer != null) {
              self.KYCReview();
            } else {
              this.translate.get("Select row to do KYC Review").subscribe(res => {
                this._notificationService.error(res);
              });
            }
          }
        },

        {
          type: 'button',
          icon: 'ui-icon-refresh',
          cls: 'pq-filter-button',
          label: data.route.snapshot.data.Data['Reset filters'],
          listener: function () {
            this.reset({ filter: true });
            $(".filterValue").val(null);
          }
        },
        {
          type: 'button',
          label: 'Add',
          listener: function () {
            self.onNoClick();
            self._dialog.closeAll();
            self._router.navigateByUrl("/crm/manage");
          }
        }
      ];
    }
    this.loadIndividual();

    this.loadCompanyGrid();

  }

  loadIndividual() {

    var self = this;
    this.gridOptionsInd = {
      title: "Pick an Customer",
      height: "300",
      pageModel: { type: "remote", rPP: environment.pq_grid.dialog_rPP, curPage: 1, rPPOptions: [5, 10, 20, 50, 100] },
      sortModel: {
        type: 'remote',
        single: false,
        sorter: [{ dataIndx: 'CustomerName', dir: 'up' }],
        space: true,
        multiKey: null,
        on: false
      },
      dataModel: {
        location: "remote",
        postData: { OwnerID: GetUserObject.GetOwnerID(), isIndividual: true },
        dataType: "JSON",
        method: "POST",
        url: environment.apiUrl + "pick-customer-v2",
        getData: function (dataJSON) {
          var data = JSON.parse(dataJSON.Data);
          return { curPage: data.curPage, totalRecords: data.totalRecords, data: data.data };
        }
      },
      selectionModel: { type: 'row', mode: 'single', toggle: true },
      filterModel: {
        on: true,
        mode: "OR",
        header: false,
        type: 'remote',
        menuIcon: false //show filter row icon initially.
      },

      toolbar: {
        items: self.ToolbarItem
      },
      colModel: [
        {
          title: "ID", dataType: "string", dataIndx: "CustomerID"
        },
        {
          title: "Name", dataType: "string", dataIndx: "CustomerName"
        },
        {
          title: "Social Security/SIN", dataType: "string", dataIndx: "NationalID"
        },
        {
          title: "Nationality", dataType: "string", dataIndx: "Nationality"
        },
        {
          title: "Email", dataType: "string", dataIndx: "CustomerEmail"
        },
        {
          title: "Phone", dataType: "string", dataIndx: "CustomerPhone"
        },
        {
          title: "Country Of Residence", dataType: "string", dataIndx: "CountryOfResidence"
        },
        {
          title: "Date Of Birth", dataType: "string", dataIndx: "DateOfBirth"
        },
        {
          title: "Company Name", dataType: "string", dataIndx: "CompanyName"
        }

      ],
      rowDblClick: function (ui, evt) {
        self.pickCustomer(evt.rowData);
      },
      rowClick: function (ui, evt) {
        if (evt['addList'] != undefined && evt['addList'] != null) {
          self.SelectedCustomer = evt['rowData'];
        } else {
          self.SelectedCustomer = null;
        }
      }
    };
  }

  loadCompanyGrid() {
    var self = this;
    this.gridOptionsCmp = {
      title: "Pick a Customer",
      height: "300",
      pageModel: { type: "remote", rPP: environment.pq_grid.dialog_rPP, curPage: 1, rPPOptions: [5, 10, 20, 50, 100] },
      sortModel: {
        type: 'remote',
        single: false,
        sorter: [{ dataIndx: 'CustomerName', dir: 'up' }],
        space: true,
        multiKey: null
      },
      dataModel: {
        location: "remote",
        postData: { OwnerID: GetUserObject.GetOwnerID(), isIndividual: false },
        dataType: "JSON",
        method: "POST",
        url: environment.apiUrl + "pick-customer-v2",
        getData: function (dataJSON) {
          var data = JSON.parse(dataJSON.Data);
          return { curPage: data.curPage, totalRecords: data.totalRecords, data: data.data };
        }
      },
      selectionModel: { type: 'row', mode: 'single', toggle: true },
      filterModel: {
        on: true,
        mode: "OR",
        header: false,
        type: 'remote',
        menuIcon: false //show filter row icon initially.
      },

      toolbar: {
        items: self.ToolbarItem
      },
      colModel: [
        {
          title: "ID", dataType: "string", dataIndx: "CustomerID"
        },
        {
          title: "Name", dataType: "string", dataIndx: "CustomerName"
        },
        {
          title: "Incorporation Number", dataType: "string", dataIndx: "IncorporationNumber"
        },
        {
          title: "Email", dataType: "string", dataIndx: "CustomerEmail"
        },
        {
          title: "Phone", dataType: "string", dataIndx: "CustomerPhone"
        }
      ],
      rowDblClick: function (ui, evt) {
        self.pickCustomer(evt.rowData);
      },
      rowClick: function (ui, evt) {
        if (evt['addList'] != undefined && evt['addList'] != null) {
          self.SelectedCustomer = evt['rowData'];
        } else {
          self.SelectedCustomer = null;
        }
      }
    };
  }
  ngOnInit() {
    this.fnTranslate();
    if (this.data.CustomerExtID?.indexOf("-CMP-") >= 0) {
      this.isIndividual = true;
    }
    this.keyWord = this.data.CustomerExtID == GetUserObject.GetOwnerCode() + "-Walkin" ? null : this.data.CustomerExtID;
    this.search();
  }


  onNoClick(): void {
    if (this.data.CustomerExtID == GetUserObject.GetOwnerCode() + "-Walkin") {
      this._dialogRef.close({
        "CustomerID": GetUserObject.GetOwnerCode() + "-Walkin",
        "CustomerName": "Walkin",
        "isWalkin": true,
        "OwnerID": GetUserObject.GetOwnerID()
      });
      return;
    }
    this._dialogRef.close();

  }


  pickCustomer(val) {
    if (this.data.isForInvoice == true) {
      if (val.NoteCount > 0) {
        var Obj = new Object();
        Obj['InternalTableName'] = val.isCompany == true ? this.TableNames.TableNameCompany : this.TableNames.TableNameIndividual;
        Obj['InternalRecordID'] = val.ID;
        Obj['OwnerID'] = GetUserObject.GetOwnerID();
        Obj['route'] = this.data.route;

        const dialogRef = this._dialog.open(NotePreviewInvoiceComponent, {
          data: Obj,
          disableClose: true,
          width: '900px'
        });

        dialogRef.afterClosed().subscribe((dialogResult: any) => {
          if (dialogResult == true) {
            // this.CloseDialog(val);
            if (!val.hasOwnProperty("isWalkin")) {
              val['isWalkin'] = false;
            }
            if (val['isWalkin'] == false) {
              if (val.onhold == true) {
                this.translate.get("On Hold - reason").subscribe(res => {
                  this._notificationService.info(res + ": " + val.onholdText);
                });
                return;
              } else if (val.Suspicious == true) {
                this.translate.get("Suspicious - reason").subscribe(res => {
                  this._notificationService.info(res + ": " + val.SuspiciousText);
                });
              }
            }
            if (val.isSelf != true && val.isKYCReviewRequired == true && val.isCompany == true) {
              const dialogRef = this._dialog.open(CompanyKycReviewDialogComponent, {
                data: { CompanyID: val.ID },
                disableClose: true,
                width: '900px',
                backdropClass: 'DialogGreenBackGround'
              });

              dialogRef.afterClosed().subscribe((dialogResult: any) => {
                if (dialogResult == true) {
                  this._dialogRef.close(val);
                }
              });
            } else if (val.isSelf != true && val.isKYCReviewRequired == true && val.isCompany == false) {

              const dialogRef = this._dialog.open(IndividualKycReviewDialogComponent, {
                data: { IndividualID: val.ID },
                disableClose: true,
                width: '900px',
                backdropClass: 'DialogBluebackGround'
              });

              dialogRef.afterClosed().subscribe((dialogResult: any) => {
                if (dialogResult == true) {
                  this._dialogRef.close(val);
                }
              });
            } else {
              this._dialogRef.close(val);
            }
          } else {
            return;
          }
        });
      }
      else {
        if (!val.hasOwnProperty("isWalkin")) {
          val['isWalkin'] = false;
        }
        if (val['isWalkin'] == false) {
          if (val.onhold == true) {
            this.translate.get("On Hold - reason").subscribe(res => {
              this._notificationService.info(res + ": " + val.onholdText);
            });
            return;
          } else if (val.Suspicious == true) {
            this.translate.get("Suspicious - reason").subscribe(res => {
              this._notificationService.info(res + ": " + val.SuspiciousText);
            });
          }
        }
        if (val.isSelf != true && val.isKYCReviewRequired == true && val.isCompany == true) {
          const dialogRef = this._dialog.open(CompanyKycReviewDialogComponent, {
            data: { CompanyID: val.ID },
            disableClose: true,
            width: '900px',
            backdropClass: 'DialogGreenBackGround'
          });

          dialogRef.afterClosed().subscribe((dialogResult: any) => {
            if (dialogResult == true) {
              this._dialogRef.close(val);
            }
          });
        } else if (val.isSelf != true && val.isKYCReviewRequired == true && val.isCompany == false) {

          const dialogRef = this._dialog.open(IndividualKycReviewDialogComponent, {
            data: { IndividualID: val.ID },
            disableClose: true,
            width: '900px',
            backdropClass: 'DialogBluebackGround'
          });

          dialogRef.afterClosed().subscribe((dialogResult: any) => {
            if (dialogResult == true) {
              this._dialogRef.close(val);
            }
          });
        } else {
          this._dialogRef.close(val);
        }
      }
    }
    else {
      this._dialogRef.close(val);
    }
  }

  CloseDialog(val) {

    if (this.data.isForInvoice == true) {
      if (!val.hasOwnProperty("isWalkin")) {
        val['isWalkin'] = false;
      }
      if (val['isWalkin'] == false) {
        if (val.onhold == true) {
          this.translate.get("On Hold - reason").subscribe(res => {
            this._notificationService.error(res + ": " + val.onholdText);
          });
          return;
        } else if (val.Suspicious == true) {
          this.translate.get("Suspicious - reason").subscribe(res => {
            this._notificationService.info(res + ": " + val.SuspiciousText);
          });
        }
      }
      if (val.isKYCReviewRequired == true && val.isCompany == true) {
        const dialogRef = this._dialog.open(CompanyKycReviewDialogComponent, {
          data: { CompanyID: val.ID },
          disableClose: true,
          width: '900px',
          backdropClass: 'DialogGreenBackGround'
        });

        dialogRef.afterClosed().subscribe((dialogResult: any) => {
          if (dialogResult == true) {
            this._dialogRef.close(val);
          }
        });
      } else if (val.isKYCReviewRequired == true && val.isCompany == false) {

        const dialogRef = this._dialog.open(IndividualKycReviewDialogComponent, {
          data: { IndividualID: val.ID },
          disableClose: true,
          width: '900px',
          backdropClass: 'DialogBluebackGround'
        });

        dialogRef.afterClosed().subscribe((dialogResult: any) => {
          if (dialogResult == true) {
            this._dialogRef.close(val);
          }
        });
      }
    } else {
      this._dialogRef.close(val);
    }
  }



  KYCReview() {
    var val = this.SelectedCustomer;
    if (val.isCompany == true) {
      const dialogRef = this._dialog.open(CompanyKycReviewDialogComponent, {
        data: { CompanyID: val.ID },
        disableClose: true,
        width: '900px',
        backdropClass: 'DialogGreenBackGround'
      });

      dialogRef.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult == true) {
          this.gridCmp.refreshDataAndView();
          this.gridInd.refreshDataAndView();
          // this._dialogRef.close(val);
        }
      });
    } else if (val.isCompany == false) {

      const dialogRef = this._dialog.open(IndividualKycReviewDialogComponent, {
        data: { IndividualID: val.ID },
        disableClose: true,
        width: '900px',
        backdropClass: 'DialogBluebackGround'
      });

      dialogRef.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult == true) {
          this.gridCmp.refreshDataAndView();
          this.gridInd.refreshDataAndView();
          // this._dialogRef.close(val);
        }
      });
    }
  }

  fnTranslate() {
    for (let index = 0; index < this.gridOptionsCmp.colModel.length; index++) {
      if (this.gridOptionsCmp.colModel[index].title) {
        this.translate.get(this.gridOptionsCmp.colModel[index].title.toString()).subscribe(translationValue => {
          this.gridOptionsCmp.colModel[index].title = translationValue;
        });
      }
    }
    this.translate.get(this.gridOptionsCmp.title).subscribe(translationValue => {
      this.gridOptionsCmp.title = translationValue;
    });

    for (let index = 0; index < this.gridOptionsInd.colModel.length; index++) {
      if (this.gridOptionsInd.colModel[index].title) {
        this.translate.get(this.gridOptionsInd.colModel[index].title.toString()).subscribe(translationValue => {
          this.gridOptionsInd.colModel[index].title = translationValue;
        });
      }
    }
    this.translate.get(this.gridOptionsInd.title).subscribe(translationValue => {
      this.gridOptionsInd.title = translationValue;
    });
  }

  onChangeCustomerType() {
    if (this.isIndividual) {
      this.gridOptionsInd.dataModel.postData = {
        OwnerID: GetUserObject.GetOwnerID(), isIndividual: true, KeyWord: this.keyWord
      };
    }
    else {
      this.gridOptionsCmp.dataModel.postData = {
        OwnerID: GetUserObject.GetOwnerID(), isIndividual: false, KeyWord: this.keyWord
      };
    }
  }

  search() {
    this.onChangeCustomerType();
  }
}
