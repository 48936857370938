<div class="header" [ngClass]="{'orange': !data.isReceived , 'dark-blue' : data.isReceived }">
    <!-- <button mat-raised-button type="button" color="primary" class="add-dark-hover btn-default pull-right"
        (click)="ResetCalculator()">Reset</button> -->
    <h4 class="flex">
        <span align="left" class="m-l-r-10"> {{'Enter Denomination' | translate}}&nbsp;<span
                *ngIf="data.isReceived">{{'Received' | translate}}</span><span *ngIf="!data.isReceived">{{'Paid' |
                translate}}</span>
            <span></span> </span>

        <span class="pull-right m-l-r-10"><button type="button" class="close pull-right" (click)="onNoClick()"
                aria-label="Close">
                <span aria-hidden="true" style="color: white;">&times;</span>
            </button></span>
    </h4>
</div>


<div mat-dialog-content [ngClass]="{'sell-background': !data.isReceived , 'buy-background' : data.isReceived }">
    <h4>{{'Amount' | translate}}: {{data.CalculatorData.In | numberFormat}} {{data.CalculatorData.Currency}}
        <button mat-raised-button type="button" style="margin-bottom: 10px;" class="btn btn-success pull-right"
            (click)="fnAutoFill()" [style.cursor]="data.isReceived == true ? 'not-allowed' :'pointer'"
            [disabled]="data.isReceived == true">
            {{'Auto Fill' | translate}}
        </button>

        <button mat-raised-button type="button" style="margin-bottom: 10px;" class="btn btn-success pull-right m-r-5"
            (click)="fnAutoFillBasedOnEstimate()" [style.cursor]="data.isReceived == true ? 'not-allowed' :'pointer'"
            [disabled]="data.isReceived == true">
            {{'Auto Fill Based on Estimated Quantity' | translate}}
        </button>
    </h4>
    <div class="row">
        <div class="col-xs-12">
            <div class="table-responsive">
                <table class="table table-bordered table-hover">
                    <thead class="tbl-head thead">
                        <tr>
                            <th>{{'Quantity' | translate}}</th>
                            <th>{{'Denomination' | translate}}</th>
                            <th *ngIf="!showAllDenominations">{{'Estimated Available Qunatity' | translate}}</th>
                            <th>{{'Value' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="DenominationValue.length == 0">
                            <td colspan="3">{{'No denominations' | translate}}</td>
                        </tr>
                        <tr *ngFor="let item of DenominationValue;let idx = index">
                            <td>
                                <cxr-generic-number-input [(ngModel)]="item.Quantity" numberType="4" name=""
                                    (ngModelChange)="DenominationChange($event,item)">
                                </cxr-generic-number-input>
                            </td>
                            <td>
                                <input type="text" tabindex="-1" class="form-control"
                                    (ngModelChange)="DenominationChange($event,item)"
                                    [value]="item.Denomination |  numberFormat : 2"
                                    style="font-weight: bold;text-align: right;" readonly>
                            </td>
                            <td *ngIf="!showAllDenominations">
                                <input type="text" tabindex="-1" [value]="item.AvailableQuantity | numberFormat : 0"
                                    readonly class="form-control">

                            </td>
                            <td>
                                <input type="text" tabindex="-1" [value]="item.Value | numberFormat : 2" tabindex="-1"
                                    (ngModelChange)="DenominationChange($event,item)" class="form-control text-right"
                                    readonly>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<mat-dialog-actions *ngIf="DenominationValue.length !=0">
    <div class="col-xs-12">
        <div class="row">
            <table class="table table-bordered">
                <tr>
                    <td colspan="2"><b>{{'Total' | translate}}</b></td>
                    <td class="text-right"><b>{{totalValue | numberFormat}}</b></td>
                </tr>
            </table>
        </div>
    </div>
</mat-dialog-actions>
<mat-dialog-actions align="end">
    <button mat-raised-button type="button" class="add-dark-hover btn-default" (click)="onNoClick()">{{'Cancel' |
        translate}}</button>
    <button mat-raised-button class="add-hover btn-secondary" type="button" (click)="fnFixDiscrepancy()">
        {{'Fix Discrepancy' | translate}}</button>
    <button mat-raised-button class="add-hover btn-success" type="button" (click)="onSubmit()">{{'Save' |
        translate}}</button>
</mat-dialog-actions>
