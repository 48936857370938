<mat-card class="mat-card-graph">
    <div class="row">
        <div fxFlex="100" fxFlex.gt-sm fxFlex.sm class="flex-p w-100">
            <div class="tableFixHead table-responsive scroll">
                <div class="col-md-12 col-sm-12 custom-band">
                    {{'Recent AML Alerts' | translate}}
                </div>
                <table class="table table-striped table-bordered table-hover">
                    <thead style="background: #fa867a!important;color:#eee">
                        <tr>
                            <th>{{'Alert ID' | translate}}</th>
                            <th style="width: 25%;">{{'Alert Date' | translate}}</th>
                            <th style="width: 25%;">{{'Type' | translate}}</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let alert of alertList;let i = index">
                            <td class="hyper-link"><span (click)="updateAMLAlert(alert.ID)">{{alert.ID}}</span></td>
                            <td>{{alert.AlertDate}}</td>
                            <td
                                [ngStyle]="{background : alert.Status == 1 ? '#99bc8c' :  alert.Status == 0 ? '#fb9691' : '#d5d5d5'}">
                                {{alert.Type}}
                            </td>
                        </tr>
                        <tr *ngIf="alertList?.length == 0">
                            <td colspan="5" style="text-align:center">{{'No records found' | translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</mat-card>
