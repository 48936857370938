import { Pipe, PipeTransform } from '@angular/core';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';

@Pipe({
    name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
    transform(value: any, exponent?: number): string {
        if (value === undefined || value === null) {
            return '';
        }


        if (isNaN(value)) return '';
        let format = GetUserObject.GetUserObject().NumberFormat || 1;
        // Convert value to number
        // value = Number(value);

        if (format === 1) {
            // Apply formatting logic for format 1
            return this.formatWithComma(value, exponent);
        } else if (format === 2) {
            // Apply formatting logic for format 2
            return this.formatWithApostrophe(value, exponent);
        } else if (format === 3) {
            // Apply formatting logic for format 3
            return this.formatWithSpace(value, exponent);
        }

        // Default to format 1 if format is not recognized
        return this.formatWithComma(value, exponent);
    }

    private formatWithComma(value: number, exponent: number): string {
        let formattedNumber: string;

        if (exponent === 0) {
            formattedNumber = value.toFixed(0);
        } else if (exponent == null || exponent === undefined) {
            formattedNumber = value.toString();
        } else {
            formattedNumber = value.toFixed(exponent);
        }

        const parts = formattedNumber.split('.');
        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        const decimalPart = parts.length > 1 ? parts[1] : '';

        return decimalPart === '' ? integerPart : integerPart + '.' + decimalPart;
    }

    private formatWithApostrophe(value: number, exponent: number | null | undefined): string {
        let formattedNumber: string;

        if (exponent === 0) {
            formattedNumber = value.toFixed(0);
        } else if (exponent == null || exponent === undefined) {
            formattedNumber = value.toString();
        } else {
            formattedNumber = value.toFixed(exponent);
        }

        const parts = formattedNumber.split('.');
        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        const decimalPart = parts.length > 1 ? parts[1] : '';

        return decimalPart === '' ? integerPart : integerPart + ',' + decimalPart;
    }




    private formatWithSpace(value: number, exponent: number): string {
        let formattedNumber: string;

        if (exponent === 0) {
            formattedNumber = value.toFixed(0);
        } else if (exponent == null || exponent === undefined) {
            formattedNumber = value.toString();
        } else {
            formattedNumber = value.toFixed(exponent);
        }

        const parts = formattedNumber.split('.');
        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        const decimalPart = parts.length > 1 ? parts[1] : '';

        return decimalPart === '' ? integerPart : integerPart + ',' + decimalPart;
    }
}
