<div mat-dialog-title>
    <span *ngIf="!data">{{'Add Note Template' | translate}}</span>
    <span *ngIf="data">{{'Update Note Template' | translate}}</span>
    <button type="button" class="close pull-right" (click)="close()" aria-label="Close">
        <span aria-hidden="true" class="text-black">&times;</span>
    </button>
</div>

<div mat-dialog-content>
    <div class="col-xs-12 padding-top">
        <form [formGroup]="NoteTemplateForm" (ngSubmit)="onSubmit(false)">
            <div class="row">
                <div class="col-xs-12 col-sm-6">
                    <div class="form-group">
                        <label class="mandatory">{{'Note Template Name' | translate}}:</label>
                        <input type="text" class="form-control" placeholder="{{'Note Template Name' | translate}}"
                            maxlength="100" formControlName="NoteCode"
                            [ngClass]="{ 'is-invalid': submitted && f.NoteCode.errors }">
                        <div *ngIf="submitted && f.NoteCode.errors" class="invalid-feedback">
                            <div *ngIf="f.NoteCode.errors.required">{{'Required' | translate}}</div>
                            <div *ngIf="!f.NoteCode.hasError('required') && f.NoteCode.hasError('cannotContainSpace')">
                                {{'Can not contain white space' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12">
                    <div class="form-group">
                        <label class="mandatory">{{'Description' | translate}}:</label>
                        <!-- <textarea class="form-control" placeholder="{{'Description' | translate}}" maxlength="100"
                            formControlName="Note" [ngClass]="{ 'editor-invalid-focus': submitted && f.Note.errors }"></textarea> -->
                        <!-- <ejs-richtexteditor [ngClass]="{ 'is-invalid': submitted && f.Note.errors }" height="300"
                            formControlName="Note" #toolsRTE [toolbarSettings]='tools'>
                        </ejs-richtexteditor> -->
                        <!-- <angular-editor id="editor2" [config]="editorConfig"></angular-editor>
                         -->
                        <cxr-rich-text-editor [Placeholder]="'Description'" formControlName="Note"
                            placeholder="{{'Description' | translate}}" [Type]="1">
                        </cxr-rich-text-editor>
                        <div *ngIf="submitted && f.Note.errors" class="invalid-feedback">
                            <div *ngIf="f.Note.errors.required">{{'Required' | translate}}</div>
                            <div *ngIf="!f.Note.hasError('required') && f.Note.hasError('cannotContainSpace')">
                                {{'Can not contain white space' | translate }}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <ckeditor [editor]="Editor" data="<p>Hello, world!</p>"></ckeditor> -->
                <div class="row m-t-20">
                    <div class="col-xs-12">
                        <button type="submit" color="primary" mat-raised-button class="m-b-10 pull-right btn-success">
                            <i class="fa fa-save m-r-5"></i>{{'Save' | translate}} </button>
                        <button *ngIf="!data" mat-raised-button type="button" (click)="onSubmit(true)"
                            class="pull-right m-r-5 btn-success">{{'Save
                            & Add More'|
                            translate}}
                        </button>
                        <button mat-raised-button type="button" (click)="close()"
                            class="pull-right m-r-5 btn-secondary">{{'Cancel' | translate}}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
