<h4 mat-dialog-title>{{'Pick Note' | translate}}
    <button type="button" class="close pull-right" (click)="onNoClick()" aria-label="Close">
        <span aria-hidden="true" class="text-black">&times;</span>
    </button>
</h4>
<div class="row">
    <div class="col-xs-12">
        <pqgrid [GridID]="GridName" #gridKey [options]="gridOptions"></pqgrid>
    </div>
</div>
