import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Localisation } from 'src/app/_services/localisation';
import { GetUserObject } from '../_helpers/get-user-object';

@Injectable({ providedIn: 'root' })
export class ToolBarResolver implements Resolve<any> {
    constructor (private translate: TranslateService,) {
        translate.addLangs(Localisation.supportLanguages);
        const browserLang = GetUserObject.getLanguage();
        translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.translate.get(['Reset filters', 'Export CSV', 'Export PDF', 'Open Cases', 'Closed Cases',
            'Enter your keyword', 'Back to Summary Page', 'Filter', 'Click to delete', 'Click to edit',
            'New Customer', 'Create Cash Accounts', 'Click to Settle', 'Click to void', 'Add Row', 'Add Denomination', 'Settle', 'Shortcut to add row ( forward or back slash )', 'KYC Review', 'AML Review', 'Choose customers', 'Attachment', 'Pivot', 'Normal', 'Apply AML Rules', 'Currencies Rate Board', 'Copy Currencies Rate Board Link', 'Use Inverse Rates', 'Save Changes', 'Pick Non Branch', 'Change Sequence Order', 'Export All(PDF)', 'Export All(CSV)', 'Use Inverse Rates', 'Save Changes', 'Pick Non Branch', 'Change Order', 'Export All', 'Match Customer', 'Change Role Permissions', 'Create Invoice', 'Create Customer', 'Pending', 'Paid', 'Click to view', 'All', 'Print', 'Accepted', 'Completed', 'Copy Rate Board URL', 'Copy Rates API URL', 'Update rates', 'Rate Board', 'Common Rates', 'Cancel Booking', 'Scan Document']); //
    }
}
