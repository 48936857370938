<mat-card class="mat-card-graph card-padding">
    <div class="row">
        <div fxFlex="100" fxFlex.gt-sm fxFlex.sm class="flex-p w-100 ">
            <div class="tableFixHead table-responsive scroll">
                <div class="col-md-12 col-sm-12 custom-band">

                    {{'Transaction Volume for Favorite Currencies' | translate}}


                    <div class="col-md-3 col-sm-3  pull-right ">
                        <mat-select class=" form-group form-control dropdown" (selectionChange)="Changedays($event)"
                            placeholder="{{'Select' | translate}}" [value]='DefaultCurrenciesValue'>
                            <mat-option *ngFor="let item of FilterDropdown" [value]="item.value">{{item.name |
                                translate}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
                <table class="table table-striped table-bordered table-hover table-scrollbar">
                    <thead style="background: #1ba4fc!important;color:#eee">
                        <tr>
                            <th>{{'Flag' | translate}}</th>
                            <th>{{'Code' | translate}}</th>
                            <th style="width: 25%;">{{'Volume Bought' | translate}}</th>
                            <th style="width: 25%;">{{'Volume Sold' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of tableList;let i = index">
                            <td class="text-align-center"> <img *ngIf="!last" [src]="item.Flag" [alt]="item.currency"
                                    style="width:40px;"></td>
                            <td>{{item.Currency}}</td>
                            <td>{{item.TotalDebitLC | numberFormat:2}}</td>
                            <td>{{item.TotalCreditLC | numberFormat:2}}</td>
                        </tr>
                        <tr *ngIf="tableList?.length == 0">
                            <td colspan="5" style="text-align:center">{{'No records found' | translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</mat-card>
