<button mat-icon-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
    <mat-icon>account_circle</mat-icon>
</button>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu user-menu">
    <!--<span (mouseleave)="userMenuTrigger.closeMenu()">-->
    <!-- <mat-toolbar color="primary">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">
                <img [src]="userImage" alt="user-image" width="80" class="rounded mat-elevation-z6">
                <p>Emilio Verdines <br> <small>Web developer</small></p>
            </div>
        </mat-toolbar>
        <a mat-menu-item routerLink="profile">
            <mat-icon>person</mat-icon>
            <span>Profile</span>
        </a>
        <a mat-menu-item routerLink="settings">
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
        </a>
        <a mat-menu-item routerLink="/">
            <mat-icon>lock</mat-icon>
            <span>Lock screen</span>
        </a>
        <a mat-menu-item routerLink="help">
            <mat-icon>help</mat-icon>
            <span>Help</span>
        </a> -->
    <!-- <div class="divider"></div> -->
    <!-- <a mat-menu-item (click)="addEditRole(1)">
        <mat-icon>account_circle</mat-icon>
        <span>Licensing</span>
    </a> -->
    <a mat-menu-item (click)="logout()">
        <mat-icon>power_settings_new</mat-icon>
        <span>Log out</span>
    </a>
    <!--</span>-->
</mat-menu>



<!--Add edit Note modal pop up-->
<div bsModal #addEditRoleModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-sizes-name1" [config]="{backdrop: 'static'}">
    <bs-modal-backdrop></bs-modal-backdrop>
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Licensing</h4>
                <button type="button" class="close pull-right" (click)="addEditRole(0)" aria-label="Close">
                    <span aria-hidden="true" class="text-white">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="form-group">
                            <label class="mandatory">Client Code:</label>
                            <input type="text" class="form-control" placeholder="Client Code">
                            <!-- <div *ngIf="submitted && f.RoleName.errors" class="invalid-feedback">
                                <div *ngIf="f.RoleName.errors.required">Role Name is required</div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-xs-12">
                        <div class="form-group">
                            <label class="mandatory">Client key:</label>
                            <input type="text" class="form-control" placeholder="Client key">
                            <!-- <div *ngIf="submitted && f.RoleName.errors" class="invalid-feedback">
                                <div *ngIf="f.RoleName.errors.required">Role Name is required</div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-xs-12">
                        <div class="form-group">
                            <label class="mandatory" for="Status">Base Currency:</label>
                            <br>
                            <mat-select placeholder="Select Status" class="form-control">
                                <mat-option value="null" selected disabled>Select</mat-option>
                                <mat-option value="true">USD</mat-option>
                                <mat-option value="true">CAD</mat-option>
                                <mat-option value="true">INR</mat-option>
                                <mat-option value="true">GBP</mat-option>
                                <mat-option value="true">AAA</mat-option>
                            </mat-select>
                            <!--   <div *ngIf="submitted && f.isActive.errors" class="invalid-feedback">
                                <div *ngIf="f.isActive.errors.required">Role Status is required</div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-12">
                        <button mat-raised-button type="submit" class="pull-right btn-success">Save</button>
                        <button mat-raised-button type="button" (click)="addEditRole(0)"
                            class="pull-right m-r-5 btn-secondary">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>