import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { DenominationModel } from 'projects/cxr-crm/src/app/pages/_models/admin/demomination.model';
import { DecimalValidation, NullNumberToNumber } from 'src/app/_helpers/decimal.validation';
import { Localisation } from 'src/app/_services/localisation';
import { NotificationService } from 'src/app/shared/_services/notification.service';
import { SharedService } from '../shared.service';

export class DenominationCalculations {
  Quantity: number = null;
  Denomination: number = null;
  Value: number = null;
  AvailableQuantity: number = null;
}
@Component({
  selector: 'cxr-denomination-calculator',
  templateUrl: './denomination-calculator.component.html',
  styleUrls: ['./denomination-calculator.component.scss']
})
export class DenominationCalculatorComponent implements OnInit {

  DenominationValue: DenominationCalculations[] = [];
  MainDenominationList: DenominationCalculations[] = [];
  public showAllDenominations: boolean = false;
  constructor (
    private _dialogRef: MatDialogRef<DenominationCalculatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _notificationService: NotificationService,
    private translate: TranslateService,
    private _sharedService: SharedService
  ) {
    translate.addLangs(Localisation.supportLanguages);
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
    _dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.fetchDenominations();
  }
  fnAutoFill() {
    this.DenominationValue = this.MainDenominationList;
    let y = NullNumberToNumber.toNumber(this.data.CalculatorData.In);
    this.DenominationValue.forEach(element => {
      let x = NullNumberToNumber.toNumber(element.Denomination);
      var quotient = NullNumberToNumber.toNumber(Math.floor(NullNumberToNumber.toNumber(y) / NullNumberToNumber.toNumber(x)));
      var remainder = NullNumberToNumber.toNumber((NullNumberToNumber.toNumber(y) % NullNumberToNumber.toNumber(x)).toFixed(2));
      y = remainder;
      element.Quantity = quotient == 0 ? null : NullNumberToNumber.toNumber(quotient);
      element.Value = NullNumberToNumber.toNumber(element.Denomination) * NullNumberToNumber.toNumber(element.Quantity);
      element.Value = element.Value == 0 ? null : NullNumberToNumber.toNumber(element.Value);
    });
    this.totalValue = NullNumberToNumber.toNumber(_.sumBy(this.DenominationValue, 'Value').toFixed(2));
  }

  fnFixDiscrepancy() {
    if (this.totalValue == 0) {
      this.translate.get("Cannot fix the discrepancy when total is zero").subscribe(res => {
        this._notificationService.error(res);
      });
      return;
    }
    this.data.CalculatorData.In = NullNumberToNumber.toNumber(_.sumBy(this.DenominationValue, 'Value').toFixed(2));
  }
  onNumberFieldKeyPress($evt) {
    return DecimalValidation.isIntegerKey($evt);
  }
  totalValue: number = 0;
  DenominationChange(e, item) {
    this.DenominationValue.find(x => x.Denomination == item.Denomination).Value = NullNumberToNumber.toNumber(e) * NullNumberToNumber.toNumber(item.Denomination);
    this.totalValue = NullNumberToNumber.toNumber(_.sumBy(this.DenominationValue, 'Value').toFixed(2));
  }

  fetchDenominations() {
    this.DenominationValue = [];
    this.data.CalculatorData.OwnerID = GetUserObject.GetOwnerID();
    var editData = [];

    if (this.data.rowData != null && this.data.rowData?.DenominationsInOutList != null) {
      editData = this.data.rowData.DenominationsInOutList;
    }
    this._sharedService.fnGetDenominations(this.data.CalculatorData).subscribe(
      data => {
        data.Data.forEach((element: DenominationModel) => {
          let denom = new DenominationCalculations();
          let editquantity = editData.find(x => x.Denomination == element.Value);
          denom.Quantity = editquantity != null ? (editquantity.Quantity == 0 ? null : editquantity.Quantity) : null;
          denom.Denomination = element.Value;
          denom.AvailableQuantity = element.AvailableQuantity;
          denom.Value = editquantity != null ? (editquantity.Value == 0 ? null : editquantity.Value) : null;
          this.DenominationValue.push(denom);
        });
        this.DenominationValue = this.DenominationValue.sort((a, b) => {
          return b.Denomination > a.Denomination ? 1 : -1;
        });
        this.MainDenominationList = this.DenominationValue;
        this.totalValue = NullNumberToNumber.toNumber(_.sumBy(this.DenominationValue, 'Value').toFixed(2));

      }
    );
  }
  onNoClick(): void {
    this._dialogRef.close();
  }

  onSubmit() {

    if (NullNumberToNumber.toNumber(this.data.CalculatorData.In) != NullNumberToNumber.toNumber(this.totalValue) && NullNumberToNumber.toNumber(this.totalValue) != 0 && (!this.data.isCashTransfer || NullNumberToNumber.toNumber(this.data.CalculatorData.In) > 0)) {
      this.translate.get("Denomination does not match total").subscribe(res => {
        this._notificationService.error(res);
      });
      // this._snackBar.open('Denomination doesnot match total', '', {
      //   duration: 3000,
      //   panelClass: 'redSnackBar'
      // });
      return;
    }

    let Obj = {
      Amount: this.data.CalculatorData.In,
      TotalValue: this.totalValue,
      DenominationsInOutList: this.totalValue == 0 ? null : this.DenominationValue
    };
    this._dialogRef.close(Obj);
  }

  fnAutoFillBasedOnEstimate() {
    let DenominationValue = this.DenominationValue.filter(x => x.AvailableQuantity > 0);
    let y = NullNumberToNumber.toNumber(this.data.CalculatorData.In);
    DenominationValue.forEach(element => {
      let x = NullNumberToNumber.toNumber(element.Denomination);
      var quotient = NullNumberToNumber.toNumber(Math.floor(NullNumberToNumber.toNumber(y) / NullNumberToNumber.toNumber(x)));
      if (quotient > 0 && quotient > element.AvailableQuantity) {
        quotient = element.AvailableQuantity;
      }
      var remainder = NullNumberToNumber.toNumber((NullNumberToNumber.toNumber(y) - NullNumberToNumber.toNumber(x * quotient)).toFixed(2));
      y = remainder;
      element.Quantity = quotient == 0 ? null : NullNumberToNumber.toNumber(quotient);
      element.Value = NullNumberToNumber.toNumber(element.Denomination) * NullNumberToNumber.toNumber(element.Quantity);
      element.Value = element.Value == 0 ? null : NullNumberToNumber.toNumber(element.Value);
    });
    this.totalValue = NullNumberToNumber.toNumber(_.sumBy(DenominationValue, 'Value').toFixed(2));
  }
}
