<!-- <div class="col-xs-12">
    <h3 style="margin:0px;">
        <span
            *ngIf="companyValue.CompanyName != undefined && companyValue.CompanyName != ''  && companyValue.CompanyName != null">
            {{f.CompanyName.value}}
        </span>
    </h3>
</div> -->
<div class="col-xs-12">
    <form [formGroup]="companyAddEditForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory">Company Name:</label>
                    <input type="text" class="form-control" placeholder="Company Name" formControlName="CompanyName"
                        [ngClass]="{ 'is-invalid': submitted && f.CompanyName.errors }">
                    <div *ngIf="submitted && f.CompanyName.errors" class="invalid-feedback">
                        <div *ngIf="f.CompanyName.errors.required">required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory">Type of Business:</label>
                    <input type="text" class="form-control" placeholder="Type of Business"
                        formControlName="TypeOfBusiness"
                        [ngClass]="{ 'is-invalid': submitted && f.TypeOfBusiness.errors }">
                    <div *ngIf="submitted && f.TypeOfBusiness.errors" class="invalid-feedback">
                        <div *ngIf="f.TypeOfBusiness.errors.required">required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory">Incorporation #:</label>
                    <input type="text" class="form-control" placeholder="Incorporation No."
                        formControlName="IncorporationNumber"
                        [ngClass]="{ 'is-invalid': submitted && f.IncorporationNumber.errors }">
                    <div *ngIf="submitted && f.IncorporationNumber.errors" class="invalid-feedback">
                        <div *ngIf="f.IncorporationNumber.errors.required">required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory">Incorporation Date:</label>
                    <cxr-prime-calendar formControlName="IncorporationDate"
                        [ngClass]="{ 'is-date-invalid': submitted && f.IncorporationDate.errors }">
                    </cxr-prime-calendar>
                    <div *ngIf="submitted && f.IncorporationDate.errors" class="invalid-feedback">
                        <div *ngIf="f.IncorporationDate.errors.required">required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory">Reg. State/Province:</label>
                    <input type="text" class="form-control" placeholder="Registration State/Province"
                        formControlName="RegistrationStateProvince"
                        [ngClass]="{ 'is-invalid': submitted && f.RegistrationStateProvince.errors }">
                    <div *ngIf="submitted && f.RegistrationStateProvince.errors" class="invalid-feedback">
                        <div *ngIf="f.RegistrationStateProvince.errors.required">required
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory" for="Country">Country:</label>
                    <mat-select placeholder="Select Country" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.Country.errors }" formControlName="Country">
                        <mat-option [value]="null" selected>Select</mat-option>
                        <mat-option *ngFor="let item of countriesList" [value]="item.name">
                            {{item.alpha2Code}} - {{item.name}}
                        </mat-option>
                    </mat-select>
                    <div *ngIf="submitted && f.Country.errors" class="invalid-feedback">
                        <div *ngIf="f.Country.errors.required">Required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory">MSB Registration #:</label>
                    <input type="text" class="form-control" placeholder="MSB Registration #"
                        formControlName="MSBRegistrationNumber"
                        [ngClass]="{ 'is-invalid': submitted && f.MSBRegistrationNumber.errors }">
                    <div *ngIf="submitted && f.MSBRegistrationNumber.errors" class="invalid-feedback">
                        <div *ngIf="f.MSBRegistrationNumber.errors.required">required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory">MSB Registration Date:</label>
                    <cxr-prime-calendar formControlName="MSBRegistrationDate"
                        [ngClass]="{ 'is-date-invalid': submitted && f.MSBRegistrationDate.errors }">
                    </cxr-prime-calendar>
                    <div *ngIf="submitted && f.MSBRegistrationDate.errors" class="invalid-feedback">
                        <div *ngIf="f.MSBRegistrationDate.errors.required">required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory">Work Phone:</label>
                    <input type="text" class="form-control" placeholder="Work Phone" formControlName="WorkPhone"
                        [ngClass]="{ 'is-invalid': submitted && f.WorkPhone.errors }">
                    <div *ngIf="submitted && f.WorkPhone.errors" class="invalid-feedback">
                        <div *ngIf="f.WorkPhone.errors.required">required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory">Work Fax:</label>
                    <input type="text" class="form-control" placeholder="Work Fax" formControlName="WorkFax"
                        [ngClass]="{ 'is-invalid': submitted && f.WorkFax.errors }">
                    <div *ngIf="submitted && f.WorkFax.errors" class="invalid-feedback">
                        <div *ngIf="f.WorkFax.errors.required">required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory">Email Address:</label>
                    <input type="text" class="form-control" placeholder="Work Fax" formControlName="EmailAddress"
                        [ngClass]="{ 'is-invalid': submitted && f.EmailAddress.errors }">
                    <div *ngIf="submitted && f.EmailAddress.errors" class="invalid-feedback">
                        <div *ngIf="f.EmailAddress.errors.required">required</div>
                        <div *ngIf="f.EmailAddress.hasError('invalidEmail') && f.EmailAddress.touched">Invalid Email
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory">Nature Of Business:</label>
                    <input type="text" class="form-control" placeholder="Nature Of Business"
                        formControlName="NatureOfBusiness"
                        [ngClass]="{ 'is-invalid': submitted && f.NatureOfBusiness.errors }">
                    <div *ngIf="submitted && f.NatureOfBusiness.errors" class="invalid-feedback">
                        <div *ngIf="f.NatureOfBusiness.errors.required">required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory" for="Status">Status:</label>
                    <mat-select placeholder="Select Status" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.isActive.errors }" formControlName="isActive">
                        <mat-option [value]="null" selected disabled>Select</mat-option>
                        <mat-option [value]="true">Active</mat-option>
                        <mat-option [value]="false">Inactive</mat-option>
                    </mat-select>
                    <div *ngIf="submitted && f.isActive.errors" class="invalid-feedback">
                        <div *ngIf="f.isActive.errors.required">Status is required</div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
