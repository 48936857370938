import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { responseModel } from '../_models/response.model';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  headers: { headers: any; };

  constructor (private _http: HttpClient) { }
  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return (this.headers = httpOptions);
  }


  fnGetEWireInfo(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }transactions/get-ewire-info`, val);
  }
  fnGetEWireInfoWOPin(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }transactions/get-ewire-info-wo-pin`, val);
  }

  fnFetchEWireInfo(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }transactions/fetch-ewire`, val);
  }
  fnSettleEWireSuperAgent(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }transactions/settle-ewire-super-agent`, val);
  }
  fnUnfetchEWire(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }transactions/unfetch-ewire`, val);
  }
  fnCancelEWire(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }transactions/cancel-ewire`, val);
  }

  fnGetEwires(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }transactions/get-ewires`, val);
  }

  fnGetEwireTransactionPreview(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }transactions/get-ewire-transactions-preview`, val);
  }
  //#endregion

  // #region Note Template 
  fnNoteTemplateDelete(values) {
    return this._http.post(environment.apiUrl + 'transactions/delete-note-template', values);
  }

  fnNoteTemplateUpsert(values) {
    return this._http.post(environment.apiUrl + 'transactions/upsert-note-template', values);
  }

  fnMarkEWireAsPaid(value) {
    return this._http.post<responseModel>(environment.apiUrl + 'transactions/mark-ewire-as-paid', value);
  }
  // #endregion
}

