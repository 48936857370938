import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AmlAlertsService } from '../../_services/aml-alerts.service';
import { AddEditAmlAlertDialogComponent } from '../../compliance/add-edit-aml-alert-dialog/add-edit-aml-alert-dialog.component';
import { ManagementReportsService } from '../../reports/_service/management-reports.service';

export interface LatestAlertsModel {
  ID: string;
  Description: string;
}

@Component({
  selector: 'cxr-recent-aml-alerts',
  templateUrl: './recent-aml-alerts.component.html',
  styleUrls: ['./recent-aml-alerts.component.scss']
})
export class RecentAmlAlertsComponent implements OnInit {

  alertList: Array<LatestAlertsModel> = [];
  constructor (
    private _managementReportsService: ManagementReportsService,
    private _amlAlertService: AmlAlertsService,
    private _dialog: MatDialog,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._managementReportsService.fnGetLatestAMLAlerts().subscribe(data => {
      this.alertList = data.Data;
    });
  }

  updateAMLAlert(ID: string) {
    const dialogRef = this._dialog.open(AddEditAmlAlertDialogComponent, {
      data: {
        data: { AMLAlertID: ID },
        route: this._route
      },
    });
    this._amlAlertService.fnGetAMLAlert(ID).subscribe(res => {


    });
  }

}
