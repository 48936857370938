import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DecimalValidation, NullNumberToNumber } from 'src/app/_helpers';
import { ConfirmationDialogComponent } from 'src/app/shared-dialog/confirmation-dialog/confirmation-dialog.component';
import { GetUserObject } from '../../_helpers/get-user-object';
import { CurrencyModel } from '../../_models/currency/currency-model.model';
import { CurrencyService } from '../../_services/currency.service';

@Component({
  selector: 'cxr-change-manual-rates',
  templateUrl: './change-manual-rates.component.html',
  styleUrls: ['./change-manual-rates.component.scss']
})
export class ChangeManualRatesComponent implements OnInit {

  submitted: boolean = false;
  currencyList: Array<CurrencyModel>;
  currencySeleted: Array<CurrencyModel>;
  currencySeletedPreRates: Array<CurrencyModel>;
  currencies: Array<string> = [];
  currency: string;
  isMainCurrency: boolean = true;
  //Form Group
  Form: UntypedFormGroup;
  constructor (
    private _fb: UntypedFormBuilder,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<ChangeManualRatesComponent>,
    private _currencyService: CurrencyService,
  ) {
    _dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.formInit();
    this.getCurrencyList();
  }
  getCurrencyList() {
    this._currencyService.fnGetCurrencyRateList(GetUserObject.GetUserObject()).subscribe(
      data => {
        if (data['Success']) {
          this.currencyList = data['Data'];
          this.currencies = [];
          this.currencyList.forEach(x => {
            if ((this.currencies?.indexOf(x.FromCurrency) == -1) && (!this.isMainCurrency || x.isMainCurrency)) {
              this.currencies.push(x.FromCurrency);
            }
          });
        }
      });
  }

  formInit() {
    this.Form = this._fb.group({
      BuyAmount: new UntypedFormControl(),
      SellAmount: new UntypedFormControl(),
      Currency: new UntypedFormControl(),
    });
  }

  get f() {
    return this.Form.controls;
  }

  onSelectCurrency(currency: string) {
    this.currency = currency;
    this.currencySeleted = this.currencyList.filter(x => x.FromCurrency == currency);
    this.currencySeletedPreRates = this.currencyList.filter(x => x.FromCurrency == currency);
  }

  onNoClick(): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: null,
        buttonText: {
          ok: 'Ignore and Continue',
          cancel: 'Back to Form'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this._dialogRef.close("");
      }
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if ((this.f.BuyAmount.value == null || this.f.BuyAmount.value == 0) &&
      (this.f.SellAmount.value == null || this.f.SellAmount.value == 0)) {
      return;
    }
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Are you sure you wants to perform this action",
        buttonText: {
          ok: 'Confirm',
          cancel: 'Cancel'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this._currencyService.fnUpdateCurrencyManualRates({
          BuyRate: parseFloat(NullNumberToNumber.toNumber(this.f.BuyAmount.value).toString()),
          SellRate: parseFloat(NullNumberToNumber.toNumber(this.f.SellAmount.value).toString()),
          Currency: this.f.Currency.value
        }).subscribe(data => {
          if (data.Success) {
            this._dialogRef.close();
          }
        });

      } else { this._dialogRef.close(); }
    });
  }

  onDecimalNumberFieldKeyPress($evt) {
    return DecimalValidation.isDecimalNumberKey($evt);
  }
  applyRates() {
    this._currencyService.fnGetCurrencyRateList(GetUserObject.GetUserObject()).subscribe(
      data => {
        if (data['Success']) {
          let currencyList = data.Data;
          currencyList.forEach(x => {
            x.BuyRate = x.BuyRate + parseFloat(NullNumberToNumber.toNumber(this.f.BuyAmount.value).toString());
            x.SellRate += parseFloat(NullNumberToNumber.toNumber(this.f.SellAmount.value).toString());
          });
          this.currencySeleted = currencyList.filter(x => x.FromCurrency == this.currency);
        }
      });
  }

  onChangeMainCurrency(event) {
    this.isMainCurrency = event.checked;
    this.getCurrencyList();
  }
}
