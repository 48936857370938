<h4 mat-dialog-title>{{data.Heading}}
    <button type="button" class="close pull-right" (click)="onCloseDialog()" aria-label="Close">
        <span aria-hidden="true" class="text-black">&times;</span>
    </button>
</h4>

<div mat-dialog-content>
    <div class="row">
        <div class="" *ngFor="let item of ItemsToDisplay;let idx=index"
            [ngClass]="(item.Value?.length > 50 && item.Type != 'image') ?  'col-xs-12 col-sm-12 col-lg-6' :'col-xs-12 col-sm-6 col-lg-3'">
            <div class="form-group " *ngIf="item.Type != 'image'">
                <label class="colon">{{item.Title}}</label>
                <input *ngIf="(item.Value + '')?.length < 30" type="text" class="form-control" readonly
                    [value]="item.Value">
                <textarea *ngIf="item.Value?.length >= 30" [value]="item.Value" cols="30" class="form-control" rows="2"
                    readonly></textarea>
            </div>
            <div class="form-group" *ngIf="item.Type == 'image'">
                <img [src]="item.Value" class="img img-thumbnail img-thumbnail-sm" alt=""
                    (dblclick)="openImgInNewtab(item.Value)">
            </div>
        </div>
        <div class="col-xs-12 col-lg-12" *ngFor="let item of LongerTextItems">
            <div class="form-group ">
                <label class="colon">{{item.Title}}</label>
                <textarea [value]="item.Value" cols="30" class="form-control" rows="7" readonly></textarea>
            </div>
        </div>


    </div>
</div>