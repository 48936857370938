import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ReportsGuard implements CanActivate, CanActivateChild {

    constructor (
        private _router: Router,
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if ((!(GetUserObject.isAdmin() || GetUserObject.isOwner() || GetUserObject.isAllowToPrintReports()))) {
            this._router.navigateByUrl("/error");
            return false;
        }
        return true;

    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if ((!(GetUserObject.isAdmin() || GetUserObject.isOwner() || GetUserObject.isAllowToPrintReports()))) {
            return true;
        }
        return true;
    }
}
