import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalDirective } from 'ngx-bootstrap';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { AddressModel } from 'projects/cxr-crm/src/app/pages/_models/generic-models/generic-model';
import { PqgridComponent } from 'src/app/pqgrid.component';
import { ConfirmationDialogComponent } from 'src/app/shared-dialog/confirmation-dialog/confirmation-dialog.component';
import { GenericPreviewModel, PreviewGenericRowDataDialogComponent } from 'src/app/shared-dialog/preview-generic-row-data-dialog/preview-generic-row-data-dialog.component';
import { environment } from 'src/environments/environment';
import pq from 'src/paramquery';
import { GenericComponentService } from '../../_services/generic-component.service';

@Component({
  selector: 'cxr-request-address',
  templateUrl: './request-address.component.html',
  styleUrls: ['./request-address.component.scss']
})
export class RequestAddressComponent implements OnInit {

  // create: function () {
  //   //restore state of grid.
  //   var StateVal = localStorage.getItem('pq-grid' + self.GridName);
  //   this.loadState({ refresh: false, state: StateVal });
  // },
  @HostListener('window:beforeunload', ['$event'])
  saveGridState($event) {
    this.gridAddressReq.SaveState();
  }
  ngOnDestroy() {
    this.gridAddressReq.SaveState();
  }
  GridName: string = 'GenericAddress' + GetUserObject.GetUserID();

  @Input('InternalTableName') InternalTableName: string;
  @Input('InternalRecordID') InternalRecordID: number;
  @Input('countriesList') countriesList: any[] = [];
  @Input('AddressTypeDD') AddressTypeDD: any[] = [];

  @ViewChild('gridAddressReq', { static: false }) gridAddressReq: PqgridComponent;
  optionsGridAddress: pq.gridT.options;

  constructor (private _formBuilder: UntypedFormBuilder, private _dialog: MatDialog, private _genericComponentService: GenericComponentService) {
  }

  ngOnInit() {
    try {
      this.addressFormInit(new AddressModel());
      this.GridInit();
    } catch (error) {
    }

  }

  GridInit() {
    var self = this;
    this.optionsGridAddress = {
      title: "Address",
      scrollModel: { autoFit: true },
      // create: function () {
      //   //restore state of grid.
      //   var StateVal = localStorage.getItem('pq-grid' + self.GridName);
      //   this.loadState({ refresh: false, state: StateVal });
      // },
      toolbar: {
        items: [
          {
            type: 'button',
            icon: 'ui-icon-plus',
            cls: 'pq-add-button',
            label: '',
            listener: function () {
              self.addEditAddress(new AddressModel(), 1);
            }
          }
        ]
      },
      pageModel: {
        type: "remote", rPP: environment.pq_grid.default_rPP, curPage: 1, rPPOptions: [5, 10, 20, 50, 100],
        layout: []
        //layout: ['prev', 'next', "|", "strPage"]
        // layout: ['first', 'prev', 'next', 'last', "|", "strPage"]
      },
      filterModel: {
        on: false,
        header: false,
        type: 'remote',
        menuIcon: false
      },
      sortModel: {
        type: 'remote',
        single: false,
        sorter: [{ dataIndx: 'AddressID', dir: 'down' }],
        space: true,
        multiKey: null
      },
      colModel: [
        {
          title: "", editable: false, width: 25, sortable: false, align: "center", menuIcon: false,

          render: function (ui) {
            return "<a style='color: green;cursor: pointer;' title='Click to edit' class='edit_btn'><span>&#x270F;</span></a>\
            <a style='color: red;cursor: pointer;' title='Click to delete' class='delete_btn'><span>&#x274C;</span></a>";
          },
          postRender: function (ui) {
            var rowIndx = ui.rowIndx,
              grid = this,
              $cell = grid.getCell(ui);
            //edit button
            $cell.find(".edit_btn").off("click")
              .on("click", function (evt) {
                self.addEditAddress(ui.rowData, 1);
              });
            //delete button
            $cell.find(".delete_btn").off("click")
              .on("click", function (evt) {
                self.showConfirmationModal();
                self.deleteRowValue = ui.rowData;
                self.deleteRowType = 'address';
              });
          }
        },
        { title: "Type", dataType: "string", dataIndx: "AddressType", filter: { crules: [{ condition: 'range' }] } },
        { title: "Street", dataType: "string", dataIndx: "Street", filter: { crules: [{ condition: 'contain' }] } },
        { title: "City/Town", dataType: "string", dataIndx: "City", filter: { crules: [{ condition: 'contain' }] } },
        { title: "State/Province", dataType: "string", dataIndx: "StateProvince", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Country", dataType: "string", dataIndx: "Country", filter: { crules: [{ condition: 'contain' }] } },
        { title: "Zip Code", dataType: "string", dataIndx: "ZipCode", filter: { crules: [{ condition: 'contain' }] } },
        // {
        //   title: "Actions", hidden: true, editable: false, sortable: false, align: "center",
        //   render: function (ui) {
        //     return "<button type='button' class='edit_btn m-r-5'></button> <button type='button' class='delete_btn'></button>";
        //   },
        //   postRender: function (ui) {
        //     var rowIndx = ui.rowIndx,
        //       grid = this,
        //       $cell = grid.getCell(ui);
        //     //edit button
        //     $cell.find(".edit_btn").button({ icons: { primary: 'ui-icon-pencil' } }).off("click")
        //       .on("click", function (evt) {
        //         self.addEditAddress(ui.rowData, 1);
        //       });
        //     //delete button
        //     $cell.find(".delete_btn")
        //       .button({ icons: { primary: 'ui-icon-close' } }).off("click")
        //       .on("click", function (evt) {
        //         self.showConfirmationModal();
        //         self.deleteRowValue = ui.rowData;
        //         self.deleteRowType = 'address';
        //       });
        //   }
        // }
      ],
      selectionModel: { type: 'row' },

      rowDblClick: function (evt, ui) {
        var previewObj: GenericPreviewModel = new GenericPreviewModel();
        previewObj.Columns = this.colModel;
        previewObj.RowData = ui.rowData;
        previewObj.Heading = 'Grid Details Preview';
        const dialogRef = self._dialog.open(PreviewGenericRowDataDialogComponent, {
          data: previewObj,
          width: '1000px'
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      },
      // sort: function (evt, ui) {
      //   this.onSort(evt, ui);
      // },
      dataModel: {
        location: "remote",
        postData:
        {
          InternalRecordID: self.InternalRecordID,
          InternalTableName: self.InternalTableName
        },
        dataType: "JSON",
        method: "POST",
        url: environment.apiUrl + "generic/get-address-list",
        getData: function (dataJSON) {
          var data = JSON.parse(dataJSON.Data);
          return { curPage: data.curPage, totalRecords: data.totalRecords, data: data.data };
        }
      }
    };
  }


  // get Modal Pop-up data
  fnGetDropDownData() {

  }

  // get Modal Pop-up data ends here


  // Confirmation modal pop over
  @ViewChild('confirmationModal', { static: false }) confirmationModal: ModalDirective;
  deleteRowValue: any;
  deleteRowType: string;
  showConfirmationModal(): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Are you sure you want to delete this record?",
        isDelete: true,
        buttonText: {
          ok: 'Confirm',
          cancel: 'Decline'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteRecord();
      }
    });
  }

  hideConfirmationModal(): void {
    this.confirmationModal.hide();
  }
  // Confirmation modal pop over end here
  // Address code starts here
  AddressForm: UntypedFormGroup;

  addressSubmitted: boolean;
  AddressValue: AddressModel;
  @ViewChild('addEditAddressModal', { static: false }) addEditAddressModal: ModalDirective;
  addressFormInit(val: AddressModel) {
    this.AddressForm = this._formBuilder.group({
      AddressID: new UntypedFormControl(val.AddressID),
      InternalRecordID: new UntypedFormControl(this.InternalRecordID, [Validators.required]),
      InternalTableName: new UntypedFormControl(this.InternalTableName, [Validators.required]),
      // LinkID: new FormControl(null, Validators.required),
      AddressType: new UntypedFormControl(val.AddressType, Validators.required),
      Street: new UntypedFormControl(val.Street),
      City: new UntypedFormControl(val.City),
      StateProvince: new UntypedFormControl(val.StateProvince),
      Country: new UntypedFormControl(val.Country, [Validators.required]),
      ZipCode: new UntypedFormControl(val.ZipCode, [Validators.required, Validators.maxLength(10)]),
      isDeleted: new UntypedFormControl(false),
    });
  }
  // convenience getter for easy access to form fields
  get AddressF() {
    return this.AddressForm.controls;
  }
  onAddressFormSubmit() {
    this.addressSubmitted = true;
    // stop here if form is invalid
    if (this.AddressForm.invalid) {
      return;
    }
    this._genericComponentService.fnUpsertAddressRequest(this.AddressForm.value).subscribe(data => {
      if (data.Success) {
        this.AddressValue = new AddressModel();
        this.addressSubmitted = false;
        this.gridAddressReq.refreshDataAndView();
        this.addEditAddress(new AddressModel(), 0);
      }
    });
  }
  addEditAddress(val: AddressModel, flag) {
    this.addressSubmitted = false;
    // if (val != null) {
    //   this._genericComponentService.fnGetAddressData(val).subscribe(data => {
    //     this.addressFormInit(data.Data);
    //   });
    // }
    if (flag == 1) {
      this.addressFormInit(val);
      this.addEditAddressModal.show();
      // this._genericComponentService.fnGetRequestsAddressData(val).subscribe(data => {
      //   this.addressFormInit(data.Data);
      //   this.addEditAddressModal.show();
      // });
    }
    else
      this.addEditAddressModal.hide();
  }

  deleteRecord() {
    this._genericComponentService.fnDeleteAddressRequests(this.deleteRowValue).subscribe(data => {
      if (data.Success) {
        this.gridAddressReq.refreshDataAndView();
        this.hideConfirmationModal();
      }
      this.deleteRowValue = null;
      this.deleteRowType = null;
    });
  }
  // Address code ends here
}
