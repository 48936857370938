import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalDirective } from 'ngx-bootstrap';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { TransactionService } from 'projects/cxr-crm/src/app/pages/_services/transaction.service';
import { AddEditNoteTemplateComponent, NoteTemplateModel } from 'projects/cxr-crm/src/app/pages/transactions/note-templates/add-edit-note-template/add-note-template.component';
import { PqgridComponent } from 'src/app/pqgrid.component';
import { environment } from 'src/environments/environment';
import pq from 'src/paramquery';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { GenericPreviewModel } from '../preview-generic-row-data-dialog/preview-generic-row-data-dialog.component';

@Component({
  selector: 'cxr-note-picker-dialog',
  templateUrl: './note-picker-dialog.component.html',
  styleUrls: ['./note-picker-dialog.component.scss']
})
export class NotePickerDialogComponent implements OnInit {
  gridOptions: pq.gridT.options;
  @ViewChild('gridKey', { static: false }) grid: PqgridComponent;
  GridName: string = 'NotePicker' + GetUserObject.GetUserID();
  clickToEdit = 'Click to edit';
  clickToDelete = 'Click to delete';
  deleteRowValue: NoteTemplateModel;
  constructor (
    private _dialogref: MatDialogRef<NotePickerDialogComponent>,
    private _dialog: MatDialog,
    private _transactionService: TransactionService,

  ) {
    _dialogref.disableClose = true;
    var self = this;
    this.gridOptions = {
      scrollModel: { autoFit: true },
      title: 'Pick Note',
      height: "400",
      selectionModel: { type: 'row', mode: 'single', toggle: true },

      // Toolabar
      toolbar: {
        items: [
          {
            type: 'button',
            icon: 'ui-icon-plus',
            cls: 'pq-add-button',
            label: '',
            listener: function () {
              self.openAddEditNoteDialog(null);
            }
          },

          // {
          //   type: 'button',
          //   icon: 'ui-icon-refresh',
          //   label: this._route.snapshot.data.Data['Reset filters'],
          //   listener: function () {
          //     this.reset({ filter: true });
          //   }
          // },

        ]
      },

      freezeCols: 0,

      //Double Click Row data 
      rowDblClick: function (evt, ui) {
        var previewObj: GenericPreviewModel = new GenericPreviewModel();
        previewObj.Columns = this.colModel;
        previewObj.RowData = ui.rowData;
        previewObj.Heading = 'Grid Details Preview';
        if (previewObj) {
          self._dialogref.close({ 'NoteCode': ui.rowData.NoteCode, 'Note': ui.rowData.Note });
        }
      },

      // Grid Columns 
      colModel: [


        { title: 'Note Code', dataType: "string", dataIndx: "NoteCode", filter: { crules: [{ condition: 'contain' }] } },
        {
          title: 'Description', dataType: "string", width: "400", dataIndx: "Note", filter: { crules: [{ condition: 'contain' }] },
          render: function (ui) {
            return ui.rowData.Note;
          }
        }
      ],
      // Pagination For Grid 
      pageModel: { type: "remote", rPP: environment.pq_grid.default_rPP, curPage: 1, rPPOptions: [5, 10, 20] },
      filterModel: {
        on: true,
        header: true,
        type: 'remote',
        menuIcon: false
      },
      sortModel: {
        type: 'remote',
        single: false,
        sorter: [{ dataIndx: 'NoteTemplateID', dir: 'up' }],
        space: true,
        multiKey: null
      },

      // API call for Data fetch for Grid
      dataModel: {
        location: "remote",
        dataType: "JSON",
        method: "POST",
        postData: { OwnerID: GetUserObject.GetOwnerID() },
        url: environment.apiUrl + "transactions/get-note-template",
        getData: function (dataJSON) {
          var data = JSON.parse(dataJSON.Data);
          return { curPage: data.curPage, totalRecords: data.totalRecords, data: data.data };
        }
      }
    };


  }

  ngOnInit(): void {
  }

  //Add edit Date Ranges
  openAddEditNoteDialog(val: any): void {
    const dialogRef = this._dialog.open(AddEditNoteTemplateComponent, {
      data: val,

      width: "750px"
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.grid.refreshDataAndView();
      }
    });
  }
  //Confirmation Modal POPUP for delete Action
  @ViewChild('confirmationModal', { static: false }) confirmationModal: ModalDirective;
  showConfirmationModal(): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Are you sure you want to delete this record?",
        isDelete: true,
        buttonText: {
          ok: 'Confirm',
          cancel: 'Decline'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteKey(this.deleteRowValue);
      }
    });
  }
  // Delete row data funtion
  deleteKey(val: NoteTemplateModel) {
    this._transactionService.fnNoteTemplateDelete(val).subscribe(
      data => {
        this.grid.refreshDataAndView();
      });
    this.deleteRowValue = null;
  }
  onNoClick() {
    this._dialogref.close("");

  }
}
