import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { ChangeBranchDialogComponent } from './pages/_dialogs/change-branch-dialog/change-branch-dialog.component';
import { GetUserObject } from './pages/_helpers/get-user-object';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public settings: Settings;

  constructor (public appSettings: AppSettings, private _router: Router, private _dialog: MatDialog,) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
  }

  openChangeBranchDialog() {
    const dialogRef = this._dialog.open(ChangeBranchDialogComponent, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result != 'undefined') {
        // GetUserObject.RemoveSession();
        GetUserObject.SetSession(result);
      }
      //this.getUserBranch();
    });
  }
  // printpath(parent: String, config: Route[]) {
  //   for (let i = 0; i < config.length; i++) {
  //     const route = config[i];
  //     if (route.children) {
  //       const currentPath = route.path ? parent + '/' + route.path : parent;
  //       this.printpath(currentPath, route.children);
  //     }
  //   }
  // }
}
