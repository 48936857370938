<!-- <div class="col-xs-12" *ngIf="f.IndividualID.value ==0">
    <nav>
        <ul class="nav nav-pills">
            <li class="m-b-10 cursor-none" routerLinkActive="active">
                <a routerLink="/crm/manage/individual/add-edit-individual/profile" class="pointer-none"
                    queryParamsHandling="merge"><span>Profile</span></a>
            </li>
            <li class="m-b-10 cursor-none" routerLinkActive="active">
                <a routerLink="/crm/manage/individual/add-edit-individual/pictureid" class="pointer-none"
                    queryParamsHandling="merge"><span>Picture
                        ID</span></a>
            </li>
            <li class="m-b-10 cursor-none" routerLinkActive="active">
                <a routerLink="/crm/manage/individual/add-edit-individual/bankinfo" class="pointer-none"
                    queryParamsHandling="merge"><span>Bank
                        Info</span></a>
            <li class="m-b-10 cursor-none" routerLinkActive="active">
                <a routerLink="/crm/manage/individual/add-edit-individual/kycaml" class="pointer-none"
                    queryParamsHandling="merge"><span>KYC /
                        AML</span></a>
            </li>
        </ul>
    </nav>
</div> -->

<!-- <div class="col-xs-12" *ngIf="f.IndividualID.value !=0">
    <nav>
        <ul class="nav nav-pills">
            <li class="m-b-10" routerLinkActive="active">
                <a routerLink="/crm/manage/individual/add-edit-individual/profile"
                    queryParamsHandling="merge"><span>Profile</span></a>
            </li>
            <li class="m-b-10" routerLinkActive="active">
                <a routerLink="/crm/manage/individual/add-edit-individual/pictureid"
                    queryParamsHandling="merge"><span>Picture
                        ID</span></a>
            </li>
            <li class="m-b-10" routerLinkActive="active">
                <a routerLink="/crm/manage/individual/add-edit-individual/bankinfo"
                    queryParamsHandling="merge"><span>Bank
                        Info</span></a>
            <li class="m-b-10" routerLinkActive="active">
                <a routerLink="/crm/manage/individual/add-edit-individual/kycaml" queryParamsHandling="merge"><span>KYC
                        /
                        AML</span></a>
            </li>
        </ul>
    </nav>
</div> -->


<div class="col-xs-12">
    <form [formGroup]="IndividualProfileForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory" for="Salutation">Salutation:</label>
                    <mat-select placeholder="Select Salutation" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.Salutation.errors }" formControlName="Salutation">
                        <mat-option [value]="null" selected>Select</mat-option>
                        <mat-option *ngFor="let item of SalutationDD" [value]="item.LookupID">
                            {{item.LookupName}}
                        </mat-option>
                    </mat-select>
                    <div *ngIf="submitted && f.Salutation.errors" class="invalid-feedback">
                        <div *ngIf="f.Salutation.errors.required">Required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory">First Name:</label>
                    <input type="text" class="form-control" placeholder="First Name" formControlName="FirstName"
                        [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }">
                    <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
                        <div *ngIf="f.FirstName.errors.required">Required</div>
                        <div *ngIf="f.FirstName.hasError('pattern')">pattern mismatch</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="">Last Name:</label>
                    <input type="text" class="form-control" placeholder="Last Name" formControlName="LastName"
                        [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }">
                    <div *ngIf="submitted && f.LastName.errors" class="invalid-feedback">
                        <div *ngIf="f.LastName.errors.required">Required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory" for="Gender">Gender:</label>
                    <mat-select placeholder="Select Gender" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.Gender.errors }" formControlName="Gender">
                        <mat-option [value]="null" selected disabled>Select</mat-option>
                        <mat-option *ngFor="let item of GenderDD" [value]="item.LookupID">
                            {{item.LookupName}}
                        </mat-option>
                    </mat-select>
                    <div *ngIf="submitted && f.Gender.errors" class="invalid-feedback">
                        <div *ngIf="f.Gender.errors.required">Required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory">Date Of Birth:</label>
                    <cxr-prime-calendar formControlName="DateOfBirth"
                        [ngClass]="{ 'is-date-invalid': submitted && f.DateOfBirth.errors }">
                    </cxr-prime-calendar>
                    <div *ngIf="submitted && f.DateOfBirth.errors" class="invalid-feedback">
                        <div *ngIf="f.DateOfBirth.errors.required">Required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory">Nationality:</label>
                    <mat-select placeholder="Select Nationality" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.Nationality.errors }" formControlName="Nationality">
                        <mat-option [value]="null" selected>Select</mat-option>
                        <mat-option *ngFor="let item of countriesList" [value]="item.demonym">
                            {{item.demonym}}
                        </mat-option>
                    </mat-select>
                    <!-- <input type="text" class="form-control" placeholder="Nationality" formControlName="Nationality"
                        [ngClass]="{ 'is-invalid': submitted && f.Nationality.errors }"> -->
                    <div *ngIf="submitted && f.Nationality.errors" class="invalid-feedback">
                        <div *ngIf="f.Nationality.errors.required">Required</div>
                        <!-- <div *ngIf="f.Nationality.hasError('pattern')">pattern mismatch</div> -->
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="mandatory" for="Country of Residence ">Country of Residence:</label>
                    <mat-select placeholder="Select Country" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.CountryOfResidence.errors }"
                        formControlName="CountryOfResidence">
                        <mat-option [value]="null" selected>Select</mat-option>
                        <mat-option *ngFor="let item of countriesList" [value]="item.name">
                            {{item.alpha2Code}} - {{item.name}}
                        </mat-option>
                    </mat-select>
                    <div *ngIf="submitted && f.CountryOfResidence.errors" class="invalid-feedback">
                        <div *ngIf="f.CountryOfResidence.errors.required">Required</div>
                    </div>
                </div>
            </div>



            <!-- <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="">Country of Residence:</label>
                    <input type="text" class="form-control" placeholder="Country of Residence"
                        formControlName="CountryOfResidence"
                        [ngClass]="{ 'is-invalid': submitted && f.CountryOfResidence.errors }">
                    <div *ngIf="submitted && f.CountryOfResidence.errors" class="invalid-feedback">
                        <div *ngIf="f.CountryOfResidence.errors.required">Required</div>
                        <div *ngIf="f.CountryOfResidence.hasError('pattern')">pattern mismatch</div>
                    </div>
                </div>
            </div> -->
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="">Ethnic Spelling:</label>
                    <input type="text" class="form-control" placeholder="Ethnic Spelling"
                        formControlName="EthnicSpelling"
                        [ngClass]="{ 'is-invalid': submitted && f.EthnicSpelling.errors }">
                    <div *ngIf="submitted && f.EthnicSpelling.errors" class="invalid-feedback">
                        <div *ngIf="f.EthnicSpelling.errors.required">Required</div>
                        <div *ngIf="f.EthnicSpelling.hasError('pattern')">pattern mismatch</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="">Occupation:</label>
                    <input type="text" class="form-control" placeholder="Occupation" formControlName="Occupation"
                        [ngClass]="{ 'is-invalid': submitted && f.Occupation.errors }">
                    <div *ngIf="submitted && f.Occupation.errors" class="invalid-feedback">
                        <div *ngIf="f.Occupation.errors.required">Required</div>
                        <div *ngIf="f.Occupation.hasError('pattern')">pattern mismatch</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <div class="form-group form-group-height">
                    <label class="">Job Title/Position:</label>
                    <input type="text" class="form-control" placeholder="Job Title/Position"
                        formControlName="JobPosition" [ngClass]="{ 'is-invalid': submitted && f.JobPosition.errors }">
                    <div *ngIf="submitted && f.JobPosition.errors" class="invalid-feedback">
                        <div *ngIf="f.JobPosition.errors.required">Required</div>
                        <div *ngIf="f.JobPosition.hasError('pattern')">pattern mismatch</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2 offset-lg-1">
                <div class="form-group form-group-height">
                    <label class="" for="Company">Company:</label>
                    <mat-select placeholder="Select Company" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.CompanyID.errors }" formControlName="CompanyID">
                        <mat-option [value]="null" selected>Select</mat-option>
                        <mat-option *ngFor="let item of companiesDD" [value]="item.Value">
                            {{item.Name}}
                        </mat-option>
                    </mat-select>
                    <div *ngIf="submitted && f.CompanyID.errors" class="invalid-feedback">
                        <div *ngIf="f.CompanyID.errors.required">Required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2 offset-lg-1">
                <div class="form-group form-group-height">
                    <label class="" for="Group Type">Group Type:</label>
                    <mat-select placeholder="Select Group Type" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.GroupType.errors }" formControlName="GroupType">
                        <mat-option [value]="null" selected>Select</mat-option>
                        <mat-option *ngFor="let item of GroupTypeDD" [value]="item.LookupID">
                            {{item.LookupName}}
                        </mat-option>

                    </mat-select>
                    <div *ngIf="submitted && f.GroupType.errors" class="invalid-feedback">
                        <div *ngIf="f.GroupType.errors.required">Required</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="form-group form-group-height">
                    <label class="">Social Security/SIN (National ID):</label>
                    <input type="text" class="form-control" placeholder="Social Security/SIN (National ID)"
                        formControlName="NationalID" [ngClass]="{ 'is-invalid': submitted && f.NationalID.errors }">
                    <div *ngIf="submitted && f.NationalID.errors" class="invalid-feedback">
                        <div *ngIf="f.NationalID.errors.required">Required</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-xs-12 m-b-10">
                <button *ngIf="f.IndividualID.value !=0" mat-raised-button color="accent" class="pull-right m-b-10"
                    (click)="nextTab()">
                    <mat-icon>arrow_forward</mat-icon>Next
                </button>
                <button type="submit" color="primary" mat-raised-button class="m-b-10 pull-right m-r-5 btn-success">
                    <i class="fa fa-save m-r-5"></i> <span *ngIf="f.IndividualID.value ==0">Save </span> <span
                        *ngIf="f.IndividualID.value !=0">Update </span> Individual</button>

                <button type="button" color="primary" mat-raised-button class="m-b-10 pull-right m-r-5 btn-success"
                    *ngIf="f.IndividualID.value !=0" (click)="fnCheckAgainstSanctionList()">
                    Check against Sanction List</button>
                <div class="m-b-10 pull-right m-r-10 ">
                    <a (click)="fnGoToSanctionList()" class="a-button">
                        <i *ngIf="warning == false" class="fa fa fa-check fa-2x m-t-5 text-green"></i>
                        <i *ngIf="warning == true" class="fa fa-exclamation-circle fa-2x m-t-5  text-red"
                            aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div> -->
    </form>


    <div class="row m-t-30">
        <div class="col-xs-12 col-md-6 m-b-10">
            <app-generic-phone-numbers [InternalTableName]="TableNames.TableNameIndividual"
                [InternalRecordID]="IndividualID" [PhoneTypeDD]="PhoneTypeDD">
            </app-generic-phone-numbers>
            <!-- <pqgrid [GridID]="GridName" #gridPhoneNumbers [options]="optionsGridPhoneNumbers"></pqgrid> -->
        </div>
        <div class="col-xs-12 col-md-6 m-b-10">
            <app-generic-email [InternalTableName]="TableNames.TableNameIndividual" [InternalRecordID]="IndividualID"
                [EmailTypeDD]="EmailTypeDD"></app-generic-email>
            <!-- <pqgrid [GridID]="GridName" #gridMailIDs [options]="optionsGridMailIDs"></pqgrid> -->
        </div>
        <!-- <div class="col-xs-12 col-md-6 m-b-10">
                <pqgrid [GridID]="GridName" #gridSocialHandle [options]="optionsGridSocialHandle"></pqgrid>
            </div> -->
    </div>

    <div class="row m-t-30">
        <div class="col-xs-12 col-md-6 m-b-10">

            <app-generic-address [countriesList]="countriesList" [hideAddButton]="'true'"
                [InternalTableName]="TableNames.TableNameIndividual" [InternalRecordID]="f.IndividualID.value"
                [AddressTypeDD]="AddressTypeDD"></app-generic-address>

        </div>

        <!-- <div class="col-xs-12 col-md-4 m-b-10">
                <pqgrid [GridID]="GridName" #notesGrid [options]="notesoptions"></pqgrid>
            </div> -->
        <div class="col-xs-12 col-md-6 m-b-10">
            <!-- <cxr-notes [InternalTableName]="TableNames.TableNameIndividual" isForCustomerReport="true"
                [InternalRecordID]="IndividualID">
            </cxr-notes> -->
        </div>


    </div>


    <div class="row">

        <div class="col-xs-12 col-md-6">
            <!-- <pqgrid [GridID]="GridName" #gridSocialHandle [options]="optionsGridSocialHandle"></pqgrid> -->
        </div>

    </div>

    <!--Add edit phone modal pop up-->
    <div bsModal #addEditPhoneModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
        aria-labelledby="dialog-sizes-name1" [config]="{backdrop: 'static'}">
        <form [formGroup]="IndividualPhoneForm" (ngSubmit)="onIndividualPhoneFormSubmit()">
            <bs-modal-backdrop></bs-modal-backdrop>
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="dialog-sizes-name1" class="modal-title pull-left">
                            <span *ngIf="IndividualPhoneF.PhoneID.value == null">Add </span>
                            <span *ngIf="IndividualPhoneF.PhoneID.value != null">Update </span>
                            Phone Number
                        </h4>
                        <button type="button" class="close pull-right" (click)="addEditPhone(null,0)"
                            aria-label="Close">
                            <span aria-hidden="true" class="text-white">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group form-group-height">
                                    <label class="mandatory" for="Phone Type">Phone Type:</label>
                                    <mat-select placeholder="Select Phone Type" class="form-control"
                                        [ngClass]="{ 'is-invalid': phoneSubmitted && IndividualPhoneF.PhoneType.errors }"
                                        formControlName="PhoneType">
                                        <mat-option [value]="null" selected disabled>Select</mat-option>
                                        <mat-option *ngFor="let item of PhoneTypeDD" [value]="item.LookupID">
                                            {{item.LookupName}}
                                        </mat-option>
                                    </mat-select>
                                    <div *ngIf="phoneSubmitted && IndividualPhoneF.PhoneType.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="IndividualPhoneF.PhoneType.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group form-group-height">
                                    <label class="mandatory">Phone Number:</label>
                                    <input type="text" class="form-control" placeholder="Phone" formControlName="Phone"
                                        [ngClass]="{ 'is-invalid': phoneSubmitted && IndividualPhoneF.Phone.errors }">
                                    <div *ngIf="phoneSubmitted && IndividualPhoneF.Phone.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="IndividualPhoneF.Phone.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="row">
                            <div class="col-xs-12">
                                <button mat-raised-button type="submit" class="pull-right btn-success">
                                    <span *ngIf="IndividualPhoneF.PhoneID.value == null">Save </span>
                                    <span *ngIf="IndividualPhoneF.PhoneID.value != null">Update </span>
                                </button>
                                <button mat-raised-button type="button" class="pull-right m-r-5 btn-secondary"
                                    (click)="addEditPhone(null,0)">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!--Add edit Email modal pop up-->
    <div bsModal #addEditEmailModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
        aria-labelledby="dialog-sizes-name1" [config]="{backdrop: 'static'}">
        <form [formGroup]="IndividualEmailForm" (ngSubmit)="onIndividualEmailFormSubmit()">
            <bs-modal-backdrop></bs-modal-backdrop>
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="dialog-sizes-name1" class="modal-title pull-left">
                            <span *ngIf="IndividualEmailF.EmailID.value == null">Add </span>
                            <span *ngIf="IndividualEmailF.EmailID.value != null">Update </span>
                            Email
                        </h4>
                        <button type="button" class="close pull-right" (click)="addEditEmail(null,0)"
                            aria-label="Close">
                            <span aria-hidden="true" class="text-white">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group form-group-height">
                                    <label class="mandatory" for="Email Type">Email Type:</label>
                                    <mat-select placeholder="Select Email Type" class="form-control"
                                        [ngClass]="{ 'is-invalid': emailSubmitted && IndividualEmailF.EmailType.errors }"
                                        formControlName="EmailType">
                                        <mat-option [value]="null" selected disabled>Select</mat-option>
                                        <mat-option *ngFor="let item of EmailTypeDD" [value]="item.LookupID">
                                            {{item.LookupName}}
                                        </mat-option>
                                    </mat-select>
                                    <div *ngIf="emailSubmitted && IndividualEmailF.EmailType.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="IndividualEmailF.EmailType.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group form-group-height">
                                    <label class="mandatory">Email Address:</label>
                                    <input type="text" class="form-control" placeholder="Email Address"
                                        formControlName="EmailAddress"
                                        [ngClass]="{ 'is-invalid': emailSubmitted && IndividualEmailF.EmailAddress.errors }">
                                    <div *ngIf="emailSubmitted && IndividualEmailF.EmailAddress.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="IndividualEmailF.EmailAddress.errors.required">Required</div>
                                        <div
                                            *ngIf="IndividualEmailF.EmailAddress.hasError('invalidEmail') && IndividualEmailF.EmailAddress.touched">
                                            Invalid Email</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" modal-footer">
                        <div class="row">
                            <div class="col-xs-12">
                                <button mat-raised-button type="submit" class="pull-right btn-success">
                                    <span *ngIf="IndividualEmailF.EmailID.value == null">Save </span>
                                    <span *ngIf="IndividualEmailF.EmailID.value != null">Update </span>
                                </button>
                                <button mat-raised-button type="button" class="pull-right m-r-5 btn-secondary"
                                    (click)="addEditEmail(null,0)">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!-- <div bsModal #addEditEmailModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-sizes-name1" [config]="{backdrop: 'static'}">
    <bs-modal-backdrop></bs-modal-backdrop>
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="dialog-sizes-name1" class="modal-title pull-left">Add Email</h4>
                <button type="button" class="close pull-right" (click)="addEditEmailModal.hide()" aria-label="Close">
                    <span aria-hidden="true" class="text-white">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <div class="form-group">
                            <label for="emailType">Type:</label>
                            <select name="" id="" placeholder="Select Email Type" class="form-control">
                                <option value="" selected disabled>Select</option>
                                <option value="">Work</option>
                                <option value="">Home</option>
                            </select>
                        </div>
                    </div>
                    <app-input-feild label="Email" type="text" class="col-xs-12 col-sm-6">
                    </app-input-feild>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-xs-12">
                        <button mat-raised-button (click)="addEditEmailModal.hide()"
                            class="pull-right m-r-5 btn-success">Save</button>
                        <button mat-raised-button (click)="addEditEmailModal.hide()"
                            class="pull-right btn-secondary">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

    <!--Add edit Address modal pop up-->
    <div bsModal #addEditAddressModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
        aria-labelledby="dialog-sizes-name1" [config]="{backdrop: 'static'}">

        <form [formGroup]="IndividualAddressForm" (ngSubmit)="onIndividualAddressFormSubmit()">
            <bs-modal-backdrop></bs-modal-backdrop>
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="dialog-sizes-name1" class="modal-title pull-left">
                            <span *ngIf="IndividualAddressF.AddressID.value == null">Add </span>
                            <span *ngIf="IndividualAddressF.AddressID.value != null">Update </span>
                            Address
                        </h4>
                        <button type="button" class="close pull-right" (click)="addEditAddress(null,0)"
                            aria-label="Close">
                            <span aria-hidden="true" class="text-white">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group form-group-height">
                                    <label class="mandatory" for="Email Type">Address Type:</label>
                                    <mat-select placeholder="Select Address Type" class="form-control"
                                        [ngClass]="{ 'is-invalid': addressSubmitted && IndividualAddressF.AddressType.errors }"
                                        formControlName="AddressType">
                                        <mat-option [value]="null" selected disabled>Select</mat-option>
                                        <mat-option *ngFor="let item of EmailTypeDD" [value]="item.LookupID">
                                            {{item.LookupName}}
                                        </mat-option>
                                    </mat-select>
                                    <div *ngIf="addressSubmitted && IndividualAddressF.AddressType.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="IndividualAddressF.AddressType.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group form-group-height">
                                    <label class="">Street:</label>
                                    <input type="text" class="form-control" placeholder="Enter Street"
                                        formControlName="Street"
                                        [ngClass]="{ 'is-invalid': addressSubmitted && IndividualAddressF.Street.errors }">
                                    <div *ngIf="addressSubmitted && IndividualAddressF.Street.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="IndividualAddressF.Street.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group form-group-height">
                                    <label class="">City / Town:</label>
                                    <input type="text" class="form-control" placeholder="Enter City / Town"
                                        formControlName="City"
                                        [ngClass]="{ 'is-invalid': addressSubmitted && IndividualAddressF.City.errors }">
                                    <div *ngIf="addressSubmitted && IndividualAddressF.City.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="IndividualAddressF.City.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group form-group-height">
                                    <label class="">State / Province:</label>
                                    <input type="text" class="form-control" placeholder="Enter State / Province"
                                        formControlName="StateProvince"
                                        [ngClass]="{ 'is-invalid': addressSubmitted && IndividualAddressF.StateProvince.errors }">
                                    <div *ngIf="addressSubmitted && IndividualAddressF.StateProvince.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="IndividualAddressF.StateProvince.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group form-group-height">
                                    <label class="mandatory" for="Country ">Country:</label>
                                    <mat-select placeholder="Select Country" class="form-control"
                                        [ngClass]="{ 'is-invalid': addressSubmitted && IndividualAddressF.Country.errors }"
                                        formControlName="Country">
                                        <mat-option [value]="null" selected disabled>Select</mat-option>
                                        <mat-option *ngFor="let item of countriesList" [value]="item.name">
                                            {{item.alpha2Code}} - {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                    <div *ngIf="addressSubmitted && IndividualAddressF.Country.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="IndividualAddressF.Country.errors.required">Required</div>

                                    </div>
                                </div>
                            </div>


                            <!-- <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="">Country:</label>
                                <input type="text" class="form-control" placeholder="Enter Country"
                                    formControlName="Country"
                                    [ngClass]="{ 'is-invalid': addressSubmitted && IndividualAddressF.Country.errors }">
                                <div *ngIf="addressSubmitted && IndividualAddressF.Country.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="IndividualAddressF.Country.errors.required">Required</div>
                                </div>
                            </div>
                        </div> -->
                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group form-group-height">
                                    <label class="">Zip Code:</label>
                                    <input type="text" class="form-control" placeholder="Enter Zip Code"
                                        formControlName="ZipCode"
                                        [ngClass]="{ 'is-invalid': addressSubmitted && IndividualAddressF.ZipCode.errors }">
                                    <div *ngIf="addressSubmitted && IndividualAddressF.ZipCode.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="IndividualAddressF.ZipCode.errors.required">Required</div>
                                        <div *ngIf="IndividualAddressF.ZipCode.errors.maxlength">Shouldn't exceed more
                                            than
                                            10 characters</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="row">
                            <div class="col-xs-12">
                                <button mat-raised-button type="submit" class="pull-right btn-success">
                                    <span *ngIf="IndividualAddressF.AddressID.value == null">Save </span>
                                    <span *ngIf="IndividualAddressF.AddressID.value != null">Update </span>
                                </button>
                                <button mat-raised-button type="button" class="pull-right m-r-5 btn-secondary"
                                    (click)="addEditAddress(null,0)">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!--Add edit Social modal pop up-->
    <div bsModal #addEditSocialModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
        aria-labelledby="dialog-sizes-name1" [config]="{backdrop: 'static'}">
        <form [formGroup]="IndividualSocialHandleForm" (ngSubmit)="onIndividualSocialHandleFormSubmit()">
            <bs-modal-backdrop></bs-modal-backdrop>
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="dialog-sizes-name1" class="modal-title pull-left">
                            <span *ngIf="IndividualSocialHandleF.IndividualSocialHandleID.value == null">Add </span>
                            <span *ngIf="IndividualSocialHandleF.IndividualSocialHandleID.value != null">Update </span>
                            Social Handle
                        </h4>
                        <button type="button" class="close pull-right" (click)="addEditSocialHandle(null,0)"
                            aria-label="Close">
                            <span aria-hidden="true" class="text-white">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group form-group-height">
                                    <label class="mandatory" for="">Social Handle Type:</label>
                                    <mat-select placeholder="Select Social Handle Type" class="form-control"
                                        [ngClass]="{ 'is-invalid': socialHandleSubmitted && IndividualSocialHandleF.SocialHandleType.errors }"
                                        formControlName="SocialHandleType">
                                        <mat-option [value]="null" selected disabled>Select</mat-option>
                                        <mat-option *ngFor="let item of SocialHandleTypeDD" [value]="item.LookupID">
                                            {{item.LookupName}}
                                        </mat-option>
                                    </mat-select>
                                    <div *ngIf="socialHandleSubmitted && IndividualSocialHandleF.SocialHandleType.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="IndividualSocialHandleF.SocialHandleType.errors.required">Required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group form-group-height">
                                    <label class="mandatory">URL:</label>
                                    <input type="text" class="form-control" placeholder="Enter URL"
                                        formControlName="SocialHandleURL"
                                        [ngClass]="{ 'is-invalid': socialHandleSubmitted && IndividualSocialHandleF.SocialHandleURL.errors }">
                                    <div *ngIf="socialHandleSubmitted && IndividualSocialHandleF.SocialHandleURL.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="IndividualSocialHandleF.SocialHandleURL.errors.required">Required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="row">
                            <div class="col-xs-12">
                                <button mat-raised-button type="submit" class="pull-right btn-success">
                                    <span *ngIf="IndividualSocialHandleF.IndividualSocialHandleID.value == null">Save
                                    </span>
                                    <span *ngIf="IndividualSocialHandleF.IndividualSocialHandleID.value != null">Update
                                    </span>
                                </button>
                                <button mat-raised-button type="button" class="pull-right m-r-5 btn-secondary"
                                    (click)="addEditSocialHandle(null,0)">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>


    <!--Add edit Note modal pop up-->
    <div bsModal #addEditNoteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
        aria-labelledby="dialog-sizes-name1" [config]="{backdrop: 'static'}">
        <form [formGroup]="IndividualNoteForm" (ngSubmit)="onIndividualNoteFormSubmit()">
            <bs-modal-backdrop></bs-modal-backdrop>
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="dialog-sizes-name1" class="modal-title pull-left">
                            <span *ngIf="IndividualNoteF.NoteID.value == null">Add </span>
                            <span *ngIf="IndividualNoteF.NoteID.value != null">Update </span>
                            Note
                        </h4>
                        <button type="button" class="close pull-right" (click)="addEditNote(null,0)" aria-label="Close">
                            <span aria-hidden="true" class="text-white">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <!-- <div class="col-xs-12 col-sm-6">
                            <div class="form-group form-group-height">
                                <label class="">Branch:</label>
                                <input type="text" class="form-control" placeholder="Enter Branch"
                                    formControlName="BranchID"
                                    [ngClass]="{ 'is-invalid': noteSubmitted && IndividualNoteF.BranchID.errors }">
                                <div *ngIf="noteSubmitted && IndividualNoteF.BranchID.errors" class="invalid-feedback">
                                    <div *ngIf="IndividualNoteF.BranchID.errors.required">Required</div>
                                </div>
                            </div>
                        </div> -->
                            <div class="col-xs-12 col-sm-6">
                                <div class="form-group form-group-height">
                                    <label class="">Note:</label>
                                    <input type="text" class="form-control" placeholder="Enter Note"
                                        formControlName="Notes"
                                        [ngClass]="{ 'is-invalid': noteSubmitted && IndividualNoteF.Notes.errors }">
                                    <div *ngIf="noteSubmitted && IndividualNoteF.Notes.errors" class="invalid-feedback">
                                        <div *ngIf="IndividualNoteF.Notes.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="row">
                            <div class="col-xs-12">
                                <button mat-raised-button type="submit" class="pull-right btn-success">
                                    <span *ngIf="IndividualNoteF.NoteID.value == null">Save
                                    </span>
                                    <span *ngIf="IndividualNoteF.NoteID.value != null">Update
                                    </span>
                                </button>
                                <button mat-raised-button type="button" class="pull-right m-r-5 btn-secondary"
                                    (click)="addEditNote(null,0)">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <!-- <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="dialog-sizes-name1" class="modal-title pull-left">Add Notes</h4>
                <button type="button" class="close pull-right" (click)="addEditNoteModal.hide()" aria-label="Close">
                    <span aria-hidden="true" class="text-white">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <app-input-feild label="Branch" type="text" class="col-xs-12 col-sm-6"></app-input-feild>
                    <app-input-feild label="Created By" type="text" class="col-xs-12 col-sm-6"></app-input-feild>
                    <app-input-feild label="Date Stamp" type="text" class="col-xs-12 col-sm-6"></app-input-feild>
                    <app-input-feild label="Pop" type="text" class="col-xs-12 col-sm-6"></app-input-feild>
                    <app-input-feild label="AML" type="text" class="col-xs-12 col-sm-6"></app-input-feild>
                    <app-input-feild label="Notes" type="text" class="col-xs-12 col-sm-6"></app-input-feild>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-xs-12">
                        <button mat-raised-button (click)="addEditNoteModal.hide()"
                            class="pull-right btn-success">Save</button>
                        <button mat-raised-button (click)="addEditNoteModal.hide()"
                            class="pull-right m-r-5 btn-secondary">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    </div>




    <!--Confirmation modal pop up-->
    <div bsModal #confirmationModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
        [config]="{backdrop: 'static'}">
        <bs-modal-backdrop></bs-modal-backdrop>
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 id="dialog-sizes-name1" class="modal-title pull-left">Confirmation</h4>
                    <button type="button" class="close pull-right" (click)="hideConfirmationModal()" aria-label="Close">
                        <span aria-hidden="true" class="text-white">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <h5>Are you sure you want to delete.?</h5>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-xs-12">
                            <button mat-raised-button type="submit" (click)="deleteRecord();hideConfirmationModal()"
                                class="pull-right" color="warn">Confirm</button>
                            <button mat-raised-button (click)="hideConfirmationModal()"
                                class="pull-right m-r-5 btn-secondary">Decline</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
