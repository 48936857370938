import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cxr-merge-accounts-confirmation-dialog',
  templateUrl: './merge-accounts-confirmation-dialog.component.html',
  styleUrls: ['./merge-accounts-confirmation-dialog.component.scss']
})
export class MergeAccountsConfirmationDialogComponent {

  constructor (
    private _dialogRef: MatDialogRef<MergeAccountsConfirmationDialogComponent>,

  ) {
  }

  close(): void {
    this._dialogRef.close();
  }
  confirm(): void {
    this._dialogRef.close(true);
  }
}
