<div fxLayout="row wrap" class="breadcrumb" style="padding-bottom: 8px !important;" *ngIf="router.url != '/'">
    <div fxFlex="100" class="">
        <h3 style="margin-top:10px;margin-bottom:10px;">{{Header}}</h3>
        <mat-card fxLayout="row" class="breadcrumb helptext bc">
            <div fxFlex="100" class="flex-p">
                <span class="page-title nomargin">{{description}}</span>
                <div fxLayout="row" style="float: right !important;" class="pull-right hidden-xs">
                    <!-- <a *ngIf="router.url != '/'" routerLink="/" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center"
                        (click)="closeSubMenus()">
                        <span class="breadtitle breadcrumb-title">Home</span>
                    </a> -->
                    <div *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item" fxLayout="row"
                        fxLayoutAlign="start center">
                        <a [hidden]="i == (breadcrumbs.length - 1)"
                            [routerLink]="[breadcrumb.url]">{{breadcrumb.name}}</a>
                        <span [hidden]="i != (breadcrumbs.length - 1)" class="breadcrumb-title active margin-right">{{breadcrumb.name
                            | titlecase}}</span>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>



<!-- <div fxLayout="row wrap" *ngIf="router.url != '/'">
    <div fxFlex="100" class="flex-p">
        <mat-card fxLayout="row" class="breadcrumb">
            <a *ngIf="router.url != '/'" routerLink="/" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center" (click)="closeSubMenus()">
                <mat-icon>home</mat-icon>
                <span class="breadcrumb-title">Home</span>
            </a>
            <div *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center">                 
                <a [hidden]="i == (breadcrumbs.length - 1)" [routerLink]="[breadcrumb.url]">{{breadcrumb.name}}</a>   
                <span [hidden]="i != (breadcrumbs.length - 1)" class="breadcrumb-title active">{{breadcrumb.name}}</span>
            </div> 
        </mat-card>
    </div> 
</div>
 -->